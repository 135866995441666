import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { useSalesModeModification } from "@hooks/useSalesModeModification";

import { IdleDetection } from "@components/IdleDetection";
import { OrderTakingBackgroundWrapper } from "@components/OrderTaking/common/OrderTakingBackgroundWrapper";
import { MemoizedCartLayout } from "@components/OrderTaking/Components/Cart/CartLayout";
import { CategoriesSwiperWrapperMemoised } from "@components/OrderTaking/Components/Categories/CategoriesSwiperWrapper";
import AllergensDialog from "@components/OrderTaking/Components/Dialogs/Allergen/AllergensDialog";
import { MemoisedOrderTakingTopBanner } from "@components/OrderTaking/Components/OrderTakingTopBanner";
import ProductWrapper from "@components/OrderTaking/Components/Products/ProductWrapper";
import { TagsSwiperWrapperMemoised } from "@components/OrderTaking/Components/Tags/TagsSwiperWrapper";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import {
    kioskStore,
    setIsAnimationTimeOut,
    setIsStandbyModalOpened,
} from "@pages/Kiosk/store";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

import CategoriesBreadCrumb from "./CategoriesBreadCrumb";
import { HelloUser } from "./helloUser";
import "./index.css";

import "swiper/swiper.min.css";

const parentVariant = {
    initial: { opacity: 1 },
    animate: { opacity: 1, transition: { staggerChildren: 3 } },
};

export default function OrderTakingScreen() {
    useSalesModeModification();
    const productImgWidth = "245px";
    const {
        timeout,
        isStandbyModalOpened,
        isUserAccountModalOpened,
        project: { files: images },
        isLogoutProblemModalOpened,
    } = useSnapshot(kioskStore);
    const {
        isOrderTakingTopBannerActive,
        isOrderTakingTagsActive,
        userAccountMessage,
        globalColor,
        isHeaderActive,
        isBackgroundActive,
    } = useSelector((state) => state.settingSlice.generalSetting, shallowEqual);

    const { isSelectedCategoryActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );

    const [containerRef, setContainerRef] = React.useState();

    const [isAllergenDialogOpened, setisAllergenDialogOpened] =
        React.useState(false);

    const backgroundImage = images.find((image) => {
        return image.key === "background" && image.name === "orderTaking";
    });

    const imageContent = getImageContentById(backgroundImage.id, images);

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;

    const handleAllergenDialogOnCLickEvent = () => {
        setisAllergenDialogOpened(!isAllergenDialogOpened);
    };

    function setCardColor(newColor) {
        document.documentElement.style.setProperty("--global-color", newColor);
    }

    React.useEffect(() => {
        setCardColor(globalColor);
    }, [globalColor]);

    React.useEffect(() => {
        document.documentElement.style.setProperty(
            "--width-product-img",
            productImgWidth
        );
        setIsStandbyModalOpened(false);
    }, []);
    React.useEffect(() => {
        setIsAnimationTimeOut(false);

        const timer = setTimeout(() => {
            setIsAnimationTimeOut(true);
        }, 600);
        return () => clearTimeout(timer);
    }, []);
    return (
        <AnimatePresence>
            <div ref={(ref) => setContainerRef(ref)}>
                {/* <IdleDetection
                    timeout={timeout * 1000}
                    once={true}
                    onIdle={() => {
                        setIsStandbyModalOpened(true);
                    }}
                /> */}
                {containerRef ? (
                    isStandbyModalOpened === true ? (
                        <StandbyModalWrapper containerRef={containerRef} />
                    ) : null
                ) : null}
                {containerRef ? (
                    isUserAccountModalOpened === true ? (
                        <UserAccountModalWrapper containerRef={containerRef} />
                    ) : null
                ) : null}
                {isLogoutProblemModalOpened === true ? (
                    <LogoutProblemModal />
                ) : null}
                {isAllergenDialogOpened && (
                    <AllergensDialog
                        open={isAllergenDialogOpened}
                        onClose={handleAllergenDialogOnCLickEvent}
                        globalColor={globalColor}
                    />
                )}

                <motion.div
                    variants={parentVariant}
                    animate="animate"
                    className="order-taking-container order-taking-layout"
                >
                    {isOrderTakingTopBannerActive ? (
                        <header className="order-taking-header">
                            <MemoisedOrderTakingTopBanner />
                        </header>
                    ) : null}
                    {isHeaderActive && (
                        <div className="order-taking-user">
                            <HelloUser
                                userAccountMessage={userAccountMessage}
                                style={{ gridArea: "user user" }}
                                handleAllergenDialogOnCLickEvent={
                                    handleAllergenDialogOnCLickEvent
                                }
                            />
                            <hr className="separation-line" />
                        </div>
                    )}
                    <nav
                        id="categories"
                        style={{ gridArea: "categories" }}
                        className="h-100"
                    >
                        <CategoriesSwiperWrapperMemoised />
                    </nav>
                    <div style={{ gridArea: "headertitle" }}>
                        {isSelectedCategoryActive && <CategoriesBreadCrumb />}

                        {isOrderTakingTagsActive ? (
                            <TagsSwiperWrapperMemoised />
                        ) : null}
                        <hr className="separation-line  mx-1" />
                    </div>
                    (
                    <section id="products" style={{ gridArea: "products" }}>
                        <OrderTakingBackgroundWrapper
                            filename={imageSrc}
                            isBackgroundActive={isBackgroundActive}
                        >
                            <ProductWrapper />
                        </OrderTakingBackgroundWrapper>
                    </section>
                    )
                    <footer id="usual-footer">
                        <MemoizedCartLayout />
                    </footer>
                </motion.div>
            </div>
        </AnimatePresence>
    );
}
