import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    kioskStore,
    setChosenTransition,
    setIsSubStepActionButtonsActive,
    setIsSubStepIconActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepLogoActive,
    setIsSubStepTopBannerActive,
    setKeyboardInputsValues,
    setNumericKeyboardInputValue,
    setPseudoNameValue,
    setSubStepActionButtons,
    setSubStepIconId,
    setSubStepInformationMessageItems,
} from "@pages/Kiosk/store";

import { EmailStep } from "../EmailStep";
import { LoyaltyCardStep } from "../LoyaltyCardStep";
import { PhoneNumberStep } from "../PhoneNumberStep";

export function AuthenticationModeConsommation(): JSX.Element {
    const {
        selectedAuthMode,
        project: {
            template: {
                pages: { subSteps },
            },
        },
    } = useSnapshot(kioskStore);
    const { transitions } = useSnapshot(store);

    React.useEffect(() => {
        setIsSubStepIconActive(
            // eslint-disable-next-line
            (subSteps[selectedAuthMode] as PageType).header.icon!.active
        );
        setIsSubStepInformationMessageActive(
            (subSteps[selectedAuthMode] as PageType).header.informationMessage
                .active
        );
        setSubStepInformationMessageItems(
            (subSteps[selectedAuthMode] as PageType).header.informationMessage
                .languages
        );
        setIsSubStepTopBannerActive(
            (subSteps[selectedAuthMode] as PageType).topBanner.active
        );
        setIsSubStepLogoActive(
            (subSteps[selectedAuthMode] as PageType).topBanner.logo.active
        );
        setIsSubStepActionButtonsActive(
            (subSteps[selectedAuthMode] as PageType).actionsButtons.active
        );
        setSubStepActionButtons(
            (subSteps[selectedAuthMode] as PageType).actionsButtons.items
        );

        setSubStepIconId(
            // eslint-disable-next-line
            (subSteps[selectedAuthMode] as PageType).header.icon!.id
        );

        setChosenTransition(
            transitions[(subSteps[selectedAuthMode] as PageType).animation]
        );
        setNumericKeyboardInputValue("");
        setPseudoNameValue("");
        setKeyboardInputsValues({});
    }, []);

    return (
        <React.Fragment>
            {selectedAuthMode === "phoneNumber" ? (
                <PhoneNumberStep />
            ) : selectedAuthMode === "emailAndPassword" ? (
                <EmailStep />
            ) : (
                <LoyaltyCardStep />
            )}
        </React.Fragment>
    );
}
