import {
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    CATEGORY_TYPE,
    ITEM_TYPE,
    NO_ThANKS_CARD,
    REDIRECTION,
} from "@constants";
import { Box, Card } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { areEqual } from "react-window";

import AdditionalSale from "@components/OrderTaking/Components/Dialogs/AdditionalSale";
import AlertDialog from "@components/OrderTaking/Components/Dialogs/AlertDialog/AlertDialog";
import NutritionalInformationDialog from "@components/OrderTaking/Components/Dialogs/NutritionalInformation/NutritionalInformationDialog";
import ProductSizeDialog from "@components/OrderTaking/Components/Dialogs/ProductSizeDialog";
import { RemoveProductConfirmation } from "@components/OrderTaking/Components/Dialogs/RemoveProductConfirmation";
import { WorkflowDialog } from "@components/OrderTaking/Components/Dialogs/WorkflowDialog";
import {
    groupWorkflowShopCard,
    orderItemsToDelete,
    useToggle,
} from "@components/OrderTaking/Helpers";
import {
    addToOrder,
    addToOrderworkflow,
    addWorkFlowItemShopCart,
    ClearWorkflowItem,
    memoizedGlobalAllergens,
    removeFromOrder,
    removeFromSelectedAdditionalItems,
    removeFromWorkFlowItemShopCart,
    selectedAdditionalItems,
    SelectProduct,
    setActiveCategoryParent,
    setAdditionalItems,
    setIsModification,
    setiuudWorkflow,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { NoThanks } from "../Cart";
import { animatePicture, animatePicturePrm } from "./animation";
import { OutOfStock } from "./component";
import ComponentRender from "./ComponentRender";
import {
    canAddItemToShopCard,
    getItemPrice,
    haveAllergens,
    useAdditionnalSaleItems,
    useAddNbrOfChoiceStep,
    useNextStep,
    useRemoveNbrOfChoiceStep,
} from "./hooks";
import "./index.css";

export default function CardProduct({ product, listworkflow, orderItems }) {
    let listItemsToBeDeleted = [];
    const dispatch = useDispatch();
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const productId = uuid();
    const removeNbrOfChoiceStep = useRemoveNbrOfChoiceStep(listworkflow);
    const NextStep = useNextStep(listworkflow);
    const addNbrOfChoiceStep = useAddNbrOfChoiceStep(
        listworkflow,
        product,
        AddWorkFlowItemShopCartData
    );
    const [isWorkflowDialogOpened, setIsWorkflowDialogOpened] =
        React.useState(false);

    const [
        isRemoveProductConfirmationDialogOpened,
        setIsRemoveProductConfirmationDialogOpened,
    ] = useToggle();

    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const productType =
        product?.isRedirect && listworkflow
            ? REDIRECTION
            : product?.type === BASIC_COMPOSITION
            ? BASIC_COMPOSITION
            : product?.type === NO_ThANKS_CARD
            ? NO_ThANKS_CARD
            : "";

    const globalAllergens = useSelector((state) =>
        memoizedGlobalAllergens(state)
    );

    const [
        isAllergenConfirmationDialogOpened,
        setIsAllergenConfirmationDialogOpened,
    ] = useToggle();

    const [
        isNutritionalInformationDialogOpen,
        setIsNutritionalInformationDialogOpen,
    ] = useToggle();

    const isAllergenIconActive = haveAllergens(product, globalAllergens);
    const [isAdditionalSaleOpen, SetIsAdditionalSaleOpen] = useToggle();

    const [isProductSizeDialogOpen, setIsProductSizeDialogOpen] = useToggle();

    const { isPrm, selectedSaleModeId } = useSnapshot(kioskStore);
    const itemPrice = getItemPrice(
        workflowData,
        product,
        selectedSaleModeId,
        orderWorkflowItemSlice?.item
    );

    let initialItemOrder =
        product.type === ITEM_TYPE
            ? {
                  iuud: product.iuud,
                  name:
                      product?.name?.content !== undefined
                          ? product.name.content
                          : product.title,
                  quantity: 1,
                  price: itemPrice.price,

                  specialPrice: product.specialPrice,
                  priceOfItemInStep: product.priceOfItemInStep,
                  urlImage: product.image,
                  modifier: product.modifier,
                  reference: product.reference,

                  categoryParent: product.parentCategory,
                  categoryVisibility: product.categoryParent
                      ? globalcard.categories[product.categoryParent]
                            ?.KioskVisibility
                      : {},
                  categoryTitle: product.categoryTitle,
                  subcategoryTitle: product.subcategoryTitle,
                  fidelity: product.fidelity,
                  tva: product.price?.saleModeVatRates[selectedSaleModeId]
                      ? product.price?.saleModeVatRates[selectedSaleModeId]
                      : 0,
                  iuudworkflow: "",
                  workflow: {},
                  shopCart: {},
                  isModification: false,
                  selectedOptions: [],
                  selectedOptionsUuid: [],
                  date: Date.now(),
                  advancedItemPrice: product.price,
                  productVisibility: product.KioskVisibility,
                  haveWorkflow: product.isMenu,
                  haveBasicComposition: product.haveBasicComposition,
              }
            : {
                  iuud: product.iuud,
                  name:
                      product?.name?.content !== undefined
                          ? product.name.content
                          : product.title,
                  quantity: 1,
              };

    const { additionalSale } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );

    const getItemQuantityInStep = () => {
        let quantity = 0;
        ExistOrderItems = [];
        if (Object.keys(orderWorkflowItemSlice.item.shopCart).length > 0) {
            let OrderItemsworkflow = listworkflow.workflow[
                listworkflow.index
            ].path.reduce((prev, key) => {
                return prev[key.name] || {};
            }, orderWorkflowItemSlice.item.shopCart);

            if (Object.keys(OrderItemsworkflow).length > 0) {
                listItemsToBeDeleted = Object.keys(OrderItemsworkflow).reduce(
                    (prev, curr) => {
                        if (OrderItemsworkflow[curr].iuud === product.iuud) {
                            quantity += OrderItemsworkflow[curr].quantity;
                            return {
                                ...prev,
                                [curr]: OrderItemsworkflow[curr],
                            };
                        }
                        return { ...prev };
                    },
                    {}
                );
                ExistOrderItems = groupWorkflowShopCard(listItemsToBeDeleted);
                listItemsToBeDeleted = orderItemsToDelete(listItemsToBeDeleted);
            }
        }

        return quantity;
    };
    const selectedAdditionnalItem = React.useCallback(() => {
        return additionalSale?.selectedItems?.filter(
            (item) => item.iuud === product.iuud
        );
    }, [additionalSale?.selectedItems]);

    // here is items founded many times on order with different workflow but same itemIuud
    let ExistOrderItems = product.isAdditionnalProduct
        ? selectedAdditionnalItem()
        : orderItems?.filter((orderItem) => orderItem.iuud === product.iuud);
    const additionalItemsQuantityChosen = () => {
        const additionnalItem = additionalSale?.selectedItems?.filter(
            (item) => item.iuud === product.iuud
        );
        return additionnalItem.length > 0 ? additionnalItem[0].quantity : 0;
    };
    const ItemQuantity = listworkflow
        ? getItemQuantityInStep()
        : product.isAdditionnalProduct
        ? additionalItemsQuantityChosen()
        : ExistOrderItems?.reduce((prev, curr) => prev + curr.quantity, 0);

    const isModifier =
        product.type === ITEM_TYPE && product.haveWorkflow
            ? true
            : product.haveBasicComposition;

    const quantity =
        ExistOrderItems.length > 0 ||
        listworkflow ||
        product.isAdditionnalProduct
            ? ItemQuantity
            : 0;

    const canAddItem = canAddItemToShopCard(
        quantity,
        product,
        workflowData,
        orderWorkflowItemSlice
    );

    const opacity =
        (listworkflow &&
            listworkflow?.workflow[listworkflow.index]?.NbrOfChoices ===
                listworkflow?.workflow[listworkflow.index]?.maxNbrOfChoices &&
            listworkflow?.workflow[listworkflow.index].type !==
                BASIC_COMPOSITION_TYPE) ||
        !canAddItem
            ? "0.2"
            : "1"; //quantity === product.maxQuantity ? "0.2" : "1";

    function handleRemoveProductFromOrder() {
        if (listworkflow) {
            const data = {
                [product.title]: {
                    name: product.title,
                    type: ITEM_TYPE,
                    quantity: 1,
                    image: product.image,
                    path: [...product.path],
                    price: product.price.content,
                },
            };

            let path = [];
            data[product.title].path.forEach((elm) => {
                elm?.name ? path.push(elm.name) : path.push(elm);
            });

            if (
                quantity > 1 &&
                (ExistOrderItems.length > 1 || product.modifier)
            ) {
                handleRemoveProductConfirmation();
            } else {
                dispatch(
                    removeFromWorkFlowItemShopCart(listItemsToBeDeleted[0])
                );
            }

            removeNbrOfChoiceStep(listworkflow);
        } else {
            !product.isAdditionnalProduct && ExistOrderItems.length > 1
                ? handleRemoveProductConfirmation()
                : product.isAdditionnalProduct
                ? dispatch(
                      removeFromSelectedAdditionalItems(ExistOrderItems[0])
                  )
                : dispatch(removeFromOrder(ExistOrderItems[0]));
        }
    }
    // ! add product to basket
    function handleAddProductToShopCart() {
        if (product.isAdditionnalProduct) {
            dispatch(selectedAdditionalItems(initialItemOrder));
            return;
        }
        dispatch(addToOrder(initialItemOrder));

        // AdditionalSale;
        if (product.haveAdditionalSale) {
            addAdditionnalItemToOrder();
        }

        const clonedImage = isPrm
            ? animatePicturePrm(productId)
            : animatePicture(productId);

        let timer = setTimeout(() => {
            clonedImage.remove();
        }, 1490);

        return () => clearTimeout(timer);
    }

    function addAdditionnalItemToOrder() {
        const additionalSale = useAdditionnalSaleItems(globalcard, product);
        if (additionalSale?.listItems?.length > 0) {
            dispatch(setAdditionalItems(additionalSale));
            SetIsAdditionalSaleOpen();
        }
    }

    function AddWorkFlowItemShopCartData(
        copyworkflowData,
        withSpecialPrice,
        withStepPrice
    ) {
        let title = product.title;
        let path = [...product.path];
        dispatch(setiuudWorkflow(""));
        const currentItemPriceOptions = JSON.parse(
            localStorage.getItem("currentItemPriceOptions")
        );

        let newPrice = itemPrice.price;
        let selectedOptions = [];
        let selectedOptionsUuid = [];
        if (currentItemPriceOptions?.selectedOptions?.length > 0) {
            selectedOptions = currentItemPriceOptions.selectedOptions;
            selectedOptionsUuid = currentItemPriceOptions.selectedOptionsUuid;
            newPrice = currentItemPriceOptions.newPrice;
        }

        if (Object.keys(orderWorkflowItemSlice.item.shopCart).length > 0) {
            let objectTostep = product.path.reduce((prev, key) => {
                return prev[key.name] || {};
            }, orderWorkflowItemSlice.item.shopCart);

            if (Object.keys(objectTostep).length > 0) {
                let newId = "|" + uuid();
                if (product.modifier) {
                    title += newId;
                    path.pop();
                    path.push({
                        name: title,
                        iuud: product.iuud,
                        type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                    });
                    // eslint-disable-next-line no-undef
                    dispatch(setiuudWorkflow(newId));
                } else {
                    title += newId;
                    path.pop();
                    path.push({
                        name: title,
                        iuud: product.iuud,
                        type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                    });
                }

                // if (
                //     !isEqual(objectTostep.selectedOptions, selectedOptions)
                //     //  ||
                //     // newPrice !== objectTostep.price / objectTostep.quantity
                // ) {
                //     title += newId;
                //     path.pop();
                //     path.push({
                //         name: title,
                //         iuud: product.iuud,
                //         type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                //     });
                // }
            }
        }

        const data = {
            [title]: {
                name: product.title,
                iuud: product.iuud,
                categoryParent: product.parentCategory,
                categoryTitle: product.categoryTitle,
                subcategoryTitle: product.subcategoryTitle,
                categoryVisibility: product.categoryParent
                    ? globalcard.categories[product.categoryParent]
                          ?.KioskVisibility
                    : {},

                reference: product.reference,
                type: ITEM_TYPE,
                quantity: 1,
                urlImage: product.image,
                path: [...path],
                price: newPrice,
                specialPrice: product.specialPrice,
                priceOfItemInStep: product.priceOfItemInStep,
                fidelity: product.fidelity,
                modifier: product?.modifier,
                workflow: [...copyworkflowData],
                isModification: false,
                selectedOptions,
                selectedOptionsUuid,
                tva: product.price?.saleModeVatRates[selectedSaleModeId]
                    ? product.price?.saleModeVatRates[selectedSaleModeId]
                    : 0,
                withSpecialPrice,
                withStepPrice,
                shopCart: {},
                date: Date.now(),
                priceType: withSpecialPrice ? 1 : withStepPrice ? 2 : 3,
                advancedItemPrice: product.price,
                stepTitle: product.stepTitle ? product.stepTitle : "",
                productVisibility: product.KioskVisibility,
                haveWorkflow: product.isMenu,
                haveBasicComposition: product.haveBasicComposition,
            },
        };

        dispatch(addWorkFlowItemShopCart(data[title]));

        // if (isAddProductConfirmationDialogOpened) {
        //     setIsAddProductConfirmationDialogOpened(false);
        // }

        // AdditionalSale;
        if (product.haveAdditionalSale) {
            addAdditionnalItemToOrder();
        }
    }

    function handleRemoveProductConfirmation() {
        setIsRemoveProductConfirmationDialogOpened(
            !isRemoveProductConfirmationDialogOpened
        );
    }

    const handleAddProductClick = () => {
        if (opacity === "0.2") return; //in case workflow step  nbr of choice = max click
        if (product.type === ITEM_TYPE) {
            if (
                product?.optionsKiosk?.showOptions &&
                listworkflow === undefined
            ) {
                setIsProductSizeDialogOpen();
                return;
            }
        }

        isModifier && product.haveWorkflow
            ? handleWorkDialogOnCLickEvent()
            : handleProductOnClickEvent();
    };

    const handleValidateOption = () => {
        const currentItemPriceOptions =
            localStorage.getItem("currentItemPriceOptions") !== null
                ? JSON.parse(localStorage.getItem("currentItemPriceOptions"))
                : {
                      selectedOptions: [],
                      selectedOptionsUuid: [],
                      newPrice: 0,
                      tva: 0,
                  };

        if (currentItemPriceOptions.selectedOptions.length > 0) {
            initialItemOrder = {
                ...initialItemOrder,
                selectedOptions: currentItemPriceOptions.selectedOptions,
                selectedOptionsUuid:
                    currentItemPriceOptions.selectedOptionsUuid,
                price: currentItemPriceOptions.newPrice,
                tva: product.price?.saleModeVatRates[selectedSaleModeId]
                    ? product.price?.saleModeVatRates[selectedSaleModeId]
                    : 0,
            };
        }

        product.haveWorkflow || product.haveBasicComposition
            ? handleWorkDialogOnCLickEvent()
            : handleProductOnClickEvent();
    };

    function handleProductOnClickEvent() {
        if (listworkflow) {
            if (
                listworkflow.workflow[listworkflow.index].maxNbrOfChoices >
                listworkflow.workflow[listworkflow.index].NbrOfChoices
            ) {
                addNbrOfChoiceStep(
                    listworkflow.workflow[listworkflow.index],
                    product,
                    AddWorkFlowItemShopCartData
                );
            } else {
                NextStep(listworkflow);
            }
        } else {
            /** sous categories */
            if (product.type === CATEGORY_TYPE) {
                dispatch(setActiveCategoryParent(product.iuud));
            } else {
                handleAddProductToShopCart();
            }
        }
    }

    const handleAllergenConfirmationDialogOnCLickEvent = () => {
        setIsAllergenConfirmationDialogOpened(
            !isAllergenConfirmationDialogOpened
        );
    };

    const handleNutritionalInformationDialogOnCloseEvent = () => {
        setIsNutritionalInformationDialogOpen(
            !isNutritionalInformationDialogOpen
        );
    };

    const handleProductSizeDialogOnCloseEvent = (_event, reason) => {
        if (reason && reason == "backdropClick") return;
        setIsProductSizeDialogOpen(!isProductSizeDialogOpen);
    };

    function handleWorkDialogOnCLickEvent() {
        if (listworkflow) {
            addNbrOfChoiceStep(
                listworkflow.workflow[listworkflow.index],
                product,
                AddWorkFlowItemShopCartData
            );
            if (product.isRedirect) {
                const data = {
                    [product.title]: {
                        iuud: product.iuud,
                        type: CATEGORY_TYPE,
                        image: product.image,
                        path: [...product.path],
                    },
                };
                dispatch(addWorkFlowItemShopCart(data[product.title]));
            }
            dispatch(SelectProduct(product));
        } else {
            setIsWorkflowDialogOpened(true);
            dispatch(ClearWorkflowItem());
            dispatch(setIsModification(false));

            dispatch(addToOrderworkflow(initialItemOrder));

            dispatch(setWorkflowData([]));
            dispatch(SelectProduct(product));
        }
    }

    const handleWorkDialogCloseEvent = (canAdditionnal) => {
        if (orderWorkflowItemSlice.item.modifier && canAdditionnal) {
            const additionalSale = useAdditionnalSaleItems(
                globalcard,
                orderWorkflowItemSlice.item
            );
            if (additionalSale?.listItems?.length > 0) {
                dispatch(setAdditionalItems(additionalSale));
                SetIsAdditionalSaleOpen();
            }
        }

        dispatch(ClearWorkflowItem());
        dispatch(SelectProduct({}));
        dispatch(setWorkflowData([]));
        setIsWorkflowDialogOpened(!isWorkflowDialogOpened);
    };

    // const isLoading = false;
    // isOrderLoading ||
    // (listworkflow &&
    //     Object.keys(listworkflow).length > 0 &&
    //     isWorkflowLoading);
    return (
        <React.Fragment>
            {isAdditionalSaleOpen && (
                <AdditionalSale
                    open={isAdditionalSaleOpen}
                    onClose={SetIsAdditionalSaleOpen}
                />
            )}
            {isWorkflowDialogOpened && (
                <WorkflowDialog
                    open={isWorkflowDialogOpened}
                    onClose={handleWorkDialogCloseEvent}
                    handleProductOnClickEvent={handleProductOnClickEvent}
                    isAllergenIconActive={isAllergenIconActive}
                />
            )}
            {isRemoveProductConfirmationDialogOpened &&
                ExistOrderItems.length > 0 && (
                    <RemoveProductConfirmation
                        open={isRemoveProductConfirmationDialogOpened}
                        onClose={handleRemoveProductConfirmation}
                        items={ExistOrderItems}
                        isWorkflow={listworkflow ? true : false}
                    />
                )}
            {/* 
            {isAddProductConfirmationDialogOpened && (
                <AddCompoundProductConfirmation
                    open={isAddProductConfirmationDialogOpened}
                    onClose={() => {
                        setIsAddProductConfirmationDialogOpened(false);
                    }}
                    Image={product.image}
                    handleValidationOnClickEvent={() => {
                        setIsAddProductConfirmationDialogOpened(false);
                        if (listworkflow) {
                            isAddProductConfirmed = true;
                            addNbrOfChoiceStep(
                                listworkflow.workflow[listworkflow.index]
                            );
                        } else {
                            setIsAddProductConfirmationDialogOpened(false);
                            dispatc'1'h(addToOrder(ExistOrderItems[0]));
                        }
                    }}
                    handleOnCancelAddConfirmation={() => {
                        isAddProductConfirmed = false;
                        setIsAddProductConfirmationDialogOpened(false);

                        handleWorkDialogOnCLickEvent();

                        setIsAddProductConfirmationDialogOpened(false);
                    }}
                />
            )} */}
            {isAllergenConfirmationDialogOpened && (
                <AlertDialog
                    open={isAllergenConfirmationDialogOpened}
                    onClose={handleAllergenConfirmationDialogOnCLickEvent}
                    handleProductOnClickEvent={handleAddProductClick}
                    handleProductSizeDialogOnCloseEvent={
                        handleProductSizeDialogOnCloseEvent
                    }
                    product={product}
                    isModifier={isModifier}
                    handleValidateOption={handleValidateOption}
                />
            )}
            {isNutritionalInformationDialogOpen && (
                <NutritionalInformationDialog
                    open={isNutritionalInformationDialogOpen}
                    onClose={handleNutritionalInformationDialogOnCloseEvent}
                    product={{
                        ...product,
                        price: { ...product.price, content: itemPrice.price },
                    }}
                    handleProductOnClickEvent={handleAddProductClick}
                    isAllergenIconActive={isAllergenIconActive}
                    handleAllergenConfirmationDialogOnCLickEvent={
                        handleAllergenConfirmationDialogOnCLickEvent
                    }
                />
            )}
            {isProductSizeDialogOpen && (
                <ProductSizeDialog
                    open={isProductSizeDialogOpen}
                    onClose={handleProductSizeDialogOnCloseEvent}
                    product={{
                        ...product,
                        price: { ...product.price, content: itemPrice.price },
                    }}
                    isModifier={isModifier}
                    handleValidateOption={handleValidateOption}
                />
            )}

            <Box
                className={`${!isPrm && "card_Height"} centered-block`}
                style={{ height: "100%" }}
            >
                <OutOfStock isOutOfStock={product.outOfStock} />
                {productType === NO_ThANKS_CARD ? (
                    <NoThanks />
                ) : (
                    <CardActionArea
                        style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "15px",
                        }}
                    >
                        <Card
                            key={product.iuud}
                            style={{
                                background:
                                    product.outOfStock &&
                                    "#A8A8A8 0% 0% no-repeat padding-box",
                                opacity: product.outOfStock && "0.3",
                                borderRadius: "15px",
                                justifyContent: "space-between",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className={`w-100 h-100 ${isPrm && "pmr-grid"} ${
                                productType === BASIC_COMPOSITION &&
                                product.basicCompositionActive &&
                                "basic_compositionActive"
                            } ${
                                productType === BASIC_COMPOSITION &&
                                !product.basicCompositionActive &&
                                "basic_compositionInactive"
                            }`}
                        >
                            <ComponentRender
                                product={{
                                    ...product,
                                    price: {
                                        ...product.price,
                                        content: itemPrice.price,
                                    },
                                }}
                                productType={productType}
                                isAllergenIconActive={isAllergenIconActive}
                                setIsAllergenConfirmationDialogOpened={
                                    setIsAllergenConfirmationDialogOpened
                                }
                                handleAddProductClick={handleAddProductClick}
                                productId={productId}
                                handleProductSizeDialogOnCloseEvent={
                                    handleProductSizeDialogOnCloseEvent
                                }
                                handleRemoveProductFromOrder={
                                    handleRemoveProductFromOrder
                                }
                                opacity={opacity}
                                quantity={quantity}
                                handleNutritionalInformationDialogOnCloseEvent={
                                    handleNutritionalInformationDialogOnCloseEvent
                                }
                                globalColor={globalColor}
                                listworkflow={listworkflow}
                            />
                        </Card>
                    </CardActionArea>
                )}
            </Box>
        </React.Fragment>
    );
}

export const MemoizedCardProduct = React.memo(CardProduct, areEqual);
CardProduct.propTypes = {
    product: PropTypes.object.isRequired,
    listworkflow: PropTypes.object,
    orderItems: PropTypes.array,
};
