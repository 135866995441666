import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import CardProduct from "@components/OrderTaking/Components/Products/ProductWrapper/CardProduct";
import {
    memoizedActiveCategoryUid,
    memoizedOrderItems,
    memoizedSelectedProducts,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

// Import Swiper styles
import "swiper/swiper.min.css";

// import "swiper/css/pagination";

export default function PmrProducts({
    listProducts,
    listworkflow,
    listAdditionnalItems,
}) {
    const { selectedSaleModeValue } = useSnapshot(kioskStore);
    const activeCategory = useSelector((state) =>
        memoizedActiveCategoryUid(state)
    );
    const orderItems = useSelector((state) => memoizedOrderItems(state));
    const products =
        listProducts !== undefined
            ? listProducts
            : listAdditionnalItems !== undefined
            ? listAdditionnalItems
            : useSelector((state) =>
                  memoizedSelectedProducts(state, activeCategory)
              );
    if (document.getElementsByClassName("swiper-pagination")[0] !== undefined) {
        products.length <= 3
            ? document
                  .getElementsByClassName("swiper-pagination")[0]
                  .classList.add("d-none")
            : document
                  .getElementsByClassName("swiper-pagination")[0]
                  .classList.remove("d-none");
    }
    const keySlide =
        listProducts !== undefined ? listProducts.length : activeCategory;

    return (
        <Slide
            in={true}
            key={keySlide}
            unmountOnExit
            style={{ transformOrigin: "0 0 0 " }}
            timeout={{ appear: 0, enter: 600, exit: 100 }}
            direction="up"
        >
            <div
                style={{
                    paddingRight: "3px",
                    width: "95%",
                }}
            >
                <Swiper
                    slidesPerView={"auto"}
                    slidesPerGroup={3}
                    spaceBetween={15}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="prm-products-swiper-container pr-4"
                >
                    {products.map((product) => {
                        return (
                            product?.visibilityInfo.isVisible &&
                            (product?.KioskVisibility[selectedSaleModeValue] ||
                                product?.KioskVisibility[
                                    selectedSaleModeValue
                                ] === undefined) && (
                                <SwiperSlide
                                    key={product.iuud}
                                    className="prm-products-swiper-slide"
                                >
                                    <CardProduct
                                        product={product}
                                        listworkflow={listworkflow}
                                        orderItems={orderItems}
                                    />
                                </SwiperSlide>
                            )
                        );
                    })}
                </Swiper>
            </div>
        </Slide>
    );
}
PmrProducts.propTypes = {
    listProducts: PropTypes.array,
    listworkflow: PropTypes.object,
    listAdditionnalItems: PropTypes.array,
};
