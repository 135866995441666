import { KeyboardInput } from "react-simple-keyboard";

import { kioskStore as store } from "../store";

export function setCustomerLanguage(language: LanguagesItemType): void {
    store.customerLanguage = language;
}

export function setIsPrm(isPrm: boolean): void {
    store.isPrm = isPrm;
}

export function setIsStandbyModalOpened(isStandbyModalOpened: boolean): void {
    store.isStandbyModalOpened = isStandbyModalOpened;
}

export function setIsHelpModalOpened(isHelpModalOpened: boolean): void {
    store.isHelpModalOpened = isHelpModalOpened;
}

export function setSaleMethodsNumber(saleMethodsNumber: number): void {
    store.saleMethodsNumber = saleMethodsNumber;
}

export function setRegulationModesNumber(regulationModesNumber: number): void {
    store.regulationModesNumber = regulationModesNumber;
}

export function setIsTopBannerActive(isTopBannerActive: boolean): void {
    store.isTopBannerActive = isTopBannerActive;
}

export function setIsInformationMessageActive(
    isInformationMessageActive: boolean
): void {
    store.isInformationMessageActive = isInformationMessageActive;
}

export function setIsUserAccountActive(isUserAccountActive: boolean): void {
    store.isUserAccountActive = isUserAccountActive;
}

export function setIsLanguagesActive(isLanguagesActive: boolean): void {
    store.isLanguagesActive = isLanguagesActive;
}

export function setActiveLanguages(activeLanguages: LanguagesItemType[]): void {
    store.activeLanguages = activeLanguages;
}

export function setIsConfigOptionsActive(isConfigOptionsActive: boolean): void {
    store.isConfigOptionsActive = isConfigOptionsActive;
}

export function setActiveConfigOptions(
    activeConfigOptions: OptionsItemType[]
): void {
    store.activeConfigOptions = JSON.parse(JSON.stringify(activeConfigOptions));
}

export function setIsLogoActive(isLogoActive: boolean): void {
    store.isLogoActive = isLogoActive;
}

export function setIsActionButtonsActive(isActionButtonsActive: boolean): void {
    store.isActionButtonsActive = isActionButtonsActive;
}

export function setIsHeaderActive(isHeaderActive: boolean): void {
    store.isHeaderActive = isHeaderActive;
}

export function setActionsButtonsItems(
    actionsButtonsItems: ActionsButtonsDesignItemType[]
): void {
    store.actionsButtonsItems = actionsButtonsItems;
}

export function setMainContentItems(
    mainContentItems:
        | ProjectMainContentItemType[]
        | PaymentMainContentItemType[]
): void {
    store.mainContentItems = mainContentItems;
}

export function setInformationMessageItems(
    informationMessageItems: HeaderLanguageItemsType
): void {
    store.informationMessageItems = informationMessageItems;
}

export function setUserAccountMessageItems(
    userAccountMessageItems: HeaderLanguageItemsType
): void {
    store.userAccountMessageItems = userAccountMessageItems;
}

export function setActiveStandbyScreenItems(
    activeStandbyScreenItems: StandbyScreenItemType[]
): void {
    store.activeStandbyScreenItems = activeStandbyScreenItems;
}

export function setProjectTimeout(timeout: number): void {
    store.timeout = timeout;
}

export function setActiveActionsButtons(
    activeActionsButtons: ActionsButtonsDesignItemType[]
): void {
    store.activeActionsButtons = activeActionsButtons;
}

export function setInitialStepTimeout(initialStepTimeout: number): void {
    store.initialStepTimeout = initialStepTimeout;
}

export function setIsUserAccountModalOpened(
    isUserAccountModalOpened: boolean
): void {
    store.isUserAccountModalOpened = isUserAccountModalOpened;
}

export function setSelectedAuthMode(selectedAuthMode: string): void {
    store.selectedAuthMode = selectedAuthMode;
}

export function setChosenTransition(chosenTransition: TransitionType): void {
    store.chosenTransition = chosenTransition;
}

export function setIsAnimationIn(isAnimationIn: boolean): void {
    store.isAnimationIn = isAnimationIn;
}

export function setSelectedSaleModeValue(selectedSaleModeValue: string): void {
    store.selectedSaleModeValue = selectedSaleModeValue;
}
export function setSelectedSaleModeId(selectedSaleModeId: string): void {
    store.selectedSaleModeId = selectedSaleModeId;
}
export function setSelectedSaleModeOrderTaking(
    selectedSaleModeOrderTaking: string
): void {
    store.selectedSaleModeOrderTaking = selectedSaleModeOrderTaking;
}

export function setKioskWays(kioskWays: WaysType[]): void {
    store.kioskWays = kioskWays;
}

export function setSelectedInformationMode(
    selectedInformationMode: string
): void {
    store.selectedInformationMode = selectedInformationMode;
}

export function setIsSubStepIconActive(isSubStepIconActive: boolean): void {
    store.isSubStepIconActive = isSubStepIconActive;
}

export function setIsSubStepTopBannerActive(
    isSubStepTopBannerActive: boolean
): void {
    store.isSubStepTopBannerActive = isSubStepTopBannerActive;
}

export function setIsSubStepLogoActive(isSubStepLogoActive: boolean): void {
    store.isSubStepLogoActive = isSubStepLogoActive;
}

export function setIsSubStepInformationMessageActive(
    isSubStepInformationMessageActive: boolean
): void {
    store.isSubStepInformationMessageActive = isSubStepInformationMessageActive;
}

export function setIsSubStepActionButtonsActive(
    isSubStepActionButtonsActive: boolean
): void {
    store.isSubStepActionButtonsActive = isSubStepActionButtonsActive;
}

export function setNavigationIndex(navigationIndex: number): void {
    store.navigationIndex = navigationIndex;
}

export function setAuthModalStepIndex(authModalStepIndex: number): void {
    store.authModalStepIndex = authModalStepIndex;
}

export function setProject(project: ProjectType): void {
    store.project = project;
}

export function setSubStepActionButtons(
    subStepActionButtons: ActionsButtonsDesignItemType[]
): void {
    store.subStepActionButtons = subStepActionButtons;
}

export function setIsUserAuthenticated(isUserAuthenticated: boolean): void {
    store.isUserAuthenticated = isUserAuthenticated;
}

export function setSubStepInformationMessageItems(
    subStepInformationMessageItems: HeaderLanguageItemsType
): void {
    store.subStepInformationMessageItems = subStepInformationMessageItems;
}

export function setSubStepIconId(subStepIconId: string): void {
    store.subStepIconId = subStepIconId;
}

export function setIsInvalidQrCodeModalOpened(
    isInvalidQrCodeModalOpened: boolean
): void {
    store.isInvalidQrCodeModalOpened = isInvalidQrCodeModalOpened;
}

export function setIsOrderProcessedModalWrapperOpened(
    isOrderProcessedModalWrapperOpened: boolean
): void {
    store.isOrderProcessedModalWrapperOpened =
        isOrderProcessedModalWrapperOpened;
}

export function setIsQrCodeCorrectOrderNotProcessedModalOpened(
    isQrCodeCorrectOrderNotProcessedModalOpened: boolean
): void {
    store.isQrCodeCorrectOrderNotProcessedModalOpened =
        isQrCodeCorrectOrderNotProcessedModalOpened;
}

export function setSelectedRegulationModeValue(
    selectedRegulationModeValue: string
): void {
    store.selectedRegulationModeValue = selectedRegulationModeValue;
}

export function setIsConfirmationModalOpened(
    isConfirmationModalOpened: boolean
): void {
    store.isConfirmationModalOpened = isConfirmationModalOpened;
}

export function setNumericKeyboardInputValue(
    numericKeyboardInputValue: string
): void {
    store.numericKeyboardInputValue = numericKeyboardInputValue;
}

export function setIsAuthenticationErrorModalOpened(
    isAuthenticationErrorModalOpened: boolean
): void {
    store.isAuthenticationErrorModalOpened = isAuthenticationErrorModalOpened;
}

export function setKeyboardInputsValues(
    keyboardInputsValues: KeyboardInput
): void {
    store.keyboardInputsValues = keyboardInputsValues;
}

export function setLogo(logo: GeneralDesignLogoType): void {
    store.logo = logo;
}

export function setIsTicketNumberMessageFooterActive(
    ticketNumberMessageActive: boolean
): void {
    store.isTicketNumberMessageFooterActive = ticketNumberMessageActive;
}

export function setTicketNumberMessageFooter(
    ticketNumberMessage: HeaderLanguageItemsType
): void {
    store.ticketNumberMessageFooter = ticketNumberMessage;
}

export function setFinalMessageFooter(
    finalMessageFooter: HeaderLanguageItemsType
): void {
    store.finalMessageFooter = finalMessageFooter;
}

export function setIsFinalMessageFooterActive(
    finalMessageFooterActive: boolean
): void {
    store.isFinalMessageFooterActive = finalMessageFooterActive;
}

export function setFinalMessage(finalMessage: string): void {
    store.finalMessage = finalMessage;
}

export function setFinalMessageSelectedRegulationMode(
    SelectedRegulationMode: string
): void {
    store.finalMessageSelectedRegulationMode = SelectedRegulationMode;
}

export function setFinalMessageSelectedInformationMode(
    SelectedInformationMode: string
): void {
    store.finalMessageSelectedInformationMode = SelectedInformationMode;
}

export function setTotalOrderAfterPaidWithLoyaltyAccountBalance(
    totalOrderAfterPaidWithLoyaltyAccountBalance: number
): void {
    store.totalOrderAfterPaidWithLoyaltyAccountBalance =
        totalOrderAfterPaidWithLoyaltyAccountBalance;
}

export function setIsChangeMachineRenderModalOpened(
    isChangeMachineRenderModalOpened: boolean
): void {
    store.isChangeMachineRenderModalOpened = isChangeMachineRenderModalOpened;
}

export function setIsRefundAnomalyModalOpened(
    isRefundAnomalyModalOpened: boolean
): void {
    store.isRefundAnomalyModalOpened = isRefundAnomalyModalOpened;
}

export function setAmountDeposited(amountDeposited: number): void {
    store.amountDeposited = amountDeposited;
}

export function setTotalAmountDeposited(totalAmountDeposited: number): void {
    store.totalAmountDeposited = totalAmountDeposited;
}

export function setLeftToPay(leftToPay: number): void {
    store.leftToPay = leftToPay;
}

export function setRenderAmount(renderAmount: number): void {
    store.renderAmount = renderAmount;
}

export function setOrderNumber(orderNumber: string): void {
    store.orderNumber = orderNumber;
}

export function setChangeMachineBalance(changeMachineBalance: number): void {
    store.changeMachineBalance = changeMachineBalance;
}

export function initStoreVariablesInStandbyScreenStep(): void {
    store.selectedRegulationModeValue = "";
    store.numericKeyboardInputValue = "";
    store.selectedInformationMode = "";
    store.isUserAccountActive = false;
    store.isUserAuthenticated = false;
    store.isPrm = false;
    store.isOrderPaidWithLoyaltyAccountBalance = false;
    store.isCreatingVoucherAccepted = false;
    store.user = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        loyaltyAccount: 0,
        loyaltyAmount: 0,
    };
    store.amountDeposited = 0;
    store.paymentModes = [];
    store.chosenInformationMode = {
        type: "",
        data: { infoModeUuId: "", value: "" },
    };
    store.leftToPay = 0;
    store.leftToPayAfterCashMachineValidation = 0;
    store.orderNumber = "";
    store.isRefundAnomalyModalOpened = false;
    store.isAbortTransactionModalOpened = false;
    store.isBackButtonDisabled = false;
    store.isAuthenticationErrorModalOpened = false;
    store.isConfirmationModalOpened = false;
    store.isRegulationModesModalOpened = false;
    store.isAnotherPaymentModeButtonDisabled = true;
    store.isTransactionCanceled = false;
    store.isWithdrawalProblemModalOpened = false;
    store.isPaymentRefused = false;
    store.isUserAccountModalOpened = false;
    store.selectedSaleModeValue = "";
    store.finalMessageCounter = 0;
    store.isAmountReturned = false;
    store.isCommandInsertionProblemModalOpened = false;
    store.isAnotherPaymentModeButtonCBDisabled = true;
    store.isPaymentTechnicalProblemModalOpened = false;
    store.isPaymentTechnicalProblemDetected = false;
    store.isOrderTakingPreviousStep = false;
    store.isOrderInsertedSuccessfully = false;
    store.renderAmount = 0;
    store.orderNumber = "";
    store.regulationModesModalKey = "";
    store.qrCodeValue = "";
    store.selectedSaleModeId = "";
    store.pseudoNameValue = "";
    store.cashUuID = "";
    store.creditCardUuId = "";
    store.loyaltyUuId = "";
    store.isSelectedSaleModeWithoutInformationModes = false;
    store.selectedSaleModeOrderTaking = "";
    store.orderItemsLength = 0;
    store.saleMode = {};
    store.orderModificationMode = false;
    store.chosenLocalInformationMode = {
        type: "",
        data: { infoModeUuId: "", value: "" },
    };
    store.localSelectedInformationMode = "";
    store.finalMessageSelectedInformationMode = "none";
    store.finalMessageSelectedRegulationMode = "At the counter";
    store.totalOrderAfterPaidWithLoyaltyAccountBalance = 0;
    store.isBackWithoutCreditCardPaymentChoice = false;
    store.isAuthenticationAccepted = false;
    store.isOrderPartialPaidWithLoyaltyAccountBalance = false;
    store.selectedAuthMode = "";
    store.isInformationMessageActive = false;
    store.isOrderInsertionRefused = false;
    store.returnedAmount = 0;
    store.isChangeMachineReady = false;
    store.isAcceptAbortCBTransaction = false;
    store.totalAmountDeposited = 0;
    store.isRegulationModesModalBackButtonClicked = false;
    store.isEventStreamProblem = false;
}

export function setIsRegulationModesModalOpened(
    isRegulationModesModalOpened: boolean
): void {
    store.isRegulationModesModalOpened = isRegulationModesModalOpened;
}

export function setIsTpaValinaActive(isTpaValinaActive: boolean): void {
    store.isTpaValinaActive = isTpaValinaActive;
}

export function setIsTpaValinaIconActive(isTpaValinaIconActive: boolean): void {
    store.isTpaValinaIconActive = isTpaValinaIconActive;
}

export function setTpaValinaIconId(tpaValinaIconId: string): void {
    store.tpaValinaIconId = tpaValinaIconId;
}

export function setIsTpaValinaInformationMessageActive(
    isTpaValinaInformationMessageActive: boolean
): void {
    store.isTpaValinaInformationMessageActive =
        isTpaValinaInformationMessageActive;
}

export function setTpaValinaInformationMessageItems(
    tpaValinaInformationMessageItems: HeaderLanguageItemsType
): void {
    store.tpaValinaInformationMessageItems = tpaValinaInformationMessageItems;
}

export function setTpaValinaActionButtons(
    tpaValinaActionButtons: ActionsButtonsDesignItemType[]
): void {
    store.tpaValinaActionButtons = tpaValinaActionButtons;
}

export function setIsTpaValinaActionButtonsActive(
    isTpaValinaActionButtonsActive: boolean
): void {
    store.isTpaValinaActionButtonsActive = isTpaValinaActionButtonsActive;
}

export function setIsTpaValinaModalOpened(
    isTpaValinaModalOpened: boolean
): void {
    store.isTpaValinaModalOpened = isTpaValinaModalOpened;
}
export function setIsBackWithoutCreditCardPaymentChoice(
    isBackWithoutCreditCardPaymentChoice: boolean
): void {
    store.isBackWithoutCreditCardPaymentChoice =
        isBackWithoutCreditCardPaymentChoice;
}

export function setCreditCardPaymentChoice(
    creditCardPaymentChoice: string
): void {
    store.creditCardPaymentChoice = creditCardPaymentChoice;
}

export function setIsAuthenticationAccepted(
    isAuthenticationAccepted: boolean
): void {
    store.isAuthenticationAccepted = isAuthenticationAccepted;
}

export function setIsOrderPaidWithLoyaltyAccountBalance(
    isOrderPaidWithLoyaltyAccountBalance: boolean
): void {
    store.isOrderPaidWithLoyaltyAccountBalance =
        isOrderPaidWithLoyaltyAccountBalance;
}

export function setIsOrderPartialPaidWithLoyaltyAccountBalance(
    isOrderPartialPaidWithLoyaltyAccountBalance: boolean
): void {
    store.isOrderPartialPaidWithLoyaltyAccountBalance =
        isOrderPartialPaidWithLoyaltyAccountBalance;
}

export function setIsLocalSettingModalOpened(
    isLocalSettingModalOpened: boolean
): void {
    store.isLocalSettingModalOpened = isLocalSettingModalOpened;
}
export function setIsAuthenticationModalOpened(
    isAuthenticationModalOpened: boolean
): void {
    store.isAuthenticationModalOpened = isAuthenticationModalOpened;
}

export function setIsLocalConfigModalOpened(
    isLocalConfigModalOpened: boolean
): void {
    store.isLocalConfigModalOpened = isLocalConfigModalOpened;
}
export function setIsErrorLogInModalWrapperOpened(
    isErrorLogInModalWrapperOpened: boolean
): void {
    store.isErrorLogInModalWrapperOpened = isErrorLogInModalWrapperOpened;
}

export function setActiveTabTitle(activeTabTitle: string): void {
    store.activeTabTitle = activeTabTitle;
}

export function setLocalProjectItems(
    name: string,
    items:
        | ProjectMainContentItemType[]
        | LanguagesItemType[]
        | OptionsItemType[]
): void {
    (
        store.localProject.template.content[name] as
            | ProjectContentItemType
            | LanguagesType
            | OptionsType
    ).items = items;
}

export function setLocalProject(localProject: ProjectType): void {
    store.localProject = JSON.parse(JSON.stringify(localProject));
}

export function setIsMenuModalOpened(isMenuModalOpened: boolean): void {
    store.isMenuModalOpened = isMenuModalOpened;
}

export function setIsAppPaused(isAppPaused: boolean): void {
    store.isAppPaused = isAppPaused;
}

export function setConfigInformationModes(
    informationModes: ProjectMainContentItemType[]
): void {
    const updatedArray = (
        store.localProject.template.content
            .informationModes as ProjectContentItemType
    ).items.map((globalItem) => {
        const element = informationModes.find(
            (item) => item.id === globalItem.id
        );

        if (element !== undefined) {
            return element;
        }

        return globalItem;
    });
    (
        store.localProject.template.content
            .informationModes as ProjectContentItemType
    ).items = updatedArray;
}

export function setActiveStateScreenItems(
    activeStateScreenItems: StandbyScreenItemType[]
): void {
    store.activeStateScreenItems = activeStateScreenItems;
    Object.keys(store.project.template.pages.ways).includes("standbyScreen")
        ? ((
              store.localProject.template.pages.ways
                  .standbyScreen as StandbyScreenPageType
          ).items = activeStateScreenItems)
        : ((
              store.localProject.template.pages.elements
                  .standbyScreen as StandbyScreenPageType
          ).items = activeStateScreenItems);
}

export function setStandbyStateScreenItems(
    standbyStateScreenItems: StandbyScreenItemType[]
): void {
    store.standbyStateScreenItems = standbyStateScreenItems;
    (
        store.localProject.template.content
            .standbyStateScreen as StandbyStateScreenItemsType
    ).items = standbyStateScreenItems;
}

export function setStandbyScreenItems(
    standbyScreenItems: StandbyScreenItemType[]
): void {
    store.standbyScreenItems = standbyScreenItems;
}

export function setIsConfirmationStandbyStateModalOpened(
    isConfirmationStandbyStateModalOpened: boolean
): void {
    store.isConfirmationStandbyStateModalOpened =
        isConfirmationStandbyStateModalOpened;
}

export function setIsUsersListModalOpened(
    isUsersListModalOpened: boolean
): void {
    store.isUsersListModalOpened = isUsersListModalOpened;
}

export function setIsScanQrCodeModalOpened(
    isScanQrCodeModalOpened: boolean
): void {
    store.isScanQrCodeModalOpened = isScanQrCodeModalOpened;
}

export function setIsAbortTransactionModalOpened(
    isAbortTransactionModalOpened: boolean
): void {
    store.isAbortTransactionModalOpened = isAbortTransactionModalOpened;
}

export function setRegulationModesModalKey(
    regulationModesModalKey: string
): void {
    store.regulationModesModalKey = regulationModesModalKey;
}

export function setLeftToPayAfterCashMachineValidation(
    leftToPayAfterCashMachineValidation: number
): void {
    store.leftToPayAfterCashMachineValidation =
        leftToPayAfterCashMachineValidation;
}

export function setQrCodeValue(qrCodeValue: string): void {
    store.qrCodeValue = qrCodeValue;
}

export function setUser(user: UserType): void {
    store.user = user;
}

export function setPseudoNameValue(pseudoNameValue: string): void {
    store.pseudoNameValue = pseudoNameValue;
}

export function setChosenInformationMode(
    chosenInformationMode: InformationModeType
): void {
    store.chosenInformationMode = chosenInformationMode;
}

export function setPaymentModes(paymentModes: PaymentModesType): void {
    store.paymentModes = paymentModes;
}

export function setCashUuID(cashUuID: string): void {
    store.cashUuID = cashUuID;
}

export function setCreditCardUuId(creditCardUuId: string): void {
    store.creditCardUuId = creditCardUuId;
}

export function setLoyaltyUuId(loyaltyUuId: string): void {
    store.loyaltyUuId = loyaltyUuId;
}

export function setIsCreatingVoucherAccepted(
    isCreatingVoucherAccepted: boolean
): void {
    store.isCreatingVoucherAccepted = isCreatingVoucherAccepted;
}

export function setIsCloseAppModalOpened(isCloseAppModalOpened: boolean): void {
    store.isCloseAppModalOpened = isCloseAppModalOpened;
}
export function setIsRestartModalOpened(isRestartModalOpened: boolean): void {
    store.isRestartModalOpened = isRestartModalOpened;
}
export function setIsShutdownModalOpened(isShutdownModalOpened: boolean): void {
    store.isShutdownModalOpened = isShutdownModalOpened;
}
export function setIsSelectedSaleModeWithoutInformationModes(
    isSelectedSaleModeWithoutInformationModes: boolean
): void {
    store.isSelectedSaleModeWithoutInformationModes =
        isSelectedSaleModeWithoutInformationModes;
}

export function setIsBackButtonDisabled(isBackButtonDisabled: boolean): void {
    store.isBackButtonDisabled = isBackButtonDisabled;
}

export function setIsAnotherPaymentModeButtonDisabled(
    isAnotherPaymentModeButtonDisabled: boolean
): void {
    store.isAnotherPaymentModeButtonDisabled =
        isAnotherPaymentModeButtonDisabled;
}
export function setIsLogout(isLogout: boolean): void {
    store.isLogout = isLogout;
}

export function setIsCashMachineConfigured(
    isCashMachineConfigured: boolean
): void {
    store.isCashMachineConfigured = isCashMachineConfigured;
}

export function setIsFenceProblemDetected(
    isFenceProblemDetected: boolean
): void {
    store.isFenceProblemDetected = isFenceProblemDetected;
}

export function setTechnicalProblemKey(technicalProblemKey: string): void {
    store.technicalProblemKey = technicalProblemKey;
}

// eslint-disable-next-line
export function setShopOpeningHour(shopOpeningHour: any[]): void {
    store.shopOpeningHour = shopOpeningHour;
}

export function setIsTransactionCanceled(isTransactionCanceled: boolean): void {
    store.isTransactionCanceled = isTransactionCanceled;
}

export function setIsWithdrawalProblemModalOpened(
    isWithdrawalProblemModalOpened: boolean
): void {
    store.isWithdrawalProblemModalOpened = isWithdrawalProblemModalOpened;
}

export function setIsPaymentRefused(isPaymentRefused: boolean): void {
    store.isPaymentRefused = isPaymentRefused;
}

export function setVoucherAmount(voucherAmount: number): void {
    store.voucherAmount = voucherAmount;
}

export function setIsCreationVoucherCanceled(
    isCreationVoucherCanceled: boolean
): void {
    store.isCreationVoucherCanceled = isCreationVoucherCanceled;
}

export function setPagesItems(pagesItems: ItemsPagesType): void {
    store.pagesItems = JSON.parse(JSON.stringify(pagesItems));
    store.localProject.template.pages = JSON.parse(JSON.stringify(pagesItems));
}

export function setIsCashTransactionValidated(
    isCashTransactionValidated: boolean
): void {
    store.isCashTransactionValidated = isCashTransactionValidated;
}

export function setIsAnotherPaymentModeButtonCBDisabled(
    isAnotherPaymentModeButtonCBDisabled: boolean
): void {
    store.isAnotherPaymentModeButtonCBDisabled =
        isAnotherPaymentModeButtonCBDisabled;
}
export function setOrderItemsLength(orderItemsLength: number): void {
    store.orderItemsLength = orderItemsLength;
}

export function setIsPaymentTechnicalProblemModalOpened(
    isPaymentTechnicalProblemModalOpened: boolean
): void {
    store.isPaymentTechnicalProblemModalOpened =
        isPaymentTechnicalProblemModalOpened;
}

export function setIsPaymentTechnicalProblemDetected(
    isPaymentTechnicalProblemDetected: boolean
): void {
    store.isPaymentTechnicalProblemDetected = isPaymentTechnicalProblemDetected;
}

export function setIsCommandInsertionProblemModalOpened(
    isCommandInsertionProblemModalOpened: boolean
): void {
    store.isCommandInsertionProblemModalOpened =
        isCommandInsertionProblemModalOpened;
}

export function setFinalMessageCounter(finalMessageCounter: number): void {
    store.finalMessageCounter = finalMessageCounter;
}

export function setIsAmountReturned(isAmountReturned: boolean): void {
    store.isAmountReturned = isAmountReturned;
}

export function setIsInformationModesModalOpened(
    isInformationModesModalOpened: boolean
): void {
    store.isInformationModesModalOpened = isInformationModesModalOpened;
}

export function setIsInformationModesConsommationModalOpened(
    isInformationModesConsommationModalOpened: boolean
): void {
    store.isInformationModesConsommationModalOpened =
        isInformationModesConsommationModalOpened;
}

// eslint-disable-next-line
export function setSaleMode(saleMode: any): void {
    store.saleMode = saleMode;
}

export function setOrderModificationMode(): void {
    store.orderModificationMode = !store.orderModificationMode;
}

export function setChosenLocalInformationMode(
    chosenLocalInformationMode: InformationModeType
): void {
    store.chosenLocalInformationMode = chosenLocalInformationMode;
}
export function setLocalSelectedInformationMode(
    localSelectedInformationMode: string
): void {
    store.localSelectedInformationMode = localSelectedInformationMode;
}

export function setIsOrderTakingPreviousStep(
    isOrderTakingPreviousStep: boolean
): void {
    store.isOrderTakingPreviousStep = isOrderTakingPreviousStep;
}

export function setIsOrderInsertedSuccessfully(
    isOrderInsertedSuccessfully: boolean
): void {
    store.isOrderInsertedSuccessfully = isOrderInsertedSuccessfully;
}

export function setReturnedAmount(returnedAmount: number): void {
    store.returnedAmount = returnedAmount;
}

export function setIsOrderInsertionRefused(
    isOrderInsertionRefused: boolean
): void {
    store.isOrderInsertionRefused = isOrderInsertionRefused;
}

export function setIsLogoutProblemModalOpened(
    isLogoutProblemModalOpened: boolean
): void {
    store.isLogoutProblemModalOpened = isLogoutProblemModalOpened;
}

export function setIsChangeMachineReady(isChangeMachineReady: boolean): void {
    store.isChangeMachineReady = isChangeMachineReady;
}

export function setIsAcceptAbortCBTransaction(
    isAcceptAbortCBTransaction: boolean
): void {
    store.isAcceptAbortCBTransaction = isAcceptAbortCBTransaction;
}

export function setIsCashMachineTransactionCanceled(
    isCashMachineTransactionCanceled: boolean
): void {
    store.isCashMachineTransactionCanceled = isCashMachineTransactionCanceled;
}
export function setIsOrderLoading(isloading: boolean): void {
    store.isOrderLoading = isloading;
}

export function setIsWorkflowLoading(isWorkflowLoading: boolean): void {
    store.isWorkflowLoading = isWorkflowLoading;
}

export function setIsAnimationTimeOut(isAnimationTimeOut: boolean): void {
    store.isAnimationTimeOut = isAnimationTimeOut;
}

export function setIsRegulationModesModalBackButtonClicked(
    isRegulationModesModalBackButtonClicked: boolean
): void {
    store.isRegulationModesModalBackButtonClicked =
        isRegulationModesModalBackButtonClicked;
}

export function setIsEventStreamProblem(isEventStreamProblem: boolean): void {
    store.isEventStreamProblem = isEventStreamProblem;
}

export function setChangeMachineConfigurationDate(
    changeMachineConfigurationDate: string
): void {
    store.changeMachineConfigurationDate = changeMachineConfigurationDate;
}
