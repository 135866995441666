const REDIRECTION = "isRedirect";
const ETK_CESAR = 3;

const COMPOSITIONS = "compositions";
const ALLERGENS = "allergènes";
const BASIC_COMPOSITION = "basic_composition";
const IS_NEXT_IN_LIST = "isNext";
const ISCHEAPEST = "isCheapest";
const ISEXPENSIVE = "isCheapest";
const NO_ThANKS_CARD = "noThanks";
const ALLERGENS_OPTION = "Allergen";
const ACCOUNT_OPTION = "Customer account";
const DELIVERY_MODE = "Delivery";
const EMPTY_BASKET_ICON_ROLE = 1;
const FULL__BASKET_ICON_ROLE = 2;
const OUT_OF_STOCK_ROLE = 3;
const BACK_BUTTON_ROLE = 3;
const WITHOUT_BUTTON = 4;
const DESIGN_NO_THANKS_BUTTON_ROLE = 5;
const BASIC_COMPOSITION_TYPE = "composition de base";
const CATEGORY_TYPE = "categories";
const ITEM_TYPE = "items";
const STEP_TYPE = "steps";
const SPECIAL_PRICE_TYPE = 1;
const STEP_PRICE_TYPE = 2;
const ITEM_PRICE_TYPE = 3;
const VALIDATE_BUTTON_ROLE = 1;

const PAY_BUTTON_ROLE = 8;
const PRM_PAY_BUTTON_ROLE = 9;
const CANCEL_BUTTON_ROLE = 10;
const PRM_CANCEL_BUTTON_ROLE = 11;
const VALIDATE_IN_STEP_BUTTON_ROLE = 12;
const NEXT_IN_STEP_BUTTON_ROLE = 13;
const PREVIOUS_IN_STEP_BUTTON_ROLE = 14;
const ABANDON_BUTTON_ROLE = 15;
const CONTINUE_ORDER_BUTTON_ROLE = 16;
const NO_THANKS_BUTTON_ROLE = 17;
const YES_BUTTON_ROLE = 18;
const NO_BUTTON_ROLE = 19;
const ADD_TO_ORDER_BUTTON_ROLE = 20;
const PREVIOUS_BUTTON_ROLE = 21;
const NEXT_BUTTON_ROLE = 22;
const PRM_BUTTON = "prm/child";
const ASK_FOR_HELP_BUTTON = "ask for help";
const VALID_IN_STEP = "valid in step";
const NEXT_IN_STEP = "next in step";
const PREVIOUS_IN_STEP = "previous in step";
const CESAR_CATLOGUE_TYPE = 3;
const USUAL_MODE = "usual";

export {
    REDIRECTION,
    BASIC_COMPOSITION,
    PAY_BUTTON_ROLE,
    PRM_PAY_BUTTON_ROLE,
    CANCEL_BUTTON_ROLE,
    PRM_CANCEL_BUTTON_ROLE,
    VALIDATE_IN_STEP_BUTTON_ROLE,
    NEXT_IN_STEP_BUTTON_ROLE,
    PREVIOUS_IN_STEP_BUTTON_ROLE,
    ABANDON_BUTTON_ROLE,
    CONTINUE_ORDER_BUTTON_ROLE,
    NO_THANKS_BUTTON_ROLE,
    YES_BUTTON_ROLE,
    NO_BUTTON_ROLE,
    ADD_TO_ORDER_BUTTON_ROLE,
    NEXT_BUTTON_ROLE,
    PREVIOUS_BUTTON_ROLE,
    EMPTY_BASKET_ICON_ROLE,
    FULL__BASKET_ICON_ROLE,
    OUT_OF_STOCK_ROLE,
    DESIGN_NO_THANKS_BUTTON_ROLE,
    BACK_BUTTON_ROLE,
    PRM_BUTTON,
    ASK_FOR_HELP_BUTTON,
    WITHOUT_BUTTON,
    BASIC_COMPOSITION_TYPE,
    IS_NEXT_IN_LIST,
    ISCHEAPEST,
    ISEXPENSIVE,
    NO_ThANKS_CARD,
    COMPOSITIONS,
    ALLERGENS,
    CATEGORY_TYPE,
    ITEM_TYPE,
    STEP_TYPE,
    ALLERGENS_OPTION,
    ACCOUNT_OPTION,
    SPECIAL_PRICE_TYPE,
    STEP_PRICE_TYPE,
    ITEM_PRICE_TYPE,
    VALID_IN_STEP,
    NEXT_IN_STEP,
    PREVIOUS_IN_STEP,
    DELIVERY_MODE,
    VALIDATE_BUTTON_ROLE,
    CESAR_CATLOGUE_TYPE,
    USUAL_MODE,
    ETK_CESAR,
};
