import { proxy, ref, subscribe } from "valtio";

type StoreType = {
    collapsed: boolean;
    kioskImage: ImageType;
    uploadedFiles: UploadedFilesType[];
    transitions: TransitionsType;
    getTransitions: () => Promise<void>;
    isAppSynchronized: boolean;
    isLicenseProblem: boolean;
    isTechProblemModalOpened: boolean;
    isMiddlewareApiUrlExists: boolean;
    middlewareApiUrl: string;
    isAutoLaunchActivated: boolean;
};

export const store = proxy<StoreType>({
    collapsed: false,
    kioskImage: {},
    uploadedFiles: [],
    transitions:
        JSON.parse(localStorage.getItem("transitions") as string) || {},
    getTransitions: async function () {
        if (localStorage.getItem("transitions") !== null) {
            store.transitions = JSON.parse(
                localStorage.getItem("transitions") as string
            );
        } else {
            const response: Response = await fetch("./transitions.json");
            const transitions = await response.json();
            store.transitions = transitions;
        }
    },
    isAppSynchronized:
        JSON.parse(localStorage.getItem("isAppSynchronized") as string) ||
        false,
    isLicenseProblem: false,
    isTechProblemModalOpened: false,
    isMiddlewareApiUrlExists:
        JSON.parse(
            localStorage.getItem("isMiddlewareApiUrlExists") as string
        ) || false,
    middlewareApiUrl:
        JSON.parse(localStorage.getItem("middlewareApiUrl") as string) ||
        "http://localhost:5001/api/v1",
    isAutoLaunchActivated:
        JSON.parse(localStorage.getItem("isAutoLaunchActivated") as string) ||
        false,
});

store.getTransitions();

subscribe(store, () => {
    localStorage.setItem("transitions", JSON.stringify(store.transitions));
    localStorage.setItem(
        "isAppSynchronized",
        JSON.stringify(store.isAppSynchronized)
    );
    localStorage.setItem(
        "isMiddlewareApiUrlExists",
        JSON.stringify(store.isMiddlewareApiUrlExists)
    );
    localStorage.setItem(
        "middlewareApiUrl",
        JSON.stringify(store.middlewareApiUrl)
    );
    localStorage.setItem(
        "isAutoLaunchActivated",
        JSON.stringify(store.isAutoLaunchActivated)
    );
});

export function setCollapsed(collapsed: boolean): void {
    store.collapsed = collapsed;
}

export function setUploadedFiles(uploadedFiles: UploadedFilesType[]): void {
    store.uploadedFiles = uploadedFiles.map(ref);
}

export function setIsAppSynchronized(synchronized: boolean): void {
    store.isAppSynchronized = synchronized;
}

export function setIsTechProblemModalOpened(opened: boolean): void {
    store.isTechProblemModalOpened = opened;
}

export function setIsLicenseProblem(isProblem: boolean): void {
    store.isLicenseProblem = isProblem;
}

export function setIsMiddlewareApiUrlExists(exists: boolean): void {
    store.isMiddlewareApiUrlExists = exists;
}

export function setMiddlewareApiUrl(url: string): void {
    store.middlewareApiUrl = url;
}

export function setIsAutoLaunchActivated(activated: boolean): void {
    store.isAutoLaunchActivated = activated;
}
