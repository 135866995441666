import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore as store } from "@pages/Kiosk/store";

export function SubStepsInformationMessage(): JSX.Element {
    const { subStepInformationMessageItems, customerLanguage } =
        useSnapshot(store);

    return (
        <div className="d-flex justify-content-center align-items-center text-center">
            <span
                style={{
                    fontSize: "55px",
                    marginLeft: "10%",
                    marginRight: "10%",
                }}
            >
                {subStepInformationMessageItems[customerLanguage.name].content}
            </span>
        </div>
    );
}
