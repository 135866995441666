import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore, setStandbyStateScreenItems } from "@pages/Kiosk/store";

import { StandbyStateScreenTable } from "./StandbyStateScreenTable";

export function StandbyStateScreen(): JSX.Element {
    const { localProject } = useSnapshot(kioskStore);

    React.useEffect(() => {
        setStandbyStateScreenItems(
            (
                localProject.template.content
                    .standbyStateScreen as StandbyStateScreenItemsType
            ).items as StandbyScreenItemType[]
        );
    }, []);

    return <StandbyStateScreenTable />;
}
