import {
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    ITEM_TYPE,
} from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import ClosecircleIcon from "@components/OrderTaking/Components/Icons/ClosecircleIcon";
import { InfoIcon } from "@components/OrderTaking/Components/Icons/InfoIcon";
import {
    addWorkFlowItemShopCart,
    deleteBasicCompositionFromShopCart,
    deleteWorkFlowStepShopCart,
    SelectProduct,
    setIsModification,
    setNextWorkflowStep,
    setPreviousWorkflowStep,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { WorkflowStepper } from "./WorkflowStepper";

function WorkflowHeader({ workflowItems, onClose }) {
    const {
        isPrm,
        project: { files: images },
    } = useSnapshot(kioskStore);
    const dispatch = useDispatch();

    const isModification = useSelector(
        (state) => state.orderWorkflowItemSlice.isModification,
        shallowEqual
    );
    const {
        isHeaderColorActive,
        isClosingButtonActive,
        closingButtonContent,
        isProductInfoIconActive,
        productInfoIconId,
    } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );
    const { globalcard } = useSelector((state) => state.orderSlice);
    const { workflowData } = useSelector((state) => state.workFlowDataSlice);
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    const OnClickClose = () => {
        const copyWorkflow = JSON.parse(JSON.stringify(workflowData));
        const workflow = [...copyWorkflow];
        workflow && workflow.splice(copyWorkflow.length - 1, 1);

        if (workflow?.length === 0) {
            onClose();
        } else {
            let title =
                copyWorkflow[copyWorkflow.length - 1].workflow[
                    copyWorkflow[copyWorkflow.length - 1].index
                ].title;

            const data = {
                [title]: {
                    path: [
                        ...copyWorkflow[copyWorkflow.length - 1].workflow[
                            copyWorkflow[copyWorkflow.length - 1].index
                        ].path,
                    ],
                },
            };
            if (!isModification) {
                dispatch(deleteWorkFlowStepShopCart(data[title]));
            }
            isModification && dispatch(setIsModification(false));
            dispatch(setWorkflowData(workflow));
        }
    };

    const imageContent = React.useMemo(() => {
        return getImageContentById(closingButtonContent, images);
    }, []);
    const infoImageContent = React.useMemo(() => {
        return getImageContentById(productInfoIconId, images);
    }, []);
    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;

    const InfoImageSrc =
        infoImageContent?.includes("http") || imageContent?.includes("https")
            ? infoImageContent
            : `./images_order/${infoImageContent}`;

    const options = [];
    const product = {
        ...globalcard?.items?.[
            workflowItems?.workflow[workflowItems.index]?.iuudproduct
        ],
        selectedOptions: options,
    };

    function NextStep(workflow) {
        if (workflow.step > workflow.index) {
            addIndexstep(workflow);
        } else {
            const copyworkflowData = JSON.parse(JSON.stringify(workflowData));
            copyworkflowData.splice(copyworkflowData.length - 1, 1);
            const copyDataworkflow = [...copyworkflowData];
            let index = copyDataworkflow.length;
            while (index > 0) {
                let workflowIndex = index - 1;
                let workflow = copyDataworkflow[workflowIndex];
                if (
                    workflow.workflow[workflow.index].NbrOfChoices <=
                    workflow.workflow[workflow.index].maxNbrOfChoices
                ) {
                    dispatch(setWorkflowData(copyworkflowData));

                    return;
                } else if (workflow.step > workflow.index) {
                    addIndexstep(workflow);
                    return;
                } else {
                    index -= 1;
                    copyDataworkflow.splice(workflowIndex, 1);
                }
            }
            // ** ici on efface workflow
            if (copyDataworkflow.length === 0) {
                dispatch(setWorkflowData([]));
                dispatch(SelectProduct({}));
            }
        }
    }

    function AddorderBasicCompositionWorkflow() {
        let listProduct =
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1]?.index
            ].Items;
        let add_basic_composition = false;

        const path = [
            {
                name: BASIC_COMPOSITION_TYPE,
                iuud: BASIC_COMPOSITION_TYPE,
                type: BASIC_COMPOSITION_TYPE,
            },
        ];
        dispatch(
            deleteBasicCompositionFromShopCart({
                type: BASIC_COMPOSITION_TYPE,
                urlImage: orderWorkflowItemSlice.item.urlImage,
                path,
            })
        );

        const canAddBasicCompositionShopCart = listProduct.some(
            (prod) => prod.basicCompositionActive === false
        );

        if (canAddBasicCompositionShopCart) {
            if (!add_basic_composition) {
                const path = [
                    {
                        name: BASIC_COMPOSITION_TYPE,
                        iuud: BASIC_COMPOSITION_TYPE,
                        type: BASIC_COMPOSITION_TYPE,
                    },
                ];

                let data = {
                    [BASIC_COMPOSITION_TYPE]: {
                        type: BASIC_COMPOSITION_TYPE,
                        urlImage: orderWorkflowItemSlice.item.urlImage,
                        path,
                    },
                };

                dispatch(addWorkFlowItemShopCart(data[BASIC_COMPOSITION_TYPE]));
                add_basic_composition = true;
            }
        }
        listProduct.forEach((product) => {
            if (!product.basicCompositionActive) {
                let data = {
                    [product.title]: {
                        name: "SANS " + product.title,
                        urlImage: product.image,
                        type: ITEM_TYPE,
                        quantity: 1,
                        path: [...product.path],
                        categoryParent: product.categoryParent,
                        categoryTitle:
                            globalcard.categories[product.categoryParent].title,
                        reference: product.reference,
                        iuud: product.iuud,
                        price: product.price.content,
                        modifier: product?.modifier,
                        fidelity: product.fidelity,
                        workflow: [...workflowData],
                    },
                };

                dispatch(addWorkFlowItemShopCart(data[product.title]));
            }
        });
    }
    function addIndexstep() {
        dispatch(setNextWorkflowStep());
    }
    function NextOnClickEvent() {
        if (
            workflowItems.workflow[workflowItems.index]?.NbrOfChoices <
            workflowItems.workflow[workflowItems.index]?.minNbrOfChoices
        )
            return;
        if (workflowItems) {
            if (
                workflowItems.workflow[workflowItems.index].type ===
                BASIC_COMPOSITION
            ) {
                AddorderBasicCompositionWorkflow();
            }
            if (workflowItems.step > workflowItems.index) {
                addIndexstep(workflowItems);
            } else {
                NextStep(workflowItems);
            }
        }
    }
    function PreviousOnClickEvent() {
        if (workflowItems.index > 0) {
            dispatch(setPreviousWorkflowStep());
        }
    }

    return (
        <>
            <div
                className={[`${isPrm ? "h-auto" : "pt-3"} workflow-header`]}
                style={{
                    background: isHeaderColorActive
                        ? `transparent linear-gradient(180deg, #ffffff 0%, ${globalColor} 200%) 0% 0% no-repeat padding-box`
                        : "",
                }}
            >
                <div className="d-flex justify-content-around p-2 w-100">
                    <div
                        className=" d-flex justify-content-center align-items-center workflow-title w-100"
                        style={{ paddingLeft: isPrm ? "0px" : "3rem" }}
                    >
                        <div className="d-flex flex-column align-items-center">
                            <span className="workflow-product-title">
                                {workflowItems &&
                                    globalcard?.items?.[
                                        workflowItems?.workflow[
                                            workflowItems.index
                                        ]?.iuudproduct
                                    ]?.title}
                            </span>
                            {product?.selectedOptions?.length > 0 && (
                                <div className="info-options-product">
                                    {product?.selectedOptions.join(",")}
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className="d-flex justify-content-center h-100"
                        style={{ width: "10%" }}
                    >
                        {isProductInfoIconActive ? (
                            <div className="pt-2 d-flex align-items-start justify-content-end disable-edit h-100 w-50">
                                {infoImageContent !== "" ? (
                                    <div
                                        style={{
                                            backgroundImage: `url(${InfoImageSrc})`,
                                            backgroundSize: "100% 100%",
                                            height: "80%",
                                            width: "100%",
                                            borderRadius: "100%",
                                        }}
                                    ></div>
                                ) : (
                                    <InfoIcon width={45} height={45} />
                                )}
                            </div>
                        ) : null}
                        {!isPrm && isClosingButtonActive ? (
                            <div
                                className={[
                                    `${
                                        workflowData.length > 1
                                            ? ""
                                            : "pt-1 d-flex align-items-start justify-content-end h-100 w-50"
                                    } `,
                                ]}
                                onClick={OnClickClose}
                            >
                                {imageContent !== "" ? (
                                    <img
                                        style={{
                                            height: "80%",
                                            width: "100%",
                                            borderRadius: "100%",
                                        }}
                                        src={imageSrc}
                                    />
                                ) : (
                                    <ClosecircleIcon />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="workflow-timeline">
                    <WorkflowStepper
                        workflowItems={workflowItems?.workflow}
                        activestep={workflowItems?.index}
                        NextOnClickEvent={NextOnClickEvent}
                        PreviousOnClickEvent={PreviousOnClickEvent}
                    />
                </div>
            </div>
        </>
    );
}

export default WorkflowHeader;
WorkflowHeader.propTypes = {
    workflowItems: PropTypes.object,
    onClose: PropTypes.func,
    handleProductOnClickEvent: PropTypes.func,
    isAllergenIconActive: PropTypes.bool,
    setIsShowDetailsOpened: PropTypes.func,
    isShowDetailsOpened: PropTypes.bool,
};
