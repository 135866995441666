/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { snapshot } from "valtio";

import { convertSalesModeValue } from "@components/OrderTaking/Helpers/Commonfunction";
import { syncStore } from "@components/Synchronization/store";

const { machineIpAddress } = snapshot(syncStore);

const initialState = {
    middlwareOrder: {
        orderUuid: "",
        codeManager: "",
        nomManager: "",
        codeVendeur: "",
        nomOVendeur: "",
        nbClient: "",
        orderOrigine: "KIOSK",
        ipOrigine: machineIpAddress,
        numCommande: "",
        sourceOrderOrigine: "", // à verifier
        sourceOrderNumber: 0,
        destinationOrderOrigine: "",
        destinationTicketNumber: 0,
        horodatage: 0,
        tag: "",
        dateFiscale: 0,
        OrderId: 0,
        remiseGlobale: {},
        salesMode: "",
        totalttc: 0,
        deviseCode: "",
        deviseTaux: 1,
        deviseLibelle: "",
        lines: [],
        reglements: [],
        complement: {
            //"modeinfo":{"type":1,"data":""}
            modeInfo: { type: "", data: { infoModeUuId: "", value: "" } },
            ///  modeReg: { type: "", data: "" },
            modeAuth: { type: "", data: "" },
        },
        clientPhoneNumber: "",
    },
};
let data = [];
let lineDiscount = {
    discountMontant: 0,
    discountTaux: 0,
    libelle: "",
};

export const middlwareOrderSlice = createSlice({
    name: "middlwareOrderSlice",
    initialState,
    reducers: {
        setMiddlwareOrder: (state, action) => {
            clearMiddlwareOrder();
            data = [];
            const {
                order,
                selectedSaleModeValue,
                selectedAuthMode,
                chosenInformationMode,
                paymentModes,
                phoneNumber,
            } = action.payload;

            state.middlwareOrder.orderUuid = uuid();
            state.middlwareOrder.codeManager = 0;
            state.middlwareOrder.nomManager = "";
            state.middlwareOrder.codeVendeur = 0;
            state.middlwareOrder.nomOVendeur = "";

            state.middlwareOrder.ipOrigine = machineIpAddress;
            state.middlwareOrder.numCommande = 0;
            state.middlwareOrder.sourceOrderOrigine = "";
            state.middlwareOrder.sourceOrderNumber = 0;
            state.middlwareOrder.destinationOrderOrigine = "";
            state.middlwareOrder.destinationTicketNumber = 0;
            state.middlwareOrder.horodatage = Date.now();
            state.middlwareOrder.tag = "";
            state.middlwareOrder.dateFiscale = Date.now();
            state.middlwareOrder.OrderId = 0;
            state.middlwareOrder.remiseGlobale = {};
            state.middlwareOrder.salesMode = convertSalesModeValue(
                selectedSaleModeValue
            );
            /**  must be calculated with this formula
             * totalttc = Somme des montantTotalTTC - (Somme des montantTotalTTC*(remiseGlobale.discountTaux/100))  */

            state.middlwareOrder.deviseCode = "euro";
            state.middlwareOrder.deviseTaux = 0;
            state.middlwareOrder.deviseLibelle = "euro";

            // state.middlwareOrder.complement.modeInfo.type =
            //     selectedInformationMode;

            // state.middlwareOrder.complement.modeReg.type =
            //     selectedRegulationModeValue;

            state.middlwareOrder.complement.modeInfo = chosenInformationMode;
            state.middlwareOrder.reglements = paymentModes;
            state.middlwareOrder.clientPhoneNumber = phoneNumber;

            state.middlwareOrder.complement.modeAuth.type = selectedAuthMode;

            order.orderItems.forEach((item) => {
                if (Object.keys(item?.shopCart).length === 0) {
                    state.middlwareOrder.lines.push({
                        lineUuid: item.iuudOrder,
                        orderUuid: state.middlwareOrder.orderUuid,
                        horodatage: item.date,
                        codeManager: state.middlwareOrder.codeManager,
                        nomManager: state.middlwareOrder.nomManager,
                        codeVendeur: state.middlwareOrder.codeVendeur,
                        nomVendeur: state.middlwareOrder.nomOVendeur,
                        reference: item.reference,
                        itemUuid: item.iuud,
                        typeLigne: "ARTICLE",
                        designation: item.name,
                        prix: item.price,
                        //prixUnitaireCarte: item.price,
                        qte: item.quantity,
                        poids: 1, // à verifier
                        unite: "PIECE", // à verifier
                        libFamille: item.categoryTitle,
                        libSousFamille: item.subcategoryTitle, // à ajouter
                        numFamille: item.categoryParent, // à ajouter
                        numSousFamille: 0, // à ajouter
                        libelleTVA: "", // à verifier
                        numTVA: 0, // à verifier
                        tauxTVA: item.tva !== undefined ? item.tva : 0, // à verifier
                        ipOrigine: machineIpAddress,
                        orderOrigine: "", // à ajouter
                        lineDiscount: lineDiscount,
                        montantTotalTTC:
                            item.price * item.quantity -
                            item.price *
                                item.quantity *
                                (lineDiscount.discountTaux / 100), // à verifier
                        montantTotalHT: 0, // à verifier
                        tag: "",
                        pointFid: item.fidelity,
                        ModifierUuid: "",
                        parentLineUuid: "",
                        stepTitle: "",
                        selectedOptions: item.selectedOptions,
                    });
                } else {
                    let orderInfo = {
                        orderUuid: state.middlwareOrder.orderUuid,
                        codeManager: state.middlwareOrder.codeManager,
                        nomManager: state.middlwareOrder.nomManager,
                        codeVendeur: state.middlwareOrder.codeVendeur,
                        nomVendeur: state.middlwareOrder.nomOVendeur,
                    };

                    state.middlwareOrder.lines.push({
                        lineUuid: item.iuudOrder,
                        orderUuid: state.middlwareOrder.orderUuid,
                        horodatage: item.date,
                        codeManager: state.middlwareOrder.codeManager,
                        nomManager: state.middlwareOrder.nomManager,
                        codeVendeur: state.middlwareOrder.codeVendeur,
                        nomVendeur: state.middlwareOrder.nomOVendeur,
                        reference: item.reference,
                        itemUuid: item.iuud,
                        typeLigne: item.haveWorkflow ? "MENU" : "Article",
                        designation: item.name,
                        prix: item.defaultPrice,
                        // prixUnitaireCarte: item.price,
                        qte: item.quantity,
                        poids: 1, // à verifier
                        unite: "PIECE", // à verifier
                        libFamille: item.categoryTitle,
                        libSousFamille: item.subcategoryTitle,
                        numFamille: item.categoryParent, // à ajouter
                        numSousFamille: 0, // à ajouter
                        libelleTVA: "", // à verifier
                        numTVA: 0, // à verifier
                        tauxTVA: item.tva !== undefined ? item.tva : 0, // to be removed
                        ipOrigine: machineIpAddress,
                        orderOrigine: "", // à ajouter
                        lineDiscount: lineDiscount,
                        montantTotalTTC:
                            item.defaultPrice * item.quantity -
                            item.defaultPrice *
                                item.quantity *
                                (lineDiscount.discountTaux / 100), // à verifier
                        montantTotalHT: 0, // à verifier
                        tag: "",
                        pointFid: item.fidelity,
                        ModifierUuid: "",
                        parentLineUuid: "",
                        stepTitle: "",
                        selectedOptions: item.selectedOptions,
                    });

                    fillMiddlwareOrder(
                        item.shopCart,
                        item.iuudOrder,
                        orderInfo
                    );

                    state.middlwareOrder.lines = [
                        ...state.middlwareOrder.lines,
                        ...data,
                    ];
                }
            });

            state.middlwareOrder.lines = [
                ...new Set(state.middlwareOrder.lines),
            ].sort((a, b) => {
                return a.horodatage - b.horodatage;
            });

            let totalTtc = 0;

            state.middlwareOrder.lines.forEach((line) => {
                // if child we must see parent qty
                if (line.parentLineUuid !== "") {
                    const parent = state.middlwareOrder.lines.find(
                        (parentLineUuid) =>
                            parentLineUuid.lineUuid === line.parentLineUuid
                    );

                    if (parent !== undefined) {
                        // a child
                        totalTtc += line.prix * line.qte * parent.qte;
                    } else {
                        totalTtc += line.prix * line.qte;
                    }
                } else {
                    // a parent
                    totalTtc += line.prix * line.qte;
                }
            });

            state.middlwareOrder.totalttc = totalTtc;
        },
        clearMiddlwareOrder: (state) => {
            state.middlwareOrder = {
                orderUuid: "",
                codeManager: "",
                nomManager: "",
                codeVendeur: "",
                nomOVendeur: "",
                nbClient: "",
                orderOrigine: "KIOSK",
                ipOrigine: "",
                numCommande: "",
                sourceOrderOrigine: "",
                sourceOrderNumber: 0,
                destinationOrderOrigine: "",
                destinationTicketNumber: 0,
                horodatage: 0,
                tag: "",
                dateFiscale: 0,
                OrderId: 0,
                remiseGlobale: {},
                salesMode: "",
                totalttc: 0,
                deviseCode: "",
                deviseTaux: 1,
                deviseLibelle: "",
                lines: [],
                reglements: [],
                complement: {
                    modeInfo: {
                        type: "",
                        data: { infoModeUuId: "", value: "" },
                    },
                    //      modeReg: { type: "", data: "" },
                    modeAuth: { type: "", data: "" },
                },
                clientPhoneNumber: "",
            };
        },
    },
});

function fillMiddlwareOrder(compoundItem, parent, orderInfo) {
    let compoundLines = [];

    Object.values(compoundItem).forEach((item) => {
        if (item?.compositions !== undefined) {
            if (item.name) {
                let localLineIuud = uuid();

                compoundLines.push({
                    lineUuid: localLineIuud,
                    orderUuid: orderInfo.orderUuid,
                    horodatage: item.date,
                    codeManager: orderInfo.codeManager,
                    nomManager: orderInfo.nomManager,
                    codeVendeur: orderInfo.codeVendeur,
                    nomVendeur: orderInfo.nomVendeur,
                    reference: item.reference,
                    itemUuid: item.iuud,
                    typeLigne: "MENU", //  typeLigne: item.haveWorkflow ? "MENU" : "Article",
                    designation: item.name,
                    prix: item.defaultPrice,
                    //prixUnitaireCarte: item.price,
                    qte: item.quantity,
                    poids: 0,
                    unite: "PIECE",
                    libFamille: item.categoryTitle,
                    libSousFamille: item.subcategoryTitle,
                    numFamille: item.categoryParent,
                    numSousFamille: 0,
                    libelleTVA: "",
                    numTVA: 0,
                    tauxTVA: item.tva !== undefined ? item.tva : 0,
                    ipOrigine: machineIpAddress,
                    orderOrigine: "",
                    lineDiscount: lineDiscount,
                    montantTotalTTC:
                        item.defaultPrice * item.quantity -
                        item.defaultPrice *
                            item.quantity *
                            (lineDiscount.discountTaux / 100),
                    montantTotalHT: 0,
                    tag: "",
                    pointFid: item.fidelity,
                    ModifierUuid: "",
                    parentLineUuid: parent,
                    stepTitle: item.stepTitle,
                });

                parent = localLineIuud;
            }

            Object.entries(item?.compositions).forEach(
                ([
                    key,
                    {
                        compositions,
                        name,
                        quantity,
                        price,
                        reference,
                        categoryTitle,
                        categoryParent,
                        iuud,
                        fidelity,
                        tva,
                        path,
                        date,
                        stepTitle,
                        selectedOptions,
                        subcategoryTitle,
                    },
                ]) => {
                    if (compositions === undefined) {
                        let localLineIuud = uuid();

                        compoundLines.push({
                            lineUuid: localLineIuud,
                            orderUuid: orderInfo.orderUuid,
                            horodatage: date,
                            codeManager: orderInfo.codeManager,
                            nomManager: orderInfo.nomManager,
                            codeVendeur: orderInfo.codeVendeur,
                            nomVendeur: orderInfo.nomVendeur,
                            reference: reference,
                            itemUuid: iuud,
                            typeLigne:
                                path[path.length - 3].name ===
                                "composition de base"
                                    ? "COMPOSANT DE BASE"
                                    : "Article",
                            designation: name,
                            prix:
                                path[path.length - 3].name ===
                                "composition de base"
                                    ? 0
                                    : price,
                            //prixUnitaireCarte: price,
                            qte: quantity,
                            poids: 0,
                            unite: "PIECE",
                            libFamille: categoryTitle,

                            libSousFamille: subcategoryTitle,
                            numFamille: categoryParent,
                            numSousFamille: 0,
                            libelleTVA: "",
                            numTVA: 0,
                            tauxTVA: tva !== undefined ? tva : 0,
                            ipOrigine: machineIpAddress,
                            orderOrigine: "",
                            lineDiscount: lineDiscount,
                            montantTotalTTC:
                                price * quantity -
                                price *
                                    quantity *
                                    (lineDiscount.discountTaux / 100),
                            montantTotalHT: 0,
                            tag: "",
                            pointFid: fidelity,
                            ModifierUuid: "",
                            parentLineUuid: parent,
                            stepTitle,
                            selectedOptions,
                        });
                    } else {
                        let localParent = parent;
                        if (name) {
                            let localLineIuud = uuid();
                            compoundLines.push({
                                lineUuid: localLineIuud,
                                orderUuid: orderInfo.orderUuid,
                                horodatage: date,
                                codeManager: orderInfo.codeManager,
                                nomManager: orderInfo.nomManager,
                                codeVendeur: orderInfo.codeVendeur,
                                nomVendeur: orderInfo.nomVendeur,
                                reference: reference,
                                itemUuid: iuud,
                                typeLigne: "MENU", //  typeLigne: item.haveWorkflow ? "MENU" : "Article",
                                designation: name,
                                prix: price,
                                //prixUnitaireCarte: price,
                                qte: quantity,
                                poids: 0,
                                unite: "PIECE",
                                libFamille: categoryTitle,
                                libSousFamille: subcategoryTitle,
                                numFamille: categoryParent,
                                numSousFamille: 0,
                                libelleTVA: "",
                                numTVA: 0,
                                tauxTVA: tva !== undefined ? tva : 0,
                                ipOrigine: machineIpAddress,
                                orderOrigine: "",
                                lineDiscount: lineDiscount,
                                montantTotalTTC:
                                    price * quantity -
                                    price *
                                        quantity *
                                        (lineDiscount.discountTaux / 100),
                                montantTotalHT: 0,
                                tag: "",
                                pointFid: fidelity,
                                ModifierUuid: "",
                                parentLineUuid: parent,
                                stepTitle,
                                selectedOptions,
                            });
                            localParent = localLineIuud;
                        }
                        fillMiddlwareOrder(
                            compositions,
                            localParent,
                            orderInfo
                        ); //key
                    }
                }
            );
        } else {
            let localLineIuud = uuid();

            compoundLines.push({
                lineUuid: localLineIuud,
                orderUuid: orderInfo.orderUuid,
                horodatage: item.date,
                codeManager: orderInfo.codeManager,
                nomManager: orderInfo.nomManager,
                codeVendeur: orderInfo.codeVendeur,
                nomVendeur: orderInfo.nomVendeur,
                reference: item.reference,
                itemUuid: item.iuud,
                typeLigne:
                    item.path[item.path.length - 3].name ===
                    "composition de base"
                        ? "COMPOSANT DE BASE"
                        : "Article",
                designation: item.name,
                prix:
                    item.path[item.path.length - 3].name ===
                    "composition de base"
                        ? 0
                        : item.price,
                //prixUnitaireCarte: item.price,
                qte: item.quantity,
                poids: 0,
                unite: "PIECE",
                libFamille: item.categoryTitle,
                libSousFamille: item.subcategoryTitle,
                numFamille: item.categoryParent,
                numSousFamille: 0,
                libelleTVA: "",
                numTVA: 0,
                tauxTVA: item.tva !== undefined ? item.tva : 0,
                ipOrigine: machineIpAddress,
                orderOrigine: "",
                lineDiscount: lineDiscount,
                montantTotalTTC:
                    item.price * item.quantity -
                    item.price *
                        item.quantity *
                        (lineDiscount.discountTaux / 100),
                montantTotalHT: 0,
                tag: "",
                pointFid: item.fidelity,
                ModifierUuid: "",
                parentLineUuid: parent,
            });
        }
    });
    data = [...data, ...compoundLines];
    return compoundLines;
}

export const { setMiddlwareOrder, clearMiddlwareOrder } =
    middlwareOrderSlice.actions;

export default middlwareOrderSlice.reducer;
