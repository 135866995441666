import { t } from "i18next";
import enLayout from "simple-keyboard-layouts/build/layouts/english";
import frLayout from "simple-keyboard-layouts/build/layouts/french";
import { useSnapshot } from "valtio";

import React from "react";
import Keyboard from "react-simple-keyboard";

import { kioskStore, setPseudoNameValue } from "@pages/Kiosk/store";

import "../UsualKeyboardWrapper/index.css";

export function InputKeyboardWrapper(): JSX.Element {
    const {
        project: {
            template: { keyboard: keyboardLayout },
        },
    } = useSnapshot(kioskStore);

    const [input, setInput] = React.useState<string>("");
    const [layoutName, setLayoutName] = React.useState<string>("default");
    const [enterKeyDisabled, setEnterKeyDisabled] =
        React.useState<boolean>(false);

    // eslint-disable-next-line
    const keyboard = React.useRef<any>(null);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const enterButtonDisabledAttribute = enterKeyDisabled
        ? [
              {
                  attribute: "disabled",
                  value: `${enterKeyDisabled}`,
                  buttons: "{enter}",
              },
          ]
        : [];

    function setInputCaretPosition(
        element: HTMLInputElement,
        position: number
    ) {
        if (element.setSelectionRange) {
            element.focus();
            element.setSelectionRange(position, position);

            if (position === element.value.length)
                element.scrollLeft = element.scrollWidth;
        }
    }

    function handleKeyboardOnChangeEvent(input: string) {
        setInput(input);
        setPseudoNameValue(input);
        const caretPosition = keyboard.current.caretPosition;

        if (caretPosition !== null) {
            setInputCaretPosition(
                inputRef.current as unknown as HTMLInputElement,
                keyboard.current.caretPosition
            );
        }
    }

    function handleShift() {
        setLayoutName(layoutName === "default" ? "shift" : "default");
    }

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        }
    }

    function onBlurInput(event: React.ChangeEvent<HTMLInputElement>) {
        keyboard.current.caretPosition = event.target.selectionStart;
    }

    React.useEffect(() => {
        setEnterKeyDisabled(input.length === 0);
    }, [input]);

    return (
        <div
            className="p-1"
            style={{
                display: "grid",
                border: "1px solid rgb(221, 214, 214)",
                borderRadius: "10px",
                boxShadow: "rgba(5, 4, 4, 0.15) 0px 2px 4px",
                gap: "15px",
                width: "95%",
            }}
        >
            <div className="d-flex justify-content-center">
                <input
                    ref={inputRef}
                    value={input}
                    placeholder={t("Name or pseudonym")}
                    className="w-50 text-center"
                    style={{
                        font: "normal normal 300 34px/46px Segoe UI",
                        border: "none",
                        borderBottom: "1px solid rgb(221, 214, 214)",
                        height: "60px",
                    }}
                    onBlur={onBlurInput}
                />
            </div>
            <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                theme="keyboard__clz hg-theme-default ml-5"
                layout={
                    keyboardLayout === "azerty"
                        ? frLayout.layout
                        : enLayout.layout
                }
                layoutName={layoutName}
                onChange={handleKeyboardOnChangeEvent}
                onKeyPress={handleKeyboardOnKeyPressEvent}
                buttonTheme={[
                    {
                        class: "enter-key__clz",
                        buttons: "{enter}",
                    },
                ]}
                preventMouseDownDefault={true}
                display={{
                    "{bksp}": `<img src="./images/backspace.svg" />`,
                    "{enter}": t("Enter key"),
                    "{tab}": `<img src="./images/tab.svg" width="25" height="25" />`,
                    "{lock}": `<img src="./images/caps.svg" width="25" height="25" />`,
                    "{shift}": `<img src="./images/shift.svg" />`,
                    "{space}": " ",
                }}
                buttonAttributes={enterButtonDisabledAttribute}
            />
        </div>
    );
}
