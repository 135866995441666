import { Dialog } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import { kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

export function BasketChangeConfirmationDialog({ open, onClose, isPrm }) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const {
        project: {
            files: images,
            template: {
                content: { actionsButtons },
            },
        },
        customerLanguage,
    } = useSnapshot(store);
    return (
        <Dialog
            onClose={onClose}
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "50px!important",
                    alignItems: "center !important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "1335px!important",
                    width: "860px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    borderTop: `15px solid ${globalColor}!important`,
                    top: isPrm ? "15%" : "unset",
                    right: "unset!important",
                    left: "unset!important",
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div className="logo-alert">
                <img
                    src={getAlertActionButtonImageSrc(
                        actionsButtons,
                        images,
                        customerLanguage.name
                    )}
                    style={{ width: "35%", height: "70%" }}
                />
            </div>
            <div className="content-alert-cart">
                <span>
                    {t("Be careful with your order")}
                    <br /> {t("has undergone changes.")}
                </span>
            </div>
            <div
                className="footer-alert-cart"
                onClick={() => {
                    onClose();
                }}
            >
                <span className="text-footer-alert-cart">
                    {t("Click to")} <br />
                    {t("Check your order")}
                </span>
            </div>
        </Dialog>
    );
}

BasketChangeConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isPrm: PropTypes.bool,
};
