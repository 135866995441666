import { BASIC_COMPOSITION, COMPOSITIONS } from "@constants";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
        opacity: 1,
        transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
    }),
};

const child = {
    visible: {
        opacity: 1,
        x: 0,

        transition: {
            type: "spring",
            damping: 12,
            stiffness: 100,
        },
    },
    hidden: {
        opacity: 0,
        x: 0,

        transition: {
            type: "spring",
            damping: 12,
            stiffness: 100,
        },
    },
};

function WorkflowTree() {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const { isBreadcrumbTrailActive, isInformationMessageActive } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const { secondaryColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const { workflowData } = useSelector((state) => state.workFlowDataSlice);
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice
    );

    let workflowTree = [orderWorkflowItemSlice.item.name];

    if (workflowData.length > 0) {
        let listworkflow = workflowData[workflowData.length - 1];

        let path = [];
        listworkflow?.workflow?.[listworkflow.index]?.path.forEach((elm) => {
            if (elm.name !== COMPOSITIONS) {
                if (elm.name.indexOf("|") > 1) {
                    elm.name.substr(0, elm.name.indexOf("|"));
                } else {
                    path.push(elm.name);
                }
            }
        });

        // title step
        let Step = listworkflow?.workflow?.[listworkflow.index]?.title;
        workflowTree = [...workflowTree, ...path];
        if (!workflowTree.includes(Step)) {
            workflowTree.push(Step);
        }
    }

    return (
        <>
            <AnimatePresence>
                {isBreadcrumbTrailActive ? (
                    <div className="workflow-tree d-flex align-items-center px-1 w-100">
                        {workflowTree?.map((item, index) => {
                            const name = item?.name ? item.name : item;
                            return index !== workflowTree.length - 1 ? (
                                <React.Fragment key={name}>
                                    <div className="workflow-step d-flex justify-content-center d-flex align-items-center mr-1">
                                        <span className="mx-3">
                                            {name.toUpperCase()}
                                        </span>
                                    </div>
                                    <div className="mx-1">{" / "}</div>
                                </React.Fragment>
                            ) : (
                                <div
                                    key={name}
                                    className="workflow-step d-flex justify-content-center d-flex align-items-center mr-1"
                                    style={{
                                        backgroundColor: `${globalColor} `,
                                        color: "white",
                                    }}
                                >
                                    <span
                                        className="mx-3"
                                        style={{
                                            color: secondaryColor,
                                        }}
                                    >
                                        {name?.toUpperCase()}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {!isInformationMessageActive &&
                workflowData[workflowData.length - 1]?.workflow[
                    workflowData[workflowData.length - 1]?.index
                ]?.type !== BASIC_COMPOSITION ? (
                    <motion.div
                        key="titlechoice"
                        variants={container}
                        initial="hidden"
                        animate="visible"
                        className="d-flex justify-content-center workflow-tree-step-title pt-2 "
                        style={{ flexDirection: "column" }}
                    >
                        <motion.span variants={child}>
                            {`Veuillez choisir les ${
                                workflowTree[workflowTree.length - 1]
                            }
                de votre ${orderWorkflowItemSlice.item.name}    
                `}
                        </motion.span>

                        <motion.span
                            key="nbrchoice"
                            variants={child}
                            className="my-2"
                        >
                            {workflowData[workflowData.length - 1]?.workflow[
                                workflowData[workflowData.length - 1]?.index
                            ]?.minNbrOfChoices > 0
                                ? `(${
                                      workflowData[workflowData.length - 1]
                                          ?.workflow[
                                          workflowData[workflowData.length - 1]
                                              ?.index
                                      ]?.minNbrOfChoices
                                  } choix)`
                                : " "}
                        </motion.span>

                        {/* // ! this for dev  */}
                        {/* Minimum par étape :{" "}
                    {
                        workflowData[workflowData.length - 1]?.workflow[
                            workflowData[workflowData.length - 1]?.index
                        ]?.minNbrOfChoices
                    }{" "}
                    choix , Maximum par étape :{" "}
                    {
                        workflowData[workflowData.length - 1]?.workflow[
                            workflowData[workflowData.length - 1]?.index
                        ]?.maxNbrOfChoices
                    }{" "}
                    choix , Nbre de choix :{}
                    {
                        workflowData[workflowData.length - 1]?.workflow[
                            workflowData[workflowData.length - 1]?.index
                        ]?.NbrOfChoices
                    }{" "}
                    choix
                    <row>
                        <span className="mx-3">
                            nbrOfChoicesWithStepPrice:
                            {
                                workflowData[workflowData.length - 1]?.workflow[
                                    workflowData[workflowData.length - 1]?.index
                                ]?.nbrOfChoicesWithStepPrice
                            }
                        </span>

                        <span>
                            selectedChoicesWithItemPrice:
                            {
                                workflowData[workflowData.length - 1]?.workflow[
                                    workflowData[workflowData.length - 1]?.index
                                ]?.selectedChoicesWithStepPrice
                            }
                        </span>
                    </row>
                    <row>
                        <span className="mx-3">
                            nbrOfChoicesWithspecialPrice:{" "}
                            {
                                workflowData[workflowData.length - 1]?.workflow[
                                    workflowData[workflowData.length - 1]?.index
                                ]?.nbrOfChoicesWithspecialPrice
                            }
                        </span>
                        <span>
                            selectedChoicesWithspecialPrice:
                            {
                                workflowData[workflowData.length - 1]?.workflow[
                                    workflowData[workflowData.length - 1]?.index
                                ]?.selectedChoicesWithspecialPrice
                            }
                        </span>
                    </row> */}
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </>
    );
}

export default WorkflowTree;
