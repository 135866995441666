import { createAsyncThunk } from "@reduxjs/toolkit";
import { snapshot } from "valtio";

import { store } from "@store";

const { middlewareApiUrl } = snapshot(store);

export const getCard = createAsyncThunk("carte/getCarte", async () => {
    let result;
    let url;
    let catalog = JSON.parse(localStorage.getItem("card"));
    const schema = localStorage.getItem("viewADB-schema");
    const uuidCatalog = localStorage.getItem("viewADB-iuudCard");

    if (schema !== null && uuidCatalog !== null) {
        localStorage.setItem("isAppSynchronized", true);
        url = `${process.env.REACT_APP_CATALOGUE_API_URL}/Kioskview/${schema}/${uuidCatalog}`;
    }
    console.log(
        "🚀 ~ file: card.js:19 ~ getCard ~ url:",
        url,
        schema,
        uuidCatalog
    );

    result = await fetch(url, {
        headers: { "Cache-Control": "no-cache" },
    }).then((data) => {
        const rawData = data.json();
        return rawData.error === true ? null : rawData;
    });

    // if (process.env.REACT_APP_CARD_ENV === "dev") {
    //     // url = `${process.env.REACT_APP_CATALOGUE_API_URL}/Kioskview/franchise_demo_51/d316277a-6f05-371e-853c-986c1138b409`;

    //     url = `https://demo-catalogue-api.aureskonnect.com/api_etk_article_bd/v1/kiosk/franchise_demo_33/3/card`;
    //     url = `http://192.168.2.62:8080/api_etk_article_bd/v1/Kioskview/franchise_demo_4/40a01286-db0a-022a-5685-6d2e6d45c886`;
    //     url = `https://demo-catalogue-api.aureskonnect.com/api_etk_article_bd/v1/kiosk/franchise_maestro_food_test_etk_kiosk/2/card`;
    //     result = await fetch(url).then((data) => {
    //         const rawData = data.json();
    //         return rawData.error === true ? null : rawData;
    //     });

    //     try {
    //         localStorage.setItem("card", JSON.stringify(result));
    //     } catch (error) {
    //         console.log("can't save catalogue", error);
    //     }

    //     return result;
    // }

    if (catalog === null) {
        const shopDetail = JSON.parse(localStorage.getItem("shopDetail"));

        if (shopDetail !== null) {
            const timestamp = new Date().getTime(); // current timestamp
            url = `${middlewareApiUrl}/kiosk/${shopDetail.schema}/${shopDetail.id}/card?timestamp=${timestamp}`;

            if (schema !== null && uuidCatalog !== null) {
                localStorage.setItem("isAppSynchronized", true);
                url = `${process.env.REACT_APP_CATALOGUE_API_URL}/Kioskview/${schema}/${uuidCatalog}`;
            }

            result = await fetch(url, {
                headers: { "Cache-Control": "no-cache" },
            }).then((data) => {
                const rawData = data.json();
                return rawData.error === true ? null : rawData;
            });
        } else if (schema && uuidCatalog) {
            localStorage.setItem("isAppSynchronized", true);
            url = `${process.env.REACT_APP_CATALOGUE_API_URL}/Kioskview/${schema}/${uuidCatalog}`;
            result = await fetch(url).then((data) => {
                const rawData = data.json();
                return rawData.error === true ? null : rawData;
            });
        } else {
            result = await fetch("./card.json").then((data) => {
                return data.json();
            });
        }
        try {
            localStorage.setItem("card", JSON.stringify(result));
        } catch (error) {
            console.log("can't save catalogue", error);
        }

        return result;
    } else {
        return catalog;
    }
});
