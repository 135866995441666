import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import i18n from "@i18n";

import {
    kioskStore,
    setIsAuthenticationModalOpened,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setIsScanQrCodeModalOpened,
    setLocalProject,
    setPagesItems,
    setQrCodeValue,
} from "@pages/Kiosk/store";

import "./index.css";
import { LocalSettingsHeader } from "./LocalSettingsHeader";

export function LocalSettings(): JSX.Element {
    const { isLocalSettingModalOpened, project, qrCodeValue } =
        useSnapshot(kioskStore);

    function handleOnChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
        setQrCodeValue(e.target.value);
    }

    React.useEffect(() => {
        setLocalProject(project);
        setPagesItems(project.template.pages);
    }, []);

    React.useEffect(() => {
        i18n.changeLanguage("fr");
    }, []);

    React.useEffect(() => {
        if (qrCodeValue === "2396 3309 7947 78") {
            setIsLocalConfigModalOpened(true);
            setIsLocalSettingModalOpened(false);
        } else if (
            qrCodeValue.length === 17 &&
            qrCodeValue !== "2396 3309 7947 78"
        ) {
            setIsScanQrCodeModalOpened(true);
        }
    }, [qrCodeValue]);

    return (
        <Modal
            isOpen={isLocalSettingModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1366px",
                    width: "914px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <LocalSettingsHeader />
            <div
                className="d-flex flex-column align-items-center"
                style={{
                    height: "1150px",
                    gap: "20px",
                    marginTop: "150px",
                }}
            >
                <div
                    style={{
                        height: "206px",
                        width: "206px",
                        position: "relative",
                    }}
                >
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            position: "fixed",
                            height: "206px",
                            width: "206px",
                        }}
                    >
                        <source src="./images/settings.mp4" type="video/mp4" />
                    </video>
                </div>
                <span
                    style={{
                        marginLeft: "240px",
                        marginRight: "240px",
                        marginBottom: "60px",
                        font: "normal normal 600 35px/47px Segoe UI",
                        textAlign: "center",
                    }}
                >
                    {t("To access the configuration interface, please")}
                </span>
                <span
                    style={{
                        font: "normal normal bold 25px/33px Segoe UI",
                        textAlign: "center",
                        marginTop: "100px",
                    }}
                >
                    {t("Scan your badge")}
                </span>
                <span
                    style={{
                        font: "normal normal normal 20px/23px Helvetica Neue",
                        textAlign: "center",
                    }}
                >
                    {" "}
                    {t("Or")}
                </span>
                <StyledButton
                    rounded={true}
                    className="m-2 authentication-button__clz"
                    style={{
                        width: "452px",
                        height: "75px",
                        font: "normal normal 600 18px/24px Segoe UI",
                    }}
                    onClick={() => {
                        setIsAuthenticationModalOpened(true);
                        setIsLocalSettingModalOpened(false);
                    }}
                >
                    {t("Enter your username and password")}
                </StyledButton>
                <input
                    className="form-input text-center hidden-qr-code__clz"
                    type="password"
                    value={qrCodeValue}
                    autoFocus={true}
                    onChange={handleOnChangeEvent}
                />
            </div>
        </Modal>
    );
}
