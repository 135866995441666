import {
    ALLERGENS_OPTION,
    ASK_FOR_HELP_BUTTON,
    DELIVERY_MODE,
    PRM_BUTTON,
} from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";

import { getImageContentById } from "@helpers/general";

import { getOrdertakingDesignOptions } from "@components/OrderTaking/Helpers";

import {
    setAuthModalStepIndex,
    setIsLogoutProblemModalOpened,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setUser,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

export function HeaderConfigOptionsWrapper({
    handleAllergenDialogOnCLickEvent,
}) {
    const {
        navigationIndex,
        isUserAuthenticated,
        isUserAccountModalOpened,
        kioskWays,
        project: { files: images },
        customerLanguage,
        user,
        activeConfigOptions,
        selectedSaleModeValue,
    } = useSnapshot(store);
    const { designItems } = useSelector((state) => state.settingSlice);

    const [isLogoutButtonClicked, setIsLogoutButtonClicked] =
        React.useState(false);

    const optionList = [PRM_BUTTON, ASK_FOR_HELP_BUTTON, ALLERGENS_OPTION];
    const allergen = designItems.find(
        (option) => option.name === ALLERGENS_OPTION
    );
    const newOptionConfig = getOrdertakingDesignOptions(
        activeConfigOptions,
        designItems,
        optionList
    );

    if (allergen?.active === true) {
        newOptionConfig.push(allergen);
    }

    const filteredOptions = newOptionConfig.filter((option) => {
        if (!isUserAuthenticated) {
            return (
                option.active === true &&
                (option.name.toLowerCase() === "customer account" ||
                    option.name.toLowerCase() === "allergen")
            );
        } else {
            return (
                option.active === true &&
                option.name.toLowerCase() === "allergen"
            );
        }
    });

    function handleConfigOptionOnClickEvent(option) {
        if (option.name.toLowerCase() === "allergen") {
            handleAllergenDialogOnCLickEvent();
        } else if (
            option.name.toLowerCase() === "customer account" &&
            !isUserAuthenticated
        ) {
            setIsUserAccountModalOpened(true);
            setAuthModalStepIndex(navigationIndex);
        }
    }

    return (
        <>
            <div
                className="d-flex justify-content-end align-items-center w-50 px-3 py-2"
                style={{ gap: "5px", height: "100%" }}
            >
                {isLogoutButtonClicked ? (
                    <Tooltip
                        className="order-taking-tooltip"
                        placement="bottom-end"
                        isOpen={isLogoutButtonClicked}
                        target={"Tooltip"}
                        onClick={() => {
                            if (selectedSaleModeValue === DELIVERY_MODE) {
                                setIsLogoutProblemModalOpened(true);
                                setIsLogoutButtonClicked(false);
                            } else {
                                setIsUserAuthenticated(false);
                                setIsLogoutButtonClicked(false);
                                setUser({
                                    firstName: "",
                                    lastName: "",
                                    phoneNumber: "",
                                    email: "",
                                    loyaltyAccount: 0,
                                    loyaltyAmount: 0,
                                });
                            }
                        }}
                        style={{
                            cursor: "pointer",
                            height: "50px",
                            borderRadius: "5px",
                        }}
                    >
                        <span style={{ font: "normal 26px/30px Segoe UI" }}>
                            {t("Sign out")}
                        </span>
                    </Tooltip>
                ) : null}
                {isUserAuthenticated ? (
                    <div id="Tooltip">
                        <Avatar
                            email={user.email}
                            name={user.lastName}
                            size="55px"
                            round="10px"
                            onClick={() =>
                                setIsLogoutButtonClicked(!isLogoutButtonClicked)
                            }
                        />
                    </div>
                ) : null}
                {filteredOptions.length > 0
                    ? filteredOptions.map((option) => {
                          const imageContent = getImageContentById(
                              option.id,
                              images,
                              customerLanguage.name
                          );

                          const imageSrc =
                              imageContent?.includes("http") ||
                              imageContent?.includes("https")
                                  ? imageContent
                                  : `./images_order/${imageContent}`;

                          return (
                              <div
                                  className="d-flex"
                                  key={option.id}
                                  style={{ width: "50%" }}
                              >
                                  <img
                                      src={imageSrc}
                                      alt={option.name}
                                      onClick={() => {
                                          handleConfigOptionOnClickEvent(
                                              option
                                          );
                                      }}
                                      style={{
                                          maxHeight: "65px",
                                          width: "100%",

                                          opacity:
                                              option.name.toLowerCase() ===
                                                  "customer account" &&
                                              (isUserAccountModalOpened ||
                                                  isUserAuthenticated ||
                                                  kioskWays[navigationIndex]
                                                      .name === "connection" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModes" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModeConsommation")
                                                  ? "0.5"
                                                  : "1",
                                          pointerEvents:
                                              option.name.toLowerCase() ===
                                                  "customer account" &&
                                              (isUserAccountModalOpened ||
                                                  isUserAuthenticated ||
                                                  kioskWays[navigationIndex]
                                                      .name === "connection" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModes" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModeConsommation")
                                                  ? "none"
                                                  : "auto",
                                      }}
                                  />
                              </div>
                          );
                      })
                    : null}
            </div>
        </>
    );
}

HeaderConfigOptionsWrapper.propTypes = {
    handleAllergenDialogOnCLickEvent: PropTypes.func,
};
