import { useSnapshot } from "valtio";

import React from "react";

import {
    getBackgroundImagePath,
    getSubStepBackgroundImagePath,
} from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

type PropsType = {
    children: React.ReactNode;
    selectedMode?: string;
};

export function PreviewBackgroundWrapper({
    children,
    selectedMode,
}: PropsType): JSX.Element {
    const {
        project: { files: images },
        kioskWays,
        navigationIndex,
        isPrm,
    } = useSnapshot(kioskStore);

    const filename =
        selectedMode !== undefined
            ? getSubStepBackgroundImagePath(images, selectedMode)
            : getBackgroundImagePath(images, kioskWays, navigationIndex);

    const possibleExtensions = ["mp4", "mov", "avi", "flv", "wmv"];

    const extension = filename.split(".")[filename.split(".").length - 1];

    return (
        <React.Fragment>
            {possibleExtensions.includes(extension) ? (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            objectFit: "cover",
                            height:
                                isPrm &&
                                kioskWays[navigationIndex].name !==
                                    "finalMessage" &&
                                kioskWays[navigationIndex].name !== "payment" &&
                                kioskWays[navigationIndex].name !==
                                    "loyaltyConsommation"
                                    ? "920px"
                                    : "100%",
                            width: "100%",
                            zIndex: -2,
                        }}
                    >
                        <source src={filename} />
                    </video>
                    {children}
                </React.Fragment>
            ) : (
                <div
                    style={{
                        backgroundImage: `url(${filename})`,
                        backgroundSize: "cover",
                        height:
                            isPrm &&
                            kioskWays[navigationIndex].name !==
                                "finalMessage" &&
                            kioskWays[navigationIndex].name !== "payment" &&
                            kioskWays[navigationIndex].name !==
                                "loyaltyConsommation"
                                ? "920px"
                                : "100%",
                        width: "100%",
                        zIndex: -2,
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}
