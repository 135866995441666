import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";

import { formatCurrency } from "@helpers/general";

import { kioskStore as store } from "@pages/Kiosk/store";

export function LoyaltyConsommationCard(): JSX.Element {
    const {
        order: { totalPrice },
        loyaltyTotal,
        // eslint-disable-next-line
    } = useSelector((state: any) => state.orderSlice);

    const {
        customerLanguage,
        project: {
            template: { content },
        },
        user,
    } = useSnapshot(store);

    const localMainContentItems = (
        content.loyaltyConsommation as ProjectContentItemType
    ).items;

    return (
        <Card
            style={{
                width: "910px",
                height: "300px",
                borderRadius: "31px",
                borderBottom: "12px solid #D51B1B",
                boxShadow: "0px 3px 6px #00000029",
                font: "Segoe UI",
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateRows: "1fr 0.1fr  0.8fr 0.8fr",
                    height: "100%",
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        gap: "280px",
                        font: "normal normal normal 50px/128px Segoe UI",
                    }}
                >
                    {
                        (
                            localMainContentItems as ProjectMainContentItemType[]
                        )[0].languages[customerLanguage.name].name
                    }

                    <div className="font-weight-bold">{totalPrice}€</div>
                </div>
                <hr
                    style={{
                        height: "2px",
                        width: "88%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                    }}
                />

                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-2"
                    style={{
                        font: "normal normal 600 45px/60px Segoe UI",
                    }}
                >
                    {
                        (
                            localMainContentItems as ProjectMainContentItemType[]
                        )[1].languages[customerLanguage.name].name
                    }

                    <div>{formatCurrency(user.loyaltyAmount)}</div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-4"
                    style={{
                        font: "normal normal normal 30px/40px Segoe UI",
                        color: "#7C7C7C",
                    }}
                >
                    {
                        (
                            localMainContentItems as ProjectMainContentItemType[]
                        )[2].languages[customerLanguage.name].name
                    }

                    <div>{loyaltyTotal}</div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-4"
                    style={{
                        font: "normal normal normal 15px/20px Segoe UI",
                        color: "#7C7C7C",
                    }}
                >
                    {
                        (
                            localMainContentItems as ProjectMainContentItemType[]
                        )[3].languages[customerLanguage.name].name
                    }
                </div>
            </div>
        </Card>
    );
}
