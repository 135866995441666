import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import React from "react";

import { setIsSyncAuthenticationModalOpened } from "@components/Synchronization/store";

export function ModalMainContentWrapper(): JSX.Element {
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center m-5"
            style={{
                height: "800px",
                gap: "20px",
            }}
        >
            <video
                autoPlay={true}
                muted={true}
                loop={true}
                style={{
                    height: "206px",
                    width: "206px",
                }}
            >
                <source src="./images/settings.mp4" type="video/mp4" />
            </video>
            <span
                className="text-uppercase"
                style={{
                    marginLeft: "100px",
                    marginRight: "100px",
                    marginBottom: "150px",
                    font: "normal normal bold 35px/42px Segoe UI",
                    textAlign: "center",
                    letterSpacing: "0px",
                }}
            >
                {t("To access the configuration interface, please")}
            </span>
            <span
                style={{
                    font: "normal normal bold 25px/30px Segoe UI",
                    textAlign: "center",
                    color: "#7E7E7E",
                    letterSpacing: "0px",
                }}
            >
                {t("Scan your badge")}
            </span>
            <span
                className="text-uppercase"
                style={{
                    font: "normal normal normal 20px/24px Segoe UI",
                    textAlign: "center",
                    color: "#7E7E7E",
                    letterSpacing: "0px",
                }}
            >
                {t("Or")}
            </span>
            <StyledButton
                rounded={true}
                className="m-2 text-uppercase auth-button__clz"
                onClick={() => {
                    setIsSyncAuthenticationModalOpened(true);
                }}
            >
                {t("Enter your username and password")}
            </StyledButton>
        </div>
    );
}
