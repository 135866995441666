import { ASK_FOR_HELP_BUTTON, PRM_BUTTON, USUAL_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { getOrdertakingOptions } from "@components/OrderTaking/Helpers";

import {
    setIsHelpModalOpened,
    setIsPrm,
    kioskStore as store,
} from "@pages/Kiosk/store";

export function OrderTakingConfigOptionsWrapper() {
    const {
        isPrm,
        customerLanguage,
        project: { files: images },
        activeConfigOptions,
    } = useSnapshot(store);
    const { orderTakingOptions } = useSelector((state) => state.settingSlice);

    const newOrderTakionOptions = orderTakingOptions.filter(
        (config) => config.title.toLowerCase() !== USUAL_MODE
    );
    const newOptionConfig = getOrdertakingOptions(
        activeConfigOptions,
        newOrderTakionOptions
    );

    function handleConfigOptionOnClickEvent(option) {
        if (
            option.title.toLowerCase() === PRM_BUTTON ||
            option.title.toLowerCase() === USUAL_MODE
        ) {
            setIsPrm(!isPrm);
        } else if (option.title.toLowerCase() === ASK_FOR_HELP_BUTTON) {
            setIsHelpModalOpened(true);
        }
    }

    return (
        <div
            className="d-flex justify-content-end align-items-center px-3"
            style={{ gap: "5px" }}
        >
            {newOptionConfig.map((option, index) => {
                let imageContent = "";
                imageContent = getImageContentById(
                    option.id,
                    images,
                    customerLanguage.name
                );
                /* this if statement is incorrect because it should be fixed in settings kios so it's temporary */
                if (option.title.toLowerCase() === "prm/child") {
                    imageContent =
                        typeof option["content"] !== "string"
                            ? isPrm
                                ? option["content"].usual
                                : getImageContentById(
                                      option.id,
                                      images,
                                      customerLanguage.name
                                  )
                            : option.content;
                }

                const imageSrc =
                    imageContent?.includes("http") ||
                    imageContent?.includes("https")
                        ? imageContent
                        : `./images/${imageContent}`;

                return (
                    <img
                        key={index}
                        src={imageSrc}
                        alt={option.title}
                        onClick={() => {
                            handleConfigOptionOnClickEvent(option);
                        }}
                        style={{
                            height: "70px",
                            width: "70px",
                        }}
                    />
                );
            })}
        </div>
    );
}
