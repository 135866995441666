import { ITEM_TYPE, STEP_TYPE } from "@constants";

const getlistitems = (globalcard, iuudStep, iuudMD5, path, typeModifier) => {
    let listItems = [];
    globalcard[STEP_TYPE]?.[iuudStep]?.stepItems &&
        Object.entries(globalcard[STEP_TYPE]?.[iuudStep]?.stepItems).forEach(
            ([iuud]) => {
                /// product is modifier
                if (
                    Object.keys(globalcard.modifier[iuudMD5][typeModifier])
                        .length === 0
                ) {
                    return;
                }

                const modifier = globalcard.modifier[iuudMD5][typeModifier][
                    iuudStep
                ].itemModifiers[iuud]
                    ? globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                          .itemModifiers[iuud]
                    : "";

                if (
                    Object.prototype.hasOwnProperty.call(
                        globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                            .itemModifiers,
                        iuud
                    )
                ) {
                    listItems = [
                        ...listItems,
                        {
                            iuud: iuud,
                            ...globalcard.items?.[iuud],
                            type: ITEM_TYPE,

                            modifier,
                            isAdditionnalProduct: true,
                        },
                    ];
                } else {
                    listItems = [
                        ...listItems,
                        {
                            iuud,
                            ...globalcard.items?.[iuud],

                            type: ITEM_TYPE,

                            modifier,
                            isAdditionnalProduct: true,
                        },
                    ];
                }
            }
        );
    // list items in step
    return listItems.filter((elm) => elm.modifier === "");
};

export function useAdditionnalSaleItems(globalcard, product) {
    const iuudMD5 = product.modifier;
    let additionalSale = {};
    // let workflowAdd = [];
    globalcard.modifier[iuudMD5]?.add &&
        Object.entries(globalcard.modifier[iuudMD5]?.add).length > 0 &&
        Object.entries(globalcard.modifier[iuudMD5]?.add)
            .sort((a, b) => a[1].rank - b[1].rank)
            .forEach(([iuudStep]) => {
                ///path step
                const currentStep =
                    globalcard.modifier[iuudMD5]?.steps[iuudStep]?.overrides &&
                    Object.keys(
                        globalcard.modifier[iuudMD5]?.steps[iuudStep].overrides
                    ).length > 0
                        ? globalcard.modifier[iuudMD5]?.steps[iuudStep]
                              .overrides
                        : globalcard?.steps?.[iuudStep];
                let path = [];

                const titleStep =
                    currentStep.displayName.Default.nameDefault !== ""
                        ? currentStep.displayName.Default.nameDefault
                        : currentStep.title;

                if (product.modifier) {
                    path.push({ name: product.title, iuud: product.iuud });
                    // titleStep = product.title;
                } else {
                    path.push({
                        name: globalcard?.steps?.[iuudStep]?.title,
                        iuud: iuudStep,
                    });
                }
                const listItems = getlistitems(
                    globalcard,
                    iuudStep,
                    iuudMD5,
                    path,
                    "add"
                );
                const filteredItems = listItems.filter(
                    (item) => item.modifier === ""
                );
                additionalSale = {
                    name: titleStep,
                    listItems: filteredItems,
                    selectedItems: [],
                };
            });

    return additionalSale;
}
