import React from "react";

import { KioskAppLogoSvgIcon } from "@components/SvgIcons";

export function VideoBackgroundHeaderWrapper(): JSX.Element {
    return (
        <header style={{ height: "157px" }}>
            <video
                autoPlay={true}
                muted={true}
                loop={true}
                style={{
                    objectFit: "cover",
                    position: "fixed",
                    height: "157px",
                    width: "913px",
                    zIndex: -1,
                }}
            >
                <source src="./images/background.mp4" type="video/mp4" />
            </video>
            <div className="d-flex justify-content-center align-items-center">
                <KioskAppLogoSvgIcon width="300" height="157" />
            </div>
        </header>
    );
}
