import React from "react";
import { PuffLoader } from "react-spinners";

export function SpinnerWrapper(): JSX.Element {
    return (
        <div className="h-100vh__clz d-flex justify-content-center align-items-center">
            <PuffLoader color="#0c1b28" />
        </div>
    );
}
