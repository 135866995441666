import React from "react";

export function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="114.031"
            height="114.029"
            data-name="close (3)"
            viewBox="0 0 114.031 114.029"
        >
            <g data-name="Groupe 8771">
                <path
                    fill="#d41b1b"
                    fillRule="evenodd"
                    d="M177.792 69.378a9.581 9.581 0 010 13.553L82.93 177.792a9.583 9.583 0 01-13.552-13.552l94.864-94.864a9.584 9.584 0 0113.55.002z"
                    data-name="Tracé 8656"
                    transform="translate(-66.572 -66.571)"
                ></path>
            </g>
            <g data-name="Groupe 8772">
                <path
                    fill="#d41b1b"
                    fillRule="evenodd"
                    d="M69.378 69.378a9.585 9.585 0 0113.553 0l94.864 94.862a9.584 9.584 0 11-13.553 13.555L69.378 82.93a9.585 9.585 0 010-13.552z"
                    data-name="Tracé 8657"
                    transform="translate(-66.572 -66.572)"
                ></path>
            </g>
        </svg>
    );
}
