import PropTypes from "prop-types";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddProductConfirmation } from "@components/OrderTaking/Components/Dialogs/AddProductConfirmation";
import ShowDetailsDialog from "@components/OrderTaking/Components/Dialogs/ShowDetails/ShowDetailsDialog";
import { WorkflowDialog } from "@components/OrderTaking/Components/Dialogs/WorkflowDialog";
import { AddIconWrapper } from "@components/OrderTaking/Components/Icons/AddIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";
import { useToggle } from "@components/OrderTaking/Helpers";
import {
    addToOrder,
    addToOrderworkflow,
    ClearWorkflowItem,
    memoizedOrderItems,
    removeFromOrder,
    SelectProduct,
    setIsModification,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

export default function SimpleProductCartPreview({ product }) {
    const dispatchStore = useDispatch();
    const [isWorkflowDialogOpened, setIsWorkflowDialogOpened] = useToggle();
    const [
        isAddProductConfirmationDialogOpened,
        setIsAddProductConfirmationDialogOpened,
    ] = useToggle();
    const orderItems = useSelector((state) => memoizedOrderItems(state));

    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    const currentItem = orderItems.find(
        (x) => x.iuudOrder === product.iuudOrder
    );
    const [isShowDetailsOpened, setIsShowDetailsOpened] = React.useState(false);
    const quantity =
        typeof currentItem !== "undefined" ? currentItem.quantity : 0;
    const available =
        typeof currentItem !== "undefined"
            ? currentItem.available
            : product.available;
    const opacity =
        (quantity === product.maxQuantity) | (available === false)
            ? "0.2"
            : "1";
    const productOrderHandler = (product) => {
        dispatchStore(removeFromOrder(product));
    };
    const cancelOrRemoveFromOrder = (product) => {
        dispatchStore(removeFromOrder(product));
    };

    const width = 52;
    const height = 54;

    function addProduct() {
        Object.keys(product.shopCart).length !== 0
            ? handleAddProductOnCLickEvent()
            : dispatchStore(addToOrder(product));
    }
    function removeProduct() {
        currentItem.quantity > 1
            ? productOrderHandler(product, -1)
            : cancelOrRemoveFromOrder(product);
    }
    const [path, setPath] = React.useState([]);

    function handleAddProductOnCLickEvent() {
        setIsAddProductConfirmationDialogOpened();
    }

    function handleShowDetailOnClickEvent() {
        if (Object.keys(product.shopCart).length === 0) {
            dispatchStore(ClearWorkflowItem());

            dispatchStore(setIsModification(true));
            dispatchStore(addToOrderworkflow(product));

            dispatchStore(setWorkflowData([]));
            dispatchStore(SelectProduct(product));
            setIsWorkflowDialogOpened(true);
            return;
        }

        setIsShowDetailsOpened(!isShowDetailsOpened);
        setPath([]);
    }
    const handleWorkDialogCloseEvent = () => {
        dispatchStore(SelectProduct({}));
        dispatchStore(setWorkflowData([]));
        setIsWorkflowDialogOpened();
    };
    const handleOnCancelAddConfirmation = () => {
        dispatchStore(setIsModification(false));
        dispatchStore(
            addToOrderworkflow({
                ...product,
                date: Date.now(),
                price: product.defaultPrice,
                quantity: 1,
                shopCart: {},
                workflow: {},
            })
        );
        dispatchStore(setWorkflowData([]));
        dispatchStore(SelectProduct({ ...product }));
        setIsWorkflowDialogOpened();
    };

    return (
        <>
            {isWorkflowDialogOpened && (
                <WorkflowDialog
                    open={isWorkflowDialogOpened}
                    onClose={handleWorkDialogCloseEvent}
                />
            )}

            <AddProductConfirmation
                open={isAddProductConfirmationDialogOpened}
                onClose={handleAddProductOnCLickEvent}
                product={product}
                onCancel={handleOnCancelAddConfirmation}
            />
            {isShowDetailsOpened && (
                <ShowDetailsDialog
                    open={isShowDetailsOpened}
                    onClose={handleShowDetailOnClickEvent}
                    product={product}
                    path={path}
                    setPath={setPath}
                    setIsShowDetailsOpened={setIsShowDetailsOpened}
                />
            )}

            <div className="simple-product-preview">
                <div className="info-container-product">
                    <div className="info-image-price">
                        <div
                            className="imag-product-preview"
                            style={{
                                backgroundImage: `url(${product.urlImage})`,
                            }}
                        ></div>
                        <div className="price-product-preview">
                            {(quantity * product.price).toFixed(2)} €
                        </div>
                    </div>
                    <div className="title-product-preview preview-text ">
                        {product.name}
                    </div>
                    {product?.selectedOptions?.length > 0 && (
                        <div className="info-options-product preview-text  ">
                            {product.selectedOptions.join(",")}
                        </div>
                    )}
                </div>
                <div className="actions-composed-product-preview px-2">
                    {((product.shopCart &&
                        Object.keys(product.shopCart).length !== 0) ||
                        (Object.keys(product.shopCart).length === 0 &&
                            product.haveBasicComposition) ||
                        (Object.keys(product.shopCart).length === 0 &&
                            product.haveWorkflow)) && (
                        <button
                            className="see-details"
                            style={{ backgroundColor: globalColor }}
                            onClick={handleShowDetailOnClickEvent}
                        >
                            Voir détails
                        </button>
                    )}
                    <div
                        className="simple-product-operations"
                        style={{ minWidth: "140px" }}
                    >
                        <div className="remove-item">
                            <RemoveIcon
                                width={width}
                                height={height}
                                onClick={removeProduct}
                            ></RemoveIcon>
                        </div>
                        <div className="number-product-preview">{quantity}</div>
                        <div className="add-item">
                            <AddIconWrapper
                                opacity={opacity}
                                width={width}
                                height={height}
                                onClick={addProduct}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export const MemoizedSimpleProductCartPreview = React.memo(
    SimpleProductCartPreview
);
SimpleProductCartPreview.propTypes = {
    product: PropTypes.object.isRequired,
};
