import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function AllergenPrmLogoWrapper() {
    const {
        logo,
        isTopBannerActive,
        isLogoActive,
        uploadedImages,
        customerLanguage,
        project: { files: images },
    } = useSnapshot(kioskStore);

    const imageContent = getImageContentById(
        logo.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        uploadedImages?.filter(
            // eslint-disable-next-line
            (img) => img.fieldname === logo.id
        )[0] === undefined
            ? imageContent?.includes("http") || imageContent?.includes("https")
                ? imageContent
                : `./images/${imageContent}`
            : uploadedImages?.filter(
                  // eslint-disable-next-line
                  (img) => img.fieldname === logo.id
              )[0].path;

    return (
        <React.Fragment>
            {isTopBannerActive ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "580px", width: "580px" }}
                >
                    {isLogoActive ? (
                        <img
                            src={imageSrc}
                            alt="logo"
                            className="allergen-prm-logo"
                        />
                    ) : null}
                </div>
            ) : null}
        </React.Fragment>
    );
}
