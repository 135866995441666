import { CrossIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import React from "react";

import { KioskAppLogoSvgIcon } from "@components/SvgIcons/KioskAppLogoSvgIcon";
import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsLocalSettingModalOpened,
    setQrCodeValue,
} from "@pages/Kiosk/store";

export function LocalSettingsHeader(): JSX.Element {
    const { isAuthenticationModalOpened } = useSnapshot(kioskStore);

    const { selectedEntityChild, selectedShopApp } = useSnapshot(syncStore);

    return (
        <div
            style={{
                display: "grid",
                height: 160,
                width: 900,
                position: "relative",
                gridTemplateColumns: "2fr 3fr",
                gridTemplateRows: "1fr",
            }}
        >
            <video
                autoPlay
                muted
                loop
                style={{
                    objectFit: "cover",
                    position: "fixed",
                    height: 160,
                    width: 914,
                    zIndex: -1,
                }}
            >
                <source src="./images/background.mp4" type="video/mp4" />
            </video>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{
                    marginTop: "20px",
                    marginLeft: "40px",
                    gap: 70,
                }}
            >
                <KioskAppLogoSvgIcon width="300" height="125" />
                <div
                    style={{
                        height: "125px",
                        width: "1px",
                        borderRight: "1px solid #c4c2c2",
                    }}
                />
            </div>

            <div
                className="d-flex  justify-content-between ml-4"
                style={{ gap: "15px" }}
            >
                <div
                    className="d-flex  flex-column justify-content-center align-items-center"
                    style={{
                        gap: "5px",
                        marginTop: "10px",
                    }}
                >
                    <div
                        style={{
                            color: "#FFFFFF",
                            font: "normal bold 30px / 55px Segoe UI",
                            textAlign: "left",
                            letterSpacing: "1.2px",
                        }}
                    >
                        {selectedEntityChild?.name}
                    </div>
                    <div
                        className="d-flex align-items-end"
                        style={{
                            color: "#FFFFFF",
                            font: "normal 27px/36px Segoe UI",
                            textAlign: "left",
                            letterSpacing: "1.2px",
                        }}
                    >
                        {selectedShopApp.name as string}
                    </div>
                </div>

                <div
                    className="d-flex  align-items-start"
                    style={{
                        height: "45px",
                        width: "45px",
                    }}
                >
                    {!isAuthenticationModalOpened ? (
                        <StyledIconButton
                            rounded={true}
                            className="authentication-button__clz"
                            onClick={() => {
                                setIsLocalSettingModalOpened(false);
                                setQrCodeValue("");
                            }}
                            style={{
                                height: "45px !important",
                                width: "45px !important",
                            }}
                        >
                            <CrossIcon height={20} width={20} fill="white" />
                        </StyledIconButton>
                    ) : (
                        <div
                            className="d-flex  align-items-start"
                            style={{
                                height: "45px",
                                width: "45px",
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
