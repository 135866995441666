import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import {
    getImageContentById,
    getPreviousSkippedStepNumber,
    getSkippedStepNumber,
    handleAbortCBTransaction,
    handleUserAuthentication,
} from "@helpers/general";

import { orderSalesModeModification } from "@components/OrderTaking/Store/feature";

import {
    kioskStore,
    setAuthModalStepIndex,
    setFinalMessageSelectedInformationMode,
    setIsAbortTransactionModalOpened,
    setIsAnimationIn,
    setIsCashTransactionValidated,
    setIsConfirmationModalOpened,
    setIsOrderTakingPreviousStep,
    setIsPaymentRefused,
    setIsRegulationModesModalOpened,
    setIsTpaValinaModalOpened,
    setIsUserAccountModalOpened,
    setKeyboardInputsValues,
    setLeftToPayAfterCashMachineValidation,
    setLocalSelectedInformationMode,
    setNavigationIndex,
    setNumericKeyboardInputValue,
    setOrderItemsLength,
    setOrderModificationMode,
    setSelectedAuthMode,
    setSelectedInformationMode,
    setTotalAmountDeposited,
} from "@pages/Kiosk/store";

type ImageContentPropsType = {
    item: ActionsButtonsDesignItemType;
};

export function ActionsButtonsImageContent({
    item,
}: ImageContentPropsType): JSX.Element {
    const { middlewareApiUrl } = useSnapshot(store);
    const dispatchStore = useDispatch();
    const {
        customerLanguage,
        selectedAuthMode,
        kioskWays,
        authModalStepIndex,
        selectedSaleModeValue,
        navigationIndex,
        numericKeyboardInputValue,
        isAuthenticationErrorModalOpened,
        keyboardInputsValues,
        isConfirmationModalOpened,
        isTpaValinaModalOpened,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        project: {
            template: { content },
            files: images,
        },
        pseudoNameValue,
        isBackButtonDisabled,
        isAnotherPaymentModeButtonDisabled,
        amountDeposited,
        totalAmountDeposited,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        leftToPayAfterCashMachineValidation,
        isTransactionCanceled,
        isCashTransactionValidated,
        isAnotherPaymentModeButtonCBDisabled,
        saleMode,
        orderModificationMode,
        isOrderInsertionRefused,
    } = useSnapshot(kioskStore);

    // eslint-disable-next-line
    const { totalPrice, orderItems } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.orderSlice.order
    );

    const imageContent = getImageContentById(
        item.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleOnClickEvent(item: ActionsButtonsDesignItemType) {
        switch (item.name.toLowerCase()) {
            case "Validate".toLowerCase(): {
                if (selectedAuthMode !== "") {
                    const authModeInfo: AuthModeInfoType =
                        selectedAuthMode === "phoneNumber"
                            ? {
                                  phoneNumber: numericKeyboardInputValue,
                              }
                            : selectedAuthMode === "emailAndPassword"
                            ? {
                                  email: keyboardInputsValues[
                                      t("Enter your email")
                                  ],
                                  password:
                                      keyboardInputsValues[
                                          t("Enter your password")
                                      ],
                              }
                            : {
                                  loyaltyCardNumber: numericKeyboardInputValue,
                              };

                    setOrderItemsLength(orderItems.length);
                    handleUserAuthentication(
                        authModeInfo,
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        authModalStepIndex,
                        isAuthenticationErrorModalOpened,
                        content
                    );
                } else {
                    setIsConfirmationModalOpened(!isConfirmationModalOpened);
                }
                break;
            }
            case "Back".toLowerCase(): {
                if (selectedAuthMode !== "") {
                    if (authModalStepIndex !== 0) {
                        setNavigationIndex(authModalStepIndex);
                    } else if (
                        selectedSaleModeValue === "Delivery" ||
                        saleMode.name === "Delivery"
                    ) {
                        if (orderItems.length === 0) {
                            const salesMethodsStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page) => page?.name === "salesMethods"
                                )[0]
                            );
                            setNavigationIndex(salesMethodsStepIndex);
                        } else {
                            setIsOrderTakingPreviousStep(true);
                            const orderTakingStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page) => page?.name === "orderTaking"
                                )[0]
                            );
                            setNavigationIndex(orderTakingStepIndex);
                        }
                    } else {
                        setNavigationIndex(
                            navigationIndex -
                                Number(
                                    getPreviousSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue
                                    )
                                )
                        );
                    }
                    setSelectedAuthMode("");
                    setNumericKeyboardInputValue("");
                    setKeyboardInputsValues({});
                    setIsAnimationIn(false);
                } else if (isTpaValinaModalOpened === true) {
                    setIsTpaValinaModalOpened(false);
                    setIsRegulationModesModalOpened(true);
                } else if (kioskWays[navigationIndex].name === "payment") {
                    console.log("back button in payment step clicked");
                    setIsAbortTransactionModalOpened(true);
                    setIsRegulationModesModalOpened(false);
                } else if (
                    kioskWays[navigationIndex].name === "meansOfPayment" &&
                    isOrderInsertionRefused
                ) {
                    setIsOrderTakingPreviousStep(true);
                    const orderTakingStepIndex = kioskWays.indexOf(
                        kioskWays.filter(
                            (page) => page?.name === "orderTaking"
                        )[0]
                    );
                    setNavigationIndex(orderTakingStepIndex);
                } else {
                    setNavigationIndex(
                        navigationIndex -
                            Number(
                                getPreviousSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue
                                )
                            )
                    );
                    setIsAnimationIn(false);
                }
                break;
            }
            case "Ignore".toLowerCase(): {
                if (selectedAuthMode !== "") {
                    /* Authentication Mode Consommation Step */
                    if (authModalStepIndex !== 0) {
                        setNavigationIndex(authModalStepIndex);
                    } else if (
                        selectedSaleModeValue === "Delivery" ||
                        saleMode.name === "Delivery"
                    ) {
                        if (orderItems.length === 0) {
                            const salesMethodsStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page) => page?.name === "salesMethods"
                                )[0]
                            );
                            setNavigationIndex(salesMethodsStepIndex);
                        } else {
                            const orderTakingStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page) => page?.name === "orderTaking"
                                )[0]
                            );
                            setIsOrderTakingPreviousStep(true);
                            setNavigationIndex(orderTakingStepIndex);
                        }
                    } else {
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content
                                    )
                                )
                        );
                    }
                    setIsUserAccountModalOpened(false);
                    setAuthModalStepIndex(0);
                    setSelectedAuthMode("");
                    setNumericKeyboardInputValue("");
                    setKeyboardInputsValues({});
                    setIsAnimationIn(false);
                } else if (
                    kioskWays[navigationIndex].name === "informationModes"
                ) {
                    setFinalMessageSelectedInformationMode("none");
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content
                                )
                            )
                    );
                    setIsAnimationIn(false);
                } else if (
                    kioskWays[navigationIndex].name === "authenticationModes"
                ) {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,

                                    selectedAuthMode
                                )
                            )
                    );
                } else {
                    /* Information Mode Consommation Step */
                    setFinalMessageSelectedInformationMode("none");
                    setSelectedInformationMode("");
                    setLocalSelectedInformationMode("");
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content
                                )
                            )
                    );
                }
                break;
            }
            case "giveUp".toLowerCase(): {
                setNavigationIndex(0);
                setIsAnimationIn(false);
                break;
            }
            case "otherPaymentMethod".toLowerCase(): {
                if (selectedRegulationModeValue === "changeMachine") {
                    setIsCashTransactionValidated(true);
                    const validCashMachineTransactionAmount =
                        leftToPayAfterCashMachineValidation !== 0
                            ? leftToPayAfterCashMachineValidation -
                              amountDeposited
                            : isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance -
                              amountDeposited
                            : totalPrice - amountDeposited;

                    console.log(
                        `Validate CashMachine Transaction with the amount:${validCashMachineTransactionAmount}`
                    );
                    setLeftToPayAfterCashMachineValidation(
                        validCashMachineTransactionAmount
                    );
                    const url = `${middlewareApiUrl}/ValidCashMachineTransaction?unitaire=${validCashMachineTransactionAmount}`;

                    fetch(url)
                        .then((response) => response.json())
                        // eslint-disable-next-line
                        .then((_) => {
                            const localTotalAmountDeposited =
                                totalAmountDeposited + amountDeposited;
                            setTotalAmountDeposited(localTotalAmountDeposited);
                            console.log(
                                `The total amount deposited will be:${localTotalAmountDeposited}`
                            );
                        })
                        .catch((error) => {
                            console.log(
                                `Error during the validation of change machine transaction, with the message ${error.message}`
                            );
                        });
                } else {
                    handleAbortCBTransaction();
                }

                setIsPaymentRefused(false);

                break;
            }
        }
    }

    React.useEffect(() => {
        if (!orderModificationMode) return;
        dispatchStore(orderSalesModeModification({ ...saleMode }));
        setOrderModificationMode();
    }, [orderModificationMode]);

    return (
        <img
            src={imageSrc}
            alt="action button"
            style={{
                height: "60px",
                width: "242px",
                opacity:
                    (numericKeyboardInputValue === "" &&
                        keyboardInputsValues[t("Enter your email")] ===
                            undefined &&
                        keyboardInputsValues[t("Enter your password")] ===
                            undefined &&
                        pseudoNameValue === "" &&
                        imageContent?.toLowerCase().includes("validate")) ||
                    (kioskWays[navigationIndex].name === "payment" &&
                        ((item.name.toLowerCase() === "Back".toLowerCase() &&
                            isBackButtonDisabled) ||
                            (item.name.toLowerCase() ===
                                "otherPaymentMethod".toLowerCase() &&
                                (isAnotherPaymentModeButtonDisabled ||
                                    isAnotherPaymentModeButtonCBDisabled)) ||
                            isTransactionCanceled ||
                            isCashTransactionValidated))
                        ? "0.5"
                        : 1,
                pointerEvents:
                    (numericKeyboardInputValue === "" &&
                        keyboardInputsValues[t("Enter your email")] ===
                            undefined &&
                        keyboardInputsValues[t("Enter your password")] ===
                            undefined &&
                        pseudoNameValue === "" &&
                        imageContent?.toLowerCase().includes("validate")) ||
                    (kioskWays[navigationIndex].name === "payment" &&
                        ((item.name.toLowerCase() === "Back".toLowerCase() &&
                            isBackButtonDisabled) ||
                            (item.name.toLowerCase() ===
                                "otherPaymentMethod".toLowerCase() &&
                                (isAnotherPaymentModeButtonDisabled ||
                                    isAnotherPaymentModeButtonCBDisabled)) ||
                            isTransactionCanceled ||
                            isCashTransactionValidated))
                        ? "none"
                        : "auto",
            }}
            onClick={() => handleOnClickEvent(item)}
        />
    );
}
