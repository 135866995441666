import { StyledSearchInput } from "@aureskonnect/react-ui";
import { t } from "i18next";
import React from "react";
import BTable from "react-bootstrap/Table";
import { usePagination, useTable, useGlobalFilter } from "react-table";
import styled from "styled-components";

import { Pagination } from "./Pagination";

type PropsType = {
    columns: TableColumnsType[];
    // eslint-disable-next-line
    data: any[];
};

const Styles = styled.div`
    table {
        th:first-child {
            width: 5%;
        }
        thead {
            font: normal normal bold 22px/55px Bebas Neue;
            letter-spacing: 0.88px;
            color: #0a0a0a;
            text-align: center;
        }
        tbody td {
            text-align: center;
            font: normal normal normal 15px/55px Arial;
            letter-spacing: 0px;
            vertical-align: middle;
        }
        tbody td:nth-child(4) {
            max-width: 180px;
        }
    }
`;

export function Table({ columns, data }: PropsType): JSX.Element {
    const ITEMS_PER_PAGE = 10;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            <StyledSearchInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setGlobalFilter(e.target.value);
                }}
                placeholder={`${t("Search")} ...`}
            />
            <Styles>
                <BTable responsive {...getTableProps()}>
                    <thead>
                        {/* eslint-disable-next-line */}
                        {headerGroups.map((headerGroup: any) => {
                            const { key: headerGroupKey } =
                                headerGroup.getHeaderGroupProps();
                            return (
                                <tr
                                    key={headerGroupKey}
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {/* eslint-disable-next-line */}
                                    {headerGroup.headers.map((column: any) => {
                                        const { key: headerKey } =
                                            column.getHeaderProps();
                                        return (
                                            <th
                                                key={headerKey}
                                                {...column.getHeaderProps()}
                                            >
                                                {column.render("Header")}
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.length ? (
                            // eslint-disable-next-line
                            page.map((row: any) => {
                                prepareRow(row);
                                const { key: rowKey } = row.getRowProps();

                                return (
                                    <tr key={rowKey} {...row.getRowProps()}>
                                        {/* eslint-disable-next-line */}
                                        {row.cells.map((cell: any) => {
                                            const { key: cellKey } =
                                                cell.getCellProps();
                                            return (
                                                <td
                                                    className="white-space-nowrap"
                                                    key={cellKey}
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    colSpan={headerGroups[0]?.headers?.length}
                                    style={{ textAlign: "center" }}
                                >
                                    {t("No data")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </BTable>
            </Styles>
            {data.length > ITEMS_PER_PAGE ? (
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                />
            ) : null}
        </React.Fragment>
    );
}
