import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { ImageContent } from "@components/common/PrmModeMainContentWrapper/ImageContent";

import { LoyaltyConsommationCard } from "@pages/Kiosk/Steps/LoyaltyConsommationStep/LoyaltyConsommationCard";
import { kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

import "swiper/swiper-bundle.min.css";

export function MainContentWrapper(): JSX.Element {
    const {
        mainContentItems,
        navigationIndex,
        kioskWays,
        selectedSaleModeValue,
        customerLanguage,
    } = useSnapshot(store);

    const activeItems =
        kioskWays[navigationIndex]?.name === "informationModes"
            ? (mainContentItems as ProjectMainContentItemType[]).filter(
                  (item) => {
                      return (
                          (item.active as ItemContentType)[
                              selectedSaleModeValue
                          ] === true
                      );
                  }
              )
            : (mainContentItems as ProjectMainContentItemType[]).filter(
                  (item) => {
                      return (
                          item.active === true &&
                          item.languages[customerLanguage.name].content !== ""
                      );
                  }
              );

    const activeItemsLength = activeItems.length;

    return (
        <React.Fragment>
            {activeItemsLength <= 3 ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center p-2"
                    style={{
                        width: "1080px",
                    }}
                >
                    {kioskWays[navigationIndex]?.name ===
                    "loyaltyConsommation" ? (
                        <div style={{ marginBottom: "500px" }}>
                            <LoyaltyConsommationCard />
                        </div>
                    ) : null}

                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            padding: "50px 0px 50px 0px",
                            gap: "40px",
                        }}
                    >
                        {activeItems.map(
                            (
                                item: ProjectMainContentItemType,
                                index: number
                            ) => (
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            )
                        )}
                    </div>
                </div>
            ) : (
                <Swiper
                    slidesPerView={3}
                    slidesPerGroup={3}
                    spaceBetween={40}
                    modules={[Pagination]}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {activeItems.map(
                        (item: ProjectMainContentItemType, index: number) => (
                            <SwiperSlide
                                key={item.id}
                                className="main-content-prm-swiper-slide__clz"
                            >
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            )}
        </React.Fragment>
    );
}
