import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";
import {
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

import {
    changeLanguage,
    getImageContentById,
    moveArrayElementToTheBegin,
} from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import {
    setActiveLanguages,
    setCustomerLanguage,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

export function PrmLanguagesWrapper(): JSX.Element {
    const {
        activeLanguages,
        customerLanguage,
        project: { files: images },
    } = useSnapshot(store);

    const [isDropDownOpened, setIsDropDownOpened] =
        React.useState<boolean>(false);

    const [dropdownItems, setDropdownItems] = React.useState<
        LanguagesItemType[]
    >(activeLanguages.filter((item) => item.name !== customerLanguage.name));

    React.useEffect(() => {
        setActiveLanguages(
            moveArrayElementToTheBegin(
                activeLanguages.filter(
                    (item) => item.name === customerLanguage.name
                )[0],
                activeLanguages
            )
        );
        setDropdownItems(
            activeLanguages.filter(
                (item) => item.name !== customerLanguage.name
            )
        );
    }, [customerLanguage]);

    return (
        <React.Fragment>
            {activeLanguages.length <= 2 ? (
                <FlexboxGrid
                    alignItemsCentered={false}
                    justifyContentCentered={false}
                    gap="10px"
                >
                    {activeLanguages.map(
                        (item: LanguagesItemType, index: number) => {
                            const imageContent = getImageContentById(
                                item.id,
                                images,
                                customerLanguage.name
                            );

                            const imgSrc =
                                item.flag !== ""
                                    ? item.flag
                                    : imageContent?.includes("http") ||
                                      imageContent?.includes("https")
                                    ? imageContent
                                    : `./images/${imageContent}`;

                            return (
                                <div
                                    key={item.id}
                                    className="d-flex justify-content-center align-items-center ml-3"
                                    style={{
                                        gap: "10px",
                                    }}
                                    onClick={() => {
                                        setCustomerLanguage(item);
                                        changeLanguage(customerLanguage.name);
                                    }}
                                >
                                    <img
                                        src={imgSrc}
                                        alt={item.title}
                                        style={{
                                            width: "100px",
                                            height: "52px",
                                        }}
                                    />
                                    <span
                                        className={classNames({
                                            "customer-language-title__clz":
                                                index === 0,
                                        })}
                                        style={{
                                            fontSize: "20px",
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            );
                        }
                    )}
                </FlexboxGrid>
            ) : (
                <ButtonDropdown
                    direction="up"
                    isOpen={isDropDownOpened}
                    toggle={() => setIsDropDownOpened(!isDropDownOpened)}
                    className="align-items-center"
                    style={{ width: "230px", marginLeft: "10px" }}
                >
                    <DropdownToggle
                        className="d-flex align-items-center"
                        caret
                        style={{
                            height: "65px",
                            gap: "20px",
                            borderColor: "#F8F8F6",
                            backgroundColor: "#F8F8F6",
                            color: " #000000",
                            borderBottomRightRadius: "20px",
                            borderBottomLeftRadius: "20px",
                        }}
                    >
                        <img
                            src={`${customerLanguage.flag}`}
                            alt={customerLanguage.title}
                            style={{
                                height: "35px",
                                width: "50px",
                            }}
                        />
                        <span
                            className="customer-language-title__clz"
                            style={{
                                fontSize: "30px",
                            }}
                        >
                            {customerLanguage.title}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "230px" }}>
                        {dropdownItems.map((item, index) => (
                            <DropdownItem
                                className="d-flex"
                                style={{ gap: "20px" }}
                                key={index}
                                onClick={() => {
                                    setCustomerLanguage(item);
                                    changeLanguage(customerLanguage.name);
                                    setDropdownItems(
                                        activeLanguages.filter(
                                            (element) =>
                                                element.name !==
                                                customerLanguage.name
                                        )
                                    );
                                }}
                            >
                                <img
                                    src={`${item.flag}`}
                                    alt={item.title}
                                    style={{
                                        height: "35px",
                                        width: "35px",
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: "30px",
                                    }}
                                >
                                    {item.title}
                                </span>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
            )}
        </React.Fragment>
    );
}
