import { CATEGORY_TYPE } from "@constants";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function ProductDetails({
    product,
    isDescriptionActive,
    isProductDescriptionActive,
}) {
    const { isPrm } = useSnapshot(kioskStore);
    const { type, price } = product;
    return (
        type !== CATEGORY_TYPE && (
            <>
                <Box
                    className={`centredItems__clz px-1 ${
                        isPrm ? "prm-price" : "price"
                    }`}
                >
                    {price?.isVisible ? price.content.toFixed(2) + " €" : null}
                </Box>
                <span
                    className={`${
                        isPrm
                            ? "prm-description two-lines-preview-text px-2"
                            : "three-lines-preview-text description__clz px-2"
                    }`}
                >
                    {isDescriptionActive //why ??!!
                        ? isProductDescriptionActive
                            ? product.description.content
                            : null
                        : null}
                </span>
            </>
        )
    );
}

ProductDetails.propTypes = {
    product: PropTypes.object.isRequired,
    isProductDescriptionActive: PropTypes.number,
    isDescriptionActive: PropTypes.bool,
};
