import { useSnapshot } from "valtio";

import React from "react";

import { setLocalProjectItems, kioskStore as store } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function SalesModes(): JSX.Element {
    const { localProject } = useSnapshot(store);

    const activeSalesModes = (
        localProject.template.content.salesMethods as ProjectContentItemType
    ).items;
    React.useEffect(() => {
        const array = [...activeSalesModes];
        const element = array.find((element) => element.name === "Delivery");
        const itemIndex = array.indexOf(element as ProjectMainContentItemType);
        if (
            element !== undefined &&
            (localProject.template.content.options as OptionsType).items[0]
                .active === false
        ) {
            array[itemIndex] = {
                ...element,
                active: false,
            };
        }
        setLocalProjectItems(
            "salesMethods",
            array as
                | ProjectMainContentItemType[]
                | LanguagesItemType[]
                | OptionsItemType[]
        );
    });

    return (
        <MainContentWrapper
            items={activeSalesModes}
            contentName="salesMethods"
        />
    );
}
