import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { store } from "@store";

import { getSkippedStepNumber, handlePutCommand } from "@helpers/general";

import { ChangeMachineRenderModalWrapper } from "@components/common/ChangeMachineRenderModalWrapper";
import { CommandInsertionProblemModalWrapper } from "@components/common/CommandInsertionProblemModalWrapper";
import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { WithdrawalProblemModalWrapper } from "@components/common/WithdrawalProblemModalWrapper";
import { setMiddlwareOrder } from "@components/OrderTaking/Store/feature/MiddlwareOrderSlice";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { Container } from "@pages/Kiosk/Steps/FinalMessageStep/Container";
import {
    kioskStore,
    setActionsButtonsItems,
    setChosenTransition,
    setFinalMessage,
    setFinalMessageCounter,
    setFinalMessageFooter,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsAnimationIn,
    setIsFinalMessageFooterActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsStandbyModalOpened,
    setIsTicketNumberMessageFooterActive,
    setIsTopBannerActive,
    setIsUserAccountActive,
    setMainContentItems,
    setNavigationIndex,
    setOrderNumber,
    setTicketNumberMessageFooter,
    setUserAccountMessageItems,
} from "@pages/Kiosk/store";

export function FinalMessageStep(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isLogoActive,
        isUserAccountActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isUserAuthenticated,
        isFinalMessageFooterActive,
        isTicketNumberMessageFooterActive,
        ticketNumberMessageFooter,
        finalMessageFooter,
        finalMessage,
        navigationIndex,
        isAnimationIn,
        chosenTransition,
        selectedInformationMode,
        selectedRegulationModeValue,
        isOrderPaidWithLoyaltyAccountBalance,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        finalMessageSelectedRegulationMode,
        finalMessageSelectedInformationMode,
        kioskWays,
        selectedAuthMode,
        user,
        isCreatingVoucherAccepted,
        voucherAmount,
        chosenInformationMode,
        paymentModes,
        orderNumber,
        isCommandInsertionProblemModalOpened,
        timeout,
        isStandbyModalOpened,
        isAmountReturned,
        finalMessageCounter,
        isWithdrawalProblemModalOpened,
        isChangeMachineRenderModalOpened,
        isOrderInsertedSuccessfully,
    } = useSnapshot(kioskStore);
    const { transitions } = useSnapshot(store);
    const [counter, setCounter] = React.useState<number>(0);

    const dispatch = useDispatch();

    const { order } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.orderSlice,
        shallowEqual
    );

    const { middlwareOrder } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.middlwareOrderSlice
    );

    React.useEffect(() => {
        setNavigationIndex(
            kioskWays.indexOf(
                kioskWays.filter((page) => page?.name === "finalMessage")[0]
            )
        );
        setIsAnimationIn(true);
        setMainContentItems(
            (content.finalMessage as ProjectContentItemType).items
        );

        setIsUserAccountActive(
            // eslint-disable-next-line
            (ways.finalMessage as PageType).header.userAccount!.active
        );
        setUserAccountMessageItems(
            // eslint-disable-next-line
            (ways.finalMessage as PageType).header.userAccount!.languages
        );

        setIsTicketNumberMessageFooterActive(
            ((ways.finalMessage as PageType).footer as FooterType)
                .ticketNumberMessage.active
        );

        setTicketNumberMessageFooter(
            ((ways.finalMessage as PageType).footer as FooterType)
                ?.ticketNumberMessage.languages
        );

        setIsFinalMessageFooterActive(
            ((ways.finalMessage as PageType).footer as FooterType).finalMessage
                .active
        );

        setFinalMessageFooter(
            ((ways.finalMessage as PageType).footer as FooterType).finalMessage
                .languages
        );

        setFinalMessage(
            (content.finalMessage as FinalMessageContentType).items.filter(
                (item: FinalMessageItemType) => {
                    return item.title === finalMessageSelectedRegulationMode;
                }
            )[0][finalMessageSelectedInformationMode].languages[
                customerLanguage.name
            ].content
        );

        setIsLogoActive((ways.finalMessage as PageType).topBanner.logo.active);

        setIsTopBannerActive((ways.finalMessage as PageType).topBanner.active);
        setIsHeaderActive((ways.finalMessage as PageType).header.active);

        setIsInformationMessageActive(
            (ways.finalMessage as PageType).header.informationMessage.active
        );

        setInformationMessageItems(
            (ways.finalMessage as PageType).header.informationMessage.languages
        );

        setIsActionButtonsActive(
            (ways.finalMessage as PageType).actionsButtons.active
        );

        setActionsButtonsItems(
            (
                (ways.finalMessage as PageType)
                    .actionsButtons as ActionsButtonsDesignType
            ).items
        );

        setChosenTransition(
            transitions[(ways.finalMessage as PageType).animation]
        );
    }, []);

    React.useEffect(() => {
        if (isOrderInsertedSuccessfully) {
            let timer: NodeJS.Timeout;

            if (counter >= 0) {
                timer = setTimeout(() => setCounter(counter + 1), 1000);
            }
            if (counter === 5) {
                if (navigationIndex === kioskWays.length - 1) {
                    setNavigationIndex(0);
                } else {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content
                                )
                            )
                    );
                }
            }
            return () => clearTimeout(timer);
        }
    }, [counter, navigationIndex, ways, isOrderInsertedSuccessfully]);

    React.useEffect(() => {
        if (isAmountReturned) {
            let timer: NodeJS.Timeout;

            if (finalMessageCounter >= 0) {
                timer = setTimeout(
                    () => setFinalMessageCounter(finalMessageCounter + 1),
                    1000
                );
            }

            if (finalMessageCounter === timeout) {
                setIsStandbyModalOpened(true);
            }

            return () => clearTimeout(timer);
        }
    }, [finalMessageCounter, isAmountReturned]);

    React.useEffect(() => {
        const changeMachinePaymentMode = paymentModes.find(
            (mode) => mode.paymentMode === "changeMachine"
        );

        const changeMachinePaymentModeIndex = paymentModes.indexOf(
            // eslint-disable-next-line
            changeMachinePaymentMode!
        );

        const localPaymentModes = paymentModes.map((paymentMode) => {
            return { ...paymentMode };
        });

        if (changeMachinePaymentMode !== undefined) {
            if (isCreatingVoucherAccepted) {
                const voucherMode = paymentModes.find(
                    (mode) => mode.paymentMode === "voucher"
                );

                // eslint-disable-next-line
                if (voucherMode!.paymentAmount < 0) {
                    localPaymentModes[changeMachinePaymentModeIndex] = {
                        ...localPaymentModes[changeMachinePaymentModeIndex],
                        totalAmountDeposited:
                            changeMachinePaymentMode.paymentAmount +
                            // eslint-disable-next-line
                            Math.abs(voucherMode!.paymentAmount),
                    };
                }
            } else {
                localPaymentModes[changeMachinePaymentModeIndex] = {
                    ...localPaymentModes[changeMachinePaymentModeIndex],
                    totalAmountDeposited:
                        changeMachinePaymentMode.paymentAmount +
                        changeMachinePaymentMode.data.renderAmount,
                };
            }
        }

        const localChosenInformationMode = {
            type: chosenInformationMode.type,
            data: {
                infoModeUuId: chosenInformationMode.data.infoModeUuId,
                value: chosenInformationMode.data.value,
            },
        };

        dispatch(
            setMiddlwareOrder({
                order,
                selectedSaleModeValue,
                selectedInformationMode,
                selectedRegulationModeValue,
                selectedAuthMode,
                chosenInformationMode: localChosenInformationMode,
                paymentModes: localPaymentModes,
                phoneNumber: user.phoneNumber,
            })
        );

        setOrderNumber("");
    }, []);

    React.useEffect(() => {
        if (middlwareOrder.orderUuid !== "") {
            handlePutCommand(
                middlwareOrder,
                isCreatingVoucherAccepted,
                voucherAmount
            );
        }
    }, [isCreatingVoucherAccepted, voucherAmount, middlwareOrder]);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <PreviewBackgroundWrapper>
                <Container
                    isTopBannerActive={isTopBannerActive}
                    isHeaderActive={isHeaderActive}
                    isLogoActive={isLogoActive}
                >
                    {isCommandInsertionProblemModalOpened === true ? (
                        <CommandInsertionProblemModalWrapper />
                    ) : null}
                    {isStandbyModalOpened === true ? (
                        <StandbyModalWrapper />
                    ) : null}
                    {isWithdrawalProblemModalOpened === true ? (
                        <WithdrawalProblemModalWrapper />
                    ) : null}
                    {isChangeMachineRenderModalOpened === true ? (
                        <ChangeMachineRenderModalWrapper />
                    ) : null}
                    <LogoWrapper />
                    {isOrderInsertedSuccessfully ? (
                        <>
                            {isHeaderActive ? (
                                <div className="d-grid__clz justify-content-center align-items-center">
                                    {isUserAccountActive ? (
                                        isUserAuthenticated ? (
                                            <FlexboxGrid
                                                alignItemsCentered={true}
                                                justifyContentCentered={true}
                                                gap="20px"
                                            >
                                                <img
                                                    src="./images/man-head-header.png"
                                                    alt="header"
                                                    style={{
                                                        height: "35px",
                                                        width: "35px",
                                                    }}
                                                />
                                                <span
                                                    className="mb-0"
                                                    style={{
                                                        fontSize: "35px",
                                                    }}
                                                >
                                                    {user.firstName === ""
                                                        ? user.lastName
                                                        : user.firstName}
                                                </span>
                                            </FlexboxGrid>
                                        ) : null
                                    ) : null}

                                    {isInformationMessageActive ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span style={{ fontSize: "55px" }}>
                                                {
                                                    informationMessageItems[
                                                        customerLanguage.name
                                                    ].content
                                                }
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            <div
                                className="d-flex justify-content-center align-items-center text-center mx-3"
                                style={{ fontSize: "35px" }}
                            >
                                {finalMessage}
                            </div>
                            <div
                                className="d-flex flex-column"
                                style={{ height: "998px" }}
                            >
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{
                                        font: "normal 55px/20px Segoe UI",
                                        height: "300px",
                                    }}
                                >
                                    {isTicketNumberMessageFooterActive
                                        ? ticketNumberMessageFooter[
                                              customerLanguage.name
                                          ]?.defaultContent !== undefined
                                            ? ticketNumberMessageFooter[
                                                  customerLanguage.name
                                              ].content
                                            : null
                                        : null}
                                </div>

                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{
                                        font: "normal 91px/121px Segoe UI",
                                        height: "398px",
                                        color: `${
                                            (
                                                content.generalDesign as GeneralDesignType
                                            ).colors[0].content
                                        }`,
                                    }}
                                >
                                    {String(orderNumber).slice(
                                        8,
                                        orderNumber.length
                                    )}
                                </div>

                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{
                                        font: "normal 55px/20px Segoe UI",
                                        height: "300px",
                                    }}
                                >
                                    {isFinalMessageFooterActive
                                        ? finalMessageFooter[
                                              customerLanguage.name
                                          ].content !== undefined
                                            ? finalMessageFooter[
                                                  customerLanguage.name
                                              ].content
                                            : null
                                        : null}
                                </div>
                            </div>
                        </>
                    ) : null}
                </Container>
            </PreviewBackgroundWrapper>
        </CSSTransition>
    );
}
