import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore, setActiveStateScreenItems } from "@pages/Kiosk/store";

import { ActiveStateScreenTable } from "./ActiveStateScreenTable";

export function ActiveStateScreen(): JSX.Element {
    const { localProject } = useSnapshot(kioskStore);

    React.useEffect(() => {
        setActiveStateScreenItems(
            Object.keys(localProject.template.pages.ways).includes(
                "standbyScreen"
            )
                ? (
                      localProject.template.pages.ways
                          .standbyScreen as StandbyScreenPageType
                  ).items
                : (
                      localProject.template.pages.elements
                          .standbyScreen as StandbyScreenPageType
                  ).items
        );
    }, []);

    return <ActiveStateScreenTable />;
}
