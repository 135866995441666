import { KeyboardInput } from "react-simple-keyboard";

import { decryptWord } from "@helpers/general";

import { syncStore } from ".";

export function setIsSyncModalOpened(opened: boolean): void {
    syncStore.isSyncModalOpened = opened;
}

export function setIsSyncAuthenticationModalOpened(
    isModalOpened: boolean
): void {
    syncStore.isSyncAuthenticationModalOpened = isModalOpened;
}

export function setIsEntitySelectionModalOpened(isModalOpened: boolean): void {
    syncStore.isEntitySelectionModalOpened = isModalOpened;
}

export function setIsEntityChildrenSelectionModalOpened(
    isOpened: boolean
): void {
    syncStore.isEntityChildrenSelectionModalOpened = isOpened;
}

export function setFranchiseSchema(schema: string): void {
    syncStore.franchise.schema = schema;
}

export function setFranchiseId(id: string): void {
    syncStore.franchise.id = id;
}

export function setIsSyncConfirmationModalWrapperOpened(
    isOpened: boolean
): void {
    syncStore.isSyncConfirmationModalWrapperOpened = isOpened;
}

export function setSelectedEntity(entity: EntityType): void {
    syncStore.selectedEntity = entity;
}

export function setSelectedEntityChildren(children: EntityType[]): void {
    syncStore.selectedEntityChildren = children;
}

export function setSelectedEntityChild(child: EntityType | undefined): void {
    syncStore.selectedEntityChild = child;
    localStorage.setItem("selectedEntityChild", JSON.stringify(child));
}

export function setIsSyncProgressModalWrapperOpened(opened: boolean): void {
    syncStore.isSyncProgressModalWrapperOpened = opened;
}

export function setIsLoginErrorModalWrapperOpened(opened: boolean): void {
    syncStore.isLoginErrorModalWrapperOpened = opened;
}

export function setConnectedUserEntity(entity: string): void {
    syncStore.connectedUserEntity = entity;
}

export async function setConnectedUser(user: ObjectType): Promise<void> {
    user.email = await decryptWord(user.email as string);
    delete user.pwd;
    syncStore.connectedUser = user;
}

export function setLoginKeyboardInputsValues(values: KeyboardInput): void {
    syncStore.loginKeyboardInputsValues = values;
}

export function setSelectedShopApp(app: ObjectType): void {
    syncStore.selectedShopApp = app;
    localStorage.setItem("selectedShopApp", JSON.stringify(app));
}

export function setMachineIpAddress(ipAddress: string): void {
    syncStore.machineIpAddress = ipAddress;
    localStorage.setItem("machineIpAddress", JSON.stringify(ipAddress));
}

export function setProgressBarValue(value: number): void {
    syncStore.progressBarValue = value;
}
