import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import {
    acceptVoucherGetting,
    formatCurrency,
    getNoActionButtonImageSrc,
    getSkippedStepNumber,
    getYesActionButtonImageSrc,
    handleCancelVoucherGetting,
} from "@helpers/general";

import { kioskStore, setNavigationIndex } from "@pages/Kiosk/store";

import "./index.css";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function RefundAnomalyModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isPrm,
        isRefundAnomalyModalOpened,
        project: {
            files: images,
            template: { content },
        },
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        customerLanguage,
        voucherAmount,
        isCreationVoucherCanceled,
    } = useSnapshot(kioskStore);

    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isRefundAnomalyModalOpened}
            contentLabel="help-modal"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (content.generalDesign as GeneralDesignType).colors[0]
                            .content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "340px" }}
            >
                <img src="./images/exclamation-icon.png" />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "117px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Refund anomaly")}
                </span>
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("Not enough coins or")}
                    <br /> {t("tickets to finalize the transaction.")}
                </span>
            </div>
            <div style={{ height: "50px" }}></div>
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{ font: "normal normal normal 35px/47px Segoe UI" }}
                >
                    {t("Do you want to create a voucher")}
                    <br />
                    {t("to have ")} {`${formatCurrency(voucherAmount)} ?`}
                </span>
            </div>
            <div style={{ height: "200px" }}></div>
            <div
                style={{ height: "450px" }}
                className="d-flex justify-content-center"
            >
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ opacity: isCreationVoucherCanceled ? "0.5" : "1" }}
                >
                    <div
                        style={{
                            height: "350px",
                            width: "350px",
                        }}
                    >
                        <img
                            src={getNoActionButtonImageSrc(
                                content.actionsButtons as ActionsButtonsDesignType,
                                images,
                                customerLanguage.name
                            )}
                            className="w-100 h-100"
                            style={{
                                backgroundSize: "cover",
                            }}
                            onClick={() =>
                                handleCancelVoucherGetting(totalPrice)
                            }
                        />
                    </div>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("giveUp")}
                    </span>
                </div>
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ opacity: isCreationVoucherCanceled ? "0.5" : "1" }}
                >
                    <div
                        style={{
                            height: "350px",
                            width: "350px",
                        }}
                    >
                        <img
                            src={getYesActionButtonImageSrc(
                                content.actionsButtons as ActionsButtonsDesignType,
                                images,
                                customerLanguage.name
                            )}
                            className="w-100 h-100"
                            style={{ backgroundSize: "cover" }}
                            onClick={() => {
                                if (isCreationVoucherCanceled === false) {
                                    acceptVoucherGetting(totalPrice);
                                    setNavigationIndex(
                                        navigationIndex +
                                            Number(
                                                getSkippedStepNumber(
                                                    kioskWays,
                                                    navigationIndex,
                                                    isAuthenticationAccepted,
                                                    isUserAuthenticated,
                                                    selectedSaleModeValue,
                                                    selectedInformationMode,
                                                    isOrderPaidWithLoyaltyAccountBalance,
                                                    selectedRegulationModeValue,
                                                    content
                                                )
                                            )
                                    );
                                }
                            }}
                        />
                    </div>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("Yes")}
                    </span>
                </div>
            </div>
        </Modal>
    );
}
