import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { store } from "@store";

import {
    getAlertActionButtonImageSrc,
    getNoActionButtonImageSrc,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import {
    kioskStore,
    setIsAbortTransactionModalOpened,
    setIsChangeMachineRenderModalOpened,
    setIsEventStreamProblem,
    setIsTransactionCanceled,
    setPaymentModes,
    setRenderAmount,
} from "@pages/Kiosk/store";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function AbortTransactionModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isPrm,
        project: {
            files: images,
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isAbortTransactionModalOpened,
        customerLanguage,
        selectedRegulationModeValue,
        isTransactionCanceled,
        isAcceptAbortCBTransaction,
        amountDeposited,
        totalAmountDeposited,
        isAnotherPaymentModeButtonDisabled,
        isBackButtonDisabled,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
    } = useSnapshot(kioskStore);
    const { middlewareApiUrl } = useSnapshot(store);

    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);

    function handleAbortTransaction() {
        if (isTransactionCanceled === false) {
            const url = `${middlewareApiUrl}/${
                selectedRegulationModeValue === "changeMachine"
                    ? "AbortTransactionCash"
                    : "AbortCBTransaction"
            }`;

            fetch(url)
                .then((response) => response.json())
                .then((response) => {
                    if (response.code === 2) {
                        setIsAbortTransactionModalOpened(false);
                        setIsTransactionCanceled(true);
                        if (amountDeposited === 0) {
                            console.log(
                                `The amount deposited in this transaction is null: ${amountDeposited}`
                            );
                            console.log(
                                `If the totalAmountDeposited is not null and not equal to the total price of the order, the change machine render modal will opened`
                            );
                            setRenderAmount(totalAmountDeposited);
                            if (
                                totalAmountDeposited !== 0 &&
                                totalAmountDeposited !==
                                    (isOrderPartialPaidWithLoyaltyAccountBalance
                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                        : totalPrice)
                            ) {
                                console.log(
                                    "The change machine render modal is opened"
                                );
                                setIsChangeMachineRenderModalOpened(true);
                            }
                        } else {
                            console.log(
                                `The amount deposited in this transaction is not null`
                            );
                            setRenderAmount(
                                totalAmountDeposited + amountDeposited
                            );
                            console.log(
                                `The render amount will be  totalAmountDeposited + amountDeposited: ${
                                    totalAmountDeposited + amountDeposited
                                }`
                            );

                            if (
                                totalAmountDeposited + amountDeposited !==
                                (isOrderPartialPaidWithLoyaltyAccountBalance
                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                    : totalPrice)
                            ) {
                                console.log(
                                    "The change machine render modal is opened"
                                );
                                setIsChangeMachineRenderModalOpened(true);
                            }
                        }

                        if (
                            isAnotherPaymentModeButtonDisabled &&
                            isBackButtonDisabled === false
                        ) {
                            setIsEventStreamProblem(true);
                        }

                        setPaymentModes([]);
                    }
                })
                .catch((error) => {
                    console.log(
                        `Error while cancelling the ${selectedRegulationModeValue} transaction, with the message: ${error.message}`
                    );
                });
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isAbortTransactionModalOpened}
            contentLabel="help-modal"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "340px" }}
            >
                <img
                    src={getAlertActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType,
                        images,
                        customerLanguage.name
                    )}
                />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "117px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Cancellation of payment")}
                </span>
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("The payment will be cancelled.")}
                </span>
            </div>

            <div style={{ height: "50px" }}></div>
            <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("Would you like to continue?")}
                </span>
            </div>
            <div style={{ height: "200px" }}></div>
            <div
                style={{ height: "450px" }}
                className="d-flex justify-content-center"
            >
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                        src={getNoActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType,
                            images,
                            customerLanguage.name
                        )}
                        style={{ height: "70%" }}
                        onClick={() => {
                            if (isTransactionCanceled === false) {
                                setIsAbortTransactionModalOpened(false);
                            }
                        }}
                    />
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("No")}
                    </span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                        src={getYesActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType,
                            images,
                            customerLanguage.name
                        )}
                        style={{
                            height: "70%",
                            pointerEvents: isAcceptAbortCBTransaction
                                ? "none"
                                : "auto",
                            opacity: isAcceptAbortCBTransaction ? "0.5" : 1,
                        }}
                        onClick={handleAbortTransaction}
                    />
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("Yes")}
                    </span>
                </div>
            </div>
        </Modal>
    );
}
