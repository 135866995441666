import { LockIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import enLayout from "simple-keyboard-layouts/build/layouts/english";
import frLayout from "simple-keyboard-layouts/build/layouts/french";
import { useSnapshot } from "valtio";

import React from "react";
import { MdMailOutline } from "react-icons/md";
import Keyboard, { KeyboardInput } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { handleUserAuthentication } from "@helpers/general";

import { kioskStore, setKeyboardInputsValues } from "@pages/Kiosk/store";

import "./index.css";

export function PrmKeyboardWrapper(): JSX.Element {
    const {
        project: {
            template: { keyboard: keyboardLayout, content },
        },
        isAuthenticationErrorModalOpened,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        authModalStepIndex,
    } = useSnapshot(kioskStore);

    const [inputs, setInputs] = React.useState<{ [key: string]: string }>({});
    const [layoutName, setLayoutName] = React.useState<string>("default");
    const [inputName, setInputName] = React.useState<string>("");
    const [isKeyboardOpened, setIsKeyboardOpened] =
        React.useState<boolean>(false);

    // eslint-disable-next-line
    const keyboard = React.useRef<any>(null);
    // eslint-disable-next-line
    const inputRef1 = React.useRef<any>(null);
    // eslint-disable-next-line
    const inputRef2 = React.useRef<any>(null);

    // eslint-disable-next-line
    function setInputCaretPosition(element: any, position: any) {
        if (element.setSelectionRange) {
            element.focus();
            element.setSelectionRange(position, position);

            if (position === element.value.length)
                element.scrollLeft = element.scrollWidth;
        }
    }

    function handleOnChangeAllEvent(inputs: KeyboardInput) {
        setInputs({ ...inputs });
        setKeyboardInputsValues(inputs);
        const caretPosition = keyboard.current.caretPosition;
        if (caretPosition !== null) {
            if (inputName === t("Enter your email")) {
                setInputCaretPosition(
                    inputRef1.current,
                    keyboard.current.caretPosition
                );
            } else if (inputName === t("Enter your password")) {
                setInputCaretPosition(
                    inputRef2.current,
                    keyboard.current.caretPosition
                );
            }
        }
    }

    function handleShift() {
        setLayoutName(layoutName === "default" ? "shift" : "default");
    }

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        } else if (button === "{enter}") {
            const authModeInfo: AuthModeInfoType = {
                email: inputs[t("Enter your email")],
                password: inputs[t("Enter your password")],
            };

            handleUserAuthentication(
                authModeInfo,
                kioskWays,
                navigationIndex,
                isAuthenticationAccepted,
                selectedSaleModeValue,
                selectedInformationMode,
                isOrderPaidWithLoyaltyAccountBalance,
                selectedRegulationModeValue,
                authModalStepIndex,
                isAuthenticationErrorModalOpened,
                content
            );
        }
    }

    function handleInputsOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const inputVal = event.target.value;

        setInputs({
            ...inputs,
            [inputName]: inputVal,
        });

        keyboard.current?.setInput(inputVal);
    }

    function getInputValue(inputName: string) {
        return inputs[inputName];
    }
    function onBlurInput(event: React.ChangeEvent<HTMLInputElement>) {
        keyboard.current.caretPosition = event.target.selectionStart;
    }

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
                width: "1080px",
                height: isKeyboardOpened ? "510px" : "398px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                zIndex: 1,
            }}
        >
            <form className="d-flex flex-column justify-content-center align-items-center">
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        whiteSpace: "nowrap",
                        gap: "10px",
                    }}
                >
                    {t("E-mail address")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                        }}
                    >
                        <MdMailOutline
                            className="m-2"
                            style={{
                                width: "40px",
                                height: "40px",
                                color: "#565555",
                            }}
                        />
                        <input
                            style={{
                                border: "none",
                                width: "930px",
                                height: "50px",
                            }}
                            id="email"
                            ref={inputRef1}
                            type="text"
                            className="px-3"
                            value={getInputValue(t("Enter your email"))}
                            onFocus={() => setInputName(t("Enter your email"))}
                            placeholder={t("Enter your email")}
                            onChange={handleInputsOnChangeEvent}
                            onClick={() => {
                                setIsKeyboardOpened(true);
                            }}
                            onBlur={onBlurInput}
                        />
                    </div>
                </label>
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        gap: "10px",
                    }}
                >
                    {t("Password")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                        }}
                    >
                        <LockIcon
                            className="m-2"
                            style={{
                                width: "40px",
                                height: "40px",
                                color: "#565555",
                            }}
                        />
                        <input
                            ref={inputRef2}
                            id="password"
                            type="password"
                            className="px-3"
                            value={getInputValue(t("Enter your password"))}
                            onFocus={() =>
                                setInputName(t("Enter your password"))
                            }
                            style={{
                                border: "none",
                                width: "930px",
                                height: "50px",
                            }}
                            placeholder={t("Enter your password")}
                            onChange={handleInputsOnChangeEvent}
                            onClick={() => {
                                setIsKeyboardOpened(true);
                            }}
                            onBlur={onBlurInput}
                        />
                    </div>
                </label>
            </form>

            {isKeyboardOpened === true ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%" }}
                >
                    <div
                        onClick={() => {
                            setIsKeyboardOpened(false);
                        }}
                        style={{ width: "70px", height: "300px" }}
                    ></div>
                    <Keyboard
                        layout={
                            keyboardLayout === "azerty"
                                ? frLayout.layout
                                : enLayout.layout
                        }
                        theme="prm-keyboard__clz hg-theme-default hg-layout-default"
                        keyboardRef={(r) => (keyboard.current = r)}
                        inputName={inputName}
                        layoutName={layoutName}
                        onChangeAll={handleOnChangeAllEvent}
                        onKeyPress={handleKeyboardOnKeyPressEvent}
                        preventMouseDownDefault={true}
                        buttonTheme={[
                            {
                                class: "enter-key__clz",
                                buttons: "{enter}",
                            },
                        ]}
                        display={{
                            "{bksp}": `<img src="./images/backspace.svg" />`,
                            "{enter}": t("Enter key"),
                            "{tab}": `<img src="./images/tab.svg" width="25" height="25" />`,
                            "{lock}": `<img src="./images/caps.svg" width="25" height="25" />`,
                            "{shift}": `<img src="./images/shift.svg" />`,
                            "{space}": " ",
                        }}
                    />
                    <div
                        onClick={() => {
                            setIsKeyboardOpened(false);
                        }}
                        style={{ width: "70px", height: "300px" }}
                    />
                </div>
            ) : null}
        </div>
    );
}
