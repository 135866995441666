import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import NutritionalInformationDialog from "@components/OrderTaking/Components//Dialogs/NutritionalInformation/NutritionalInformationDialog";
import { toggleBasicCompositionitem } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import {
    BasicCompositionActions,
    BasicCompositionTitle,
    WithoutImage,
} from "./component";

function CardBasicComposition({ product, isAllergenIconActive }) {
    const { middlewareApiUrl } = useSnapshot(store);
    const dispatch = useDispatch();
    const { isPrm } = useSnapshot(kioskStore);

    function ProductOnClick() {
        if (product.outOfStock || product.isObligatory) return;
        dispatch(toggleBasicCompositionitem(product.indexItem));
    }

    const [
        isNutritionalInformationDialogOpen,
        setIsNutritionalInformationDialogOpen,
    ] = React.useState(false);

    const handleNutritionalInformationDialogOnCloseEvent = () => {
        setIsNutritionalInformationDialogOpen(
            !isNutritionalInformationDialogOpen
        );
    };

    const { isProductTitleActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const productImg =
        process.env.REACT_APP_CARD_ENV != "dev"
            ? `${middlewareApiUrl}/static/${product.image.split("/").pop()}`
            : product.image;

    return (
        <>
            <NutritionalInformationDialog
                open={isNutritionalInformationDialogOpen}
                onClose={handleNutritionalInformationDialogOnCloseEvent}
                product={product}
                handleProductOnClickEvent={ProductOnClick}
                isAllergenIconActive={isAllergenIconActive}
                handleAllergenConfirmationDialogOnCLickEvent={() => {
                    console.log("composition de base");
                }}
            />

            <div className="d-flex h-100">
                <div
                    className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                    style={{ gap: "10px", zIndex: 1 }}
                    onClick={() => {
                        ProductOnClick();
                    }}
                >
                    <div className="d-flex justify-content-center align-items-center h-50">
                        <div className="d-flex justify-content-center align-items-center h-100 pt-2">
                            <WithoutImage product={product} />
                            <img
                                style={{
                                    opacity: !product.basicCompositionActive
                                        ? "40%"
                                        : "100%",
                                }}
                                loading="lazy"
                                id={`key-img${product.id}`}
                                className="product-image "
                                src={productImg}
                                alt={product.title}
                            />
                        </div>
                    </div>
                    <BasicCompositionTitle
                        product={product}
                        isPrm={isPrm}
                        isProductTitleActive={isProductTitleActive}
                    />
                </div>
                <BasicCompositionActions
                    product={product}
                    isAllergenIconActive={isAllergenIconActive}
                    handleNutritionalInformationDialogOnCloseEvent={
                        handleNutritionalInformationDialogOnCloseEvent
                    }
                    ProductOnClick={ProductOnClick}
                />
            </div>
        </>
    );
}

export default CardBasicComposition;
CardBasicComposition.propTypes = {
    product: PropTypes.object.isRequired,
    isInfoActive: PropTypes.bool.isRequired,
    isAllergenIconActive: PropTypes.bool,
};
