import { BASIC_COMPOSITION_TYPE, CATEGORY_TYPE } from "@constants";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { v4 as uuid } from "uuid";

import {
    deletedInvisibleNewOrder,
    editSalesMode,
    getCard,
} from "../../Helpers";
import { getLoyaltyTotal } from "../../Helpers/loyalty";

const initialState = {
    user: {},
    globalcategorys: [],
    globalcard: {},
    globalAllergens: [],
    defaultAllergens: [],
    basicComposition: {},

    listProducts: [],
    OutOfstockItems: [],
    additionalSale: { selectedItems: [] },
    order: {
        orderItems: [],
        totalPrice: 0,
        itemsCount: 0,
    },
    orderCreate: { loading: true },
    activeCategory: "",
    activeCategoryParent: "",
    defaultCategory: "",
    isInfoActive: true,
    isAllergenActive: true,
    isDescriptionActive: true,
    quantity: 0,
    tags: [],
    defaultTags: [],
    catalogueIsLoading: true,
    fidelityTotal: 0,
    currentItemPriceOptions: {
        selectedOptions: [],
        newPrice: 0,
        tva: 0,
    },
    nestedCategories: [],
};

export const orderSlice = createSlice({
    name: "orderSlice",
    initialState,
    reducers: {
        setActiveCategory: (state, action) => {
            state.activeCategory = action.payload;
            state.nestedCategories = [action.payload];
        },

        setListProduct: (state, action) => {
            const { activeCategory } = action.payload;
            let localListProducts =
                state.globalcard.categories[activeCategory].listProduct;
            let activeTags = state.tags
                .filter((tag) => {
                    return tag.active === true;
                })
                .map((activeTag) => {
                    return activeTag.id;
                });
            state.listProducts = localListProducts.filter((product) => {
                return product?.linkedTags?.some((item) =>
                    activeTags.includes(item)
                );
            });
        },
        setActiveCategoryParent: (state, action) => {
            state.activeCategoryParent = action.payload;
            state.nestedCategories = [
                ...state.nestedCategories,
                action.payload,
            ];
        },

        addToOrder: (state, action) => {
            let newItem = action.payload;

            const existItem = state.order.orderItems.find(
                (elm) =>
                    elm.iuud === newItem.iuud &&
                    isEqual(newItem.shopCart, elm.shopCart) &&
                    isEqual(newItem.selectedOptions, elm.selectedOptions)
            );
            if (existItem) {
                existItem.quantity += 1;
            }

            const iuudOrder = uuid();

            const orderItems = existItem
                ? state.order.orderItems.map((x) =>
                      x.iuudOrder === existItem.iuudOrder ? existItem : x
                  )
                : [
                      ...state.order.orderItems,
                      { ...newItem, iuudOrder, quantity: 1 },
                  ];

            const itemsCount = orderItems.reduce(
                (prev, curr) => prev + curr.quantity,
                0
            );

            const itemsPrice = orderItems.reduce(
                (a, c) => a + c.quantity * c.price,
                0
            );

            const totalPrice = Math.round(itemsPrice * 100) / 100;

            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
            };

            state.fidelityTotal = getLoyaltyTotal(orderItems);

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: [],
                    selectedOptionsUuid: [],
                    newPrice: 0,
                    tva: 0,
                })
            );
        },

        orderSalesModeModification: (state, { payload }) => {
            const { orderItems, totalPrice, itemsCount } = payload;
            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
            };
            state.fidelityTotal = getLoyaltyTotal(orderItems);
        },

        modifyOrder: (state, action) => {
            let newItem = action.payload;

            const orderItems = state.order.orderItems.map((x) =>
                x.iuudOrder === newItem.iuudOrder ? newItem : x
            );
            const itemsCount = orderItems.reduce(
                (prev, curr) => prev + curr.quantity,
                0
            );
            const itemsPrice = orderItems.reduce(
                (a, c) => a + c.quantity * c.price,
                0
            );

            const totalPrice = Math.round(itemsPrice * 100) / 100;

            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
            };
            state.fidelityTotal = getLoyaltyTotal(orderItems);
        },
        addQuantityOrderItem: (state, action) => {
            const existItem = state.order.orderItems.find(
                (elm) => elm.iuudOrder === action.payload.iuudOrder
            );
            if (existItem) {
                existItem.quantity += 1;
            }

            const orderItems = state.order.orderItems.map((x) =>
                x.iuudOrder === existItem.iuudOrder ? existItem : x
            );

            const itemsCount = orderItems.reduce(
                (prev, curr) => prev + curr.quantity,
                0
            );
            const itemsPrice = orderItems.reduce(
                (a, c) => a + c.quantity * c.price,
                0
            );

            const totalPrice = Math.round(itemsPrice * 100) / 100;
            state.order = {
                orderItems,
                itemsCount,
                totalPrice,
            };
        },
        confirmSalesModeModification: (state, { payload }) => {
            const newOrders = deletedInvisibleNewOrder(
                state.order.orderItems,
                payload.settingKey
            );
            const { orderItems, totalPrice, itemsCount } = editSalesMode(
                newOrders,
                payload.id
            );

            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
            };
        },

        removeFromOrder: (state, action) => {
            let orderItems = [
                ...JSON.parse(JSON.stringify(state.order.orderItems)),
            ];
            const itemTodelete = orderItems.filter(
                (orderItem) => orderItem.iuudOrder === action.payload.iuudOrder
            )[0];
            if (itemTodelete.quantity > 1) {
                const newItem = {
                    ...action.payload,
                    quantity: +itemTodelete.quantity - 1,
                };
                orderItems = orderItems.map((x) =>
                    x.iuudOrder === action.payload.iuudOrder ? newItem : x
                );
            } else {
                orderItems = orderItems.filter(
                    (x) => x.iuudOrder !== action.payload.iuudOrder
                );
            }

            const itemsCount = orderItems.reduce((a, c) => a + c.quantity, 0);
            const itemsPrice = orderItems.reduce(
                (a, c) => a + c.quantity * c.price,
                0
            );
            const totalPrice = Math.round(itemsPrice * 100) / 100;

            state.order = {
                orderItems,
                totalPrice,
                itemsCount,
            };
            state.fidelityTotal = getLoyaltyTotal(orderItems);
        },

        clearOrder: (state) => {
            state.order = {
                orderItems: [],
                totalPrice: 0,
                itemsCount: 0,
            };
            state.activeCategory = state.defaultCategory;
            state.nestedCategories = [state.defaultCategory];
            state.activeCategoryParent = state.defaultCategory;
            state.globalAllergens = state.defaultAllergens;
            state.tags = state.defaultTags;
            state.fidelityTotal = 0;
        },

        setAdditionalItems: (state, action) => {
            state.additionalSale = action.payload;
        },
        setOutOfstockItems: (state, action) => {
            state.OutOfstockItems = action.payload;
        },
        selectedAdditionalItems: (state, action) => {
            let newItem = action.payload;
            const existItem = state.additionalSale.selectedItems.find(
                (elm) =>
                    elm.iuud === newItem.iuud &&
                    isEqual(newItem.shopCart, elm.shopCart)
            );
            if (existItem) {
                existItem.quantity += 1;
            }

            const selectedItems = existItem
                ? state.additionalSale.selectedItems.map((x) =>
                      x.iuud === existItem.iuud ? existItem : x
                  )
                : [
                      ...state.additionalSale.selectedItems,
                      { ...newItem, quantity: 1 },
                  ];
            state.additionalSale = { ...state.additionalSale, selectedItems };
        },
        removeFromSelectedAdditionalItems: (state, action) => {
            let newItem = action.payload;
            const existItem = state.additionalSale.selectedItems.find(
                (elm) =>
                    elm.iuud === newItem.iuud &&
                    isEqual(newItem.shopCart, elm.shopCart)
            );
            if (existItem) {
                existItem.quantity -= 1;
            }

            const selectedItems =
                existItem.quantity > 0
                    ? state.additionalSale.selectedItems.map((x) =>
                          x.iuud !== existItem.iuud ? existItem : x
                      )
                    : state.additionalSale.selectedItems.filter(
                          (x) => x.iuud !== existItem.iuud
                      );
            state.additionalSale = { ...state.additionalSale, selectedItems };
        },
        validateAdditionnalItems: (state, action) => {
            let orderItems = [];
            action.payload.map((additionalItem) => {
                const iuudOrder = uuid();
                const existItem = state.order.orderItems.find(
                    (elm) =>
                        elm.iuud === additionalItem.iuud &&
                        isEqual(additionalItem.shopCart, elm.shopCart)
                );

                if (existItem) {
                    existItem.quantity += additionalItem.quantity;
                }
                orderItems = existItem
                    ? state.order.orderItems.map((x) =>
                          x.iuud === existItem.iuud ? existItem : x
                      )
                    : [
                          ...state.order.orderItems,
                          {
                              ...additionalItem,
                              iuudOrder,
                              quantity: additionalItem.quantity,
                          },
                      ];
                const itemsCount = orderItems.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0
                );

                const itemsPrice = orderItems.reduce(
                    (a, c) => a + c.quantity * c.price,
                    0
                );
                const totalPrice = Math.round(itemsPrice * 100) / 100;

                state.order = {
                    orderItems,
                    totalPrice,
                    itemsCount,
                };
            });
        },

        setTags(state, action) {
            state.tags = action.payload;
        },
        setGlobalAllergens: (state, action) => {
            state.globalAllergens = action.payload;
        },
        setCarte: (state, { payload }) => {
            state.globalcard = payload.globalCard;
            state.globalcategorys = payload.globalCategory;
            state.activeCategory = payload.CategoryActive;
            state.defaultCategory = payload.CategoryActive;
            state.defaultAllergens = payload.globalAllergens;
            state.nestedCategories = [payload.CategoryActive];
            state.listProducts =
                payload.globalCard.categories[
                    payload.CategoryActive
                ].listProduct;
            state.tags = payload.tags;
            state.defaultTags = payload.tags;
            state.globalAllergens = payload.globalAllergens;
            state.basicComposition = payload.basicComposition;
            state.catalogueIsLoading = false;
        },
        setLoyaltyTotal: (state) => {
            state.fidelityTotal = 0;
            let sum = 0;
            state.order.orderItems.forEach((item) => {
                sum = sum + item.fidelity * item.quantity;
                if (item.shopCart && Object.keys(item.shopCart).length > 0) {
                    Object.values(item.shopCart).forEach((el) => {
                        if (el.type !== BASIC_COMPOSITION_TYPE) {
                            Object.values(el.compositions).forEach(
                                (product) => {
                                    sum =
                                        sum +
                                        product.fidelity * product.quantity;
                                }
                            );
                        }
                    });
                }
            });

            state.fidelityTotal = sum;
        },

        setNewItemProperties: (state, action) => {
            state.currentItemPriceOptions = action.payload;
        },

        clearNewItemProperties: (state) => {
            state.currentItemPriceOptions.selectedOptions = [];
            state.currentItemPriceOptions.newPrice = 0;
            state.currentItemPriceOptions.tva = 0;
        },
        setNestedCategories(state, action) {
            state.nestedCategories = [
                ...state.nestedCategories,
                action.payload,
            ];
        },
        updateNestedCategories(state, action) {
            const newList = [...state.nestedCategories];

            newList.length = state.nestedCategories.indexOf(action.payload) + 1;

            state.nestedCategories = newList;

            state.activeCategoryParent = newList[newList.length - 1];
        },
        clearNestedCategories: (state) => {
            state.nestedCategories = [state.activeCategory];
        },
        setDefaultCategory: (state, action) => {
            state.defaultCategory = action.payload;
            state.nestedCategories = [action.payload];
            state.activeCategory = action.payload;
        },
    },

    extraReducers: {
        [getCard.pending]: (state) => {
            state.catalogueIsLoading = true;
        },
        [getCard.fulfilled]: (state, { payload }) => {
            state.globalcard = payload.globalCard;
            state.globalcategorys = payload.globalCategory;
            state.activeCategory = payload.CategoryActive;
            state.defaultCategory = payload.CategoryActive;
            state.defaultAllergens = payload.globalAllergens;
            state.nestedCategories = [payload.CategoryActive];
            state.defaultTags = payload.tags;
            state.listProducts =
                payload.globalCard.categories[
                    payload.CategoryActive
                ].listProduct;
            state.tags = payload.tags;
            state.globalAllergens = payload.globalAllergens;
            state.basicComposition = payload.basicComposition;
            state.catalogueIsLoading = false;
        },
        [getCard.rejected]: (state) => {
            state.catalogueIsLoading = false;
        },
    },
});

const getCategories = (state) => state.orderSlice.globalcategorys;
const getActiveCategory = (state) => state.orderSlice.activeCategory;
const getActiveCategoryParent = (state) =>
    state.orderSlice.activeCategoryParent;
const getOrderItems = (state) => state.orderSlice.order.orderItems;
const getStoreTags = (state) => state.orderSlice.tags;
const getGlobalcard = (state) => state.orderSlice.globalcard;
const getGlobalAllergens = (state) => state.orderSlice.globalAllergens;

export const memoizedSelectedProducts = createSelector(
    [
        getActiveCategory,
        getActiveCategoryParent,
        getStoreTags,
        getCategories,
        getGlobalcard,
    ],
    (
        activeCategory,
        activeCategoryParent,
        tags,
        _globalcategorys,
        globalcard
    ) => {
        const selectCategoryIuud = activeCategoryParent
            ? activeCategoryParent
            : activeCategory;
        let localListProducts =
            globalcard.categories[selectCategoryIuud].listProduct;

        const selectedCategory = globalcard.categories[selectCategoryIuud];

        let activeTags = tags
            .filter((tag) => {
                return (
                    tag.active === true &&
                    selectedCategory?.tagList.includes(tag.id)
                );
            })
            .map((activeTag) => {
                return activeTag.id;
            });

        return activeTags.length > 0
            ? localListProducts.filter((product) => {
                  return product.type === CATEGORY_TYPE
                      ? true
                      : product.linkedTags?.some((item) =>
                            activeTags.includes(item)
                        );
              })
            : localListProducts;
    }
);

export const memoizedTags = createSelector(
    [getStoreTags, getActiveCategory, getActiveCategoryParent, getCategories],
    (tags, activeCategoryParent, activeCategory, globalcategorys) => {
        const selectCategoryIuud = activeCategoryParent
            ? activeCategoryParent
            : activeCategory;

        const selectedCategory = globalcategorys?.find(
            (cat) => cat.iuud === selectCategoryIuud
        );

        const visibleTags = tags.reduce((prev, curr) => {
            let currentTag = { ...curr, isVisible: false };

            if (selectedCategory?.tagsItems.includes(currentTag.id)) {
                currentTag = { ...curr, isVisible: true };
            }
            return [...prev, currentTag];
        }, []);

        return visibleTags;
    }
);

export const memoizedActiveCategory = createSelector(
    [getActiveCategory, getActiveCategoryParent],
    (activeCategory, activeCategoryParent) =>
        activeCategoryParent ? activeCategoryParent : activeCategory
);
export const memoizedOrderItems = createSelector(
    [getOrderItems],
    (orderItems) => orderItems
);

export const memoizedCategories = createSelector(
    [getCategories],
    (globalcategorys) => globalcategorys
);

export const memoizedActiveCategoryUid = createSelector(
    [getActiveCategory],
    (activeCategory) => activeCategory
);

export const memoizedGlobalAllergens = createSelector(
    [getGlobalAllergens],
    (globalAllergens) => globalAllergens
);

export const {
    order,
    setActiveCategory,
    setListProduct,
    addToOrder,
    removeFromOrder,
    clearOrder,
    setActiveCategoryParent,
    setAdditionalItems,
    selectedAdditionalItems,
    validateAdditionnalItems,
    setTags,
    setGlobalAllergens,
    setBasicComposition,
    setCarte,
    modifyOrder,
    setLoyaltyTotal,
    setNewItemProperties,
    clearNewItemProperties,
    setNestedCategories,
    clearNestedCategories,
    updateNestedCategories,
    orderSalesModeModification,
    removeFromSelectedAdditionalItems,
    addQuantityOrderItem,
    setDefaultCategory,
    confirmSalesModeModification,
} = orderSlice.actions;

export default orderSlice.reducer;
