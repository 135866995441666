// eslint-disable-next-line
import log from "electron-log";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";

import { App } from "./App";
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// console.log = __electronLog.log;

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <App />
        </HashRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

if (process.env.REACT_APP_ELECTRON_ENV === "dev") {
    serviceWorkerRegistration.unregister();
} else {
    serviceWorkerRegistration.register();
}
