import { Alert } from "@material-ui/lab";
import { Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";

import React from "react";
import { useSelector } from "react-redux";

import BasketActionsButtons from "@components/OrderTaking/common/BasketActionButtons";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";

import "./index.css";

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={700} ref={ref} {...props} />;
});

export default function PrmDialogCart({ openDialogCart, setOpenDialogCart }) {
    const { orderItems, itemsCount } = useSelector(
        (state) => state.orderSlice.order
    );

    if (openDialogCart && orderItems.length === 0) {
        setOpenDialogCart(false);
    }
    return (
        <Dialog
            TransitionComponent={TransitionSlide}
            open={openDialogCart}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "1080px",
                    height: "540px",
                    maxHeight: "unset!important",
                    maxWidth: "unset!important",
                    position: "fixed",
                    bottom: -32,
                    overflowY: "visible",
                },
            }}
        >
            <div
                className="prm-cart-close-button"
                onClick={() => setOpenDialogCart(false)}
            >
                <span className="label-close-cart">Réduire</span>
            </div>
            <div className="prm-header-popup">
                <div className="prm-title-popup-cart">Votre commande</div>
                <div className="prm-number-product-in-cart">
                    {itemsCount} Articles dans votre panier
                </div>
                <hr className="separation-line" />
            </div>
            <section className="prm-cart-products-list">
                {!orderItems.length ? (
                    <Alert severity="error">
                        Pas de produit disponible dans le panier
                    </Alert>
                ) : (
                    orderItems.map((product) => (
                        <ComposedProductCartPopup
                            key={product.iuudOrder}
                            product={product}
                        ></ComposedProductCartPopup>
                    ))
                )}
            </section>
            <BasketActionsButtons
                openDialogCart={openDialogCart}
                setOpenDialogCart={setOpenDialogCart}
            />
        </Dialog>
    );
}

PrmDialogCart.propTypes = {
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
    ProductInfoFromState: PropTypes.object,
    settings: PropTypes.object,
};
