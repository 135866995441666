import { t } from "i18next";
import { SpinningCircles } from "svg-loaders-react";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getImageContentById,
    getNoActionButtonImageSrc,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import { setIsHelpModalOpened, kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

export function HelpModalWrapper(): JSX.Element {
    const {
        isHelpModalOpened,
        isPrm,
        project: {
            files: images,
            template: {
                content: { generalDesign, options, actionsButtons },
            },
        },
        customerLanguage,
    } = useSnapshot(store);

    const [isHelpNeeded, setIsHelpNeeded] = React.useState<boolean>(false);

    const askForHelpImageContent = getImageContentById(
        (
            (options as OptionsType).items.find(
                (option) => option.title === "Ask for help"
            ) as OptionsItemType
        ).id,
        images,
        customerLanguage.name
    );

    const askForHelpImageSrc =
        askForHelpImageContent?.includes("http") ||
        askForHelpImageContent?.includes("https")
            ? askForHelpImageContent
            : `./images/${askForHelpImageContent}`;

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isHelpModalOpened}
            onRequestClose={() => {
                setIsHelpModalOpened(!isHelpModalOpened);
            }}
            contentLabel="help-modal"
            shouldCloseOnOverlayClick={isHelpNeeded ? true : false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "340px" }}
                onClick={() => {
                    if (isHelpNeeded === true) {
                        setIsHelpModalOpened(!isHelpModalOpened);
                    }
                }}
            >
                <img src={askForHelpImageSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "117px" }}
                onClick={() => {
                    if (isHelpNeeded === true) {
                        setIsHelpModalOpened(!isHelpModalOpened);
                    }
                }}
            >
                <span style={{ font: "normal 55px Segoe UI" }}>
                    {t("Ask for help")}
                </span>
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "117px" }}
                onClick={() => {
                    if (isHelpNeeded === true) {
                        setIsHelpModalOpened(!isHelpModalOpened);
                    }
                }}
            >
                <span style={{ font: "normal normal 600 35px Segoe UI" }}>
                    {isHelpNeeded
                        ? t("A manager will come to assist you.")
                        : t("Do you need assistance?")}
                </span>
            </div>
            {isHelpNeeded ? (
                <React.Fragment>
                    <div
                        className="d-flex flex-column justify-content-center align-items-center w-100"
                        style={{ height: "515px", gap: "20px" }}
                        onClick={() => {
                            if (isHelpNeeded === true) {
                                setIsHelpModalOpened(!isHelpModalOpened);
                            }
                        }}
                    >
                        <SpinningCircles width="180px" height="180px" />
                        <span
                            style={{
                                font: "normal normal normal 50px/67px Segoe UI",
                            }}
                        >
                            {t("Please wait...")}
                        </span>
                    </div>
                    <div
                        className="d-flex justify-content-center align-items-center w-100"
                        style={{ height: "130px" }}
                        onClick={() => {
                            if (isHelpNeeded === true) {
                                setIsHelpModalOpened(!isHelpModalOpened);
                            }
                        }}
                    >
                        <span
                            style={{
                                font: "normal normal 600 45px/60px Segoe UI",
                            }}
                        >
                            {t("Touch the screen to continue")}
                        </span>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div style={{ height: "200px" }}></div>
                    <div
                        style={{ height: "450px", gap: "30px" }}
                        className="d-flex justify-content-center"
                    >
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div
                                style={{
                                    height: "350px",
                                    width: "350px",
                                }}
                            >
                                <img
                                    src={getNoActionButtonImageSrc(
                                        actionsButtons as ActionsButtonsDesignType,
                                        images,
                                        customerLanguage.name
                                    )}
                                    className="w-100 h-100"
                                    style={{ backgroundSize: "cover" }}
                                    onClick={() =>
                                        setIsHelpModalOpened(!isHelpModalOpened)
                                    }
                                />
                            </div>
                            <span
                                style={{
                                    font: "normal normal 600 37px/49px Segoe UI",
                                }}
                            >
                                {t("No")}
                            </span>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div
                                style={{
                                    height: "350px",
                                    width: "350px",
                                }}
                            >
                                <img
                                    src={getYesActionButtonImageSrc(
                                        actionsButtons as ActionsButtonsDesignType,
                                        images,
                                        customerLanguage.name
                                    )}
                                    className="w-100 h-100"
                                    style={{ backgroundSize: "cover" }}
                                    onClick={() => setIsHelpNeeded(true)}
                                />
                            </div>
                            <span
                                style={{
                                    font: "normal normal 600 37px/49px Segoe UI",
                                }}
                            >
                                {t("Yes")}
                            </span>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </Modal>
    );
}
