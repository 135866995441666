import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { useSalesModeModification } from "@hooks/useSalesModeModification";

import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { CategoriesSwiperWrapper } from "@components/OrderTaking/Components/Categories/CategoriesSwiperWrapper";
import OrderTakingTopBanner from "@components/OrderTaking/Components/OrderTakingTopBanner";
import PrmBasket from "@components/OrderTaking/Components/Prm/PrmBasket";
import ProductWrapper from "@components/OrderTaking/Components/Products/ProductWrapper";
import { TagsSwiperWrapper } from "@components/OrderTaking/Components/Tags/TagsSwiperWrapper";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import { kioskStore, setIsStandbyModalOpened } from "@pages/Kiosk/store";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

import CategoriesBreadCrumb from "./CategoriesBreadCrumb";
import "./prm-mode.css";

import "swiper/swiper.min.css";

export default function PrmOrderTakingScreen() {
    const productImgWidth = "135px";
    useSalesModeModification();
    const { user } = useSnapshot(kioskStore);
    const {
        isOrderTakingTopBannerActive,
        isOrderTakingTagsActive,
        isOrderTakingUserAccountActive,
        userAccountMessage,
        isUserAccountModalOpened,
        globalColor,
    } = useSelector((state) => state.settingSlice.generalSetting, shallowEqual);

    const { isSelectedCategoryActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );

    const [containerRef, setContainerRef] = React.useState(null);

    const {
        timeout,
        isHelpModalOpened,
        isStandbyModalOpened,
        isUserAuthenticated,
        isLogoutProblemModalOpened,
    } = useSnapshot(kioskStore);

    function setCardColor(newColor) {
        document.documentElement.style.setProperty("--global-color", newColor);
    }

    React.useEffect(() => {
        setCardColor(globalColor);
    }, [globalColor]);

    React.useEffect(() => {
        document.documentElement.style.setProperty(
            "--width-product-img",
            productImgWidth
        );
    }, []);
    return (
        <React.Fragment>
            <IdleDetection
                timeout={timeout * 1000}
                once={true}
                onIdle={() => {
                    setIsStandbyModalOpened(true);
                }}
            />
            {containerRef ? (
                isStandbyModalOpened === true ? (
                    <StandbyModalWrapper containerRef={containerRef} />
                ) : null
            ) : null}
            {containerRef ? (
                isUserAccountModalOpened === true ? (
                    <UserAccountModalWrapper containerRef={containerRef} />
                ) : null
            ) : null}
            {containerRef ? (
                isHelpModalOpened === true ? (
                    <HelpModalWrapper containerRef={containerRef} />
                ) : null
            ) : null}
            {isLogoutProblemModalOpened === true ? (
                <LogoutProblemModal />
            ) : null}
            <div
                className="order-taking-prm-container order-taking-prm-layout"
                ref={(ref) => setContainerRef(ref)}
            >
                <header className="order-taking-prm-header">
                    <div className="d-flex flex-column h-100">
                        <PrmBackgroundWrapper />
                        {isOrderTakingTopBannerActive ? (
                            <OrderTakingTopBanner />
                        ) : null}
                        <div className="d-flex flex-column py-1">
                            {isOrderTakingUserAccountActive &&
                            isUserAuthenticated ? (
                                <div className="d-flex flex-column ">
                                    <div className="prm-authenticated-user-font">
                                        {userAccountMessage} {"   "}
                                        <span className="font-weight-bold">
                                            {user.firstName === ""
                                                ? user.lastName
                                                : user.firstName}
                                        </span>{" "}
                                    </div>
                                    <hr className="separation-line mx-1" />
                                </div>
                            ) : null}
                            {isSelectedCategoryActive && (
                                <div className="d-flex flex-column h-100">
                                    <div>
                                        <CategoriesBreadCrumb />
                                    </div>
                                    <hr className="separation-line mx-1" />
                                </div>
                            )}
                        </div>
                    </div>
                </header>

                <nav id="prm-categories" className="border-right">
                    <CategoriesSwiperWrapper />
                </nav>

                <section id="prm-products">
                    {isOrderTakingTagsActive ? <TagsSwiperWrapper /> : null}

                    <ProductWrapper />
                </section>

                <footer id="prm-footer">
                    <PrmBasket />
                </footer>
            </div>
        </React.Fragment>
    );
}

PrmOrderTakingScreen.propTypes = {
    stateOrder: PropTypes.object,
    orderTakingSettings: PropTypes.object,
};
