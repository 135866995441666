import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

export function ProductTitle({ quantity, product }) {
    const { isPrm } = useSnapshot(kioskStore);
    const {
        isProductTitleActive,

        //@eslint-disable-next-line
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const { name } = product;
    return isProductTitleActive ? (
        <Box
            className={`centredItems__clz px-1 ${
                isPrm ? "prm-product-title" : "product-title__clz"
            }`}
        >
            {quantity !== 0 ? (
                <Box
                    style={{
                        color: globalColor,
                        // width: "60px",
                    }}
                >
                    <span style={{ font: "normal bold 35px/22px Segoe UI" }}>
                        {quantity}×
                    </span>
                </Box>
            ) : null}
            <Box>{name?.content}</Box>
        </Box>
    ) : null;
}

ProductTitle.propTypes = {
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number,
};
