import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";

import { formatCurrency } from "@helpers/general";

import { kioskStore, setLeftToPay } from "@pages/Kiosk/store";

export function ChangeMachineCard(): JSX.Element {
    const {
        amountDeposited,
        leftToPay,
        leftToPayAfterCashMachineValidation,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        isCashTransactionValidated,
    } = useSnapshot(kioskStore);

    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);
    const [localAmountDeposited, setLocalAmountDeposited] =
        React.useState<number>(0);

    React.useEffect(() => {
        if (isCashTransactionValidated === false) {
            setLocalAmountDeposited(amountDeposited);
        }
    }, [isCashTransactionValidated, amountDeposited]);

    React.useEffect(() => {
        setLeftToPay(
            isOrderPartialPaidWithLoyaltyAccountBalance
                ? totalOrderAfterPaidWithLoyaltyAccountBalance
                : totalPrice
        );
    }, []);

    return (
        <Card
            style={{
                width: "910px",
                height: "265px",
                borderRadius: "31px",
                borderBottom: "12px solid #D51B1B",
                boxShadow: "0px 3px 6px #00000029",
                font: "Segoe UI",
            }}
        >
            <div
                className="d-grid__clz"
                style={{
                    gridTemplateRows: "1fr 0.1fr 0.8fr 0.8fr",
                    height: "100%",
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        gap: "280px",
                        fontSize: "50px",
                    }}
                >
                    <div>{t("Left to pay")}</div>
                    <div className="font-weight-bold">
                        {formatCurrency(
                            isCashTransactionValidated
                                ? leftToPayAfterCashMachineValidation
                                : leftToPay
                        )}
                    </div>
                </div>
                <hr
                    style={{
                        height: "2px",
                        width: "88%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                    }}
                />

                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-2"
                    style={{
                        fontSize: "45px",
                    }}
                >
                    <div>{t("Total deposited")}</div>
                    <div>
                        {formatCurrency(
                            isCashTransactionValidated
                                ? localAmountDeposited
                                : amountDeposited
                        )}
                    </div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-4"
                    style={{
                        fontSize: "30px",
                        color: "#7C7C7C",
                    }}
                >
                    <div>{t("Total order")}</div>
                    <div>{formatCurrency(totalPrice)}</div>
                </div>
            </div>
        </Card>
    );
}
