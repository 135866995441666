import React from "react";

export function CartIconEmpty() {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68.437"
            height="68.383"
            viewBox="0 0 68.437 68.383"
        >
            <g id="basket-cart" transform="translate(-595.276 -365.681)">
                <path
                    id="Tracé_20443"
                    data-name="Tracé 20443"
                    d="M659.7,393.7H599.286a2.673,2.673,0,0,0-2.673,2.673v3.475a2.673,2.673,0,0,0,2.673,2.673H659.7a2.673,2.673,0,0,0,2.673-2.673h0v-3.475A2.672,2.672,0,0,0,659.7,393.7Z"
                    fill="#ede0d6"
                />
                <path
                    id="Tracé_20444"
                    data-name="Tracé 20444"
                    d="M599.286,402.519l4.947,28.013a2.674,2.674,0,0,0,2.631,2.195h45.261a2.674,2.674,0,0,0,2.631-2.195l4.947-28.013Z"
                    fill={globalColor}
                />
                <path
                    id="Tracé_20446"
                    data-name="Tracé 20446"
                    d="M655.443,367.748a2.673,2.673,0,0,0-3.78,0L627.6,391.808a2.673,2.673,0,1,0,3.742,3.818l.038-.038,24.06-24.06A2.673,2.673,0,0,0,655.443,367.748Z"
                    fill="#ede0d6"
                />
                <path
                    id="Tracé_20455"
                    data-name="Tracé 20455"
                    d="M655.444,367.748a2.673,2.673,0,0,0-3.78,0L627.6,391.808a2.673,2.673,0,0,0,3.742,3.818l.038-.038,24.06-24.06A2.673,2.673,0,0,0,655.444,367.748Z"
                    fill="#ede0d6"
                />
                <g id="Groupe_27288" data-name="Groupe 27288">
                    <g id="Groupe_27288-2" data-name="Groupe 27288-2">
                        <path
                            id="Tracé_20457"
                            data-name="Tracé 20457"
                            d="M613.256,408.236a1.951,1.951,0,0,0-1.952,1.952h0v14.063a1.953,1.953,0,0,0,3.9,0V410.188a1.952,1.952,0,0,0-1.952-1.952Z"
                            fill="#040405"
                        />
                        <path
                            id="Tracé_20458"
                            data-name="Tracé 20458"
                            d="M613.259,408.929a1.121,1.121,0,0,0-1.122,1.121h0v14.283a1.123,1.123,0,0,0,2.244,0V410.051a1.122,1.122,0,0,0-1.122-1.122Z"
                            fill="#ede0d6"
                        />
                    </g>
                    <g id="Groupe_27289" data-name="Groupe 27289">
                        <path
                            id="Tracé_20457-2"
                            data-name="Tracé 20457-2"
                            d="M621.256,408.236a1.951,1.951,0,0,0-1.952,1.952h0v14.063a1.953,1.953,0,0,0,3.9,0V410.188a1.952,1.952,0,0,0-1.952-1.952Z"
                            fill="#040405"
                        />
                        <path
                            id="Tracé_20458-2"
                            data-name="Tracé 20458-2"
                            d="M621.259,408.929a1.121,1.121,0,0,0-1.122,1.121h0v14.283a1.123,1.123,0,0,0,2.244,0V410.051a1.122,1.122,0,0,0-1.122-1.122Z"
                            fill="#ede0d6"
                        />
                    </g>
                    <g id="Groupe_27290" data-name="Groupe 27290">
                        <path
                            id="Tracé_20457-3"
                            data-name="Tracé 20457-3"
                            d="M629.256,408.236a1.951,1.951,0,0,0-1.952,1.952h0v14.063a1.953,1.953,0,0,0,3.9,0V410.188a1.952,1.952,0,0,0-1.952-1.952Z"
                            fill="#040405"
                        />
                        <path
                            id="Tracé_20458-3"
                            data-name="Tracé 20458-3"
                            d="M629.259,408.929a1.121,1.121,0,0,0-1.122,1.121h0v14.283a1.123,1.123,0,0,0,2.244,0V410.051a1.122,1.122,0,0,0-1.122-1.122Z"
                            fill="#ede0d6"
                        />
                    </g>
                    <g id="Groupe_27291" data-name="Groupe 27291">
                        <path
                            id="Tracé_20457-4"
                            data-name="Tracé 20457-4"
                            d="M637.256,408.236a1.951,1.951,0,0,0-1.952,1.952h0v14.063a1.953,1.953,0,0,0,3.9,0V410.188a1.952,1.952,0,0,0-1.952-1.952Z"
                            fill="#040405"
                        />
                        <path
                            id="Tracé_20458-4"
                            data-name="Tracé 20458-4"
                            d="M637.259,408.929a1.121,1.121,0,0,0-1.122,1.121h0v14.283a1.123,1.123,0,0,0,2.244,0V410.051a1.122,1.122,0,0,0-1.122-1.122Z"
                            fill="#ede0d6"
                        />
                    </g>
                    <g id="Groupe_27292" data-name="Groupe 27292">
                        <path
                            id="Tracé_20457-5"
                            data-name="Tracé 20457-5"
                            d="M645.256,408.236a1.951,1.951,0,0,0-1.952,1.952h0v14.063a1.953,1.953,0,0,0,3.9,0V410.188a1.952,1.952,0,0,0-1.952-1.952Z"
                            fill="#040405"
                        />
                        <path
                            id="Tracé_20458-5"
                            data-name="Tracé 20458-5"
                            d="M645.259,408.929a1.121,1.121,0,0,0-1.122,1.121h0v14.283a1.123,1.123,0,0,0,2.244,0V410.051a1.122,1.122,0,0,0-1.122-1.122Z"
                            fill="#ede0d6"
                        />
                    </g>
                </g>
                <g id="Groupe_29684" data-name="Groupe 29684">
                    <path
                        id="Tracé_21292"
                        data-name="Tracé 21292"
                        d="M650.53,392.361H636.5l3.288-3.288,7.4-7.4h0l2.48-2.48.782-.782,5.933-5.933a4.01,4.01,0,0,0-5.67-5.67l-14.861,14.865h0l-.1.105-2.249,2.249h0l-2.671,2.67h0l-3.562,3.56-2.123,2.11H599.286a4.013,4.013,0,0,0-4.01,4.01v3.475a4.016,4.016,0,0,0,2.859,3.841l4.783,27.085a4.008,4.008,0,0,0,3.946,3.292h45.261a4.011,4.011,0,0,0,3.947-3.3l4.782-27.078a4.016,4.016,0,0,0,2.859-3.841v-3.474a4.014,4.014,0,0,0-4.011-4.01h-3.858m-11.323-8.02,10.369,8.02h-2.677m-2.539-13.173-2.48,2.48m-18.645,11.084,24.06-24.059a1.336,1.336,0,0,1,1.89,1.89l-24.06,24.059a1.336,1.336,0,1,1-1.89-1.89Zm32.49,7.094a1.338,1.338,0,0,1-1.337,1.337H634.841a1.337,1.337,0,0,0-.073,2.673c.024,0,.049,0,.073,0h23.268l-4.669,26.437a1.335,1.335,0,0,1-1.316,1.1H606.863a1.335,1.335,0,0,1-1.314-1.091l-4.673-26.446h23.271a1.337,1.337,0,0,0,0-2.673H599.286a1.338,1.338,0,0,1-1.337-1.337v-3.475a1.339,1.339,0,0,1,1.337-1.337h26.426a4.013,4.013,0,0,0,6.617,1.5l1.5-1.5H659.7a1.339,1.339,0,0,1,1.337,1.337Z"
                        fill="#040405"
                    />
                    <path
                        id="Tracé_21293"
                        data-name="Tracé 21293"
                        d="M629.5,401.183h0a1.337,1.337,0,1,0,1.337,1.339A1.338,1.338,0,0,0,629.494,401.183Z"
                        fill="#040405"
                    />
                </g>
            </g>
        </svg>
    );
}
