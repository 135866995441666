import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    kioskStore,
    setChosenTransition,
    setIsSubStepActionButtonsActive,
    setIsSubStepIconActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepLogoActive,
    setIsSubStepTopBannerActive,
    setSubStepActionButtons,
    setSubStepIconId,
    setSubStepInformationMessageItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function InformationModesConsommationStep(): JSX.Element {
    const {
        selectedInformationMode,
        isPrm,
        project: {
            template: {
                pages: { subSteps },
            },
        },
    } = useSnapshot(kioskStore);

    const { transitions } = useSnapshot(store);

    React.useEffect(() => {
        setIsSubStepIconActive(
            (
                (subSteps[selectedInformationMode] as PageType).header
                    .icon as IconType
            ).active
        );
        setIsSubStepInformationMessageActive(
            (subSteps[selectedInformationMode] as PageType).header
                .informationMessage.active
        );
        setSubStepInformationMessageItems(
            (subSteps[selectedInformationMode] as PageType).header
                .informationMessage.languages
        );
        setIsSubStepTopBannerActive(
            (subSteps[selectedInformationMode] as PageType).topBanner.active
        );
        setIsSubStepLogoActive(
            (subSteps[selectedInformationMode] as PageType).topBanner.logo
                .active
        );
        setIsSubStepActionButtonsActive(
            (subSteps[selectedInformationMode] as PageType).actionsButtons
                .active
        );
        setSubStepActionButtons(
            (subSteps[selectedInformationMode] as PageType).actionsButtons.items
        );

        setSubStepIconId(
            (
                (subSteps[selectedInformationMode] as PageType).header
                    .icon as IconType
            ).id
        );
        setChosenTransition(
            transitions[
                (subSteps[selectedInformationMode] as PageType).animation
            ]
        );
    }, []);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
