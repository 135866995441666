import {
    ASK_FOR_HELP_BUTTON,
    FULL__BASKET_ICON_ROLE,
    PRM_BUTTON,
    USUAL_MODE,
} from "@constants";
import { Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById, getSkippedStepNumber } from "@helpers/general";

import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { LogoWrapper } from "@components/OrderTaking/common/LogoWrapper";
import { OrderTakingBackgroundWrapper } from "@components/OrderTaking/common/OrderTakingBackgroundWrapper";
import { BasketChangeConfirmationDialog } from "@components/OrderTaking/Components/Dialogs/CartAlert/BasketChangeConfirmationDialog";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";
import { getOrdertakingOptions } from "@components/OrderTaking/Helpers";
import { memoizedOrderItems } from "@components/OrderTaking/Store/feature";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { InformationModesConsommationModalWrapper } from "@pages/Kiosk/InformationModesConsommationModalWrapper";
import { InformationModesModalWrapper } from "@pages/Kiosk/InformationModesModalWrapper";
import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import {
    kioskStore,
    setAuthModalStepIndex,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsChangeMachineRenderModalOpened,
    setIsHelpModalOpened,
    setIsPrm,
    setIsRegulationModesModalBackButtonClicked,
    setIsRegulationModesModalOpened,
    setIsUserAccountModalOpened,
    setLeftToPay,
    setNavigationIndex,
} from "@pages/Kiosk/store";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

import "./index.css";
import { SalesMethodsItems } from "./SalesMethodsItems";
import { SalesModesChangeConfirmationDialog } from "./SalesModesChangeConfirmationDialog";
import SummaryHeader from "./SummaryHeader";
import { SummaryOption } from "./SummaryOption";

export default function OrderSummary({
    open,
    onClose,
    setIsSummaryOrderOpened,
    openDialogCart,
    setOpenDialogCart,
}) {
    const orderItems = useSelector((state) => memoizedOrderItems(state));
    const { totalPrice, itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const {
        kioskWays,
        selectedSaleModeId,
        selectedSaleModeValue,
        isHelpModalOpened,
        project,
        isPrm,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        project: {
            template: { content },
        },
        project: { files: images },
        customerLanguage,
        isRegulationModesModalOpened,
        isChangeMachineRenderModalOpened,
        activeConfigOptions,
        isInformationModesModalOpened,
        isInformationModesConsommationModalOpened,
        isConfirmationModalOpened,
        isUserAccountModalOpened,
        isLogoutProblemModalOpened,
        isRegulationModesModalBackButtonClicked,
        isAnotherPaymentModeButtonDisabled,
    } = useSnapshot(kioskStore);

    const {
        isSummaryLogoActive,
        IsAccumulatedLoyaltyPointsActive,
        summaryHeaderMessage,
        isSalesMethodsChangeActive,
        summaryActionsButtons,
        isSummaryOptionsItemsActive,
        summaryOptionsItems,
        isSummaryBackgroundActive,
    } = useSelector((state) => state.settingSlice.summary, shallowEqual);
    const { designItems } = useSelector((state) => state.settingSlice);
    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });

    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(
                  filledBasketIcon[0].id,
                  images,
                  customerLanguage.name
              )
            : "";

    const loyaltyCardItem =
        project.template.content.authenticationModes.items.filter((item) => {
            return item.name === "Loyalty card" && item.active === true;
        })[0];

    const [containerRef, setContainerRef] = React.useState();
    const [isCartChanged, setIsCartChanged] = React.useState(false);
    const [salesMethod, setSalesMethod] = React.useState();
    const [
        isConfirmedSalesMethodAlertOpened,
        setIsConfirmedSalesMethodAlertOpened,
    ] = React.useState(false);

    const salesMethodItem = kioskWays.filter((item) => {
        return item.name === "salesMethods";
    })[0];

    const salesMethodItems =
        project.template.content[salesMethodItem?.name].items;
    const activeItems = salesMethodItems.filter((item) => item.active === true);
    const newActiveOptions = isPrm
        ? activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== PRM_BUTTON
          )
        : activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== USUAL_MODE
          );

    const newOptionConfig = getOrdertakingOptions(
        newActiveOptions,
        summaryOptionsItems
    );

    const backgroundImage = images.find((image) => {
        return (
            image.key === "background" && image.name === "orderTakingSummary"
        );
    });
    const imageContent = getImageContentById(backgroundImage.id, images);

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;

    function handleCartChangedCLickEvent() {
        setIsCartChanged(!isCartChanged);
        setLeftToPay(totalPrice);
        setNavigationIndex(
            navigationIndex +
                Number(
                    getSkippedStepNumber(
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        isUserAuthenticated,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        content
                    )
                )
        );
    }

    function handleOnCLickEvent(selectedSalesMode) {
        if (selectedSaleModeId !== selectedSalesMode.id)
            setIsConfirmedSalesMethodAlertOpened(
                !isConfirmedSalesMethodAlertOpened
            );
    }

    if (isRegulationModesModalOpened) {
        setIsRegulationModesModalOpened(false);
    }
    if (isChangeMachineRenderModalOpened) {
        setIsChangeMachineRenderModalOpened(false);
    }
    if (isRegulationModesModalBackButtonClicked) {
        setIsRegulationModesModalBackButtonClicked(false);
    }
    if (isAnotherPaymentModeButtonDisabled === false) {
        setIsAnotherPaymentModeButtonDisabled(true);
    }

    if (selectedRegulationModeValue === "creditCard") {
        setIsBackButtonDisabled(true);
    }

    function handleConfigOptionOnClickEvent(option) {
        if (
            option.title.toLowerCase() === PRM_BUTTON ||
            option.title.toLowerCase() === USUAL_MODE
        ) {
            setIsPrm(!isPrm);
        } else if (option.title.toLowerCase() === ASK_FOR_HELP_BUTTON) {
            setIsHelpModalOpened(true);
        } else if (
            option.title.toLowerCase() === "customer account" &&
            !isUserAuthenticated
        ) {
            setIsUserAccountModalOpened(true);
            setAuthModalStepIndex(navigationIndex);
        }
    }

    React.useEffect(() => {
        if (open === true && orderItems.length === 0) {
            setIsSummaryOrderOpened(false);
        }
    }, [orderItems]);

    return (
        <React.Fragment>
            {isCartChanged && (
                <BasketChangeConfirmationDialog
                    open={isCartChanged}
                    onClose={handleCartChangedCLickEvent}
                    isPrm={isPrm}
                />
            )}
            {isConfirmedSalesMethodAlertOpened && (
                <SalesModesChangeConfirmationDialog
                    open={isConfirmedSalesMethodAlertOpened}
                    onClose={() => {
                        setIsConfirmedSalesMethodAlertOpened(
                            !isConfirmedSalesMethodAlertOpened
                        );
                    }}
                    salesMode={salesMethod}
                    isPrm={isPrm}
                    filledBasketIconContent={filledBasketIconContent}
                />
            )}
            {isHelpModalOpened === true ? <HelpModalWrapper /> : null}
            {isInformationModesModalOpened === true ? (
                <InformationModesModalWrapper />
            ) : null}
            {isInformationModesConsommationModalOpened === true ? (
                <InformationModesConsommationModalWrapper />
            ) : null}
            {containerRef ? (
                isConfirmationModalOpened === true ? (
                    <ConfirmationModalWrapper containerRef={containerRef} />
                ) : null
            ) : null}
            {containerRef ? (
                isUserAccountModalOpened === true ? (
                    <UserAccountModalWrapper containerRef={containerRef} />
                ) : null
            ) : null}
            {isLogoutProblemModalOpened === true ? (
                <LogoutProblemModal />
            ) : null}
            <Dialog
                fullScreen={true}
                onClose={onClose}
                open={open}
                container={() => document.querySelector(".fullscreen")}
                PaperProps={{
                    sx: {
                        display: "flex!important",
                        alignItems: "center!important",
                        flexDirection: "column!important",
                        paddingTop: "0px!important",
                        paddingBottom: "0px!important",
                        color: "black!important",
                        maxWidth: "unset!important",
                        maxHeight: "unset",
                        backgroundColor: "#FFFFFF!important",
                        borderLeft: "1px solid #707070!important",
                        borderRight: "1px solid #707070!important",
                        borderBottom: "1px solid #707070!important",
                        top: "unset!important",
                        right: "unset!important",
                        left: "unset!important",
                        margin: "unset",
                    },
                }}
                style={{ backdropFilter: "blur(5px)" }}
                ref={(ref) => setContainerRef(ref)}
            >
                <OrderTakingBackgroundWrapper
                    filename={imageSrc}
                    isBackgroundActive={isSummaryBackgroundActive}
                >
                    <div
                        className="d-flex flex-column  align-items-center"
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: 2,
                        }}
                    >
                        {isPrm ? <PrmBackgroundWrapper /> : null}
                        <div className="headerSummary my-3">
                            {isSummaryLogoActive ? (
                                <div
                                    className="centered-items"
                                    style={{ height: "250px" }}
                                >
                                    <LogoWrapper width={140} height={140} />
                                </div>
                            ) : null}

                            <div
                                className="d-flex flex-column justify-content-center"
                                style={{ height: "100%" }}
                            >
                                {loyaltyCardItem !== undefined ? (
                                    <>
                                        <hr className="separation-line" />
                                        {IsAccumulatedLoyaltyPointsActive ? (
                                            <SummaryHeader />
                                        ) : null}
                                        <hr className="separation-line" />
                                    </>
                                ) : null}
                                <div className="header-text">
                                    <span
                                        className="centered-items"
                                        style={{
                                            height: "100px",
                                            font: "normal normal 600 55px/60px Nunito Sans",
                                        }}
                                    >
                                        {summaryHeaderMessage}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {!isPrm ? <hr className="separation-line" /> : null}
                        <div
                            className="summary-cart-content py-2"
                            style={{
                                width: "100%",
                                height: isPrm ? "300px" : "100%",
                            }}
                        >
                            {orderItems.map((product) => (
                                <ComposedProductCartPopup
                                    key={product.iuudOrder}
                                    product={product}
                                />
                            ))}
                        </div>
                        {!isPrm ? <hr className="separation-line" /> : null}
                        <div className="footer-summary-cart">
                            <div className="first-block-footer-summary-cart">
                                <div
                                    className="d-flex justify-content-start align-items-center h-100"
                                    style={{ gap: "10px", width: "20%" }}
                                >
                                    {filledBasketIconContent !== "" ? (
                                        <div
                                            className="d-flex justify-content-start align-items-center h-100"
                                            style={{
                                                width: "40%",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "95%",
                                                    height: "95%",
                                                }}
                                                src={
                                                    filledBasketIconContent?.includes(
                                                        "http"
                                                    )
                                                        ? filledBasketIconContent
                                                        : `./images_order/${filledBasketIconContent}`
                                                }
                                                alt={filledBasketIconContent}
                                            />
                                        </div>
                                    ) : (
                                        <CartIconFilled />
                                    )}
                                    <span>{itemsCount} Article(s)</span>
                                </div>
                                <div className="config-option">
                                    {isSalesMethodsChangeActive ? (
                                        <>
                                            <div className="sales-method-items">
                                                {activeItems.map(
                                                    (item, index) => {
                                                        return (
                                                            <SalesMethodsItems
                                                                key={index}
                                                                salesMode={item}
                                                                handleOnCLickEvent={
                                                                    handleOnCLickEvent
                                                                }
                                                                setSalesMethod={
                                                                    setSalesMethod
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </>
                                    ) : null}

                                    <SummaryOption
                                        isSummaryOptionsItemsActive={
                                            isSummaryOptionsItemsActive
                                        }
                                        newOptionConfig={newOptionConfig}
                                        handleConfigOptionOnClickEvent={
                                            handleConfigOptionOnClickEvent
                                        }
                                    />
                                </div>
                            </div>
                            <div className="summary-actions">
                                {summaryActionsButtons
                                    .filter((item) => {
                                        return item.active === true;
                                    })
                                    .sort((a, b) => {
                                        return a.role > b.role ? -1 : 1;
                                    })
                                    .map((button, index) => {
                                        const imageContent =
                                            getImageContentById(
                                                button.id,
                                                images,
                                                customerLanguage.name
                                            );

                                        const imageSrc =
                                            imageContent?.includes("http") ||
                                            imageContent?.includes("https")
                                                ? imageContent
                                                : `./images_order/${imageContent}`;

                                        return button.name.toLowerCase() ===
                                            "pay" ? (
                                            <div
                                                className="total-order-footer"
                                                key={index}
                                                style={{
                                                    position: "relative",
                                                    backgroundSize: "100% 100%",
                                                    backgroundImage: `url(${imageSrc})`,
                                                    height: "80%",
                                                    width: "80%",
                                                }}
                                                onClick={() => {
                                                    handleCartChangedCLickEvent();
                                                }}
                                            >
                                                <div
                                                    className="total-order h-100 w-100 px-2"
                                                    style={{
                                                        color: globalColor,
                                                    }}
                                                >
                                                    {totalPrice.toFixed(2)} €
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    backgroundSize: "100% 100%",
                                                    backgroundImage: `url(${imageSrc})`,
                                                    height: "80%",
                                                    width: "40%",
                                                }}
                                                onClick={() => {
                                                    onClose();
                                                    {
                                                        openDialogCart &&
                                                            setOpenDialogCart(
                                                                !openDialogCart
                                                            );
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </OrderTakingBackgroundWrapper>
            </Dialog>
        </React.Fragment>
    );
}

OrderSummary.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    order: PropTypes.object,
    setIsSummaryOrderOpened: PropTypes.func,
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
};
