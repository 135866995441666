import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { PreviewTopBannerBackgroundWrapper } from "@components/common/PreviewTopBannerBackgroundWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function LogoWrapper(): JSX.Element {
    const {
        isTopBannerActive,
        isLogoActive,
        isPrm,
        customerLanguage,
        project: { template, files: images },
    } = useSnapshot(kioskStore);

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        images,
        customerLanguage.name
    );
    const logoSrc =
        logoImage?.includes("http") || logoImage?.includes("https")
            ? logoImage
            : `./images/${logoImage}`;

    return (
        <React.Fragment>
            {isTopBannerActive ? (
                <div className="d-flex justify-content-center align-items-center">
                    <PreviewTopBannerBackgroundWrapper>
                        {isLogoActive ? (
                            <img
                                src={logoSrc}
                                alt="logo"
                                className={classNames({
                                    "usual-mode-logo__clz": !isPrm,
                                    "prm-mode-logo__clz": isPrm,
                                })}
                            />
                        ) : null}
                    </PreviewTopBannerBackgroundWrapper>
                </div>
            ) : null}
        </React.Fragment>
    );
}
