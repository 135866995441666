import {
    ADD_TO_ORDER_BUTTON_ROLE,
    ALLERGENS,
    BACK_BUTTON_ROLE,
    BASIC_COMPOSITION,
    COMPOSITIONS,
} from "@constants";
import { Box, Card, Dialog, Slide } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { AlertIcon } from "@components/OrderTaking/Components/Icons/AlertIcon";
import { CalorieIcon } from "@components/OrderTaking/Components/Icons/CalorieIcon";
import { EuroIcon } from "@components/OrderTaking/Components/Icons/EuroIcon";
import { AllergenPrmLogoWrapper } from "@components/OrderTaking/Components/Prm/Dialogs/Allergens/AllergenPrmLogoWrapper";
import { memoizedGlobalAllergens } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";
import { ProductComposition } from "./ProductComposition";
import { useStyles } from "./style";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function NutritionalInformationDialog({
    open,
    onClose,
    product,
    handleProductOnClickEvent,
    isAllergenIconActive,
    handleAllergenConfirmationDialogOnCLickEvent,
}) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const {
        isPrm,
        project: {
            files: images,
            template: { content },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);

    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const filteredButtons = actionsButtons.filter((item) => {
        return item.role === ADD_TO_ORDER_BUTTON_ROLE;
    })[0];

    const imageContent = getImageContentById(
        filteredButtons.id,
        images,
        customerLanguage.name
    );

    const validateImageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;
    const styles = useStyles();
    const backButton = content.actionsButtons.items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backOmageContent = getImageContentById(
        backButton.id,
        images,
        customerLanguage.name
    );

    const backImageSrc =
        backOmageContent?.includes("http") ||
        backOmageContent?.includes("https")
            ? backOmageContent
            : `./images_order/${backOmageContent}`;

    const [activeTab, setActiveTab] = React.useState(
        product.basic_composition === undefined ||
            product?.basic_composition?.length !== 0
            ? COMPOSITIONS
            : ALLERGENS
    );
    const globalAllergens = useSelector((state) =>
        memoizedGlobalAllergens(state)
    );
    let productAllergens = [];
    let items = [];

    if ((product?.allergens !== undefined && product?.allergens.length) !== 0) {
        for (const key in globalAllergens) {
            const newData = globalAllergens[key]?.filter((allergen) =>
                product?.allergens?.includes(allergen.id)
            );
            productAllergens = [...productAllergens, ...newData];
        }
    }
    let num = isPrm ? 5 : 11;
    if (productAllergens.length !== 0) {
        productAllergens.forEach((_, i) => {
            if (i % num === 0) {
                items.push(
                    <div
                        className="d-flex flex-column"
                        style={{
                            gap: "10px",
                            height: isPrm ? "300px" : "100%",
                        }}
                        key={i}
                    >
                        {productAllergens
                            .slice(i, i + num)
                            .map((allergen, index) => {
                                return (
                                    <Card
                                        key={index}
                                        className="allergen-card__clz"
                                    >
                                        <img
                                            src={allergen.icon}
                                            style={{
                                                height: "42px",
                                                width: "42px",
                                                backgroundColor: "#938585",
                                                borderRadius: "100%",
                                            }}
                                        />
                                        <span
                                            className="two-lines-preview-text"
                                            style={{
                                                width: "100%",
                                                lineHeight: "1.2em",
                                            }}
                                        >
                                            {allergen.name}
                                        </span>
                                    </Card>
                                );
                            })}
                    </div>
                );
            }
            i = i + num;
        });
    }

    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "1010px!important",
                    height: "1823px!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    borderRadius: "111px 111px 0px 0px!important",
                    borderTop: `15px solid ${globalColor}!important`,
                    maxWidth: "unset!important",
                    padding: "25px!important",
                    gap: "50px!important",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "unset",
                    top: "44px!important",
                    alignItems: "center!important",
                },
            }}
            TransitionComponent={Transition}
            style={{ backdropFilter: "blur(5px)" }}
        >
            {isPrm ? <AllergenPrmLogoWrapper /> : null}
            <Box className={styles.title}> Informations Nutritionnelles</Box>

            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "307px", gap: "50px" }}
            >
                <div className="card-media">
                    <img
                        className="w-100"
                        alt={product.name?.content}
                        src={product.image}
                    />
                </div>
                <Box className={styles.customPaper}>
                    <Box className={styles.element}>
                        <EuroIcon />
                        <span>{product.price?.content.toFixed(2)} €</span>
                    </Box>
                    <Box className={styles.element}>
                        <CalorieIcon />
                        <span>{product.calorie?.content} Kcal</span>
                    </Box>
                </Box>
            </div>
            <div className="d-flex flex-column" style={{ gap: "30px" }}>
                <Box
                    className={styles.subTitle}
                    style={{ height: "100%", width: "100%" }}
                >
                    {product.name?.content}
                </Box>
                <Box className={styles.customCard} style={{ height: "100%" }}>
                    <Box
                        className="pl-3"
                        style={{
                            font: "normal normal normal 25px/30px Segoe UI",
                            padding: "5px",
                        }}
                    >
                        {
                            "L'utilisation de cette option peut vous alerter sur la présence de(s) allergène(s) dans le produit sélectionné."
                        }
                    </Box>
                </Box>
            </div>

            <div
                className="d-flex"
                style={{
                    flexDirection: isPrm ? "column-reverse" : "column",
                    gap: "40px",
                }}
            >
                <Box
                    className={styles.mainCard}
                    style={{ height: isPrm ? "387px" : "auto" }}
                >
                    <Box className="d-flex justify-content-center align-items-center">
                        {product.type !== BASIC_COMPOSITION ? (
                            product?.basic_composition?.length > 0 ? (
                                <Box
                                    className={
                                        activeTab === COMPOSITIONS
                                            ? styles.activeCardTab
                                            : styles.cardTab
                                    }
                                    onClick={() => {
                                        setActiveTab(COMPOSITIONS);
                                    }}
                                >
                                    Composition de base
                                </Box>
                            ) : null
                        ) : null}

                        <Box
                            className={
                                activeTab === ALLERGENS
                                    ? styles.activeCardTab
                                    : styles.cardTab
                            }
                            style={{
                                width:
                                    product?.basic_composition?.length === 0 &&
                                    "100%",
                            }}
                            onClick={() => {
                                setActiveTab(ALLERGENS);
                            }}
                        >
                            Allergènes
                        </Box>
                    </Box>
                    <Box className="d-flex justify-content-center align-items-center py-3">
                        <div
                            className="d-flex"
                            style={{
                                width: "875px",
                                height: isPrm ? "" : "678px",
                                gap: "45px",
                                overflowX: "auto",
                                scrollbarWidth: "none",
                            }}
                        >
                            {activeTab === COMPOSITIONS ? (
                                product?.basic_composition?.length !== 0 ? (
                                    <ProductComposition
                                        basicCompositionsIds={
                                            product?.basic_composition
                                        }
                                    />
                                ) : null
                            ) : items.length !== 0 ? (
                                items
                            ) : (
                                <div
                                    className="d-flex flex-column  justify-content-center align-items-center w-100"
                                    style={{
                                        font: "normal normal normal 25px/30px Segoe UI",
                                        color: "#000",
                                        gap: "10px",
                                        height: isPrm ? "300px" : "100%",
                                    }}
                                >
                                    {`Aucune allergène mentionnée sur ce produit,
                                    pour plus d'information veuillez
                                    contacter le vendeur. Merci`}
                                </div>
                            )}
                        </div>
                    </Box>
                </Box>
                <Box
                    className="d-flex align-items-center px-2"
                    style={{ gap: "20px", width: "924px" }}
                >
                    <AlertIcon />
                    <Box className={styles.text}>
                        {
                            "Nos produits peuvent contenir un ou plusieurs allergènes."
                        }
                        <br />
                        {
                            "Pour plus d'information veuillez contacter un responsable. Merci."
                        }
                    </Box>
                </Box>
            </div>

            <Box
                className="d-flex justify-content-end align-items-end"
                sx={{ gap: "15px" }}
            >
                {backImageSrc !== "" ? (
                    <div
                        className="mx-2"
                        style={{
                            backgroundSize: " 100% 100%",
                            backgroundImage: `url(${backImageSrc})`,
                            height: "87px",
                            width: "350px",
                        }}
                        onClick={onClose}
                    />
                ) : (
                    <img
                        alt="backButton"
                        src="./images_order/back.png"
                        style={{ height: "87px", width: "350px" }}
                        onClick={onClose}
                    />
                )}

                {validateImageSrc !== "" ? (
                    <div
                        className="mx-2"
                        style={{
                            backgroundSize: " 100% 100%",
                            backgroundImage: `url(${validateImageSrc})`,
                            height: "87px",
                            width: "350px",
                        }}
                        onClick={() => {
                            isAllergenIconActive === true
                                ? handleAllergenConfirmationDialogOnCLickEvent()
                                : handleProductOnClickEvent();
                            onClose();
                        }}
                    />
                ) : (
                    <img
                        component="img"
                        alt="addToBasketButton"
                        src="./images_order/addToBasket.png"
                        style={{ height: "87px", width: "461px" }}
                        onClick={() => {
                            isAllergenIconActive === true
                                ? handleAllergenConfirmationDialogOnCLickEvent()
                                : handleProductOnClickEvent();
                            onClose();
                        }}
                    />
                )}
            </Box>
        </Dialog>
    );
}

NutritionalInformationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    handleProductOnClickEvent: PropTypes.func.isRequired,
    isAllergenIconActive: PropTypes.bool.isRequired,
    handleAllergenConfirmationDialogOnCLickEvent: PropTypes.func.isRequired,
};
