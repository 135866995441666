import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function SummaryOption({
    isSummaryOptionsItemsActive,
    newOptionConfig,
    handleConfigOptionOnClickEvent,
}) {
    const {
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    return isSummaryOptionsItemsActive ? (
        <>
            {newOptionConfig.length !== 0 ? (
                <hr className="vertical-separation-line" />
            ) : null}

            <div
                className="d-flex justify-content-end align-items-center pr-2"
                style={{ gap: "10px" }}
            >
                {newOptionConfig.map((option, index) => {
                    const imageContent = getImageContentById(
                        option.id,
                        images,
                        customerLanguage.name
                    );

                    const imageSrc = imageContent?.includes("http")
                        ? imageContent
                        : `./images_order/${imageContent}`;

                    return (
                        <img
                            className="sales-method-image"
                            key={index}
                            src={imageSrc}
                            alt={option.title}
                            onClick={() => {
                                handleConfigOptionOnClickEvent(option);
                            }}
                        />
                    );
                })}
            </div>
        </>
    ) : null;
}

SummaryOption.propTypes = {
    isSummaryOptionsItemsActive: PropTypes.bool,
    newOptionConfig: PropTypes.array,
    handleConfigOptionOnClickEvent: PropTypes.func,
};
