import { StyledButton, StyledModal } from "@aureskonnect/react-ui";
import swal from "@sweetalert/with-react";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { ModalBody } from "reactstrap";

import { store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import {
    setIsSyncConfirmationModalWrapperOpened,
    setIsSyncProgressModalWrapperOpened,
    setProgressBarValue,
    syncStore,
} from "@components/Synchronization/store";

import { setProject } from "@pages/Kiosk/store";

import "./index.css";

export function SyncConfirmationModalWrapper(): JSX.Element {
    const {
        isSyncConfirmationModalWrapperOpened,
        selectedEntity,
        selectedEntityChild,
        selectedShopApp,
        machineIpAddress,
    } = useSnapshot(syncStore);
    const { middlewareApiUrl } = useSnapshot(store);

    async function handleGettingCatalogRequest(
        franchiseId: string,
        shopId: string
    ) {
        try {
            const timestamp = new Date().getTime(); // current timestamp
            const response = await fetch(
                `${middlewareApiUrl}/kiosk/${franchiseId}/${shopId}/card?timestamp=${timestamp}`,
                { headers: { "Cache-Control": "no-cache" } }
            );
            const totalBytes = response.headers.get("content-length");

            if (totalBytes === null) {
                throw new Error("Content-Length header not found");
            }

            const total = parseInt(totalBytes, 10);
            let loaded = 0;

            const reader = (
                response.body as ReadableStream<Uint8Array>
            ).getReader();

            const decoder = new TextDecoder("utf-8");
            let jsonStr = "";

            // eslint-disable-next-line no-constant-condition
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                if (value) {
                    loaded += value.length;
                    const percentage = Math.round((loaded / total) * 100);
                    setProgressBarValue(percentage);
                    jsonStr += decoder.decode(value, { stream: true });
                }
            }

            jsonStr += decoder.decode();

            const data = JSON.parse(jsonStr);

            if (data.error === true) {
                throw Error("There's an issue while getting catalog");
            }

            localStorage.setItem("card", JSON.stringify(data));

            (window as RendererWindow).electronAPI.getCatalogEvent({
                data: JSON.stringify(data),
            });
            (window as RendererWindow).electronAPI.getProjectEvent({
                data: JSON.stringify(selectedShopApp.project, null, 4),
            });
            fetch(
                `${process.env.REACT_APP_SETTINGS_APP_API}/settings/application/ip`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        franchiseId: selectedEntityChild?.franchiseUuid,
                        shopId: selectedEntityChild?.uid,
                        appId: selectedShopApp.name,
                        ip: machineIpAddress,
                    }),
                    method: "POST",
                }
            );
        } catch (error) {
            setIsSyncProgressModalWrapperOpened(false);
            return swal({
                icon: "error",
                content: <p>{t("There's an error")}!</p>,
                buttons: false,
                timer: 5000,
            });
        }
    }

    async function handleSyncButtonOnClickEvent() {
        setProject(selectedShopApp.project as ProjectType);

        const franchiseId =
            selectedEntity?.schema !== ""
                ? selectedEntity?.schema
                : selectedEntityChild?.schema;
        const shopId = selectedEntityChild?.id;

        handleGettingCatalogRequest(franchiseId as string, shopId as string);

        localStorage.setItem(
            "shopDetail",
            JSON.stringify({
                schema:
                    selectedEntity?.schema !== ""
                        ? selectedEntity?.schema
                        : selectedEntityChild?.schema,
                id: selectedEntityChild?.id,
                franchise_uid: selectedEntityChild?.franchiseUuid,
                shop_uid: selectedEntityChild?.uid,
            })
        );
        setIsSyncConfirmationModalWrapperOpened(false);
        setIsSyncProgressModalWrapperOpened(true);
    }

    function handleChooseAnotherAppButtonClickEvent(): void {
        setIsSyncConfirmationModalWrapperOpened(false);
    }

    return (
        <StyledModal
            backdrop={false}
            centered={true}
            className="sync-confirmation-modal__clz"
            isOpen={isSyncConfirmationModalWrapperOpened}
        >
            <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                <img
                    src="./images/alert.png"
                    style={{
                        height: "124px",
                        width: "144px",
                        marginTop: "30px",
                    }}
                    alt="Alert"
                />
                <div
                    style={{
                        textAlign: "center",
                        letterSpacing: "2px",
                        font: "normal normal bold 50px/55px Bebas Neue",
                    }}
                >
                    {t(
                        "Do you want to synchronize this station With the kiosk application ?",
                        { name: selectedEntityChild?.name }
                    )}
                </div>
                <FlexboxGrid
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                    gap="10px"
                >
                    <StyledButton
                        rounded={true}
                        className="m-2 text-uppercase"
                        style={{
                            width: "291px",
                            height: "61px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "black",
                            font: "normal normal bold 16px/19px Segoe UI",
                        }}
                        onClick={handleChooseAnotherAppButtonClickEvent}
                    >
                        {t("Choose another application")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        className="m-2 text-uppercase"
                        variant="primary"
                        style={{
                            font: "normal normal bold 16px/19px Segoe UI",
                            width: "179px",
                            height: "61px",
                            background: "#242424 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #000000",
                            borderRadius: "12px",
                        }}
                        onClick={handleSyncButtonOnClickEvent}
                    >
                        {t("Synchronize")}
                    </StyledButton>
                </FlexboxGrid>
            </ModalBody>
        </StyledModal>
    );
}
