import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { store } from "@store";

import {
    getPreviousSkippedStepNumber,
    getSkippedStepNumber,
    handleChangeMachineStatus,
} from "@helpers/general";

import { AbortTransactionModalWrapper } from "@components/AbortTransactionModalWrapper";
import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ChangeMachineRenderModalWrapper } from "@components/common/ChangeMachineRenderModalWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PaymentContainerWrapper } from "@components/common/Payment/PaymentContainer";
import { PaymentMainContentWrapper } from "@components/common/Payment/PaymentMainContentWrapper";
import { PaymentTechnicalProblemModalWrapper } from "@components/common/PaymentTechnicalProblemModalWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { RefundAnomalyModalWrapper } from "@components/common/RefundAnomalyModalWrapper";
import { RegulationModesModalWrapper } from "@components/common/RegulationModesModalWrapper";
import { WithdrawalProblemModalWrapper } from "@components/common/WithdrawalProblemModalWrapper";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";
import { TpaValinaModalWrapper } from "@components/TpaValinaModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import {
    kioskStore,
    setActionsButtonsItems,
    setAmountDeposited,
    setChosenTransition,
    setInformationMessageItems,
    setIsAcceptAbortCBTransaction,
    setIsActionButtonsActive,
    setIsAnimationIn,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsChangeMachineRenderModalOpened,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsPaymentRefused,
    setIsPaymentTechnicalProblemDetected,
    setIsPaymentTechnicalProblemModalOpened,
    setIsRefundAnomalyModalOpened,
    setIsRegulationModesModalOpened,
    setIsStandbyModalOpened,
    setIsTopBannerActive,
    setIsTpaValinaActionButtonsActive,
    setIsTpaValinaIconActive,
    setIsTpaValinaInformationMessageActive,
    setIsTpaValinaModalOpened,
    setIsTransactionCanceled,
    setIsWithdrawalProblemModalOpened,
    setLeftToPay,
    setLeftToPayAfterCashMachineValidation,
    setMainContentItems,
    setNavigationIndex,
    setPaymentModes,
    setRegulationModesNumber,
    setRenderAmount,
    setTotalAmountDeposited,
    setTpaValinaActionButtons,
    setTpaValinaIconId,
    setTpaValinaInformationMessageItems,
    setVoucherAmount,
} from "@pages/Kiosk/store";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

export function PaymentStep(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isChangeMachineRenderModalOpened,
        isHelpModalOpened,
        isStandbyModalOpened,
        actionsButtonsItems,
        isAnimationIn,
        chosenTransition,
        isUserAccountModalOpened,
        isRefundAnomalyModalOpened,
        isRegulationModesModalOpened,
        selectedRegulationModeValue,
        isTpaValinaAvailable,
        regulationModesNumber,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        isBackWithoutCreditCardPaymentChoice,
        isAbortTransactionModalOpened,
        regulationModesModalKey,
        leftToPayAfterCashMachineValidation,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        paymentModes,
        cashUuID,
        creditCardUuId,
        amountDeposited,
        isTransactionCanceled,
        totalAmountDeposited,
        isWithdrawalProblemModalOpened,
        isPaymentRefused,
        isPaymentTechnicalProblemModalOpened,
        isChangeMachineReady,
        isCashMachineTransactionCanceled,
        isEventStreamProblem,
    } = useSnapshot(kioskStore);
    const { transitions, middlewareApiUrl } = useSnapshot(store);

    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );
    const [counter, setCounter] = React.useState<number>(0);

    const [
        isCashTransactionSuccessfullyValidated,
        setIsCashTransactionSuccessfullyValidated,
    ] = React.useState<boolean>(false);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        if (regulationModesNumber === 1) {
            return item.name !== "otherPaymentMethod" && item.active === true;
        } else {
            return item.active === true;
        }
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    const isTpaValinaActive = (
        (content[ways.payment?.name] as PaymentContentItemType).items.filter(
            (item) => item.shortName === "creditCard"
        )[0].languages[customerLanguage.name].paymentInstructions
            .TPAValina as TPAValinaType
    ).active;

    React.useEffect(() => {
        if (
            selectedRegulationModeValue === "changeMachine" &&
            amountDeposited === 0
        ) {
            let timer: NodeJS.Timeout;

            if (counter >= 0) {
                timer = setTimeout(() => setCounter(counter + 1), 1000);
            }

            if (
                counter ===
                ((ways.payment as PaymentPageType).regulationMode.changeMachine
                    .timeout as number)
            ) {
                console.log("changeMachine timeout standbyMOdalOpened");
                setIsStandbyModalOpened(true);
            }

            return () => clearTimeout(timer);
        }
    }, [counter, amountDeposited, selectedRegulationModeValue]);

    React.useEffect(() => {
        if (isPaymentRefused) {
            if (isTransactionCanceled) {
                console.log(
                    `The transaction is canceled that's mean the payment request is refused`
                );

                if (totalAmountDeposited === 0) {
                    if (isEventStreamProblem) {
                        setIsRegulationModesModalOpened(true);
                    } else {
                        setIsRegulationModesModalOpened(false);
                        setNavigationIndex(
                            navigationIndex -
                                Number(
                                    getPreviousSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue
                                    )
                                )
                        );
                    }

                    setIsPaymentRefused(false);
                    setIsTransactionCanceled(false);
                    setAmountDeposited(0);
                } else {
                    console.log(
                        `the change machine will refund the amount already deposited:${totalAmountDeposited}`
                    );
                    if (isChangeMachineReady) {
                        console.log(
                            `The variable isChangeMachineReady is: ${isChangeMachineReady}, That mean the change machine is ready to call the withdrawal api`
                        );
                        console.log(
                            `The change machine refund the amount:${totalAmountDeposited}`
                        );
                        fetch(
                            `${middlewareApiUrl}/RetraitMontant?unitaire=${-totalAmountDeposited}`
                        )
                            .then((response) => response.json())
                            .then((response) => {
                                const localRenderAmount = Number(
                                    response.response
                                        .split(`:`)[2]
                                        .split(`,`)[0]
                                        .substr(
                                            1,
                                            response.response
                                                .split(`:`)[2]
                                                .split(`,`)[0].length - 2
                                        )
                                );

                                if (localRenderAmount > 0) {
                                    console.log(
                                        `The change machine refund the amount deposited:${totalAmountDeposited} successfully`
                                    );
                                    if (isEventStreamProblem) {
                                        setIsRegulationModesModalOpened(true);
                                    } else {
                                        setIsRegulationModesModalOpened(false);
                                        setNavigationIndex(
                                            navigationIndex -
                                                Number(
                                                    getPreviousSkippedStepNumber(
                                                        kioskWays,
                                                        navigationIndex,
                                                        isAuthenticationAccepted,
                                                        isUserAuthenticated,
                                                        selectedSaleModeValue,
                                                        selectedInformationMode,
                                                        isOrderPaidWithLoyaltyAccountBalance,
                                                        selectedRegulationModeValue
                                                    )
                                                )
                                        );
                                    }

                                    setTotalAmountDeposited(0);
                                } else {
                                    console.log(
                                        `The change machine does not refund the amount deposited ,a create a voucher with the amount deposited:${totalAmountDeposited} will be created`
                                    );
                                    setIsWithdrawalProblemModalOpened(true);
                                    setIsRegulationModesModalOpened(false);
                                }

                                setIsChangeMachineRenderModalOpened(false);
                                setAmountDeposited(0);
                                setLeftToPayAfterCashMachineValidation(0);
                                setIsPaymentRefused(false);
                                setIsTransactionCanceled(false);
                            })
                            .catch((error) => {
                                console.log(
                                    `Error when withdrawing the deposited amount, with the message: ${error.message}`
                                );
                            });
                    }
                }
            } else {
                console.log(
                    "another payment modes modal opened after payment refused!!!!"
                );
                setIsRegulationModesModalOpened(
                    isCashMachineTransactionCanceled ? false : true
                );
            }
        }
    }, [
        isPaymentRefused,
        isTransactionCanceled,
        isCashMachineTransactionCanceled,
        isEventStreamProblem,
        isChangeMachineReady,
    ]);

    React.useEffect(() => {
        if (selectedRegulationModeValue === "changeMachine") {
            handleChangeMachineStatus();
        }
    }, [selectedRegulationModeValue]);

    React.useEffect(() => {
        if (isChangeMachineReady && isCashTransactionSuccessfullyValidated) {
            console.log(
                "another payment modes modal opened after cash machine validation!!!!"
            );
            setIsRegulationModesModalOpened(true);
        }
    }, [isChangeMachineReady, isCashTransactionSuccessfullyValidated]);

    async function handleCommandPayment() {
        let url = "";
        const localTotalPrice = isOrderPartialPaidWithLoyaltyAccountBalance
            ? totalOrderAfterPaidWithLoyaltyAccountBalance
            : totalPrice;

        const unitPrice =
            leftToPayAfterCashMachineValidation !== 0
                ? leftToPayAfterCashMachineValidation
                : localTotalPrice;

        if (selectedRegulationModeValue === "changeMachine") {
            console.log(`PaymentWithCashMachine unit price: ${unitPrice}`);
            url = `${middlewareApiUrl}/PaymentWithCashMachine?unitaire=${unitPrice}`;
        } else {
            console.log(`Payment with CB unit price: ${unitPrice}`);
            url = `${middlewareApiUrl}/ReglementCommande?unitaire=${unitPrice}`;
        }
        let localTotalAmountAlreadyDeposited = 0;
        const ctrl = new AbortController();
        await fetchEventSource(url, {
            signal: ctrl.signal,
            onmessage(event) {
                console.log({ event: event.event, data: event.data });
                switch (event.event) {
                    case "result": {
                        const code = JSON.parse(event.data).code;

                        setIsBackButtonDisabled(false);
                        switch (code) {
                            //code returned after payment validation with the change machine
                            case 1001: {
                                console.log(
                                    `The cash machine transaction is validated, i receive the code 1001`
                                );
                                setLeftToPayAfterCashMachineValidation(
                                    Number(JSON.parse(event.data).response[1])
                                );

                                const localDepositedAmount =
                                    (isOrderPartialPaidWithLoyaltyAccountBalance
                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                        : totalPrice) -
                                    Number(JSON.parse(event.data).response[1]);

                                const selectedPaymentMode = paymentModes.find(
                                    (mode) =>
                                        mode.paymentMode ===
                                        selectedRegulationModeValue
                                );
                                if (localDepositedAmount !== 0) {
                                    if (selectedPaymentMode !== undefined) {
                                        const selectedPaymentModeIndex =
                                            paymentModes.indexOf(
                                                selectedPaymentMode
                                            );
                                        const localPaymentModes =
                                            paymentModes.map((paymentMode) => {
                                                return paymentMode;
                                            });
                                        localPaymentModes[
                                            selectedPaymentModeIndex
                                        ] = {
                                            paymentModeUuiD: cashUuID,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount: parseFloat(
                                                localDepositedAmount.toFixed(2)
                                            ),
                                            data: {
                                                renderAmount: 0,
                                            },
                                            totalAmountDeposited: parseFloat(
                                                localDepositedAmount.toFixed(2)
                                            ),
                                        };
                                        setPaymentModes(localPaymentModes);
                                    } else {
                                        setPaymentModes([
                                            ...paymentModes,
                                            {
                                                paymentModeUuiD: cashUuID,
                                                paymentMode:
                                                    selectedRegulationModeValue,
                                                paymentAmount: parseFloat(
                                                    localDepositedAmount.toFixed(
                                                        2
                                                    )
                                                ),
                                                data: {
                                                    renderAmount: 0,
                                                },
                                                totalAmountDeposited:
                                                    parseFloat(
                                                        localDepositedAmount.toFixed(
                                                            2
                                                        )
                                                    ),
                                            },
                                        ]);
                                    }
                                }

                                ctrl.abort();
                                break;
                            }
                            //code returned after payment with change machine without a render amount or payment with credit card
                            case 1002: {
                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content
                                            )
                                        )
                                );

                                const selectedPaymentMode = paymentModes.find(
                                    (mode) =>
                                        mode.paymentMode ===
                                        selectedRegulationModeValue
                                );
                                if (selectedPaymentMode !== undefined) {
                                    const selectedPaymentModeIndex =
                                        paymentModes.indexOf(
                                            selectedPaymentMode
                                        );

                                    const localPaymentModes = paymentModes.map(
                                        (paymentMode) => {
                                            return paymentMode;
                                        }
                                    );
                                    localPaymentModes[
                                        selectedPaymentModeIndex
                                    ] = {
                                        paymentModeUuiD: cashUuID,
                                        paymentMode:
                                            selectedRegulationModeValue,
                                        paymentAmount:
                                            selectedPaymentMode.paymentAmount +
                                            leftToPayAfterCashMachineValidation,
                                        data: {
                                            renderAmount: 0,
                                        },
                                        totalAmountDeposited:
                                            selectedPaymentMode.paymentAmount +
                                            leftToPayAfterCashMachineValidation,
                                    };

                                    setPaymentModes(localPaymentModes);
                                } else {
                                    setPaymentModes([
                                        ...paymentModes,
                                        {
                                            paymentModeUuiD:
                                                selectedRegulationModeValue ===
                                                "changeMachine"
                                                    ? cashUuID
                                                    : creditCardUuId,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount:
                                                leftToPayAfterCashMachineValidation !==
                                                0
                                                    ? leftToPayAfterCashMachineValidation
                                                    : isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : totalPrice,
                                            data: {
                                                renderAmount: 0,
                                            },
                                            totalAmountDeposited:
                                                leftToPayAfterCashMachineValidation !==
                                                0
                                                    ? leftToPayAfterCashMachineValidation
                                                    : isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : totalPrice,
                                        },
                                    ]);
                                }
                                ctrl.abort();
                                break;
                            }
                            //code returned after payment with the change machine with an amount returned
                            case 1003: {
                                console.log(
                                    `Payment with change machine (code 1003) with an amount returned:${Number(
                                        JSON.parse(event.data).response[3]
                                    )} and closing the change machine render modal`
                                );
                                setRenderAmount(
                                    Number(JSON.parse(event.data).response[3])
                                );
                                setIsChangeMachineRenderModalOpened(false);
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                                const selectedPaymentMode = paymentModes.find(
                                    (mode) =>
                                        mode.paymentMode ===
                                        selectedRegulationModeValue
                                );

                                if (selectedPaymentMode !== undefined) {
                                    const selectedPaymentModeIndex =
                                        paymentModes.indexOf(
                                            selectedPaymentMode
                                        );

                                    const localPaymentModes = paymentModes.map(
                                        (paymentMode) => {
                                            return paymentMode;
                                        }
                                    );
                                    localPaymentModes[
                                        selectedPaymentModeIndex
                                    ] = {
                                        paymentModeUuiD: cashUuID,
                                        paymentMode:
                                            selectedRegulationModeValue,
                                        paymentAmount:
                                            selectedPaymentMode.paymentAmount +
                                            leftToPayAfterCashMachineValidation,
                                        data: {
                                            renderAmount: Number(
                                                JSON.parse(event.data)
                                                    .response[3]
                                            ),
                                        },
                                        totalAmountDeposited:
                                            selectedPaymentMode.paymentAmount +
                                            leftToPayAfterCashMachineValidation,
                                    };

                                    setPaymentModes(localPaymentModes);
                                } else {
                                    setPaymentModes([
                                        ...paymentModes,
                                        {
                                            paymentModeUuiD: cashUuID,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount:
                                                isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : totalPrice,
                                            data: {
                                                renderAmount: Number(
                                                    JSON.parse(event.data)
                                                        .response[3]
                                                ),
                                            },
                                            totalAmountDeposited:
                                                isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : totalPrice,
                                        },
                                    ]);
                                }

                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content
                                            )
                                        )
                                );
                                ctrl.abort();
                                break;
                            }
                            //code returned after payment with the change machine in the case of a refund anomaly
                            case 999: {
                                const localVoucherAmount = Number(
                                    Number(JSON.parse(event.data).response) -
                                        (leftToPayAfterCashMachineValidation !==
                                        0
                                            ? leftToPayAfterCashMachineValidation
                                            : isOrderPartialPaidWithLoyaltyAccountBalance
                                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                            : totalPrice)
                                );
                                console.log(
                                    `Payment with the change machine in the case of a refund anomaly (code 999) with a voucher amount:${parseFloat(
                                        localVoucherAmount.toFixed(2)
                                    )} and opening the refund anomaly modal`
                                );
                                setVoucherAmount(
                                    parseFloat(localVoucherAmount.toFixed(2))
                                );
                                setAmountDeposited(
                                    Number(JSON.parse(event.data).response)
                                );
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                                setIsRefundAnomalyModalOpened(true);
                                ctrl.abort();
                                break;
                            }
                            //code returned after payment is refused
                            case 0: {
                                if (
                                    //ETK-webserver stopped
                                    JSON.parse(event.data).response ===
                                        "ERREUR PAIEMENT" ||
                                    //Network problem
                                    JSON.parse(event.data).response ===
                                        "Abort" ||
                                    JSON.parse(event.data).response ===
                                        "Erreur d'initialiser la connexion avec le lecteur Nepting"
                                ) {
                                    console.log(
                                        "A technical problem was detected"
                                    );
                                    setIsPaymentTechnicalProblemDetected(true);
                                    setIsPaymentTechnicalProblemModalOpened(
                                        true
                                    );
                                    setIsAnotherPaymentModeButtonDisabled(
                                        false
                                    );
                                    setIsBackButtonDisabled(false);
                                } else {
                                    console.log("The payment is refused");
                                    setIsPaymentRefused(true);
                                }
                                ctrl.abort();
                                break;
                            }
                        }
                        if (code === 1001) {
                            setIsCashTransactionSuccessfullyValidated(true);
                        } else {
                            setIsCashTransactionSuccessfullyValidated(false);
                        }
                        break;
                    }
                    case "Bloc": {
                        setIsAnotherPaymentModeButtonDisabled(true);
                        setIsBackButtonDisabled(true);
                        console.log(
                            "I receive the event bloc it means that the change machine is on counting and the buttons wil be disabled"
                        );
                        break;
                    }
                    case "Menu": {
                        setIsAnotherPaymentModeButtonDisabled(false);
                        setIsBackButtonDisabled(false);
                        break;
                    }
                    case "usermessage": {
                        if (selectedRegulationModeValue === "changeMachine") {
                            setIsAnotherPaymentModeButtonDisabled(false);
                            setIsBackButtonDisabled(false);

                            if (
                                JSON.parse(event.data).message.includes(
                                    "Montant inseré"
                                )
                            ) {
                                const localMessage = JSON.parse(
                                    event.data
                                ).message.split(" ");

                                setAmountDeposited(Number(localMessage[2]));

                                if (Number(localMessage[9]) <= 0) {
                                    setLeftToPay(0);
                                } else {
                                    setLeftToPay(Number(localMessage[9]));
                                }

                                if (
                                    Number(localMessage[2]) <
                                    (leftToPayAfterCashMachineValidation !== 0
                                        ? leftToPayAfterCashMachineValidation
                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                        : totalPrice)
                                ) {
                                    setIsBackButtonDisabled(false);
                                } else {
                                    setIsBackButtonDisabled(true);
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                }
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "connexion établie !  Montant inseré"
                                )
                            ) {
                                const localMessage = JSON.parse(
                                    event.data
                                ).message.split(" ");

                                setAmountDeposited(Number(localMessage[7]));
                                if (Number(localMessage[14]) <= 0) {
                                    setLeftToPay(0);
                                } else {
                                    setLeftToPay(Number(localMessage[14]));
                                }

                                if (
                                    Number(localMessage[7]) <
                                    (leftToPayAfterCashMachineValidation !== 0
                                        ? leftToPayAfterCashMachineValidation
                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                        : totalPrice)
                                ) {
                                    setIsBackButtonDisabled(false);
                                } else {
                                    setIsBackButtonDisabled(true);
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                }
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "Transaction en cours d'annulation"
                                )
                            ) {
                                localTotalAmountAlreadyDeposited =
                                    totalAmountDeposited;
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "Préparation du rendu"
                                )
                            ) {
                                const localMessage = JSON.parse(
                                    event.data
                                ).message.split(" ");
                                setIsBackButtonDisabled(true);
                                setRenderAmount(
                                    localTotalAmountAlreadyDeposited +
                                        Number(localMessage[4])
                                );
                                setIsChangeMachineRenderModalOpened(true);
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "Vérification et calcul"
                                ) ||
                                JSON.parse(event.data).message.includes(
                                    "Montant total inseré"
                                )
                            ) {
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                            } else {
                                if (amountDeposited === 0) {
                                    setIsBackButtonDisabled(false);
                                } else {
                                    setIsBackButtonDisabled(true);
                                }
                            }
                        } else {
                            if (
                                JSON.parse(event.data).message.includes(
                                    "INSEREZ CARTE"
                                )
                            ) {
                                setIsAnotherPaymentModeButtonDisabled(false);
                                setIsBackButtonDisabled(false);
                            } else if (
                                JSON.parse(event.data).message.includes(
                                    "PAIEMENT ACCEPTE"
                                )
                            ) {
                                setIsAcceptAbortCBTransaction(true);
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                            }
                        }
                    }
                }
            },
            onclose() {
                console.log("connection close");
                ctrl.abort();
            },
            onerror(error) {
                console.log(
                    `Error during the payment, with the message: ${error.message}`
                );
                setIsPaymentTechnicalProblemDetected(true);
                setIsPaymentTechnicalProblemModalOpened(true);
                setIsAnotherPaymentModeButtonDisabled(false);
                setIsBackButtonDisabled(false);
                ctrl.abort();
                throw error;
            },
        });
    }

    React.useEffect(() => {
        setNavigationIndex(
            kioskWays.indexOf(
                kioskWays.filter((page) => page?.name === "payment")[0]
            )
        );
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
        if (
            !["atTheCounter", "BDP"].includes(selectedRegulationModeValue) &&
            selectedRegulationModeValue !== undefined &&
            selectedRegulationModeValue !== ""
        ) {
            setIsLogoActive(
                (ways.payment as PaymentPageType).topBanner.logo.active
            );

            setIsTopBannerActive(
                (ways.payment as PaymentPageType).topBanner.active
            );
            setIsHeaderActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.active
            );

            setIsInformationMessageActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.informationMessage.active
            );

            setInformationMessageItems(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.informationMessage.languages
            );

            setIsActionButtonsActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (ways.payment as PaymentPageType).regulationMode[
                        selectedRegulationModeValue
                    ].actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setMainContentItems(
                (content[ways.payment?.name] as PaymentContentItemType).items
            );

            setIsTpaValinaIconActive(
                // eslint-disable-next-line
                (
                    (content.TPAValina as TPAValinaPageType)
                        .header as NewHeaderType
                ).icon!.active
            );
            setTpaValinaIconId(
                // eslint-disable-next-line
                (
                    (content.TPAValina as TPAValinaPageType)
                        .header as NewHeaderType
                ).icon!.id
            );
            setIsTpaValinaInformationMessageActive(
                (content.TPAValina as TPAValinaPageType).header
                    .informationMessage.active
            );
            setTpaValinaInformationMessageItems(
                (content.TPAValina as TPAValinaPageType).header
                    .informationMessage.languages
            );
            setIsTpaValinaActionButtonsActive(
                (content.TPAValina as TPAValinaPageType).actionsButtons.active
            );
            setTpaValinaActionButtons(
                (content.TPAValina as TPAValinaPageType).actionsButtons.items
            );
            setChosenTransition(
                transitions[(ways.payment as PaymentPageType).animation]
            );
        }
        setRegulationModesNumber(
            (content.meansOfPayment as ProjectContentItemType).items.filter(
                (item) => {
                    return item.active === true;
                }
            ).length
        );
    }, [regulationModesModalKey]);

    React.useEffect(() => {
        if (isRegulationModesModalOpened === false) {
            if (
                isTpaValinaAvailable &&
                selectedRegulationModeValue === "creditCard" &&
                isTpaValinaActive &&
                isBackWithoutCreditCardPaymentChoice
            ) {
                setIsTpaValinaModalOpened(true);
                setIsBackWithoutCreditCardPaymentChoice(false);
            } else {
                handleCommandPayment();
            }
        }
    }, [
        selectedRegulationModeValue,
        regulationModesModalKey,
        isBackWithoutCreditCardPaymentChoice,
    ]);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <PreviewBackgroundWrapper>
                <PaymentContainerWrapper
                    isTopBannerActive={isTopBannerActive}
                    isHeaderActive={isHeaderActive}
                    isActionButtonsActive={isActionButtonsActive}
                    ref={(ref) => setContainerRef(ref)}
                >
                    {isHelpModalOpened === true ? <HelpModalWrapper /> : null}

                    {(isChangeMachineRenderModalOpened === false ||
                        isRefundAnomalyModalOpened === false) &&
                    isStandbyModalOpened === true ? (
                        <StandbyModalWrapper />
                    ) : null}

                    {containerRef ? (
                        isUserAccountModalOpened === true ? (
                            <UserAccountModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {containerRef ? (
                        isAbortTransactionModalOpened === true ? (
                            <AbortTransactionModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {containerRef ? (
                        isChangeMachineRenderModalOpened === true ? (
                            <ChangeMachineRenderModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {containerRef ? (
                        isRefundAnomalyModalOpened === true ? (
                            <RefundAnomalyModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {containerRef ? (
                        isRegulationModesModalOpened === true ? (
                            <RegulationModesModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {containerRef ? (
                        isTpaValinaAvailable &&
                        selectedRegulationModeValue === "creditCard" &&
                        isTpaValinaActive ? (
                            <TpaValinaModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {isWithdrawalProblemModalOpened === true ? (
                        <WithdrawalProblemModalWrapper />
                    ) : null}

                    {isPaymentTechnicalProblemModalOpened === true ? (
                        <PaymentTechnicalProblemModalWrapper />
                    ) : null}

                    <LogoWrapper />

                    {isHeaderActive ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {isInformationMessageActive ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span style={{ fontSize: "55px" }}>
                                        {
                                            informationMessageItems[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    <PaymentMainContentWrapper />

                    {isActionButtonsActive ? (
                        <div style={{ marginBottom: "25px" }}>
                            <ActionsButtonsWrapper
                                actionsButtonsItems={mainActiveActionsButtons}
                            />
                        </div>
                    ) : null}
                </PaymentContainerWrapper>
            </PreviewBackgroundWrapper>
        </CSSTransition>
    );
}
