import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { MainContentWrapper } from "@components/common/PrmModeMainContentWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/PrmModeWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import {
    kioskStore,
    setIsAnimationIn,
    setIsLogoutProblemModalOpened,
    setIsStandbyModalOpened,
    setSelectedInformationMode,
} from "@pages/Kiosk/store";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

export function PrmMode(): JSX.Element {
    const {
        isHeaderActive,
        isTopBannerActive,
        isUserAccountActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        userAccountMessageItems,
        timeout,
        customerLanguage,
        isStandbyModalOpened,
        isHelpModalOpened,
        actionsButtonsItems,
        isUserAccountModalOpened,
        isUserAuthenticated,
        isAnimationIn,
        chosenTransition,
        navigationIndex,
        user,
        isLogoutProblemModalOpened,
    } = useSnapshot(kioskStore);

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
        setSelectedInformationMode("");
        setIsLogoutProblemModalOpened(false);
    }, []);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <GlobalPrmModeWrapper>
                <PrmBackgroundWrapper />
                <div>
                    <PreviewBackgroundWrapper>
                        <PrmModeWrapper
                            isTopBannerActive={isTopBannerActive}
                            isHeaderActive={isHeaderActive}
                            isLanguagesActive={isLanguagesActive}
                            isConfigOptionsActive={isConfigOptionsActive}
                            isActionButtonsActive={isActionButtonsActive}
                            ref={(ref) => setContainerRef(ref)}
                        >
                            <IdleDetection
                                timeout={timeout * 1000}
                                once={true}
                                onIdle={() => {
                                    if (navigationIndex !== 0) {
                                        setIsStandbyModalOpened(true);
                                    }
                                }}
                            />
                            {isStandbyModalOpened === true ? (
                                <StandbyModalWrapper />
                            ) : null}

                            {isHelpModalOpened === true ? (
                                <HelpModalWrapper />
                            ) : null}

                            {containerRef ? (
                                isUserAccountModalOpened === true ? (
                                    <UserAccountModalWrapper
                                        containerRef={containerRef}
                                    />
                                ) : null
                            ) : null}

                            {isLogoutProblemModalOpened === true ? (
                                <LogoutProblemModal />
                            ) : null}
                            <LogoWrapper />

                            {isHeaderActive ? (
                                <div
                                    style={{ display: "grid" }}
                                    className="justify-content-center align-items-center"
                                >
                                    {isUserAccountActive ? (
                                        isUserAuthenticated ? (
                                            <div
                                                className="d-flex justify-content-center align-items-center"
                                                style={{ gap: "20px" }}
                                            >
                                                <img
                                                    src="./images/man-head-header.png"
                                                    style={{
                                                        height: "35px",
                                                        width: "35px",
                                                    }}
                                                    alt="man head"
                                                />
                                                <span
                                                    style={{ fontSize: "35px" }}
                                                >
                                                    {
                                                        userAccountMessageItems[
                                                            customerLanguage
                                                                .name
                                                        ].content
                                                    }{" "}
                                                    {user.firstName === ""
                                                        ? user.lastName
                                                        : user.firstName}
                                                </span>
                                            </div>
                                        ) : null
                                    ) : null}

                                    {isInformationMessageActive ? (
                                        <div className="d-flex justify-content-center align-items-center p-2">
                                            <span
                                                style={{
                                                    fontSize: "35px",
                                                    textAlign: "center",
                                                    lineHeight: "45px",
                                                }}
                                            >
                                                {
                                                    informationMessageItems[
                                                        customerLanguage.name
                                                    ].content
                                                }
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}

                            <MainContentWrapper />

                            {isActionButtonsActive ? (
                                <PrmActionsButtonsWrapper
                                    actionsButtonsItems={
                                        mainActiveActionsButtons
                                    }
                                />
                            ) : null}

                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                            >
                                {isLanguagesActive ? (
                                    <PrmLanguagesWrapper />
                                ) : (
                                    <div />
                                )}
                                {isConfigOptionsActive ? (
                                    <ConfigOptionsWrapper />
                                ) : null}
                            </div>
                        </PrmModeWrapper>
                    </PreviewBackgroundWrapper>
                </div>
            </GlobalPrmModeWrapper>
        </CSSTransition>
    );
}
