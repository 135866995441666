import React from "react";
import { useSnapshot } from "valtio";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { ScrollContainer } from "@components/ScrollContainer";
import { ImageContent } from "@components/common/UsualModeMainContentWrapper/ImageContent";

import { LoyaltyConsommationCard } from "@pages/Kiosk/Steps/LoyaltyConsommationStep/LoyaltyConsommationCard";
import { kioskStore as store } from "@pages/Kiosk/store";

export function MainContentWrapper(): JSX.Element {
    const {
        mainContentItems,
        navigationIndex,
        kioskWays,
        selectedSaleModeValue,
        customerLanguage,
    } = useSnapshot(store);

    const activeItems =
        kioskWays[navigationIndex]?.name === "informationModes"
            ? (mainContentItems as ProjectMainContentItemType[]).filter(
                  (item) => {
                      return (
                          (item.active as ItemContentType)[
                              selectedSaleModeValue
                          ] === true
                      );
                  }
              )
            : (mainContentItems as ProjectMainContentItemType[]).filter(
                  (item) => {
                      return (
                          item.active === true &&
                          item.languages[customerLanguage.name].content !== ""
                      );
                  }
              );

    const activeItemsLength = activeItems.length;

    return (
        <CustomErrorBoundary>
            {activeItemsLength <= 2 ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center p-2"
                    style={{
                        gap: "200px",
                        width: "1080px",
                    }}
                >
                    {kioskWays[navigationIndex].name ===
                    "loyaltyConsommation" ? (
                        <div className="mb-5">
                            <LoyaltyConsommationCard />
                        </div>
                    ) : null}

                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            gap: "100px",
                            padding: "50px 0px 50px 0px",
                        }}
                    >
                        {activeItems.map((item: ProjectMainContentItemType) => (
                            <ImageContent
                                item={item}
                                key={item.id}
                                activeItemsLength={activeItemsLength}
                            />
                        ))}
                    </div>
                </div>
            ) : activeItemsLength > 2 && activeItemsLength <= 4 ? (
                <div
                    className="justify-content-center align-items-center"
                    style={{ display: "grid" }}
                >
                    <div
                        className="d-flex justify-content-center h-100"
                        style={{ gap: "100px", paddingTop: "50px" }}
                    >
                        {activeItems
                            .slice(0, 2)
                            .map((item: ProjectMainContentItemType) => (
                                <ImageContent
                                    item={item}
                                    activeItemsLength={activeItemsLength}
                                    key={item.id}
                                />
                            ))}
                    </div>
                    <div
                        className="d-flex justify-content-center h-100"
                        style={{ gap: "100px", paddingBottom: "50px" }}
                    >
                        {activeItems
                            .slice(2)
                            .map((item: ProjectMainContentItemType) => (
                                <ImageContent
                                    item={item}
                                    activeItemsLength={activeItemsLength}
                                    key={item.id}
                                />
                            ))}
                    </div>
                </div>
            ) : activeItemsLength > 4 && activeItemsLength <= 6 ? (
                <div
                    className="justify-content-center align-items-center"
                    style={{ display: "grid" }}
                >
                    <div
                        className="d-flex justify-content-center"
                        style={{ gap: "25px", paddingTop: "50px" }}
                    >
                        {activeItems
                            .slice(0, 3)
                            .map((item: ProjectMainContentItemType) => (
                                <ImageContent
                                    item={item}
                                    activeItemsLength={activeItemsLength}
                                    key={item.id}
                                />
                            ))}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{ gap: "25px", paddingBottom: "50px" }}
                    >
                        {activeItems
                            .slice(3)
                            .map((item: ProjectMainContentItemType) => (
                                <ImageContent
                                    item={item}
                                    activeItemsLength={activeItemsLength}
                                    key={item.id}
                                />
                            ))}
                    </div>
                </div>
            ) : (
                <ScrollContainer
                    style={{
                        maxHeight: "950px",
                        overflowY: "scroll",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3,1fr)",
                            gridTemplateRows: `repeat(${Math.ceil(
                                activeItems.length / 3
                            )},1fr)`,
                            rowGap: "35px",
                            columnGap: "25px",
                            padding: "50px 0px 50px 0px",
                        }}
                    >
                        {activeItems.map((item: ProjectMainContentItemType) => (
                            <ImageContent
                                item={item}
                                activeItemsLength={activeItemsLength}
                                key={item.id}
                            />
                        ))}
                    </div>
                </ScrollContainer>
            )}
        </CustomErrorBoundary>
    );
}
