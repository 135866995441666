import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorPage } from "../ErrorPage";

export function CustomErrorBoundary({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            {children}
        </ErrorBoundary>
    );
}
