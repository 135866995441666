import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getImageContentById,
    getSkippedStepNumber,
    handleChangeMachineStatus,
} from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { getDefaultCategory } from "@components/OrderTaking/Helpers";
import {
    memoizedCategories,
    setDefaultCategory,
} from "@components/OrderTaking/Store/feature";

import {
    setAmountDeposited,
    setFinalMessageSelectedInformationMode,
    setFinalMessageSelectedRegulationMode,
    setIsAnimationIn,
    setIsAnotherPaymentModeButtonDisabled,
    setIsAuthenticationAccepted,
    setIsBackButtonDisabled,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsCashTransactionValidated,
    setIsChangeMachineReady,
    setIsChangeMachineRenderModalOpened,
    setIsCreationVoucherCanceled,
    setIsOrderPaidWithLoyaltyAccountBalance,
    setIsOrderPartialPaidWithLoyaltyAccountBalance,
    setIsPaymentRefused,
    setIsRegulationModesModalOpened,
    setIsSelectedSaleModeWithoutInformationModes,
    setIsTransactionCanceled,
    setIsUserAccountModalOpened,
    setLeftToPay,
    setNavigationIndex,
    setPaymentModes,
    setRegulationModesModalKey,
    setSelectedAuthMode,
    setSelectedInformationMode,
    setSelectedRegulationModeValue,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    setTotalOrderAfterPaidWithLoyaltyAccountBalance,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

type ImageContentPropsType = {
    item: ProjectMainContentItemType;
    activeItemsLength: number;
};

export function ImageContent({ item }: ImageContentPropsType): JSX.Element {
    const {
        customerLanguage,
        kioskWays,
        navigationIndex,
        isRegulationModesModalOpened,
        selectedInformationMode,
        selectedRegulationModeValue,
        isAuthenticationAccepted,
        isUserAuthenticated,
        isOrderPaidWithLoyaltyAccountBalance,
        project: {
            template: { content },
            files: images,
        },
        selectedSaleModeValue,
        leftToPayAfterCashMachineValidation,
        user,
        paymentModes,
        loyaltyUuId,
        selectedAuthMode,
        isOrderInsertionRefused,
        isRegulationModesModalBackButtonClicked,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
    } = useSnapshot(store);
    const dispatch = useDispatch();

    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);

    const imageContent = getImageContentById(
        item.id,
        images,
        customerLanguage.name
    );
    const globalCategories = useSelector((state) => memoizedCategories(state));
    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleOnClickEvent() {
        switch (kioskWays[navigationIndex].name) {
            case "salesMethods": {
                const isLocalSelectedSaleModeWithoutInformationModes =
                    (
                        content.salesMethods as ProjectContentItemType
                    ).items.filter((option: ProjectMainContentItemType) => {
                        return option.name === item.name;
                    })[0]?.informationModes?.items.length === 0 ||
                    (
                        content.informationModes as ProjectContentItemType
                    ).items.filter((option: ProjectMainContentItemType) => {
                        return (
                            (option.active as ItemContentType)[item.name] ===
                            true
                        );
                    }).length === 0;

                setIsSelectedSaleModeWithoutInformationModes(
                    isLocalSelectedSaleModeWithoutInformationModes
                );

                if (item.name === "Delivery" && isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true);
                } else {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    item.name as string,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    ""
                                )
                            )
                    );
                }
                setSelectedSaleModeValue(item.name);
                setSelectedSaleModeOrderTaking(item.settingKey);
                setSelectedSaleModeId(item.id);
                setIsAnimationIn(false);
                const defaultCategorie = getDefaultCategory(
                    item.settingKey,
                    globalCategories
                );
                dispatch(setDefaultCategory(defaultCategorie));
                break;
            }
            case "authenticationModes": {
                setNavigationIndex(
                    navigationIndex +
                        Number(
                            getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue,
                                content,

                                item.shortName as string
                            )
                        )
                );
                setSelectedAuthMode(item.shortName as string);
                setIsAnimationIn(false);
                break;
            }
            case "informationModes": {
                setNavigationIndex(
                    navigationIndex +
                        Number(
                            getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                item.shortName as string,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue,
                                content
                            )
                        )
                );
                setSelectedInformationMode(item.shortName as string);
                setFinalMessageSelectedInformationMode(
                    item.prettyName as string
                );
                setIsAnimationIn(false);
                break;
            }
            case "meansOfPayment": {
                if (isOrderInsertionRefused) {
                    if (
                        ["atTheCounter", "BDP"].includes(
                            item.shortName as string
                        )
                    ) {
                        setNavigationIndex(kioskWays.length - 1);
                    } else {
                        const paymentStepIndex = kioskWays.indexOf(
                            kioskWays.filter(
                                (step) => step.name === "payment"
                            )[0]
                        );

                        if (paymentStepIndex !== -1) {
                            setNavigationIndex(paymentStepIndex);
                        }
                    }

                    setIsChangeMachineRenderModalOpened(false);
                    setAmountDeposited(0);
                    setLeftToPay(totalPrice);
                } else {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    item.shortName as string,
                                    content
                                )
                            )
                    );
                }

                setFinalMessageSelectedRegulationMode(item.name);
                setSelectedRegulationModeValue(item.shortName as string);
                setIsAnimationIn(false);
                break;
            }
            case "loyaltyConsommation": {
                const loyaltyPaymentMode = paymentModes.find(
                    (mode) => mode.paymentMode === "loyalty"
                );
                if (item.shortName === "useMyBalance") {
                    console.log(`I use my loyalty card balance`);

                    if (loyaltyPaymentMode === undefined) {
                        if (totalPrice < user.loyaltyAmount) {
                            setIsOrderPaidWithLoyaltyAccountBalance(true);
                            setTotalOrderAfterPaidWithLoyaltyAccountBalance(0);

                            setPaymentModes([
                                ...paymentModes,
                                {
                                    paymentModeUuiD: loyaltyUuId,
                                    paymentMode: "loyalty",
                                    paymentAmount: totalPrice,
                                    data: {
                                        renderAmount: 0,
                                    },
                                    totalAmountDeposited: 0,
                                },
                            ]);
                        } else {
                            setIsOrderPartialPaidWithLoyaltyAccountBalance(
                                true
                            );
                            setTotalOrderAfterPaidWithLoyaltyAccountBalance(
                                Number(
                                    (totalPrice - user.loyaltyAmount).toFixed(2)
                                )
                            );

                            setPaymentModes([
                                ...paymentModes,
                                {
                                    paymentModeUuiD: loyaltyUuId,
                                    paymentMode: "loyalty",
                                    paymentAmount: user.loyaltyAmount,
                                    data: {
                                        renderAmount: 0,
                                    },
                                    totalAmountDeposited: 0,
                                },
                            ]);
                        }
                    }
                } else {
                    if (loyaltyPaymentMode !== undefined) {
                        setPaymentModes([]);
                    }
                }

                setNavigationIndex(
                    navigationIndex +
                        Number(
                            getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                totalPrice < user.loyaltyAmount &&
                                    item.shortName === "useMyBalance",
                                selectedRegulationModeValue,
                                content
                            )
                        )
                );
                setIsAnimationIn(false);
                break;
            }
            case "payment": {
                if (isRegulationModesModalOpened) {
                    if (
                        ["atTheCounter", "BDP"].includes(
                            item.shortName as string
                        )
                    ) {
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        item.shortName as string,
                                        content
                                    )
                                )
                        );
                    } else if (item.shortName === "creditCard") {
                        setIsBackWithoutCreditCardPaymentChoice(true);
                        setIsAnotherPaymentModeButtonDisabled(true);
                        setIsBackButtonDisabled(true);
                        setAmountDeposited(0);
                    } else if (item.shortName === "changeMachine") {
                        setIsChangeMachineReady(false);
                        handleChangeMachineStatus();
                        setAmountDeposited(0);
                        setIsCreationVoucherCanceled(false);
                        setIsAnotherPaymentModeButtonDisabled(true);
                        setIsBackButtonDisabled(false);
                        setIsCashTransactionValidated(false);
                    }
                    setIsRegulationModesModalOpened(false);
                    setSelectedRegulationModeValue(item.shortName as string);
                    setRegulationModesModalKey(uuid());
                    setLeftToPay(
                        leftToPayAfterCashMachineValidation !== 0
                            ? leftToPayAfterCashMachineValidation
                            : isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                            : totalPrice
                    );
                    setIsPaymentRefused(false);
                    setIsCashTransactionValidated(false);
                    setIsTransactionCanceled(false);
                }
                setIsAnimationIn(false);
                break;
            }
            case "connection": {
                let localIsUserAuthenticationAccepted = false;
                if (item.shortName === "iIdentifyMySelf") {
                    setIsAuthenticationAccepted(true);
                    localIsUserAuthenticationAccepted = true;
                } else {
                    setIsAuthenticationAccepted(false);
                }
                setNavigationIndex(
                    navigationIndex +
                        Number(
                            getSkippedStepNumber(
                                kioskWays,
                                navigationIndex,
                                localIsUserAuthenticationAccepted,
                                isUserAuthenticated,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue,
                                content,
                                selectedAuthMode
                            )
                        )
                );
                setIsAnimationIn(false);
                break;
            }
            default: {
                setNavigationIndex(navigationIndex + 1);
                setIsAnimationIn(false);
                break;
            }
        }
    }

    const isImageDisabled =
        kioskWays[navigationIndex].name === "payment" &&
        isRegulationModesModalOpened &&
        isRegulationModesModalBackButtonClicked;

    return (
        <div
            className="d-grid__clz justify-content-center align-items-center"
            onClick={() => {
                if (isImageDisabled === false) {
                    handleOnClickEvent();
                }
            }}
            style={{
                gridTemplateRows: "6fr 1fr",
                pointerEvents: isImageDisabled ? "none" : "auto",
                opacity: isImageDisabled ? "0.5" : 1,
            }}
        >
            <FlexboxGrid
                justifyContentCentered={true}
                className="align-items-end h-100"
            >
                <img
                    src={imageSrc}
                    alt={item.name}
                    style={{
                        width: "330px",
                        height: "330px",
                    }}
                />
            </FlexboxGrid>

            <FlexboxGrid
                justifyContentCentered={true}
                alignItemsCentered={true}
            >
                <span style={{ fontSize: "35px" }}>
                    {item.languages[customerLanguage.name].isTextVisible ===
                    true
                        ? item["languages"][customerLanguage.name].name
                        : null}
                </span>
            </FlexboxGrid>
        </div>
    );
}
