import { t } from "i18next";
import enLayout from "simple-keyboard-layouts/build/layouts/english";
import frLayout from "simple-keyboard-layouts/build/layouts/french";
import { useSnapshot } from "valtio";

import React from "react";
import Keyboard, { KeyboardInput } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { setLoginKeyboardInputsValues } from "@components/Synchronization/store";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function UsualKeyboardWrapper(): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);

    const [layoutName, setLayoutName] = React.useState<string>("default");
    const [inputName, setInputName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    // eslint-disable-next-line
    const keyboardRef = React.useRef<any>(null);

    const emailInputRef = React.useRef<HTMLInputElement>(null);
    const passwordInputRef = React.useRef<HTMLInputElement>(null);

    function setInputCaretPosition(
        element: HTMLInputElement,
        position: number
    ) {
        if (element.setSelectionRange) {
            element.focus();
            element.setSelectionRange(position, position);

            if (position === element.value.length)
                element.scrollLeft = element.scrollWidth;
        }
    }

    function handleOnChangeAllEvent(inputs: KeyboardInput) {
        const caretPosition = keyboardRef.current.caretPosition;
        setLoginKeyboardInputsValues(inputs);

        if (caretPosition !== null) {
            if (inputName === "email") {
                setEmail(inputs["email"]);
                setInputCaretPosition(
                    emailInputRef.current as unknown as HTMLInputElement,
                    keyboardRef.current.caretPosition
                );
            } else if (inputName === "password") {
                setPassword(inputs["password"]);
                setInputCaretPosition(
                    passwordInputRef.current as unknown as HTMLInputElement,
                    keyboardRef.current.caretPosition
                );
            }
        }
    }

    function handleShift() {
        setLayoutName(layoutName === "default" ? "shift" : "default");
    }

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        }
    }

    function handleInputsOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const inputValue = event.target.value;

        if (event.target.id === "email") {
            const newState = {
                email: inputValue,
                password,
            };
            setEmail(inputValue);
            setLoginKeyboardInputsValues(newState);
        }

        if (event.target.id === "password") {
            const newState = {
                email,
                password: inputValue,
            };

            setPassword(inputValue);
            setLoginKeyboardInputsValues(newState);
        }

        keyboardRef.current?.setInput(inputValue);
    }

    function onBlurInput(event: React.ChangeEvent<HTMLInputElement>) {
        keyboardRef.current.caretPosition = event.target.selectionStart;
    }

    React.useEffect(() => {
        setLoginKeyboardInputsValues({});
    }, []);

    return (
        <FlexboxGrid
            alignItemsCentered={true}
            justifyContentCentered={true}
            className="flex-column py-1"
            gap="10px"
        >
            <form
                className="p-4 d-flex flex-column justify-content-center align-items-center"
                style={{ gap: "15px" }}
            >
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        whiteSpace: "nowrap",
                        gap: "10px",
                    }}
                >
                    {t("ID")}
                    <div className="d-flex">
                        <input
                            id="email"
                            ref={emailInputRef}
                            type="text"
                            value={email}
                            onFocus={() => setInputName("email")}
                            placeholder={t("Enter your ID")}
                            onChange={handleInputsOnChangeEvent}
                            onBlur={onBlurInput}
                            className="usual-keyboard-password__clz px-3"
                        />
                    </div>
                </label>

                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        gap: "10px",
                    }}
                >
                    {t("Password")}

                    <input
                        id="password"
                        ref={passwordInputRef}
                        className="usual-keyboard-password__clz px-3"
                        value={password}
                        type="password"
                        onFocus={() => setInputName("password")}
                        placeholder={t("Enter your password")}
                        onChange={handleInputsOnChangeEvent}
                        onBlur={onBlurInput}
                    />
                </label>
            </form>

            <FlexboxGrid
                alignItemsCentered={true}
                justifyContentCentered={true}
                className="w-100"
            >
                <Keyboard
                    layout={
                        customerLanguage.name === "fr"
                            ? frLayout.layout
                            : enLayout.layout
                    }
                    theme="keyboard__clz hg-theme-default"
                    keyboardRef={(r) => (keyboardRef.current = r)}
                    inputName={inputName}
                    layoutName={layoutName}
                    onChangeAll={handleOnChangeAllEvent}
                    onKeyPress={handleKeyboardOnKeyPressEvent}
                    display={{
                        "{bksp}": `<img src="./images/backspace.svg" />`,
                        "{enter}": t("Enter key"),
                        "{tab}": `<img src="./images/tab.svg" width="25" height="25" />`,
                        "{lock}": `<img src="./images/caps.svg" width="25" height="25" />`,
                        "{shift}": `<img src="./images/shift.svg" />`,
                        "{space}": " ",
                    }}
                />
            </FlexboxGrid>
        </FlexboxGrid>
    );
}
