import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore as store } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function CustomerAccount(): JSX.Element {
    const { localProject } = useSnapshot(store);

    const activeAuthenticationModes = (
        localProject.template.content
            .authenticationModes as ProjectContentItemType
    ).items;

    return (
        <MainContentWrapper
            items={activeAuthenticationModes}
            contentName="authenticationModes"
        />
    );
}
