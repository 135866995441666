import { ITEM_TYPE } from "@constants";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { FireIcon } from "@components/OrderTaking/Components/Icons/FireIcon";

import { kioskStore } from "@pages/Kiosk/store";

export function ProductCalorie({ product }) {
    const {
        isPrm,
        project: { files: images },
    } = useSnapshot(kioskStore);
    const {
        // isCalorieActive,

        calorieId,
        //@eslint-disable-next-line
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    const { type, calorie } = product;
    const isCalorieActive = false;
    const calorieImageContent = React.useMemo(() => {
        return getImageContentById(calorieId, images);
    }, []);
    const calorieImageSrc =
        calorieImageContent?.includes("http") ||
        calorieImageContent?.includes("https")
            ? calorieImageContent
            : `./images_order/${calorieImageContent}`;
    return (
        <Box
            className={`centredItems__clz calorie ${
                isPrm ? "prm-calorie" : "calorie"
            }`}
        >
            {isCalorieActive && type === ITEM_TYPE ? (
                calorie?.isVisible ? (
                    <div
                        className="d-flex justify-content-center align-items-center h-100"
                        style={{
                            gap: "10px",
                        }}
                    >
                        {calorieImageContent !== "" ? (
                            <img
                                src={calorieImageSrc}
                                style={{ height: "100%" }}
                            />
                        ) : (
                            <FireIcon />
                        )}

                        <span>
                            {calorie.content} {"cal"}
                        </span>
                    </div>
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center h-100"
                        style={{
                            gap: "10px",
                        }}
                    >
                        {calorieImageContent !== "" ? (
                            <img
                                src={calorieImageSrc}
                                style={{ height: "100%" }}
                            />
                        ) : (
                            <FireIcon />
                        )}

                        {"pas d'info"}
                    </div>
                )
            ) : null}
        </Box>
    );
}

ProductCalorie.propTypes = {
    product: PropTypes.object.isRequired,
};
