import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { PadLockIcon } from "@components/OrderTaking/Components/Icons/PadLockIcon";

import { kioskStore } from "@pages/Kiosk/store";

import { InformationProductIcon } from "./InformationProductIcon";
import { ProductAllergen } from "./ProductAllergen";

export function BasicCompositionActions({
    product,
    isAllergenIconActive,
    handleNutritionalInformationDialogOnCloseEvent,
    ProductOnClick,
}) {
    const { isPrm } = useSnapshot(kioskStore);
    const { isInformationProductIconActive, informationProductIconContent } =
        useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    return (
        <div
            className="d-flex flex-column justify-content-between align-items-end h-100"
            style={{ width: "15%" }}
            onClick={() => {
                ProductOnClick();
            }}
        >
            <Box
                className="d-flex flex-column  mt-2 mr-2"
                style={{ gap: "15px" }}
            >
                <InformationProductIcon
                    product={product}
                    isInformationProductIconActive={
                        isInformationProductIconActive
                    }
                    handleNutritionalInformationDialogOnCloseEvent={
                        handleNutritionalInformationDialogOnCloseEvent
                    }
                    informationProductIconContent={
                        informationProductIconContent
                    }
                />

                <ProductAllergen
                    isPrm={isPrm}
                    isAllergenIconActive={isAllergenIconActive}
                />
            </Box>
            {product.isObligatory ? (
                <div className="pl-3">
                    <PadLockIcon />
                </div>
            ) : null}
        </div>
    );
}
BasicCompositionActions.propTypes = {
    product: PropTypes.object,
    isAllergenIconActive: PropTypes.bool,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
    ProductOnClick: PropTypes.func,
};
