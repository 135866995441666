import React from "react";
import { useSnapshot } from "valtio";
import Modal from "react-modal";
import { t } from "i18next";
import { SpinningCircles } from "svg-loaders-react";

import {
    setIsQrCodeCorrectOrderNotProcessedModalOpened,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

type PropsType = {
    containerRef: HTMLElement | null;
};
export function QrCodeCorrectOrderNotProcessedModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const { isQrCodeCorrectOrderNotProcessedModalOpened, isPrm } =
        useSnapshot(store);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isQrCodeCorrectOrderNotProcessedModalOpened}
            onRequestClose={() => {
                setIsQrCodeCorrectOrderNotProcessedModalOpened(
                    !isQrCodeCorrectOrderNotProcessedModalOpened
                );
            }}
            contentLabel="qr-code -incorrect"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px solid #040404",
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                },
            }}
        >
            <div
                onClick={() => {
                    setIsQrCodeCorrectOrderNotProcessedModalOpened(
                        !isQrCodeCorrectOrderNotProcessedModalOpened
                    );
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "400px" }}
                >
                    <img src="./images/withdrawalC&C.png" />
                </div>
                <div
                    className="d-flex justify-content-center align-items-center text-center w-100"
                    style={{ height: "170px", fontSize: "55px" }}
                >
                    {t("Withdrawal C&C")}
                </div>

                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "370px" }}
                >
                    <SpinningCircles width="180px" height="180px" />
                </div>
                <div>{t("Please wait...")}</div>
            </div>
        </Modal>
    );
}
