import { OUT_OF_STOCK_ROLE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function OutOfStock({ isOutOfStock }) {
    const {
        isPrm,
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const { designItems } = useSelector((state) => state.settingSlice);
    const outOfStockImage = designItems.filter(
        (item) => item.role === OUT_OF_STOCK_ROLE
    )[0];

    const outOfStockImageContent = getImageContentById(
        outOfStockImage.id,
        images,
        customerLanguage.name
    );

    const outOfStockImageSrc = outOfStockImageContent?.includes("http")
        ? outOfStockImageContent
        : `./images_order/${outOfStockImageContent}`;

    return !isOutOfStock ? null : outOfStockImageSrc !== "" ? (
        <div
            style={{
                position: "absolute",
                height: "100%",
                width: " 100%",
                borderRadius: "15%",
            }}
        >
            <img
                src={outOfStockImageSrc}
                alt="ouf_of_stock"
                loading="lazy"
                style={{
                    width: isPrm ? "220px" : "100%",
                    height: "100%",
                    padding: "10%",
                    borderRadius: "35px",
                }}
            />
        </div>
    ) : (
        <img
            className="img-Out-Of-Stock"
            src="./images_order/out-of-stock.png"
            alt="ouf_of_stock"
            loading="lazy"
            style={{ width: isPrm ? "220px" : "363px" }}
        />
    );
}
OutOfStock.propTypes = {
    isOutOfStock: PropTypes.bool,
};
