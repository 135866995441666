import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AddProductConfirmation } from "@components/OrderTaking/Components/Dialogs/AddProductConfirmation";
import { WorkflowDialog } from "@components/OrderTaking/Components/Dialogs/WorkflowDialog";
import { AddIconWrapper } from "@components/OrderTaking/Components/Icons/AddIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";
import {
    groupWorkflowShopCard,
    refactorWorkflowData,
    useToggle,
} from "@components/OrderTaking/Helpers";
import {
    addQuantityOrderItem,
    addToOrderworkflow,
    removeFromOrder,
    removeFromWorkFlowItemShopCart,
    SelectProduct,
    setIsModification,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import "./index.css";
import ProductCardPopup from "./ProductCardPopup";

export default function ComposedProductCartPopup({
    product,
    closeOrderSummury,
    isRemovedProductDialog,
    isWorkflow,
    isSalesModeChange,
}) {
    const [
        isAddProductConfirmationDialogOpened,
        setIsAddProductConfirmationDialogOpened,
    ] = useToggle();

    const [isExpanded, setIsExpanded] = useToggle();

    const [isWorkflowDialogOpened, setIsWorkflowDialogOpened] = useToggle();

    const dispatchStore = useDispatch();
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );

    const opacity = product.quantity === product.maxQuantity ? "0.2" : "1";

    function removeProduct() {
        isWorkflow
            ? dispatchStore(removeFromWorkFlowItemShopCart(product))
            : dispatchStore(removeFromOrder(product));
    }

    const handleWorkDialogCloseEvent = () => {
        dispatchStore(SelectProduct({}));
        dispatchStore(setWorkflowData([]));
        setIsWorkflowDialogOpened();
        closeOrderSummury && closeOrderSummury();
    };
    const handleOnCancelAddConfirmation = () => {
        dispatchStore(
            addToOrderworkflow({
                ...product,
                date: Date.now(),
                price: product.defaultPrice,
                quantity: 1,
                shopCart: {},
                workflow: {},
            })
        );
        dispatchStore(setWorkflowData([]));
        dispatchStore(SelectProduct({ ...product }));
        // dispatchStore(SelectProduct(globalcard.items[product.iuud]));
        setIsWorkflowDialogOpened();
    };

    function addProduct() {
        Object.keys(product.shopCart).length !== 0
            ? setIsAddProductConfirmationDialogOpened()
            : dispatchStore(addQuantityOrderItem(product));
    }

    function handleAddProductOnCLickEvent() {
        setIsAddProductConfirmationDialogOpened();
    }

    function handleModifyProductOnCLickEvent(Item) {
        dispatchStore(setIsModification(true));
        dispatchStore(
            addToOrderworkflow({
                ...product,
            })
        );

        const workflowModify = refactorWorkflowData(
            Item.workflow,
            product.shopCart
        );
        dispatchStore(setWorkflowData([...workflowModify]));

        if (Item.modifier) {
            dispatchStore(
                SelectProduct({
                    ...globalcard.items[Item.iuud],
                    modifier: Item.modifier,
                })
            );
        }

        setIsWorkflowDialogOpened();
    }

    return (
        <>
            {isWorkflowDialogOpened && (
                <WorkflowDialog
                    open={isWorkflowDialogOpened}
                    onClose={handleWorkDialogCloseEvent}
                />
            )}

            <AddProductConfirmation
                open={isAddProductConfirmationDialogOpened}
                onClose={handleAddProductOnCLickEvent}
                product={product}
                onCancel={handleOnCancelAddConfirmation}
            />

            <div
                className="p-4 composed-product"
                style={{
                    maxHeight: !isExpanded ? "316px" : "",
                    minHeight: !isExpanded ? "180px" : "",
                }}
            >
                <div className="header-composed-product my-4">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    ></div>

                    <>
                        <div className="d-flex justify-content-between">
                            <div
                                className="d-flex align-items-center "
                                style={{
                                    gap: "0.8rem",
                                }}
                            >
                                <div
                                    className="d-flex"
                                    style={{
                                        flexDirection: isSalesModeChange
                                            ? "row-reverse"
                                            : "",
                                    }}
                                >
                                    <div className="d-flex align-items-center justify-content-center title-product mx-2">
                                        <span
                                            className="number-product"
                                            style={{ color: globalColor }}
                                        >
                                            {product.quantity} X
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            maxWidth: "120px",
                                            maxHeight: "120px",
                                        }}
                                    >
                                        <img
                                            src={product.urlImage}
                                            className="w-100"
                                            style={{ borderRadius: "10%" }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="d-flex flex-column"
                                    style={{ gap: "5px" }}
                                >
                                    <span className="recap-product-title">{`${product.name}`}</span>
                                    <span className="summary-options-product">
                                        {` ${
                                            product.selectedOptions
                                                ? product.selectedOptions?.join(
                                                      ","
                                                  )
                                                : ""
                                        }`}
                                    </span>
                                    {!isSalesModeChange ? (
                                        <div className="recap-price-product">
                                            {product?.price.toFixed(2)} €
                                        </div>
                                    ) : product.isDeleted ? (
                                        <div className="d-flex w-25">
                                            <img
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                }}
                                                src="./images_order/crossIcon.png"
                                            />
                                            <span
                                                style={{
                                                    font: "normal normal bold 12px/21px Segoe UI",
                                                    textAlign: "center",
                                                    width: "166px",
                                                    color: "#ff0000",
                                                }}
                                            >
                                                Sera supprimé
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {!isSalesModeChange ? (
                                <div className="recap-product-operations">
                                    <RemoveIcon
                                        width={72}
                                        height={69}
                                        onClick={removeProduct}
                                    ></RemoveIcon>
                                    {!isRemovedProductDialog && (
                                        <AddIconWrapper
                                            opacity={opacity}
                                            width={72}
                                            height={69}
                                            onClick={addProduct}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </>
                </div>
                {product.shopCart &&
                    Object.keys(product.shopCart).length !== 0 && (
                        <>
                            {isExpanded && (
                                <hr className="separation-line mt-2" />
                            )}
                            <div
                                className={isExpanded ? "mb-5" : ""}
                                style={{
                                    overflowY: "auto",
                                    maxHeight: "45vh",
                                    scrollbarWidth: "none",
                                }}
                            >
                                <Collapse in={isExpanded}>
                                    {Object.keys(product.shopCart).map(
                                        (key, index) => {
                                            const groupedItems =
                                                groupWorkflowShopCard(
                                                    product.shopCart[key]
                                                        .compositions
                                                );

                                            return (
                                                <div
                                                    className="d-flex flex-column align-items-center"
                                                    key={index}
                                                >
                                                    <div className="title-element my-2">
                                                        {key ===
                                                        "composition de base"
                                                            ? key.toUpperCase()
                                                            : key}
                                                    </div>
                                                    {groupedItems &&
                                                        groupedItems.map(
                                                            (Item) => {
                                                                return (
                                                                    <>
                                                                        {Item.name ? (
                                                                            <ProductCardPopup
                                                                                Item={
                                                                                    Item
                                                                                }
                                                                                Editproduct={
                                                                                    handleModifyProductOnCLickEvent
                                                                                }
                                                                                isSalesModeChange={
                                                                                    isSalesModeChange
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            Object.keys(
                                                                                Item.compositions
                                                                            ).map(
                                                                                (
                                                                                    key3
                                                                                ) => {
                                                                                    const Item2 =
                                                                                        {
                                                                                            ...Item
                                                                                                .compositions[
                                                                                                key3
                                                                                            ],
                                                                                        };
                                                                                    return (
                                                                                        <>
                                                                                            {Item2.name && (
                                                                                                <ProductCardPopup
                                                                                                    Item={
                                                                                                        Item2
                                                                                                    }
                                                                                                    Editproduct={
                                                                                                        handleModifyProductOnCLickEvent
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            )
                                                                        )}
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            );
                                        }
                                    )}
                                </Collapse>
                            </div>

                            <button
                                onClick={setIsExpanded}
                                className="view-more "
                                style={{ backgroundColor: globalColor }}
                            >
                                {isExpanded ? "masquer" : "Voir"} détails
                            </button>
                        </>
                    )}
            </div>
        </>
    );
}

ComposedProductCartPopup.propTypes = {
    styles: PropTypes.object,
    product: PropTypes.object.isRequired,
    productClickHandler: PropTypes.func,
    closeOrderSummury: PropTypes.func,
    isRemovedProductDialog: PropTypes.bool,
    isWorkflow: PropTypes.bool,
    isSalesModeChange: PropTypes.bool,
};
