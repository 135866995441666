import PropTypes from "prop-types";

import React from "react";

import { FirstBoxCardProduct, SecondBoxCardProduct } from "./component";

export default function CardProductSimple({
    product,
    productId,
    opacity,
    quantity,
    // isInfoActive,
    isAllergenIconActive,
    isDescriptionActive,
    setIsAllergenConfirmationDialogOpened,
    handleAddProductClick,
    handleNutritionalInformationDialogOnCloseEvent,
    handleRemoveProductFromOrder,
}) {
    return (
        <div
            style={{
                height: "100%",
            }}
        >
            <FirstBoxCardProduct
                product={product}
                productId={productId}
                isAllergenIconActive={isAllergenIconActive}
                setIsAllergenConfirmationDialogOpened={
                    setIsAllergenConfirmationDialogOpened
                }
                handleAddProductClick={handleAddProductClick}
                opacity={opacity}
                handleNutritionalInformationDialogOnCloseEvent={
                    handleNutritionalInformationDialogOnCloseEvent
                }
                quantity={quantity}
                handleRemoveProductFromOrder={handleRemoveProductFromOrder}
            />
            <SecondBoxCardProduct
                product={product}
                quantity={quantity}
                isDescriptionActive={isDescriptionActive}
                handleAddProductClick={handleAddProductClick}
                opacity={opacity}
                isAllergenIconActive={isAllergenIconActive}
                setIsAllergenConfirmationDialogOpened={
                    setIsAllergenConfirmationDialogOpened
                }
            />
        </div>
    );
}

CardProductSimple.propTypes = {
    product: PropTypes.object.isRequired,
    productId: PropTypes.string,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    isInfoActive: PropTypes.bool,
    isDescriptionActive: PropTypes.bool,
    isAllergenIconActive: PropTypes.bool,
    setIsAllergenConfirmationDialogOpened: PropTypes.func,
    handleAddProductClick: PropTypes.func,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
    handleProductSizeDialogOnCloseEvent: PropTypes.func,
    handleRemoveProductFromOrder: PropTypes.func,
};
