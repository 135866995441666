import useSWR from "swr";

import { fetcher } from "@helpers/fetcher";

export function useResource(url: string): {
    // eslint-disable-next-line
    resourceData: any;
    error: boolean;
    // eslint-disable-next-line
    mutate: any;
} {
    const {
        data: resourceData,
        error,
        mutate,
    } = useSWR([url], fetcher, {
        suspense: true,
        revalidateOnFocus: false,
    });

    if (resourceData?.error) {
        throw resourceData.message;
    }

    return { resourceData, error, mutate };
}
