import PropTypes from "prop-types";

import React, { lazy, Suspense } from "react";

const MemoizedCardProduct = lazy(() =>
    import(
        "@components/OrderTaking/Components/Products/ProductWrapper/CardProduct"
    )
);

export function ResponsiveItemsList({
    visibleItems,
    listworkflow,
    orderItems,
}) {
    return visibleItems.map((product) => {
        return (
            <Suspense key={product.iuud} fallback={null}>
                <React.Fragment key={product.iuud}>
                    <div
                        className="col-6 centered-block item"
                        style={{ padding: "20px" }}
                    >
                        <MemoizedCardProduct
                            product={product}
                            listworkflow={listworkflow}
                            orderItems={orderItems}
                        />
                    </div>
                </React.Fragment>
            </Suspense>
        );
    });
}
ResponsiveItemsList.propTypes = {
    listworkflow: PropTypes.object,
    visibleItems: PropTypes.array,
    orderItems: PropTypes.array,
};
