import { AuthenticationModeConsommation } from "@pages/Kiosk/Steps/AuthenticationModeConsommation";
import { AuthenticationModesStep } from "@pages/Kiosk/Steps/AuthenticationModesStep";
import { ClickAndCollectStep } from "@pages/Kiosk/Steps/ClickAndCollectStep";
import { ConnectionStep } from "@pages/Kiosk/Steps/ConnectionStep";
import { FinalMessageStep } from "@pages/Kiosk/Steps/FinalMessageStep";
import { InformationModesConsommationStep } from "@pages/Kiosk/Steps/InformationModesConsommationStep";
import { InformationModesStep } from "@pages/Kiosk/Steps/InformationModesStep";
import { LoyaltyConsommationStep } from "@pages/Kiosk/Steps/LoyaltyConsommationStep";
import { OrderTakingStep } from "@pages/Kiosk/Steps/OrderTakingStep";
import { PaymentStep } from "@pages/Kiosk/Steps/PaymentStep";
import { RegulationModesStep } from "@pages/Kiosk/Steps/RegulationModesStep";
import { SalesMethodStep } from "@pages/Kiosk/Steps/SalesMethodStep";
import { StandbyStep } from "@pages/Kiosk/Steps/StandbyStep";

export function useLocalWays(project: ProjectType): StepType[] {
    const localWaysComponents = (
        Object.values((project.template.pages as ItemsPagesType)["ways"])
            .length > 0
            ? (Object.values(
                  (project.template.pages as ItemsPagesType)["ways"]
              ) as (
                  | PageType
                  | ConsommationPageType
                  | StandbyScreenPageType
                  | PaymentPageType
              )[])
            : (Object.values(
                  (project.template.pages as ItemsPagesType)["elements"]
              ) as (
                  | PageType
                  | ConsommationPageType
                  | StandbyScreenPageType
                  | PaymentPageType
              )[])
    )
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .map(
            (
                item:
                    | PageType
                    | ConsommationPageType
                    | StandbyScreenPageType
                    | PaymentPageType
            ) => {
                switch (item.name) {
                    case "salesMethods":
                        return {
                            name: item.name,
                            stepContent: SalesMethodStep,
                            skipped: (item as PageType).skipped,
                        };

                    case "meansOfPayment":
                        return {
                            name: item.name,
                            stepContent: RegulationModesStep,
                            skipped: (item as PageType).skipped,
                        };
                    case "informationModes":
                        return {
                            name: item.name,
                            stepContent: InformationModesStep,
                            skipped: (item as PageType).skipped,
                        };
                    case "informationModesConsommation":
                        return {
                            name: item.name,
                            stepContent: InformationModesConsommationStep,
                        };
                    case "standbyScreen":
                        return {
                            name: item.name,
                            stepContent: StandbyStep,
                        };
                    case "connection":
                        return {
                            name: item.name,
                            stepContent: ConnectionStep,
                        };
                    case "authenticationModes":
                        return {
                            name: item.name,
                            stepContent: AuthenticationModesStep,
                            skipped: (item as PageType).skipped,
                        };
                    case "orderTaking":
                        return {
                            name: item.name,
                            stepContent: OrderTakingStep,
                        };
                    case "payment":
                        return {
                            name: item.name,
                            stepContent: PaymentStep,
                        };
                    case "finalMessage":
                        return {
                            name: item.name,
                            stepContent: FinalMessageStep,
                        };
                    case "loyaltyConsommation":
                        return {
                            name: item.name,
                            stepContent: LoyaltyConsommationStep,
                        };
                }
            }
        );

    const authModeStepIndex = (localWaysComponents as StepType[]).indexOf(
        (localWaysComponents as StepType[]).filter(
            (page) => (page as StepType).name === "authenticationModes"
        )[0]
    );

    const newLocalWays: StepType[] = [...(localWaysComponents as StepType[])];

    if (authModeStepIndex !== -1) {
        newLocalWays.splice(authModeStepIndex + 1, 0, {
            name: "authenticationModeConsommation",
            stepContent: AuthenticationModeConsommation,
        });
    }

    const salesMethodsStepIndex = newLocalWays.indexOf(
        newLocalWays.filter((page) => page?.name === "salesMethods")[0]
    );

    const localKioskWays: StepType[] = [...newLocalWays];

    if (salesMethodsStepIndex !== -1) {
        localKioskWays.splice(salesMethodsStepIndex + 1, 0, {
            name: "clickAndCollect",
            stepContent: ClickAndCollectStep,
        });
    }

    return localKioskWays;
}
