import { Card } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { store } from "@store";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function AllergenGroup({
    globalAllergens,
    handleActiveAllergenOnClickEvent,
    group,
}) {
    const { isPrm } = useSnapshot(kioskStore);
    const { middlewareApiUrl } = useSnapshot(store);

    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    return (
        <div
            className="d-flex flex-column align-items-center "
            style={{
                marginTop: "15px",
            }}
        >
            <span className="title-group">{group}</span>

            {!isPrm ? (
                globalAllergens[group].length > 10 ? (
                    <hr className="separation-line" />
                ) : null
            ) : globalAllergens[group].length > 3 ? (
                <hr className="separation-line" />
            ) : null}
            <div
                className="group-allergens"
                style={{
                    height: isPrm ? "230px" : "800px",
                }}
            >
                <div
                    className="d-flex flex-column"
                    style={{
                        gap: "30px",
                        maxHeight: "1200px",
                    }}
                >
                    {globalAllergens[group].map((allergen) => {
                        return (
                            <Card
                                key={allergen.id}
                                className="allergen-card__clz"
                                onClick={() => {
                                    handleActiveAllergenOnClickEvent(
                                        allergen.id,
                                        group
                                    );
                                }}
                                style={{
                                    background:
                                        allergen.active === true
                                            ? "linear-gradient(180deg, black 40%, var(--global-color) 100%)"
                                            : "transparent",
                                    color:
                                        allergen.active === true
                                            ? "white"
                                            : "black",
                                    height: "50px",
                                    lineHeight: "1em",
                                }}
                            >
                                <span className="d-flex justify-content-center align-items-center">
                                    <img
                                        src={`${middlewareApiUrl}/static/${allergen.icon
                                            .split("/")
                                            .pop()}`}
                                        style={{
                                            height: "42px",
                                            width: "42px",
                                            backgroundColor:
                                                allergen.active === true
                                                    ? "#938585"
                                                    : globalColor,
                                            borderRadius: "100%",
                                        }}
                                    />
                                </span>
                                <span
                                    className="two-lines-preview-text"
                                    style={{
                                        width: "175px",
                                    }}
                                >
                                    {allergen.name}
                                </span>
                            </Card>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
AllergenGroup.propTypes = {
    globalAllergens: PropTypes.object,
    handleActiveAllergenOnClickEvent: PropTypes.func,
    group: PropTypes.string,
};
