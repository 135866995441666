export function defaultOptionProduct(data) {
    return data.reduce((prev, curr) => {
        const defaultOption = curr.listOptions.filter((opt) => opt.isFavorite);
        const optiondata =
            defaultOption.length > 0
                ? defaultOption[0].iuud
                : curr.listOptions[0].iuud;

        return [...prev, curr.iuud, optiondata];
    }, []);
}
