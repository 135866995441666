import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Dispatch } from "@reduxjs/toolkit";
import { t } from "i18next";
import moment from "moment";
import { mutate } from "swr";
import uuid from "uuid-by-string";
import { snapshot } from "valtio";

import i18n from "@i18n";

import { store } from "@store";

import { setOrderTakingSetting } from "@components/OrderTaking/Helpers/Commonfunction";
import { setCarte, setSetting } from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import { AuthenticationModeConsommation } from "@pages/Kiosk/Steps/AuthenticationModeConsommation";
import { AuthenticationModesStep } from "@pages/Kiosk/Steps/AuthenticationModesStep";
import { ClickAndCollectStep } from "@pages/Kiosk/Steps/ClickAndCollectStep";
import { ConnectionStep } from "@pages/Kiosk/Steps/ConnectionStep";
import { FinalMessageStep } from "@pages/Kiosk/Steps/FinalMessageStep";
import { InformationModesConsommationStep } from "@pages/Kiosk/Steps/InformationModesConsommationStep";
import { InformationModesStep } from "@pages/Kiosk/Steps/InformationModesStep";
import { LoyaltyConsommationStep } from "@pages/Kiosk/Steps/LoyaltyConsommationStep";
import { OrderTakingStep } from "@pages/Kiosk/Steps/OrderTakingStep";
import { PaymentStep } from "@pages/Kiosk/Steps/PaymentStep";
import { RegulationModesStep } from "@pages/Kiosk/Steps/RegulationModesStep";
import { SalesMethodStep } from "@pages/Kiosk/Steps/SalesMethodStep";
import { StandbyStep } from "@pages/Kiosk/Steps/StandbyStep";
import {
    kioskStore,
    setActiveConfigOptions,
    setActiveLanguages,
    setAmountDeposited,
    setAuthModalStepIndex,
    setChangeMachineConfigurationDate,
    setCustomerLanguage,
    setFinalMessageSelectedInformationMode,
    setFinalMessageSelectedRegulationMode,
    setInitialStepTimeout,
    setIsAnimationIn,
    setIsAuthenticationErrorModalOpened,
    setIsCashMachineConfigured,
    setIsChangeMachineReady,
    setIsChangeMachineRenderModalOpened,
    setIsCommandInsertionProblemModalOpened,
    setIsConfigOptionsActive,
    setIsCreatingVoucherAccepted,
    setIsCreationVoucherCanceled,
    setIsLanguagesActive,
    setIsLogout,
    setIsOrderInsertedSuccessfully,
    setIsOrderInsertionRefused,
    setIsOrderTakingPreviousStep,
    setIsRefundAnomalyModalOpened,
    setIsRegulationModesModalOpened,
    setIsSelectedSaleModeWithoutInformationModes,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setIsWithdrawalProblemModalOpened,
    setKeyboardInputsValues,
    setKioskWays,
    setLeftToPayAfterCashMachineValidation,
    setLogo,
    setNavigationIndex,
    setNumericKeyboardInputValue,
    setOrderModificationMode,
    setOrderNumber,
    setPaymentModes,
    setProject,
    setProjectTimeout,
    setReturnedAmount,
    setSelectedAuthMode,
    setSelectedInformationMode,
    setSelectedRegulationModeValue,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    setTotalAmountDeposited,
    setUser,
    setVoucherAmount,
} from "@pages/Kiosk/store";

export function groupItemByLanguage(
    // eslint-disable-next-line
    items: any[],
    property: string
    // eslint-disable-next-line
): any[] {
    // eslint-disable-next-line
    return items.reduce(function (acc: any, obj: any) {
        if (!acc[property]) {
            acc[property] = [];
        }
        acc[property].push({
            ...obj.languages[property],
            id: obj.id,
            name: obj.name,
        });
        return acc;
    }, {})[property];
}

// @see https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
export function capitalize(s: string): string {
    return s?.charAt(0).toUpperCase() + s?.slice(1);
}

export function getImageContentById(
    id: string,
    images: ImageItemType[],
    activeLanguage?: string
): string | undefined {
    const image = images?.find((item: ImageItemType) => item.id === id);
    if (image !== undefined) {
        if (typeof image.content === "string") {
            return image.content;
        } else {
            // eslint-disable-next-line
            return image.content[activeLanguage!].path;
        }
    }
}

// eslint-disable-next-line
export function moveArrayElementToTheEnd(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
        element,
    ];
}

// eslint-disable-next-line
export function moveArrayElementToTheBegin(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        element,
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
    ];
}

// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export function formatCurrency(
    currencyData: number,
    minimumFractionDigits = 2,
    currency = "EUR",
    locale = "fr-FR"
): string {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits,
    }).format(currencyData);
}

export function changeLanguage(language: string): void {
    i18n.changeLanguage(language);
}

export function getBackgroundImagePath(
    images: ImageItemType[],
    kioskWays: WaysType[],
    navigationIndex: number
): string {
    const backgroundImageId = images.find((image) => {
        return (
            image.key === "background" &&
            image.name === kioskWays[navigationIndex].name
        );
    })?.id;

    const backgroundImageContent = getImageContentById(
        backgroundImageId as string,
        images
    );

    const imageSrc =
        backgroundImageContent?.includes("http") ||
        backgroundImageContent?.includes("https")
            ? backgroundImageContent
            : `./images/${backgroundImageContent}`;

    return imageSrc;
}

export function getSkippedStepNumber(
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    isUserAuthenticated: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string,
    content: ProjectContentType,
    selectedAuthMode?: string
): number | undefined {
    let localCounter = 1;
    let localSelectedInformationMode = "";
    let localSelectedRegulationMode = "";
    let localSelectedAuthMode = "";
    let localSelectedSaleModeValue = selectedSaleModeValue;
    const salesMethodsStepIndex = kioskWays.indexOf(
        kioskWays.filter((page) => page?.name === "salesMethods")[0]
    );
    const { isSelectedSaleModeWithoutInformationModes, leftToPay, user } =
        snapshot(kioskStore);
    for (let i = 1; i < kioskWays.length - navigationIndex; i++) {
        let flag = false;
        if (
            ((kioskWays[navigationIndex + i] as PageType).skipped as boolean) &&
            ["authenticationModes", "salesMethods", "meansOfPayment"].includes(
                kioskWays[navigationIndex + i].name
            )
        ) {
            if (kioskWays[navigationIndex + i].name === "authenticationModes") {
                if (isAuthenticationAccepted) {
                    const activeAuthModeName = (
                        content.authenticationModes as ProjectContentItemType
                    ).items.filter((item) => item.active === true)[0].shortName;
                    setSelectedAuthMode(activeAuthModeName as string);
                    localSelectedAuthMode = activeAuthModeName as string;
                }
                localCounter += 1;
            }

            if (kioskWays[navigationIndex + i].name === "salesMethods") {
                const activeSalesMode = (
                    content.salesMethods as ProjectContentItemType
                ).items.filter((item) => item.active === true)[0];

                const isLocalSelectedSaleModeWithoutInformationModes =
                    (
                        content.salesMethods as ProjectContentItemType
                    ).items.filter((option: ProjectMainContentItemType) => {
                        return option.name === activeSalesMode.name;
                    })[0]?.informationModes?.items.length === 0 ||
                    (
                        content.informationModes as ProjectContentItemType
                    ).items.filter((option: ProjectMainContentItemType) => {
                        return (
                            (option.active as ItemContentType)[
                                activeSalesMode.name
                            ] === true
                        );
                    }).length === 0;
                setIsSelectedSaleModeWithoutInformationModes(
                    isLocalSelectedSaleModeWithoutInformationModes
                );
                setSelectedSaleModeValue(activeSalesMode.name as string);
                setSelectedSaleModeId(activeSalesMode.id as string);
                localSelectedSaleModeValue = activeSalesMode.name as string;

                if (activeSalesMode.name === "Retrait C&C") {
                    localCounter += 1;
                } else {
                    if (
                        kioskWays[navigationIndex + i + 2].name ===
                            "informationModes" &&
                        isLocalSelectedSaleModeWithoutInformationModes === true
                    ) {
                        if (
                            kioskWays[navigationIndex + i + 3].name ===
                            "informationModesConsommation"
                        ) {
                            localCounter += 4;
                        } else {
                            localCounter += 3;
                        }
                    } else {
                        localCounter += 2;
                    }
                }
            }

            if (kioskWays[navigationIndex + i].name === "meansOfPayment") {
                const activeRegulationsModes = (
                    content.meansOfPayment as ProjectContentItemType
                ).items.filter((item) => item.active === true)[0];
                setFinalMessageSelectedRegulationMode(
                    activeRegulationsModes.name
                );
                setSelectedRegulationModeValue(
                    activeRegulationsModes.shortName as string
                );
                localSelectedRegulationMode =
                    activeRegulationsModes.shortName as string;
                localCounter += 1;
            }
        } else if (
            kioskWays[navigationIndex + i].name === "informationModes" &&
            ((
                (kioskWays[navigationIndex + i] as PageType)
                    ?.skipped as ItemContentType
            )[selectedSaleModeValue] ||
                (
                    (kioskWays[navigationIndex + i] as PageType)
                        .skipped as ItemContentType
                )[localSelectedSaleModeValue])
        ) {
            const isLocalSelectedSaleModeWithoutInformationModes =
                (content.salesMethods as ProjectContentItemType).items.filter(
                    (option: ProjectMainContentItemType) => {
                        return option.name === selectedSaleModeValue;
                    }
                )[0]?.informationModes?.items.length === 0 ||
                (
                    content.informationModes as ProjectContentItemType
                ).items.filter((option: ProjectMainContentItemType) => {
                    return (
                        (option.active as ItemContentType)[
                            selectedSaleModeValue
                        ] === true
                    );
                }).length === 0;

            if (isLocalSelectedSaleModeWithoutInformationModes === false) {
                const activeInformationMode = (
                    content.informationModes as ProjectContentItemType
                ).items
                    .map((datum: ProjectMainContentItemType) => {
                        if (
                            (datum.active as ItemContentType)[
                                selectedSaleModeValue
                            ] === true
                        ) {
                            return datum;
                        }
                    })
                    .filter((element) => element !== undefined)[0];

                if (activeInformationMode !== undefined) {
                    localSelectedInformationMode = (
                        activeInformationMode as ProjectMainContentItemType
                    ).shortName as string;
                    setSelectedInformationMode(
                        (activeInformationMode as ProjectMainContentItemType)
                            .shortName as string
                    );
                    setFinalMessageSelectedInformationMode(
                        (activeInformationMode as ProjectMainContentItemType)
                            .prettyName as string
                    );
                }
            } else {
                setSelectedInformationMode("");
                setFinalMessageSelectedInformationMode("none");
            }
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "informationModes" &&
            (salesMethodsStepIndex === -1 ||
                isSelectedSaleModeWithoutInformationModes === true)
        ) {
            localSelectedInformationMode = "";
            setSelectedInformationMode("");
            setFinalMessageSelectedInformationMode("none");
            localCounter += 1;
        } else if (
            (kioskWays[navigationIndex + i].name ===
                "informationModesConsommation" &&
                selectedInformationMode === "" &&
                localSelectedInformationMode === "") ||
            (kioskWays[navigationIndex + i].name === "payment" &&
                (["atTheCounter", "BDP"].includes(
                    selectedRegulationModeValue
                ) ||
                    (selectedRegulationModeValue === "" &&
                        localSelectedRegulationMode === "" &&
                        kioskWays[navigationIndex].name !== "meansOfPayment") ||
                    isOrderPaidWithLoyaltyAccountBalance === true ||
                    leftToPay === 0)) ||
            (kioskWays[navigationIndex + i].name === "loyaltyConsommation" &&
                (isUserAuthenticated === false ||
                    leftToPay === 0 ||
                    user.loyaltyAccount === 0))
        ) {
            localCounter += 1;
        } else if (
            (kioskWays[navigationIndex + i].name === "authenticationModes" &&
                (!isAuthenticationAccepted || isUserAuthenticated)) ||
            (kioskWays[navigationIndex + i].name === "meansOfPayment" &&
                isOrderPaidWithLoyaltyAccountBalance === true)
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name ===
                "authenticationModeConsommation" &&
            (!isAuthenticationAccepted ||
                isUserAuthenticated ||
                (isAuthenticationAccepted && !isUserAuthenticated)) &&
            localSelectedAuthMode === "" &&
            (selectedAuthMode === "" || selectedAuthMode === undefined)
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "clickAndCollect" &&
            selectedSaleModeValue !== t("Withdrawal C&C") &&
            kioskWays[navigationIndex + i - 1].skipped === false
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "connection" &&
            isUserAuthenticated
        ) {
            localCounter += 1;
        } else {
            flag = true;
            return localCounter;
        }

        if (flag) {
            break;
        }
    }
}

export function getPreviousSkippedStepNumber(
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    isUserAuthenticated: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string
): number | undefined {
    let localCounter = 1;
    const salesMethodsStepIndex = kioskWays.indexOf(
        kioskWays.filter((page) => page?.name === "salesMethods")[0]
    );
    const { isSelectedSaleModeWithoutInformationModes, leftToPay, user } =
        snapshot(kioskStore);

    for (let i = 1; i <= navigationIndex; i++) {
        let flag = false;
        if (
            // eslint-disable-next-line
            (((kioskWays[navigationIndex - i] as PageType)
                .skipped! as boolean) &&
                [
                    "authenticationModes",
                    "salesMethods",
                    "meansOfPayment",
                ].includes(kioskWays[navigationIndex - i].name)) ||
            (kioskWays[navigationIndex - i].name === "informationModes" &&
                (
                    (kioskWays[navigationIndex - i] as PageType)
                        .skipped as ItemContentType
                )[selectedSaleModeValue]) ||
            (kioskWays[navigationIndex - i].name === "clickAndCollect" &&
                selectedSaleModeValue !== t("Withdrawal C&C")) ||
            (kioskWays[navigationIndex - i].name ===
                "informationModesConsommation" &&
                selectedInformationMode === "") ||
            (kioskWays[navigationIndex - i].name === "payment" &&
                (["atTheCounter", "BDP"].includes(
                    selectedRegulationModeValue
                ) ||
                    (selectedRegulationModeValue === "" &&
                        kioskWays[navigationIndex].name !== "meansOfPayment") ||
                    isOrderPaidWithLoyaltyAccountBalance === true ||
                    leftToPay === 0)) ||
            (kioskWays[navigationIndex - i].name === "loyaltyConsommation" &&
                (!isUserAuthenticated ||
                    leftToPay === 0 ||
                    user.loyaltyAccount === 0)) ||
            kioskWays[navigationIndex - i].name ===
                "authenticationModeConsommation" ||
            (kioskWays[navigationIndex - i].name === "authenticationModes" &&
                (!isAuthenticationAccepted || isUserAuthenticated)) ||
            (kioskWays[navigationIndex - i].name === "informationModes" &&
                (salesMethodsStepIndex === -1 ||
                    isSelectedSaleModeWithoutInformationModes === true)) ||
            (kioskWays[navigationIndex - i].name === "connection" &&
                isUserAuthenticated)
        ) {
            localCounter += 1;
        } else if (kioskWays[navigationIndex - i].name === "orderTaking") {
            setIsOrderTakingPreviousStep(true);
            flag = true;
            return localCounter;
        } else {
            flag = true;

            return localCounter;
        }

        if (kioskWays[navigationIndex - i].name === "authenticationModes") {
            setSelectedAuthMode("");
            setIsUserAuthenticated(false);
        }

        if (flag) {
            break;
        }
    }
}

export function truncateString(string: string, maxLength: number): string {
    return string.length > maxLength
        ? `${string.slice(0, maxLength)}...`
        : string;
}

export function getSubStepBackgroundImagePath(
    images: ImageItemType[],
    selectedMode: string,
    customerLanguage?: string
): string {
    const backgroundImageId = images.find((image) => {
        return image.key === "background" && image.subName === selectedMode;
    })?.id;
    const backgroundImageContent = getImageContentById(
        backgroundImageId as string,
        images,
        customerLanguage
    );
    return backgroundImageContent?.includes("http") ||
        backgroundImageContent?.includes("https")
        ? backgroundImageContent
        : `./images/${backgroundImageContent}`;
}

export function getLogoImagePath(
    images: ImageItemType[],
    customerLanguage: string
): string {
    const logoId = images.find((image) => {
        return image.key === "Logo";
    })?.id;
    const logoImage = getImageContentById(
        logoId as string,
        images,
        customerLanguage
    );
    return logoImage?.includes("http") || logoImage?.includes("https")
        ? logoImage
        : `./images/${logoImage}`;
}

export function getTopBannerImagePath(
    activePageName: string,
    images: ImageItemType[]
): string {
    const topBannerBackgroundImageId = images.find((image) => {
        return (
            image.key === "topBannerBackground" && image.name === activePageName
        );
    })?.id;
    const topBannerBackgroundImageContent = getImageContentById(
        topBannerBackgroundImageId as string,
        images
    );
    return topBannerBackgroundImageContent?.includes("http") ||
        topBannerBackgroundImageContent?.includes("https")
        ? topBannerBackgroundImageContent
        : `./images/${topBannerBackgroundImageContent}`;
}

export function handleWithdrawalOfDepositedAmount(
    amountDeposited: number
): void {
    const { middlewareApiUrl } = snapshot(store);
    fetch(`${middlewareApiUrl}/RetraitMontant?unitaire=${-amountDeposited}`)
        .then((response) => response.json())
        .then((response) => {
            if (response.code === 2) {
                setTotalAmountDeposited(0);
            }
        })
        .catch((error) => {
            console.log(
                `Error when withdrawing the deposited amount, with the message: ${error.message}`
            );
        });
}

export function handleAbortCBTransaction(): void {
    const { middlewareApiUrl } = snapshot(store);
    fetch(`${middlewareApiUrl}/AbortCBTransaction`);
}

export function handleAbortCashMachineTransaction(): void {
    const { middlewareApiUrl } = snapshot(store);
    const url = `${middlewareApiUrl}/AbortTransactionCash`;

    fetch(url)
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 200) {
                setAmountDeposited(0);
            }
        })
        .catch((error) => {
            console.log(
                `Error while cancelling the change machine transaction, with the message: ${error.message}`
            );
        });
}

export function generateItemPriceUuid(strings: string[]): string {
    const lowercasedStrings = strings?.map((element) => element.toLowerCase());
    const sorted = [...lowercasedStrings].sort((a, b) => a.localeCompare(b));
    const sortedStrings = sorted.join("");
    const sortedLetters = sortedStrings.split("").sort().join("");
    return uuid(sortedLetters + sortedStrings);
}

export async function handleUserAuthentication(
    authModeInfo: AuthModeInfoType,
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string,
    authModalStepIndex: number,
    isAuthenticationErrorModalOpened: boolean,
    content: ProjectContentType
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const { orderItemsLength, saleMode, localSelectedInformationMode } =
        snapshot(kioskStore);

    await fetch(`${middlewareApiUrl}/loyaltyBalance`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(authModeInfo),
    })
        .then((response) => response.json())
        .then((result) => {
            if (result.code === 3) {
                setIsAuthenticationErrorModalOpened(
                    !isAuthenticationErrorModalOpened
                );
            } else {
                if (authModalStepIndex !== 0) {
                    setNavigationIndex(authModalStepIndex);
                } else if (
                    selectedSaleModeValue === "Delivery" ||
                    saleMode.name === "Delivery"
                ) {
                    if (orderItemsLength === 0) {
                        const salesMethodsStepIndex = kioskWays.indexOf(
                            kioskWays.filter(
                                (page) => page?.name === "salesMethods"
                            )[0]
                        );
                        setNavigationIndex(
                            salesMethodsStepIndex +
                                Number(
                                    getSkippedStepNumber(
                                        kioskWays,
                                        salesMethodsStepIndex,
                                        isAuthenticationAccepted,
                                        true,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content
                                    )
                                )
                        );
                    } else {
                        setIsOrderTakingPreviousStep(true);
                        const orderTakingStepIndex = kioskWays.indexOf(
                            kioskWays.filter(
                                (page) => page?.name === "orderTaking"
                            )[0]
                        );
                        setNavigationIndex(orderTakingStepIndex);
                        setSelectedSaleModeValue(saleMode.name);
                        setSelectedSaleModeId(saleMode.id);
                        setSelectedSaleModeOrderTaking(saleMode.settingKey);
                        setOrderModificationMode();
                        setSelectedInformationMode(
                            localSelectedInformationMode
                        );
                        if (localSelectedInformationMode === "") {
                            setFinalMessageSelectedInformationMode("none");
                        }
                    }
                } else {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    true,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content
                                )
                            )
                    );
                }
                setIsUserAccountModalOpened(false);
                setAuthModalStepIndex(0);
                setSelectedAuthMode("");
                setIsUserAuthenticated(true);
                setNumericKeyboardInputValue("");
                setKeyboardInputsValues({});
                setUser(result.response);
                setIsAnimationIn(false);
            }
        })
        .catch((error) =>
            console.error(
                `Error while getting user information, with message: ${error.message}`
            )
        );
}

function handlePrintVoucher(voucherId: string): void {
    const { middlewareApiUrl } = snapshot(store);
    fetch(`${middlewareApiUrl}/PrintBonAvoir?IdAvoir=${voucherId}`);
}

export async function handlePutVoucher(voucher: VoucherType): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const apiUrl = `${middlewareApiUrl}/PutBonAvoir`;

    mutate(
        apiUrl,
        await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(voucher),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.code === 2) {
                    handlePrintVoucher(result.id_avoir);
                }
            })
            .catch((error) =>
                console.error(
                    `Error while inserting the voucher, with the message:${error.message}`
                )
            )
    );
}

export function handlePrintClientTicket(orderNumber: number): void {
    const { middlewareApiUrl } = snapshot(store);
    fetch(`${middlewareApiUrl}/printTicket?numTicket=${orderNumber}`);
}

function handleRefundDepositedAmount(
    middlwareOrder: ObjectType,
    isCreatingVoucherAccepted: boolean,
    voucherAmount: number
) {
    const { middlewareApiUrl } = snapshot(store);

    // eslint-disable-next-line
    (middlwareOrder as any).reglements.forEach((mode: PaymentModeType) => {
        if (mode.paymentMode === "changeMachine") {
            setReturnedAmount(
                mode.paymentAmount +
                    (isCreatingVoucherAccepted ? voucherAmount : 0)
            );
            fetch(
                `${middlewareApiUrl}/RetraitMontant?unitaire=${-(
                    mode.paymentAmount +
                    (isCreatingVoucherAccepted ? voucherAmount : 0)
                )}`
            )
                .then((response) => response.json())
                .then((response) => {
                    const localRenderAmount = Number(
                        response.response
                            .split(`:`)[2]
                            .split(`,`)[0]
                            .substr(
                                1,
                                response.response.split(`:`)[2].split(`,`)[0]
                                    .length - 2
                            )
                    );

                    if (localRenderAmount > 0) {
                        setIsChangeMachineRenderModalOpened(true);
                    } else {
                        setIsWithdrawalProblemModalOpened(true);
                    }
                })
                .catch((error) => {
                    console.log(
                        `Error when withdrawing the deposited amount, with the message: ${error.message}`
                    );
                });
        }
    });
}

export async function handlePutCommand(
    middlwareOrder: ObjectType,
    isCreatingVoucherAccepted: boolean,
    voucherAmount: number
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);

    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
        controller.abort();
        setIsCommandInsertionProblemModalOpened(true);
    }, 60000);

    await fetch(`${middlewareApiUrl}/PutCommandeV2`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(middlwareOrder),
        signal,
    })
        .then((response) => response.json())
        .then((result) => {
            if (result.code === 2) {
                setOrderNumber(result.response[0] as string);
                if (isCreatingVoucherAccepted === true) {
                    handlePrintVoucher(result.response[4]);
                }
                handlePrintClientTicket(result.response[0]);
                setIsOrderInsertedSuccessfully(true);
            } else {
                handleRefundDepositedAmount(
                    middlwareOrder,
                    isCreatingVoucherAccepted,
                    voucherAmount
                );
                setIsCommandInsertionProblemModalOpened(true);
                setIsOrderInsertionRefused(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error while handling put command request, with message: ${error.message}`
            );
            setIsCommandInsertionProblemModalOpened(true);
            setIsOrderInsertionRefused(true);
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}

export function getEntityData(
    data: EntityDataType | EntityDataType[]
): EntityType[] {
    let entities: EntityType[] = [];

    if (Array.isArray(data)) {
        entities = data.map((datum: EntityDataType) => getEntityData(datum)[0]);
    } else {
        const entity: EntityType = {
            uid: data["uid"] as string,
            crm_id: data["CRMId"] as string,
            name: data["Nom commercial"] as string,
            franchise: data["Name"] as string,
            schema: data["franchise_schema"] as string,
            nature: data["Nature"] as string,
            type: data["ETKType"] as EntityTypeType,
            accountType: data["Type du compte"] as string,
            address: data["Adresse"] as string,
            children_uid: data["childs_profilelink"] as string[],
            parent: data["parent_profilelink"] as EntityParentType,
            franchiseUuid: data["uuidFranchise"],
        };

        if (data["IdStore"] !== undefined) {
            entity.id = data["IdStore"] as string | undefined;
        }

        if (data["subRows"] !== undefined) {
            const children: EntityType[] = [];

            data["subRows"].forEach((subRow: EntityDataType) => {
                children.push(getEntityData(subRow)[0]);
            });

            entity.children = children;
        }

        entities.push(entity);
    }

    return entities;
}

export async function decryptWord(wordToDecrypt: string): Promise<string> {
    try {
        return await fetch(
            `${process.env.REACT_APP_CONSOLE_APP_API}/decryptWord`,
            {
                headers: {
                    Accept: "text/plain",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `wordToDecrypted=${wordToDecrypt}`,
                method: "POST",
            }
        ).then((response) => {
            return response.text();
        });
    } catch (error) {
        console.log(
            `Error while decrypting word ${wordToDecrypt}, with message: ${
                (error as Error).message
            }`
        );
        return "";
    }
}

export function getYesActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType,
    images: ImageItemType[],
    customerLanguageName?: string
): string {
    const yesImageContent = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "Yes"
            ) as ActionsButtonsDesignItemType
        ).id,
        images,
        customerLanguageName
    );

    const yesImageSrc =
        yesImageContent?.includes("http") || yesImageContent?.includes("https")
            ? yesImageContent
            : `./images/${yesImageContent}`;

    return yesImageSrc;
}

export function getNoActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType,
    images: ImageItemType[],
    customerLanguageName?: string
): string {
    const noImageContent = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "No"
            ) as ActionsButtonsDesignItemType
        ).id,
        images,
        customerLanguageName
    );

    const noImageSrc =
        noImageContent?.includes("http") || noImageContent?.includes("https")
            ? noImageContent
            : `./images/${noImageContent}`;

    return noImageSrc;
}

export function getAlertActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType,
    images: ImageItemType[],
    customerLanguageName?: string
): string {
    const alertImageContent =
        actionsButtons?.items !== undefined && actionsButtons?.items?.length > 0
            ? getImageContentById(
                  (
                      (actionsButtons as ActionsButtonsDesignType).items.find(
                          (actionButton) => actionButton.name === "Alert"
                      ) as ActionsButtonsDesignItemType
                  ).id,
                  images,
                  customerLanguageName
              )
            : "";

    const alertImageSrc =
        alertImageContent?.includes("http") ||
        alertImageContent?.includes("https")
            ? alertImageContent
            : `./images/${alertImageContent}`;

    return alertImageSrc;
}

export function initCardReader(ipAddress: string): void {
    const { middlewareApiUrl } = snapshot(store);
    fetch(
        `${middlewareApiUrl}/LogoutLecteur?originName=${ipAddress}&Type=Logout`
    )
        .then((response) => response.json())
        .then((response) => {
            if (response.code === 0) {
                setIsLogout(false);
            } else {
                setIsLogout(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error while logout credit card, with message: ${error.message}`
            );
        });
}

export function configCashMachine(ipCashMachine: string): void {
    const { middlewareApiUrl } = snapshot(store);

    // eslint-disable-next-line
    let changeMachineConfigurationDate: any = "";
    fetch(`${middlewareApiUrl}/ConfigMonnayeur?IpCash=${ipCashMachine}`)
        .then((response) => response.json())
        .then((response) => {
            if (response.code === 0) {
                setIsCashMachineConfigured(false);
                console.log("The change machine config failed");
            } else {
                console.log("The change machine config done successfully");
                changeMachineConfigurationDate = moment(new Date()).format(
                    "YYYY-MM-DD"
                );
                console.log(
                    `The change machine configuration date is :${changeMachineConfigurationDate}`
                );
                setChangeMachineConfigurationDate(
                    changeMachineConfigurationDate
                );
                setIsCashMachineConfigured(true);
            }
        })
        .catch((error) =>
            console.error(
                `Error while change machine initialization, with the message:${error.message}`
            )
        );
}

export function getPeripheralDeviceImageSrc(item: string): string {
    if (item.toLowerCase() === "change machine") {
        return "cash-management";
    }
    if (item.toLowerCase() === "printer") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "led") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "tpe") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "scanner") {
        return "led";
    }
    return "placeholder";
}

export function removeItemFromList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
    )[],
    index: number
): [
    PageType | ConsommationPageType | StandbyScreenPageType | PaymentPageType,
    (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
    )[]
] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
    )[] = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
}

export function addItemInList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
    )[],
    index: number,
    element:
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
): (
    | PageType
    | ConsommationPageType
    | StandbyScreenPageType
    | PaymentPageType
)[] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
    )[] = Array.from(list);
    result.splice(index, 0, element);
    return result;
}

export function getCatalog(
    franchiseSchema: string,
    shopId: string,
    // eslint-disable-next-line
    dispatch: Dispatch<any>,
    saveJson?: boolean
): void {
    const { middlewareApiUrl } = snapshot(store);

    const timestamp = new Date().getTime(); // current timestamp
    fetch(
        `${middlewareApiUrl}/kiosk/${franchiseSchema}/${shopId}/card?timestamp=${timestamp}`,
        { headers: { "Cache-Control": "no-cache" } }
    )
        .then((response) => response.json())
        .then((data) => {
            if (data.error === true) {
                throw Error("There's an issue while getting catalog");
            }

            try {
                localStorage.setItem("card", JSON.stringify(data));
            } catch (error) {
                console.log("can't save catalogue", error);
            }

            dispatch(setCarte(data));

            if (saveJson !== undefined && saveJson === true) {
                (window as RendererWindow).electronAPI.getCatalogEvent({
                    data: JSON.stringify(data),
                });
            }
        })
        .catch((error) =>
            console.log(
                `Error while getting catalog, with message: ${error.message}`
            )
        );
}

export function updateStepsProject(project: ProjectType): void {
    const localWaysComponents = (
        Object.values((project.template.pages as ItemsPagesType)["ways"])
            .length > 0
            ? (Object.values(
                  (project.template.pages as ItemsPagesType)["ways"]
              ) as (
                  | PageType
                  | ConsommationPageType
                  | StandbyScreenPageType
                  | PaymentPageType
              )[])
            : (Object.values(
                  (project.template.pages as ItemsPagesType)["elements"]
              ) as (
                  | PageType
                  | ConsommationPageType
                  | StandbyScreenPageType
                  | PaymentPageType
              )[])
    )
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .map(
            (
                item:
                    | PageType
                    | ConsommationPageType
                    | StandbyScreenPageType
                    | PaymentPageType
            ) => {
                switch (item.name) {
                    case "salesMethods":
                        return {
                            name: item.name,
                            stepContent: SalesMethodStep,
                            skipped: (item as PageType).skipped,
                        };

                    case "meansOfPayment":
                        return {
                            name: item.name,
                            stepContent: RegulationModesStep,
                            skipped: (item as PageType).skipped,
                        };
                    case "informationModes":
                        return {
                            name: item.name,
                            stepContent: InformationModesStep,
                            skipped: (item as PageType).skipped,
                        };
                    case "informationModesConsommation":
                        return {
                            name: item.name,
                            stepContent: InformationModesConsommationStep,
                        };
                    case "standbyScreen":
                        return {
                            name: item.name,
                            stepContent: StandbyStep,
                        };
                    case "connection":
                        return {
                            name: item.name,
                            stepContent: ConnectionStep,
                        };
                    case "authenticationModes":
                        return {
                            name: item.name,
                            stepContent: AuthenticationModesStep,
                            skipped: (item as PageType).skipped,
                        };
                    case "orderTaking":
                        return {
                            name: item.name,
                            stepContent: OrderTakingStep,
                        };
                    case "payment":
                        return {
                            name: item.name,
                            stepContent: PaymentStep,
                        };
                    case "finalMessage":
                        return {
                            name: item.name,
                            stepContent: FinalMessageStep,
                        };
                    case "loyaltyConsommation":
                        return {
                            name: item.name,
                            stepContent: LoyaltyConsommationStep,
                        };
                }
            }
        );

    const authModeStepIndex = (localWaysComponents as StepType[]).indexOf(
        (localWaysComponents as StepType[]).filter(
            (page) => (page as StepType).name === "authenticationModes"
        )[0]
    );

    const newLocalWays: StepType[] = [...(localWaysComponents as StepType[])];

    if (authModeStepIndex !== -1) {
        newLocalWays.splice(authModeStepIndex + 1, 0, {
            name: "authenticationModeConsommation",
            stepContent: AuthenticationModeConsommation,
        });
    }

    const salesMethodsStepIndex = newLocalWays.indexOf(
        newLocalWays.filter((page) => page?.name === "salesMethods")[0]
    );

    const localKioskWays: StepType[] = [...newLocalWays];

    if (salesMethodsStepIndex !== -1) {
        localKioskWays.splice(salesMethodsStepIndex + 1, 0, {
            name: "clickAndCollect",
            stepContent: ClickAndCollectStep,
        });
    }

    setKioskWays(localKioskWays);

    setProjectTimeout(project.template.timeout);

    setIsConfigOptionsActive(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).active
    );

    setActiveConfigOptions(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).items.filter((option) => option.active === true)
    );

    setIsLanguagesActive(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).active &&
            (
                (project.template.content as ProjectContentType)
                    .languages as LanguagesType
            )?.items.filter((option) => option.active === true).length !== 1
    );

    setActiveLanguages(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).items.filter((language) => language.active === true)
    );

    setCustomerLanguage(
        (project.template.content.languages as LanguagesType).items.filter(
            (language) => language.isDefault === true
        )[0]
    );

    setInitialStepTimeout(project.template.initialStepTimeout as number);

    setLogo((project.template.content.generalDesign as GeneralDesignType).logo);
}

export function getProjectByFranchiseUidAndShopUid(
    franchise_uid: string,
    shop_uid: string,
    // eslint-disable-next-line
    dispatch: Dispatch<any>,
    saveJson?: boolean
): void {
    const { middlewareApiUrl } = snapshot(store);
    const { selectedShopApp } = snapshot(syncStore);

    fetch(
        `${middlewareApiUrl}/settings/consomation?franchise_uuid=${franchise_uid}&shop_uuid=${shop_uid}&nameKiosk=${selectedShopApp.name}`
    )
        .then((response) => response.json())
        .then(({ kiosk }) => {
            const modifiedProject = kiosk.filter(
                (kiosk: Record<string, string>) => {
                    return kiosk.name === selectedShopApp.name;
                }
            );
            if (Array.isArray(modifiedProject) && modifiedProject.length > 0) {
                setProject(modifiedProject[0].project);
                updateStepsProject(modifiedProject[0].project);
                const customLang = (
                    modifiedProject[0].project.template.content
                        .languages as LanguagesType
                ).items.filter((language) => language.isDefault === true)[0];
                dispatch(
                    setSetting(
                        setOrderTakingSetting(
                            modifiedProject[0].project.template.pages.ways
                                .orderTaking as PageType,
                            customLang.name,
                            (
                                modifiedProject[0].project.template.content
                                    .generalDesign as GeneralDesignType
                            ).colors[0].content,
                            (
                                modifiedProject[0].project.template.content
                                    .generalDesign as GeneralDesignType
                            ).colors[1].content
                        )
                    )
                );

                if (saveJson !== undefined && saveJson === true) {
                    (window as RendererWindow).electronAPI.getProjectEvent({
                        data: JSON.stringify(
                            modifiedProject[0].project,
                            null,
                            4
                        ),
                    });
                }
            }
        })
        .catch((error) =>
            console.log(
                `Error while updating project from setting, with message: ${error.message}`
            )
        );
}

export async function handleChangeMachineStatus(): Promise<void> {
    const ctrl = new AbortController();

    const { middlewareApiUrl } = snapshot(store);

    console.log("The change machine status api is started");

    await fetchEventSource(`${middlewareApiUrl}/StatusCashMachine`, {
        signal: ctrl.signal,
        onmessage(event) {
            console.log(
                `The change machine status api event:${JSON.stringify(event)}`
            );

            switch (event.event) {
                case "InventoryResponse": {
                    setIsChangeMachineReady(true);
                    console.log(
                        "We receive InventoryResponse event witch mean that the change machine is ready and remove the control from change machine status api"
                    );
                    ctrl.abort();
                    break;
                }
                case "EventCash": {
                    console.log(
                        "We receive EventCash event witch mean that the change machine is not ready"
                    );
                    setIsChangeMachineReady(false);
                    break;
                }
                case "eventError":
                case "Error":
                case "IncompleteTransaction":
                case "PowerControlResponse":
                case "SpecialDeviceError": {
                    console.log(event.data);
                    break;
                }
            }
        },
        onclose() {
            console.log("Connection close");
            ctrl.abort();
        },
        onerror(error) {
            console.log(
                `Error while getting the change machine status, with the message: ${error.message}`
            );
            ctrl.abort();
        },
    });
}

export function handleCancelVoucherGetting(totalPrice: number): void {
    const { middlewareApiUrl } = snapshot(store);
    const {
        amountDeposited,
        leftToPayAfterCashMachineValidation,
        isCreationVoucherCanceled,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        totalAmountDeposited,
    } = snapshot(kioskStore);

    if (isCreationVoucherCanceled === false) {
        console.log(
            `Creating voucher is canceled, a refund deposited amount api will be started`
        );
        if (leftToPayAfterCashMachineValidation !== 0) {
            console.log(
                `The left to pay after cash machine validation is :${leftToPayAfterCashMachineValidation}, it means that the amount deposited already is :${
                    (isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice) - leftToPayAfterCashMachineValidation
                }, and the total deposited amount is:${
                    amountDeposited +
                    (isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice) -
                    leftToPayAfterCashMachineValidation
                }`
            );
        }

        const localTotalDepositedAmount =
            amountDeposited + totalAmountDeposited;

        fetch(
            `${middlewareApiUrl}/RetraitMontant?unitaire=-${localTotalDepositedAmount}`
        )
            .then((response) => response.json())
            .then((response) => {
                console.log({ response: response.response });
                const localRenderAmount = Number(
                    response.response
                        .split(`:`)[2]
                        .split(`,`)[0]
                        .substr(
                            1,
                            response.response.split(`:`)[2].split(`,`)[0]
                                .length - 2
                        )
                );
                console.log({ localRenderAmount });
                if (localRenderAmount > 0) {
                    console.log(
                        `The change machine refund the amount deposited:${localRenderAmount} successfully`
                    );
                    setIsRefundAnomalyModalOpened(false);
                    setIsRegulationModesModalOpened(true);
                } else {
                    console.log(
                        `The change machine does not refund the amount deposited ,a voucher with the amount deposited:${localRenderAmount} will be created`
                    );
                    setVoucherAmount(localTotalDepositedAmount);
                    setIsWithdrawalProblemModalOpened(true);
                }
            })
            .catch((error) => {
                console.log(
                    `Error when withdrawing the deposited amount, with the message: ${error.message}`
                );
            });
        setLeftToPayAfterCashMachineValidation(0);
        setIsCreationVoucherCanceled(true);
        setTotalAmountDeposited(0);
    }
}

export function acceptVoucherGetting(totalPrice: number): void {
    const {
        leftToPayAfterCashMachineValidation,
        cashUuID,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        totalAmountDeposited,
        paymentModes,
        selectedRegulationModeValue,
        voucherAmount,
    } = snapshot(kioskStore);

    const selectedPaymentMode = paymentModes.find(
        (mode) => mode.paymentMode === selectedRegulationModeValue
    );

    if (selectedPaymentMode !== undefined) {
        const selectedPaymentModeIndex =
            paymentModes.indexOf(selectedPaymentMode);

        const localPaymentModes = paymentModes.map((paymentMode) => {
            return paymentMode;
        });

        localPaymentModes[selectedPaymentModeIndex] = {
            paymentModeUuiD: cashUuID,
            paymentMode: selectedRegulationModeValue,
            paymentAmount:
                totalAmountDeposited + leftToPayAfterCashMachineValidation,
            data: {
                renderAmount: 0,
            },
            totalAmountDeposited:
                totalAmountDeposited + leftToPayAfterCashMachineValidation,
        };

        setPaymentModes([
            ...localPaymentModes,
            {
                paymentModeUuiD: "",
                paymentMode: "voucher",
                //avoir rendu negatif
                paymentAmount: -voucherAmount,
                data: {
                    renderAmount: 0,
                    mode_reglement: "ESP",
                },
                totalAmountDeposited: 0,
            },
        ]);
    } else {
        setPaymentModes([
            ...paymentModes,
            {
                paymentModeUuiD: cashUuID,
                paymentMode: "changeMachine",
                paymentAmount: isOrderPartialPaidWithLoyaltyAccountBalance
                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                    : totalPrice,
                data: {
                    renderAmount: 0,
                },
                totalAmountDeposited:
                    isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice,
            },
            {
                paymentModeUuiD: "",
                paymentMode: "voucher",
                //avoir rendu negatif
                paymentAmount: -voucherAmount,
                data: {
                    renderAmount: 0,
                    mode_reglement: "ESP",
                },
                totalAmountDeposited: 0,
            },
        ]);
    }

    setIsCreatingVoucherAccepted(true);
}
