import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { handleUserAuthentication } from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import {
    kioskStore,
    setIsConfirmationModalOpened,
    setNumericKeyboardInputValue,
} from "@pages/Kiosk/store";

import "./index.css";

export function PrmNumericKeyboardWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        isAuthenticationErrorModalOpened,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        authModalStepIndex,
        selectedAuthMode,
        numericKeyboardInputValue,
        isConfirmationModalOpened,
    } = useSnapshot(kioskStore);

    const [loyaltyCode, setLoyaltyCode] = React.useState<string>("");
    const [disabledButtons, setDisabledButtons] =
        React.useState<boolean>(false);

    function handleKeyboardOnChangeEvent(input: string) {
        const slicedInputValue = input.slice(0, 20);
        setLoyaltyCode(slicedInputValue);
        setNumericKeyboardInputValue(slicedInputValue);

        if (input.length >= 20) {
            setDisabledButtons(true);
        } else {
            setDisabledButtons(false);
        }
    }

    function handleOnClickEnterButtonEvent() {
        if (selectedAuthMode !== "") {
            const authModeInfo: AuthModeInfoType =
                selectedAuthMode === "phoneNumber"
                    ? {
                          phoneNumber: numericKeyboardInputValue,
                      }
                    : {
                          loyaltyCardNumber: numericKeyboardInputValue,
                      };

            handleUserAuthentication(
                authModeInfo,
                kioskWays,
                navigationIndex,
                isAuthenticationAccepted,
                selectedSaleModeValue,
                selectedInformationMode,
                isOrderPaidWithLoyaltyAccountBalance,
                selectedRegulationModeValue,
                authModalStepIndex,
                isAuthenticationErrorModalOpened,
                content
            );
        } else {
            setIsConfirmationModalOpened(!isConfirmationModalOpened);
        }
    }

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{enter}") {
            handleOnClickEnterButtonEvent();
        }
    }

    return (
        <FlexboxGrid
            className="prm-numeric-keyboard-wrapper__clz flex-column w-100"
            gap="15px"
            alignItemsCentered={true}
            justifyContentCentered={true}
        >
            <input
                className="text-center"
                type="text"
                value={loyaltyCode}
                disabled
                placeholder={t("Your number")}
                style={{
                    width: "300px",
                    fontSize: "25px",
                    border: "unset",
                    borderBottom: "1px solid #00000029",
                }}
            />
            <Keyboard
                useButtonTag={true}
                theme="prm-numeric-keyboard__clz hg-theme-default hg-layout-numeric numeric-theme"
                display={{ "{bksp}": "&#10006", "{enter}": "&#10003" }}
                style={{ color: "#0a0909" }}
                layoutName="default"
                onChange={handleKeyboardOnChangeEvent}
                disableCaretPositioning={true}
                disableRowButtonContainers={true}
                onKeyPress={handleKeyboardOnKeyPressEvent}
                layout={{
                    default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
                }}
                buttonAttributes={
                    disabledButtons
                        ? [
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "1 2 3",
                              },
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "4 5 6",
                              },
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "7 8 9",
                              },
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "0",
                              },
                          ]
                        : []
                }
            />
        </FlexboxGrid>
    );
}
