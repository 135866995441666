import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { getSkippedStepNumber } from "@helpers/general";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { IdleDetection } from "@components/IdleDetection";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { OrderProcessedModalWrapper } from "@pages/Kiosk/Steps/ClickAndCollectStep/OrderProcessedModalWrapper";
import { QrCodeCorrectOrderNotProcessedModalWrapper } from "@pages/Kiosk/Steps/ClickAndCollectStep/QrCodeCorrectOrderNotProcessedModalWrapper";
import { QrCodeIncorrectModalWrapper } from "@pages/Kiosk/Steps/ClickAndCollectStep/QrCodeIncorrectModalWrapper";
import {
    kioskStore,
    setIsAnimationIn,
    setIsInvalidQrCodeModalOpened,
    setIsOrderProcessedModalWrapperOpened,
    setIsQrCodeCorrectOrderNotProcessedModalOpened,
    setIsStandbyModalOpened,
    setNavigationIndex,
} from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        timeout,
        isHelpModalOpened,
        isStandbyModalOpened,
        isSubStepActionButtonsActive,
        subStepActionButtons,
        isSubStepInformationMessageActive,
        isInvalidQrCodeModalOpened,
        isOrderProcessedModalWrapperOpened,
        isQrCodeCorrectOrderNotProcessedModalOpened,
        chosenTransition,
        isAnimationIn,
        kioskWays,
        navigationIndex,
        selectedRegulationModeValue,
        isAuthenticationAccepted,
        isUserAuthenticated,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedSaleModeValue,
        selectedInformationMode,
        project: {
            template: { content },
        },
    } = useSnapshot(kioskStore);

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        }
    );
    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    const [qrCode, setQrCode] = React.useState<string>("");

    const [isQrCodeShown, setIsQrCodeShown] = React.useState<boolean>(false);

    function handleOnChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
        setQrCode(e.target.value);
    }

    React.useEffect(() => {
        // this is just a `hack` for demo,
        // @todo clean it up !
        const INCORRECT_QR_CODE = "2222";
        const CORRECT_QR_CODE_ORDER_PROCESSED = "1111";
        const CORRECT_QR_CODE_AND_ORDER_NOT_PROCESSED = "0000";

        if (qrCode === INCORRECT_QR_CODE) {
            setIsInvalidQrCodeModalOpened(true);
        } else if (qrCode === CORRECT_QR_CODE_ORDER_PROCESSED) {
            setIsOrderProcessedModalWrapperOpened(true);
        } else if (qrCode === CORRECT_QR_CODE_AND_ORDER_NOT_PROCESSED) {
            setIsQrCodeCorrectOrderNotProcessedModalOpened(true);
        }
    }, [qrCode]);

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
    }, []);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <GlobalPrmModeWrapper>
                <PrmBackgroundWrapper />
                <div>
                    <PreviewBackgroundWrapper
                        selectedMode={selectedInformationMode}
                    >
                        <div
                            style={{
                                display: "grid",
                                gridTemplateRows: "15fr 20fr 7fr 5fr",
                                height: "100%",
                            }}
                            ref={(ref) => setContainerRef(ref)}
                        >
                            <IdleDetection
                                timeout={timeout * 1000}
                                once={true}
                                onIdle={() => {
                                    if (navigationIndex !== 0) {
                                        setIsStandbyModalOpened(true);
                                    }
                                }}
                            />

                            {isHelpModalOpened === true ? (
                                <HelpModalWrapper />
                            ) : null}

                            {isStandbyModalOpened === true ? (
                                <StandbyModalWrapper />
                            ) : null}
                            {containerRef ? (
                                isInvalidQrCodeModalOpened === true ? (
                                    <QrCodeIncorrectModalWrapper
                                        containerRef={containerRef}
                                    />
                                ) : null
                            ) : null}
                            {containerRef ? (
                                isOrderProcessedModalWrapperOpened === true ? (
                                    <OrderProcessedModalWrapper
                                        containerRef={containerRef}
                                    />
                                ) : null
                            ) : null}
                            {containerRef ? (
                                isQrCodeCorrectOrderNotProcessedModalOpened ===
                                true ? (
                                    <QrCodeCorrectOrderNotProcessedModalWrapper
                                        containerRef={containerRef}
                                    />
                                ) : null
                            ) : null}

                            <PrmBackgroundWrapper />
                            {isSubStepInformationMessageActive ? (
                                <SubStepsInformationMessage />
                            ) : null}

                            <div
                                className="d-flex flex-column align-items-center justify-content-center"
                                style={{ gap: "50px" }}
                            >
                                <img
                                    src="./images/qr-code.png"
                                    alt="qrCode"
                                    style={{ height: "200px", width: "200px" }}
                                    onClick={() => {
                                        setNavigationIndex(
                                            navigationIndex +
                                                Number(
                                                    getSkippedStepNumber(
                                                        kioskWays,
                                                        navigationIndex,
                                                        isAuthenticationAccepted,
                                                        isUserAuthenticated,
                                                        selectedSaleModeValue,
                                                        selectedInformationMode,
                                                        isOrderPaidWithLoyaltyAccountBalance,
                                                        selectedRegulationModeValue,
                                                        content
                                                    )
                                                )
                                        );
                                    }}
                                />
                                <div
                                    style={{
                                        borderBottom: "1px solid #00000029",
                                    }}
                                >
                                    <input
                                        className="form-input text-center qr-code__clz"
                                        type={
                                            isQrCodeShown ? "text" : "password"
                                        }
                                        autoFocus
                                        value={qrCode}
                                        placeholder={t("QR CODE")}
                                        onChange={handleOnChangeEvent}
                                    />
                                    {isQrCodeShown === false ? (
                                        <EyeIcon
                                            className="mb-3"
                                            height={30}
                                            width={30}
                                            onClick={() => {
                                                setIsQrCodeShown(
                                                    !isQrCodeShown
                                                );
                                            }}
                                        />
                                    ) : (
                                        <EyeCrossedIcon
                                            className="mb-3"
                                            height={30}
                                            width={30}
                                            onClick={() => {
                                                setIsQrCodeShown(
                                                    !isQrCodeShown
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>

                            {isSubStepActionButtonsActive ? (
                                <PrmActionsButtonsWrapper
                                    actionsButtonsItems={
                                        mainActiveActionsButtons
                                    }
                                />
                            ) : null}
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                            >
                                {isLanguagesActive ? (
                                    <PrmLanguagesWrapper />
                                ) : (
                                    <div />
                                )}
                                {isConfigOptionsActive ? (
                                    <ConfigOptionsWrapper />
                                ) : null}
                            </div>
                        </div>
                    </PreviewBackgroundWrapper>
                </div>
            </GlobalPrmModeWrapper>
        </CSSTransition>
    );
}
