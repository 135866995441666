import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { Provider } from "react-redux";

import { ChangeMachineCard } from "@components/common/Payment/ChangeMachineCard";
import { CreditCard } from "@components/common/Payment/CreditCard";
import { RestoflashCard } from "@components/common/Payment/RestoflashCard";
import store from "@components/OrderTaking/Store";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function PaymentMainContentWrapper(): JSX.Element {
    const { selectedRegulationModeValue } = useSnapshot(kioskStore);

    return (
        <Provider store={store}>
            <div
                className="d-flex flex-column justify-content-center align-items-center "
                style={{ gap: "100px" }}
            >
                {selectedRegulationModeValue === "changeMachine" ? (
                    <React.Fragment>
                        <ChangeMachineCard />
                        <div
                            className="text-center"
                            style={{
                                font: "normal normal 600 50px/67px Segoe UI",
                            }}
                        >
                            {t("Please insert the change in the")}
                            <br />
                            {t("change machine")}
                        </div>
                    </React.Fragment>
                ) : selectedRegulationModeValue === "creditCard" ? (
                    <React.Fragment>
                        <CreditCard />
                        <div
                            className="text-center"
                            style={{ fontSize: "50px" }}
                        >
                            {t("Follow the instructions from the terminal")}
                            <br />
                            {t("payment")}
                        </div>
                    </React.Fragment>
                ) : selectedRegulationModeValue === "restoflash" ? (
                    <React.Fragment>
                        <RestoflashCard />
                        <div
                            className="text-center"
                            style={{ fontSize: "50px" }}
                        >
                            {t(
                                "Please scan your QR code or enter the transaction code"
                            )}
                        </div>
                    </React.Fragment>
                ) : null}
                {selectedRegulationModeValue === "restoflash" ? (
                    <UsualNumericKeyboardWrapper />
                ) : selectedRegulationModeValue === "creditCard" ? (
                    <img src="./images/paymentInstruction.png" />
                ) : (
                    <img src="./images/paymentInstructionChangeMachine.png" />
                )}
            </div>
        </Provider>
    );
}
