import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";

import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

export function ErrorPage(): JSX.Element {
    const navigate = useNavigate();

    return (
        <div className="h-100vh__clz d-flex justify-content-center align-items-center">
            <Card>
                <CardBody>
                    <h4 className="card-title mb-3">{t("There's an error")}</h4>
                    <CardBody className="p-0">
                        <p>
                            {t(
                                "Some meals need more time and effort to do, and so some errors do"
                            )}
                            !
                        </p>
                        <FlexboxGrid
                            alignItemsCentered={true}
                            justifyContentCentered={true}
                        >
                            <StyledButton
                                rounded={true}
                                variant="success"
                                onClick={() => {
                                    navigate("/kiosk-steps");
                                    location.reload();
                                }}
                            >
                                {t("Relaunch kiosk app")}
                            </StyledButton>
                        </FlexboxGrid>
                    </CardBody>
                </CardBody>
            </Card>
        </div>
    );
}
