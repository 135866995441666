import { Box } from "@material-ui/core";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import {
    memoizedActiveCategoryUid,
    memoizedOrderItems,
    memoizedSelectedProducts,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { FixedSizeGrid, ResponsiveItemsList } from "./component";
import "./index.css";

export function ListItems({
    listProducts,
    listworkflow,
    // isAdditionalSale,
    listAdditionnalItems,
}) {
    const { isPrm, selectedSaleModeOrderTaking, isAnimationTimeOut } =
        useSnapshot(kioskStore);
    const activeCategory = useSelector((state) =>
        memoizedActiveCategoryUid(state)
    );
    const orderItems = useSelector((state) => memoizedOrderItems(state));

    const products =
        listProducts !== undefined
            ? listProducts
            : listAdditionnalItems !== undefined
            ? listAdditionnalItems
            : useSelector((state) =>
                  memoizedSelectedProducts(state, activeCategory)
              );

    const visibleItems = products.filter(
        (product) =>
            product?.visibilityInfo.isVisible &&
            (product?.KioskVisibility[selectedSaleModeOrderTaking] ||
                product?.KioskVisibility[selectedSaleModeOrderTaking] ===
                    undefined)
    );

    const keySlide =
        listProducts !== undefined ? listProducts.length : activeCategory;

    const [timeWorkflowProduct, setTimeWorkflowProduct] = React.useState(false);

    React.useEffect(() => {
        setTimeWorkflowProduct(false);
        setTimeout(() => {
            setTimeWorkflowProduct(true);
        }, 300);
    }, []);
    return (
        isAnimationTimeOut && (
            <Box
                className="d-flex justify-content-center align-items-center scrollbarY"
                style={{
                    height: "100%",
                }}
            >
                {timeWorkflowProduct && (
                    <Slide
                        in={true}
                        key={keySlide}
                        unmountOnExit
                        style={{
                            transformOrigin: "0 0 0 ",
                            width: listworkflow && !isPrm && "860px",
                            height: "100%",
                        }}
                        timeout={{ appear: 0, enter: 600, exit: 100 }}
                        direction="up"
                    >
                        <Box
                            className={`row  ${
                                visibleItems.length <= 3 &&
                                "column-centred-items "
                            } ${
                                visibleItems.length === 3
                                    ? "initialFlexDirection"
                                    : ""
                            }`}
                            style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                            }}
                        >
                            {visibleItems.length > 3 ? (
                                <FixedSizeGrid
                                    visibleItems={visibleItems}
                                    listworkflow={listworkflow}
                                    orderItems={orderItems}
                                />
                            ) : (
                                <ResponsiveItemsList
                                    visibleItems={visibleItems}
                                    listworkflow={listworkflow}
                                    orderItems={orderItems}
                                />
                            )}
                        </Box>
                    </Slide>
                )}
            </Box>
        )
    );
}

ListItems.propTypes = {
    listProducts: PropTypes.array,
    listworkflow: PropTypes.object,
    isAdditionalSale: PropTypes.bool,
    listAdditionnalItems: PropTypes.array,
};
