import {
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    CATEGORY_TYPE,
    COMPOSITIONS,
    ETK_CESAR,
    IS_NEXT_IN_LIST,
    ISCHEAPEST,
    ISEXPENSIVE,
    ITEM_PRICE_TYPE,
    ITEM_TYPE,
    NO_ThANKS_CARD,
    STEP_PRICE_TYPE,
    STEP_TYPE,
} from "@constants";
import { isEqual } from "lodash";

export const minPriceWithoutSpecialPrice = (type, priceType, compositions) => {
    let isStep = false;
    return Object.entries(compositions)
        .sort(([, a], [, b]) => a[priceType] - b[priceType])
        .filter((prod) => !prod[1][type])
        .reduce((r, [key, value], index) => {
            if (index === 0) {
                if (value.withStepPrice) {
                    isStep = true;
                }
            }
            if (isStep && !value.withStepPrice) {
                value.withStepPrice = "true";
                value.priceType = 2;
                isStep = false;
            }
            return {
                ...r,
                [key]: {
                    ...value,
                    price:
                        index === 0 ? Number(value.specialPrice) : value.price,
                    withSpecialPrice: index === 0 ? true : false,
                    withStepPrice: index === 0 ? false : value.withStepPrice,
                    priceType: index === 0 ? 1 : value.priceType,
                },
            };
        }, {});
};
export const minPriceWithStepPrice = (compositions) => {
    const result = Object.entries(compositions)
        .sort(([, a], [, b]) => a["price"] - b["price"])
        .filter((prod) => prod[1].priceType === ITEM_PRICE_TYPE)
        .reduce((r, [key, value], index) => {
            return {
                ...r,
                [key]: {
                    ...value,
                    price:
                        index === 0
                            ? Number(value.priceOfItemInStep)
                            : value.price,

                    withStepPrice: index === 0 ? true : value.withStepPrice,
                    priceType: index === 0 ? STEP_PRICE_TYPE : value.priceType,
                },
            };
        }, {});

    return result;
};

export const maxPriceWithoutSpecialPrice = (type, priceType, compositions) => {
    let isStep = false;
    return Object.entries(compositions)
        .sort(([, a], [, b]) => b[priceType] - a[priceType])
        .filter((prod) => !prod[1][type])
        .reduce((r, [key, value], index) => {
            if (index === 0) {
                if (value.withStepPrice) {
                    isStep = true;
                }
            }
            if (isStep && !value.withStepPrice) {
                value.withStepPrice = "true";
                value.priceType = 2;
                isStep = false;
            }
            return {
                ...r,
                [key]: {
                    ...value,
                    price:
                        index === 0 ? Number(value.specialPrice) : value.price,
                    withSpecialPrice: index === 0 ? true : false,
                    withStepPrice: index === 0 ? false : value.withStepPrice,
                    priceType: index === 0 ? 1 : value.priceType,
                },
            };
        }, {});
};
export const groupWorkflowShopCard = (workflowShopcard) => {
    const result = [];
    Object.entries(workflowShopcard).forEach(([key, value]) => {
        const item = { ...value, name: value.name ? value.name : key };

        const indexExistItem = result.findIndex((elm) => {
            const itemCompo =
                item.compositions && Object.keys(item.compositions).length > 0
                    ? delete item.compositions.date
                    : item.compositions;

            const elmCompo =
                elm.compositions && Object.keys(elm.compositions).length > 0
                    ? delete elm.compositions.date
                    : elm.compositions;
            return (
                item.iuud === elm.iuud &&
                isEqual(item.selectedOptionsUuid, elm.selectedOptionsUuid) &&
                isEqual(
                    JSON.stringify(item.shopCart),
                    JSON.stringify(elm.shopCart)
                ) &&
                isEqual(itemCompo, elmCompo)
            );
        });

        if (indexExistItem > -1) {
            let ExistItem = result[indexExistItem];

            ExistItem = {
                ...ExistItem,
                price: ExistItem.price + value.price,
                quantity: ExistItem.quantity + value.quantity,
            };

            result[indexExistItem] = ExistItem;
        } else {
            result.push(item);
        }
    });

    return result;
};

export const getItemComposition = (data) => {
    return Object.keys(data).reduce((prev, curr) => {
        if (curr !== BASIC_COMPOSITION_TYPE) {
            let quantity = data[curr].quantity;
            let name = data[curr].name;
            return [...prev, quantity + "x " + name];
        }
        return [...prev];
    }, []);
};
export const orderItemsToDelete = (data) => {
    return Object.entries(data)
        .sort(([, a], [, b]) => b["priceType"] - a["priceType"])
        .reduce((prev, [, value]) => {
            return [...prev, value];
        }, []);
};
export const getStepPrice = (stepComposition) => {
    return Object.keys(stepComposition).reduce((prev, curr) => {
        return prev + stepComposition[curr].price;
    }, 0);
};

export const kioskVisibilityRefactor = (globalVisibility, data) => {
    return Object.keys(data).reduce((prev, iuud) => {
        const kioskSupport = data[iuud].sellingSupport.filter(
            (support) => support.sellingSupport === "KIOSK"
        );

        return {
            ...prev,
            [iuud]: globalVisibility
                ? !data[iuud].visibility
                    ? false
                    : kioskSupport.length > 0
                    ? kioskSupport[0].visibility
                    : false
                : false,
        };
    }, {});
};

export const generatePath = (path, isproduct) => {
    const data = path.reduce((prev, curr) => {
        if (curr.name === COMPOSITIONS) return [...prev];
        return [
            ...prev,
            curr,
            {
                name: COMPOSITIONS,
                iuud: COMPOSITIONS,
                type: COMPOSITIONS,
            },
        ];
    }, []);
    if (isproduct) {
        data.pop();
    }
    return data;
};

//!!! en cas de modification de produit composé recalcul des nbre choise par chaque etape */
export const getNumberOfChoice = (path, items, orderWorkflowItemSlice) => {
    if (orderWorkflowItemSlice?.item?.shopCart === undefined) return 0;
    const stepPath = generatePath(path, false);
    let newPath = stepPath?.reduce(
        (prev, curr) => (curr?.name ? [...prev, curr.name] : [...prev, curr]),
        []
    );
    let objectToModify = newPath.reduce((prev, key) => {
        return prev[key] || {};
    }, orderWorkflowItemSlice?.item?.shopCart);
    return Object.keys(objectToModify).reduce((prev, curr) => {
        const found = items.find(
            (elm) => elm.iuud === objectToModify[curr].iuud
        );
        return found ? prev + objectToModify[curr].quantity : prev + 0;
    }, 0);
};

export const getPathStep = (workflowData) => {
    let path = [];
    workflowData.forEach((item, index) => {
        let data = {
            name: item.workflow[item.index].title,
            iuud: item.workflow[item.index].iuudproduct
                ? item.workflow[item.index].iuudproduct
                : item.workflow[item.index].uuidStep,
            type: item.workflow[item.index].type,
        };
        if (index === 0) {
            path.push(data);
        } else {
            path.push({
                name: item.workflow[item.index].path[
                    item.workflow[item.index].path.length - 2
                ].name,
                iuud: item.workflow[item.index].iuudproduct
                    ? item.workflow[item.index].iuudproduct
                    : item.workflow[item.index].uuidStep,
                type: item.workflow[item.index].type,
            });
        }
    });
    return path;
};
export const getBasicCompositionStep = (path, product, globalcard) => {
    let iuudmodifer = product.modifier;
    let iuudproduct = product.iuud;
    const iuudMD5 = iuudmodifer;
    let workflowBasic = [];
    //!!! path workflow
    let path_basic_composition = [...path];

    path_basic_composition.push({
        name: BASIC_COMPOSITION_TYPE,
        iuud: BASIC_COMPOSITION_TYPE,
        type: BASIC_COMPOSITION_TYPE,
    });
    let listItems = [];
    Object.entries(globalcard?.items?.[iuudproduct]?.basicComposition).forEach(
        ([iuudItem]) => {
            listItems = [
                ...listItems,
                {
                    indexItem: listItems.length,
                    ...globalcard.items?.[iuudItem],
                    type: BASIC_COMPOSITION,
                    basicCompositionActive: true,
                    isRedirect: false,
                    isObligatory:
                        globalcard?.items?.[iuudproduct].basicComposition[
                            iuudItem
                        ]?.isObligatory,
                    path: generatePath(
                        [
                            ...path_basic_composition,
                            {
                                name: globalcard.items?.[iuudItem].title,
                                iuud: iuudItem,
                                type: ITEM_TYPE,
                            },
                        ],
                        true
                    ),
                },
            ];
        }
    );
    workflowBasic = [
        ...workflowBasic,
        {
            title: "COMPOSITION DE BASE",
            Items: listItems,
            uuidStep: BASIC_COMPOSITION_TYPE,
            image: "./images_order/iconecompobase.png",
            iuudproduct,
            isRedirect: false,
            type: BASIC_COMPOSITION,
            previousModifier: iuudMD5,
            override: {},
            isActive: false,
            isCompleted: false,
            minNbrOfChoices: 0,
            maxNbrOfChoices: 0,
            NbrOfChoices: 0,
            path: generatePath(path, false),
        },
    ];
    return workflowBasic;
};
export const getlistitems = (
    iuudStep,
    iuudMD5,
    path,
    typeModifier,
    currentStep,
    globalcard,
    selectedSaleModeValue
) => {
    let listItems = [];

    Object.entries(currentStep?.stepItems)
        .sort((a, b) => a[1].rank - b[1].rank)
        .forEach(([iuud]) => {
            /// product is modifier
            const modifier = globalcard.modifier[iuudMD5][typeModifier][
                iuudStep
            ].itemModifiers[iuud]
                ? globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                      .itemModifiers[iuud]
                : "";
            //** path item */
            let pathitem = {
                name: globalcard.items?.[iuud].title,
                iuud: iuud,
                type: globalcard.items?.[iuud].isRedirect
                    ? CATEGORY_TYPE
                    : ITEM_TYPE,
            };

            if (
                Object.prototype.hasOwnProperty.call(
                    globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                        .itemModifiers,
                    iuud
                )
            ) {
                listItems = [
                    ...listItems,
                    {
                        ...globalcard.items?.[iuud],
                        iuud: iuud,
                        type: ITEM_TYPE,
                        isStepItem: true,
                        // ! to verify kiosk visibility step override
                        // KioskVisibility:
                        //     currentStep?.stepItems[iuud].visibilityInfo,
                        maxNbrOfChoicesInStep:
                            currentStep?.stepItems[iuud].maxNbrOfChoicesByItems,
                        minNbrOfChoicesInStep:
                            currentStep?.stepItems[iuud].minNbrOfChoicesByItems,
                        nbrOfChoicesWithspecialPrice:
                            currentStep?.stepItems[iuud]
                                .nbrOfChoicesWithspecialPrice,
                        nbrOfChoicesWithStepPrice:
                            currentStep?.stepItems[iuud]
                                .nbrOfChoicesWithItemPriceInStep,
                        color: currentStep?.stepItems[iuud].color,
                        fidelity:
                            process.env.REACT_APP_CATALOGUE_TYPE == ETK_CESAR
                                ? globalcard.items?.[iuud].fidelity
                                : currentStep?.stepItems[iuud].fidelity,
                        specialPrice: currentStep?.stepItems[iuud].specialPrice,
                        priceOfItemInStep:
                            currentStep?.stepItems[iuud].priceOfItemInStep,
                        modifier,
                        StepPriceRule: currentStep.specificOption.isNext
                            ? IS_NEXT_IN_LIST
                            : currentStep.specificOption.isCheapest
                            ? ISCHEAPEST
                            : currentStep.specificOption.isExpensive
                            ? ISEXPENSIVE
                            : ISCHEAPEST,
                        path: generatePath([...path, pathitem], true),
                        haveWorkflow:
                            globalcard.modifier[modifier]?.steps &&
                            Object.keys(globalcard.modifier[modifier]?.steps)
                                .length > 0
                                ? true
                                : false,

                        haveAdditionalSale:
                            globalcard.modifier[modifier]?.add &&
                            Object.keys(globalcard.modifier[modifier]?.add)
                                .length > 0
                                ? true
                                : false,

                        stepTitle: currentStep.title,
                        visibilityInfo:
                            currentStep?.stepItems[iuud].visibilityInfo,
                        KioskVisibility: kioskVisibilityRefactor(
                            currentStep?.stepItems[iuud].visibilityInfo
                                .isVisible,
                            currentStep?.stepItems[iuud].visibilityInfo.default
                        ),
                    },
                ];
            } else {
                listItems = [
                    ...listItems,
                    {
                        ...globalcard.items?.[iuud],
                        iuud,
                        type: ITEM_TYPE,
                        isStepItem: true,
                        fidelity:
                            process.env.REACT_APP_CATALOGUE_TYPE == ETK_CESAR
                                ? globalcard.items?.[iuud].fidelity
                                : currentStep?.stepItems[iuud].fidelity,
                        maxNbrOfChoicesInStep:
                            currentStep?.stepItems[iuud].maxNbrOfChoicesByItems,
                        minNbrOfChoicesInStep:
                            currentStep?.stepItems[iuud].minNbrOfChoicesByItems,
                        nbrOfChoicesWithspecialPrice:
                            currentStep?.stepItems[iuud]
                                .nbrOfChoicesWithspecialPrice,
                        nbrOfChoicesWithStepPrice:
                            currentStep?.stepItems[iuud]
                                .nbrOfChoicesWithItemPriceInStep,
                        color: currentStep?.stepItems[iuud].color,
                        specialPrice: currentStep?.stepItems[iuud].specialPrice,
                        priceOfItemInStep:
                            currentStep?.stepItems[iuud].priceOfItemInStep,
                        StepPriceRule: currentStep.specificOption.isNext
                            ? IS_NEXT_IN_LIST
                            : currentStep.specificOption.isCheapest
                            ? ISCHEAPEST
                            : currentStep.specificOption.isExpensive
                            ? ISEXPENSIVE
                            : ISCHEAPEST,
                        hidePrice: currentStep?.specificOption.zeroPrice,
                        modifier,
                        path: generatePath([...path, pathitem], true),
                        haveWorkflow:
                            globalcard.modifier[modifier]?.steps &&
                            Object.keys(globalcard.modifier[modifier]?.steps)
                                .length > 0
                                ? true
                                : false,

                        haveAdditionalSale:
                            globalcard.modifier[modifier]?.add &&
                            Object.keys(globalcard.modifier[modifier]?.add)
                                .length > 0
                                ? true
                                : false,
                        stepTitle: currentStep.title,
                        visibilityInfo:
                            currentStep?.stepItems[iuud].visibilityInfo,
                        KioskVisibility: kioskVisibilityRefactor(
                            currentStep?.stepItems[iuud].visibilityInfo
                                .isVisible,
                            currentStep?.stepItems[iuud].visibilityInfo.default
                        ),
                    },
                ];
            }
        });

    if (
        currentStep.specificOption?.noButton &&
        currentStep.minNbrOfChoices === 0
    ) {
        listItems.push({
            type: NO_ThANKS_CARD,
            visibilityInfo: { isVisible: true },
            KioskVisibility: { [selectedSaleModeValue]: true },
        });
    }
    // list items in step
    return listItems;
};

export const GetWorkflowData = (
    product,
    workflowData,
    globalcard,
    orderWorkflowItemSlice,
    selectedSaleModeValue
) => {
    let iuudproduct = product.iuud;
    const iuudMD5 = product.modifier;
    let workflow = [];

    // ** path in workflow
    let pathStep = getPathStep(workflowData);

    // ** get basic_composition *//
    if (product?.haveBasicComposition) {
        let pathBasicComposition = [...pathStep];

        if (pathBasicComposition.length > 0) {
            pathBasicComposition.push({
                name: COMPOSITIONS,
                iuud: COMPOSITIONS,
                type: COMPOSITIONS,
            });
            pathBasicComposition.push({
                name: product.title,
                iuud: product.iuud,
                type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
            });
        }

        workflow = getBasicCompositionStep(
            pathBasicComposition,
            product,
            globalcard
        );
    }
    // **   get list of uuid step

    if (iuudMD5 && product?.haveWorkflow) {
        Object.entries(globalcard.modifier[iuudMD5]?.steps)
            .sort((a, b) => a[1].rank - b[1].rank)
            .forEach(([iuudStep]) => {
                const currentStep =
                    globalcard.modifier[iuudMD5]?.steps[iuudStep]?.overrides &&
                    Object.keys(
                        globalcard.modifier[iuudMD5]?.steps[iuudStep].overrides
                    ).length > 0
                        ? globalcard.modifier[iuudMD5]?.steps[iuudStep]
                              .overrides
                        : globalcard?.steps?.[iuudStep];
                let listItems = [];
                //** path step */
                let path = [...pathStep];
                let titleStep =
                    currentStep.displayName.Default.nameDefault !== ""
                        ? currentStep.displayName.Default.nameDefault
                        : currentStep.title;
                if (product.modifier && workflowData.length > 0) {
                    path.push({
                        name:
                            product.title +
                            orderWorkflowItemSlice.item.iuudworkflow,
                        iuud: product.iuud,
                        type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                    });
                } else {
                    path.push({
                        name: titleStep,
                        iuud: iuudStep,
                        type: STEP_TYPE,
                    });
                }
                // get list uuid items in step
                listItems = getlistitems(
                    iuudStep,
                    iuudMD5,
                    path,
                    STEP_TYPE,
                    currentStep,
                    globalcard,
                    selectedSaleModeValue
                );
                if (!listItems) return;
                workflow = [
                    ...workflow,
                    {
                        title: titleStep,
                        Items: listItems,
                        uuidStep: iuudStep,
                        iuudproduct,
                        type: product.isRedirect ? CATEGORY_TYPE : STEP_TYPE,
                        image: currentStep.urlImage,

                        previousModifier: iuudMD5,
                        override:
                            Object.keys(
                                globalcard.modifier[iuudMD5].steps[iuudStep]
                                    ?.overrides || {}
                            ).length > 0
                                ? globalcard.modifier[iuudMD5].steps[iuudStep]
                                      ?.overrides
                                : {},
                        minNbrOfChoices: currentStep.minNbrOfChoices,
                        maxNbrOfChoices: currentStep.maxNbrOfChoices,
                        NbrOfChoices: getNumberOfChoice(
                            path,
                            listItems,
                            orderWorkflowItemSlice
                        ),
                        path: generatePath(path, false),
                        nbrOfChoicesWithStepPrice:
                            currentStep.nbrOfChoicesWithItemPrice,
                        nbrOfChoicesWithspecialPrice:
                            currentStep.nbrOfChoicesWithspecialPrice,
                        selectedChoicesWithspecialPrice: 0,
                        selectedChoicesWithStepPrice: 0,
                    },
                ];
            });
    }
    return workflow;
};
export const getPathWithStateCopy = (state, payload) => {
    const newState = { ...state }; //JSON.parse(JSON.stringify(state));
    let path = payload.path.map((elm) => {
        return elm?.name !== undefined ? elm.name : elm;
    });
    return { newState, path };
};
