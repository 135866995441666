import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { setIsEntitySelectionModalOpened } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsAppPaused,
    setIsCloseAppModalOpened,
    setIsConfirmationStandbyStateModalOpened,
    setIsRestartModalOpened,
    setIsShutdownModalOpened,
} from "@pages/Kiosk/store";

import "./index.css";

export function MenuModalWrapper(): JSX.Element {
    const { isMenuModalOpened, isAppPaused } = useSnapshot(kioskStore);

    return (
        <Modal
            isOpen={isMenuModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                    width: "250px",
                    marginTop: "1160px",
                    marginLeft: "145px",
                    borderRadius: "10px",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "430px",
                    width: "330px",
                    fontSize: "50px",
                    zIndex: 2,
                    background: "#2c2b2b 0% 0% no-repeat padding-box",
                    border: "1px solid #FFFFFF",

                    borderRadius: "12px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    opacity: 0.9,
                    boxShadow: "0px 3px 6px #00000029",
                    backdropFilter: "blur(30px)",
                },
            }}
        >
            <div
                style={{
                    height: "35px",
                    font: "600 18px / 24px Segoe UI",
                    color: "white",
                    marginLeft: "30px",
                    marginTop: "10px",
                }}
            >
                {t("Menu")}
            </div>
            <div
                style={{
                    display: "grid",
                    gap: "10px",
                    margin: "10px",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
                }}
            >
                <div>
                    {isAppPaused ? (
                        <div
                            className="menu-buttons__clz"
                            style={{ gap: "5px" }}
                            onClick={() => {
                                setIsAppPaused(false);
                            }}
                        >
                            <img className="ml-2" src="./images/play.png" />
                            {t("Put in production")}
                        </div>
                    ) : (
                        <div
                            className="menu-buttons__clz"
                            style={{ gap: "5px" }}
                            onClick={() => {
                                setIsConfirmationStandbyStateModalOpened(true);
                            }}
                        >
                            <img
                                className="ml-2"
                                src="./images/pause-button.png"
                            />
                            {t("Pause")}
                        </div>
                    )}
                </div>

                <div className="menu-buttons__clz" style={{ opacity: 0.6 }}>
                    <img
                        className="ml-2"
                        src="./images/data-synchronization.png"
                    />
                    {t("Update the app")}
                </div>
                <div
                    className="menu-buttons__clz"
                    onClick={() => {
                        setIsEntitySelectionModalOpened(true);
                    }}
                >
                    <img
                        className="ml-2"
                        src="./images/data-synchronization.png"
                    />
                    {t("Change app")}
                </div>
                <div
                    className="menu-buttons__clz"
                    onClick={() => {
                        setIsCloseAppModalOpened(true);
                    }}
                >
                    <img className="ml-2" src="./images/on-off-button.png" />
                    {t("Close app")}
                </div>
                <div
                    className="menu-buttons__clz"
                    onClick={() => {
                        setIsRestartModalOpened(true);
                    }}
                >
                    <img className="ml-2" src="./images/restart.png" />
                    {t("Restart the post")}
                </div>
                <div
                    className="menu-buttons__clz"
                    onClick={() => {
                        setIsShutdownModalOpened(true);
                    }}
                >
                    <img className="ml-2" src="./images/on-off-button.png" />
                    {t("Switch off the post")}
                </div>
            </div>
        </Modal>
    );
}
