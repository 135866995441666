import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function PrmBackgroundWrapper(): JSX.Element {
    const {
        project: {
            files: images,
            template: { content },
        },
        kioskWays,
        navigationIndex,
    } = useSnapshot(kioskStore);

    const prmBackgroundId = (
        (content.generalDesign as GeneralDesignType).prm as GeneralDesignPrmType
    ).id;

    const prmContent = images.filter((file) => file.id === prmBackgroundId)[0];

    const prmBackgroundImageContent = getImageContentById(
        prmBackgroundId as string,
        images
    );
    const imageSrc =
        prmBackgroundImageContent?.includes("http") ||
        prmBackgroundImageContent?.includes("https")
            ? prmBackgroundImageContent
            : `./images/${prmBackgroundImageContent}`;

    return (
        <div
            style={{
                height:
                    kioskWays[navigationIndex].name === "orderTaking"
                        ? "100%"
                        : "unset",
            }}
        >
            {prmContent.type?.includes("video") ? (
                <video
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    className="w-100 h-100"
                    style={{
                        objectFit: "cover",
                    }}
                >
                    <source src={imageSrc} type="video/mp4" />
                </video>
            ) : (
                <img
                    className="w-100 h-100"
                    style={{ backgroundSize: "cover" }}
                    src={imageSrc}
                    alt="prm-background-image"
                />
            )}
        </div>
    );
}
