import styled from "styled-components";

export type PropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isLanguagesActive: boolean;
    isConfigOptionsActive: boolean;
    isActionButtonsActive: boolean;
};

export const ContainerWrapper = styled.div<PropsType>`
    display: grid;
    position: relative;
    height: 100%;

    grid-template-rows: ${({
        isHeaderActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isTopBannerActive,
        isActionButtonsActive,
    }: PropsType) =>
        isLanguagesActive &&
        isHeaderActive &&
        isConfigOptionsActive &&
        isTopBannerActive &&
        isActionButtonsActive
            ? "11fr 12fr 51fr 11fr 10fr 5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 12fr 62fr 10fr 5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "89fr 11fr"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "12fr 62fr 11fr 10fr 5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 89fr;"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "12fr 73fr 10fr 5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "11fr 78fr 11fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "11fr 12fr 56fr 11fr 10fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "95fr 5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 12fr 67fr 10fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "0.84fr 0.11fr 0.05fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "12fr 67fr 11fr 10fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 84fr 5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "12fr 78fr 10fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "11fr 73fr 11fr 5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "11fr 63fr 11fr 10fr 5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "12fr 88fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 74fr 10fr 5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "12fr 77fr 11fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "74fr 11fr 10fr 5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 12fr auto"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "auto 10fr 5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "11fr 12fr 66fr 11fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "11fr 68fr 11fr 10fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "12fr 83fr 5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 79fr 10fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "12fr 72fr 11fr 5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "79fr 11fr 10fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "11fr 12fr 72fr 5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "90fr 10fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "11fr 12fr 61fr 11fr 5fr"
            : "auto"};
`;
