import { COMPOSITIONS } from "@constants";
import { Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

import ClosecircleIcon from "@components/OrderTaking/Components/Icons/ClosecircleIcon";
import { groupWorkflowShopCard } from "@components/OrderTaking/Helpers";

import { ShowDetailsCartProduct } from "./ShowDetailsCartProduct";

import "swiper/swiper.min.css";

export default function ShowDetailsDialog({
    open,
    onClose,
    path,
    title,
    setTitle,
    setPath,
    isBackActive,
    setIsBackActive,
}) {
    const { t } = useTranslation();
    let workflowCards = {};
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    if (path?.length > 0) {
        let Datapath = [];
        path.forEach((elm) => {
            Datapath.push(elm.name);
        });

        Datapath.push(COMPOSITIONS);
        let objectTostep = Datapath.reduce((prev, key) => {
            return prev[key] || {};
        }, orderWorkflowItemSlice.item.shopCart);
        workflowCards = objectTostep;
    }
    const handleBackClick = () => {
        let Data = [...path];
        Data.pop();
        Data.pop();
        Data.length <= 2 ? setPath([]) : setPath(Data);
        Data.length > 0 && setTitle(Data[Data.length - 1].name);
        Data.length <= 3 ? setIsBackActive(false) : setIsBackActive(true);
    };
    const groupedItems = groupWorkflowShopCard(workflowCards);
    function handleOnCloseEvent() {
        onClose();
    }
    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "10px!important",
                    alignItems: "start !important",
                    justifyContent: "start !important",
                    paddingTop: "15px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "420px !important",
                    width: "95%!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderRadius: "50px !important",
                    backgroundColor: "#FFFFFF!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: "22% !important",
                    right: "unset!important",
                    left: "unset!important",
                    margin: "15px !important",
                    paddingLeft: "10px !important",
                    paddingRight: "10px !important",
                },
            }}
        >
            <div className="d-flex" style={{ width: "100%" }}>
                <span
                    style={{
                        font: "normal normal 600 35px/47px Segoe UI",
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    Souhaitez-vous modifier votre {title} ?
                </span>
                <div
                    className="d-flex justify-content-end align-items-end px-3"
                    onClick={handleOnCloseEvent}
                >
                    <ClosecircleIcon />
                </div>
            </div>

            <div className="d-flex" style={{ width: "100%" }}>
                {isBackActive && (
                    <div className="d-flex mx-3" style={{ width: "10%" }}>
                        <div
                            className="d-flex flex-column  justify-content-center align-items-center"
                            style={{
                                width: "126px",
                                height: "217px",
                                background:
                                    " #FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #0000001A",
                                border: "1px solid #7B7B7B",
                                borderRadius: "10px",
                            }}
                            onClick={handleBackClick}
                        >
                            <div className="arrow-left__clz"></div>
                            <span
                                style={{
                                    font: " normal normal 600 20px/27px Segoe UI",
                                }}
                            >
                                {t("Back")}
                            </span>
                        </div>
                    </div>
                )}
                <Swiper
                    slidesPerView={3}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="swiper-container__clz"
                    style={{ width: "100% !important" }}
                >
                    <>
                        {groupedItems.map((item) => {
                            return (
                                <SwiperSlide
                                    className="slide-popup"
                                    key={item.iuud}
                                >
                                    <ShowDetailsCartProduct
                                        item={item}
                                        setPath={setPath}
                                        setTitle={setTitle}
                                        path={path}
                                        onClose={onClose}
                                        setIsBackActive={setIsBackActive}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </>
                </Swiper>
            </div>
        </Dialog>
    );
}
ShowDetailsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    path: PropTypes.array,
    title: PropTypes.string,
    item: PropTypes.Object,
    setTitle: PropTypes.func,
    setPath: PropTypes.func,
    setIsBackActive: PropTypes.func,
    isBackActive: PropTypes.bool,
};
