import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import {
    kioskStore,
    setIsAnimationIn,
    setIsLogoutProblemModalOpened,
    setIsStandbyModalOpened,
} from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        isSubStepTopBannerActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isStandbyModalOpened,
        isHelpModalOpened,
        isSubStepActionButtonsActive,
        subStepActionButtons,
        timeout,
        chosenTransition,
        isAnimationIn,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isAuthenticationErrorModalOpened,
        isConfirmationModalOpened,
        selectedAuthMode,
        navigationIndex,
        isLogoutProblemModalOpened,
    } = useSnapshot(kioskStore);

    const activeActionsButtons = subStepActionButtons
        .filter((item) => {
            return item.active === true;
        })
        .filter((item: ActionsButtonsDesignItemType) => {
            const VALIDATE_BUTTON_ROLE = 1;
            const IGNORE_BUTTON_ROLE = 2;
            const BACK_BUTTON_ROLE = 3;

            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        })
        .sort((a, b) => {
            return a.role > b.role ? -1 : 1;
        });

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
        setIsLogoutProblemModalOpened(false);
    }, []);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
                <UsualModeSubStepContainerWrapper
                    isSubStepTopBannerActive={isSubStepTopBannerActive}
                    isSubStepIconActive={isSubStepIconActive}
                    isSubStepInformationMessageActive={
                        isSubStepInformationMessageActive
                    }
                    isSubStepActionButtonsActive={isSubStepActionButtonsActive}
                    isLanguagesActive={isLanguagesActive}
                    isConfigOptionsActive={isConfigOptionsActive}
                    ref={(ref) => setContainerRef(ref)}
                >
                    <IdleDetection
                        timeout={timeout * 1000}
                        once={true}
                        onIdle={() => {
                            if (navigationIndex !== 0) {
                                setIsStandbyModalOpened(true);
                            }
                        }}
                    />

                    {isHelpModalOpened === true ? <HelpModalWrapper /> : null}

                    {isStandbyModalOpened === true ? (
                        <StandbyModalWrapper />
                    ) : null}

                    {isAuthenticationErrorModalOpened === true ? (
                        <AuthenticationErrorModal />
                    ) : null}

                    {containerRef ? (
                        isConfirmationModalOpened === true ? (
                            <ConfirmationModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {isLogoutProblemModalOpened === true ? (
                        <LogoutProblemModal />
                    ) : null}

                    <SubStepsLogoWrapper />

                    {isSubStepIconActive ? <SubStepsIconWrapper /> : null}

                    {isSubStepInformationMessageActive ? (
                        <SubStepsInformationMessage />
                    ) : null}

                    <div className="d-flex justify-content-center align-items-center">
                        <UsualNumericKeyboardWrapper />
                    </div>

                    {isSubStepActionButtonsActive ? (
                        <ActionsButtonsWrapper
                            actionsButtonsItems={activeActionsButtons}
                        />
                    ) : null}

                    {isLanguagesActive ? <UsualModeLanguagesWrapper /> : null}

                    {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
                </UsualModeSubStepContainerWrapper>
            </PreviewBackgroundWrapper>
        </CSSTransition>
    );
}
