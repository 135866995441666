import { IS_NEXT_IN_LIST, ISCHEAPEST, ISEXPENSIVE } from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import { produce } from "immer";
import { set, unset } from "lodash";

import {
    getStepDetails,
    getStepPrice,
    maxPriceWithoutSpecialPrice,
    minPriceWithoutSpecialPrice,
    minPriceWithStepPrice,
    setObj,
} from "../../Helpers";

const initialState = {
    item: {
        iuudorder: "",
        iuud: "",
        name: "",
        quantity: 1,
        price: 0,
        urlImage: "",
        iuudworkflow: "",
        categoryParent: "",
        workflow: {},
        shopCart: {},
        isModification: false,
        selectedOptions: [],
    },
    isModification: false,
};

export const orderWorkflowItemSlice = createSlice({
    name: "orderWorkflowItemSlice",
    initialState,
    reducers: {
        addToOrderworkflow: (state, { payload }) => {
            state.item = {
                ...payload,
                defaultPrice: state.isModification
                    ? payload.defaultPrice
                    : payload.price,
            };

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: [],
                    selectedOptionsUuid: [],
                    newPrice: 0,
                    tva: 0,
                })
            );
        },

        addWorkFlowItemShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });
                let objectToModify = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);
                if (Object.keys(objectToModify).length === 0) {
                    setObj(draftState.shopCart, path.join(":"), action.payload);
                } else {
                    objectToModify.workflow = action.payload.workflow;
                    objectToModify.quantity = +objectToModify.quantity + 1;
                }

                draftState.price = draftState.price + action.payload.price;
            });

            // const { newState, path } = getPathWithStateCopy(
            //     state.item,
            //     action.payload
            // );

            // let objectToModify = path.reduce((prev, key) => {
            //     return prev[key] || {};
            // }, newState.shopCart);

            // if (Object.keys(objectToModify).length === 0) {
            //     setObj(newState.shopCart, path.join(":"), action.payload);
            // } else {
            //     objectToModify.workflow = action.payload.workflow;
            //     objectToModify.quantity = +objectToModify.quantity + 1;
            // }

            // newState.price = newState.price + action.payload.price;

            // state.item = newState;

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: [],
                    selectedOptionsUuid: [],
                    newPrice: 0,
                    tva: 0,
                })
            );
        },

        removeFromWorkFlowItemShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });

                const stepPath = [...path];
                stepPath.length = path.length - 1;

                let objectToModify = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                if (Object.keys(objectToModify).length === 0) {
                    setObj(draftState.shopCart, path.join("."), action.payload);
                    draftState.price = draftState.price - action.payload.price;
                } else {
                    objectToModify.quantity = +objectToModify.quantity - 1;
                    draftState.price = draftState.price - objectToModify.price;
                }

                if (objectToModify.quantity === 0) {
                    unset(draftState.shopCart, path);
                }
                let stepComposition = stepPath.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                if (Object.keys(stepComposition).length === 0) {
                    stepPath.length = stepPath.length - 1;
                    unset(draftState.shopCart, stepPath);
                    getStepDetails(stepComposition);
                    state.item = draftState;
                    return;
                }

                let newData = {};
                let menuPrice = 0;

                if (objectToModify.withSpecialPrice) {
                    const stepPrice = getStepPrice(stepComposition);

                    menuPrice = draftState.price - stepPrice;

                    switch (objectToModify.StepPriceRule) {
                        case ISCHEAPEST:
                            newData = minPriceWithoutSpecialPrice(
                                "withSpecialPrice",
                                "specialPrice",
                                stepComposition
                            );
                            break;
                        case ISEXPENSIVE:
                            newData = maxPriceWithoutSpecialPrice(
                                "withSpecialPrice",
                                "specialPrice",
                                stepComposition
                            );
                            break;
                        case IS_NEXT_IN_LIST:
                            newData = minPriceWithoutSpecialPrice(
                                "withSpecialPrice",
                                "specialPrice",
                                stepComposition
                            );
                            break;
                        default:
                            newData = minPriceWithoutSpecialPrice(
                                "withSpecialPrice",
                                "specialPrice",
                                stepComposition
                            );
                    }

                    stepComposition = { ...stepComposition, ...newData };
                    if (process.env.REACT_APP_CATALOGUE_TYPE == 3) {
                        const newStepPrice = getStepPrice(stepComposition);
                        menuPrice += newStepPrice;
                    }
                    draftState.price = menuPrice;

                    set(
                        draftState.shopCart,
                        stepPath.join("."),
                        stepComposition
                    );
                } else if (objectToModify.withStepPrice) {
                    // const stepPrice = getStepPrice(stepComposition);
                    // menuPrice = draftState.price - stepPrice;
                    switch (objectToModify.StepPriceRule) {
                        case ISCHEAPEST:
                            newData = minPriceWithoutSpecialPrice(
                                "withSpecialPrice",
                                "specialPrice",
                                stepComposition
                            );
                            break;
                        case ISEXPENSIVE:
                            newData = maxPriceWithoutSpecialPrice(
                                "withSpecialPrice",
                                "specialPrice",
                                stepComposition
                            );
                            break;
                        case IS_NEXT_IN_LIST:
                            newData = minPriceWithoutSpecialPrice(
                                "withSpecialPrice",
                                "specialPrice",
                                stepComposition
                            );

                            break;
                        default:
                            newData = minPriceWithStepPrice(stepComposition);
                    }

                    stepComposition = { ...stepComposition, ...newData };
                    // if (process.env.REACT_APP_CATALOGUE_TYPE == 3) {
                    //     const newStepPrice = getStepPrice(stepComposition);
                    //     menuPrice += newStepPrice;
                    // }
                    // draftState.price = menuPrice;
                    set(
                        draftState.shopCart,
                        stepPath.join("."),
                        stepComposition
                    );
                }
                getStepDetails(stepComposition);
            });

            // const { newState, path } = getPathWithStateCopy(
            //     state.item,
            //     action.payload
            // );

            // const stepPath = [...path];
            // stepPath.length = path.length - 1;

            // let objectToModify = path.reduce((prev, key) => {
            //     return prev[key] || {};
            // }, newState.shopCart);

            // if (Object.keys(objectToModify).length === 0) {
            //     setObj(newState.shopCart, path.join("."), action.payload);
            //     newState.price = newState.price - action.payload.price;
            // } else {
            //     objectToModify.quantity = +objectToModify.quantity - 1;
            //     newState.price = newState.price - objectToModify.price;
            // }

            // if (objectToModify.quantity === 0) {
            //     unset(newState.shopCart, path);
            // }
            // let stepComposition = stepPath.reduce((prev, key) => {
            //     return prev[key] || {};
            // }, newState.shopCart);

            // if (Object.keys(stepComposition).length === 0) {
            //     stepPath.length = stepPath.length - 1;
            //     unset(newState.shopCart, stepPath);
            //     getStepDetails(stepComposition);
            //     state.item = newState;
            //     return;
            // }

            // let newData = {};
            // let menuPrice = 0;

            // if (objectToModify.withSpecialPrice) {
            //     const stepPrice = getStepPrice(stepComposition);

            //     menuPrice = newState.price - stepPrice;

            //     switch (objectToModify.StepPriceRule) {
            //         case ISCHEAPEST:
            //             newData = minPriceWithoutSpecialPrice(
            //                 "withSpecialPrice",
            //                 "specialPrice",
            //                 stepComposition
            //             );
            //             break;
            //         case ISEXPENSIVE:
            //             newData = maxPriceWithoutSpecialPrice(
            //                 "withSpecialPrice",
            //                 "specialPrice",
            //                 stepComposition
            //             );
            //             break;
            //         case IS_NEXT_IN_LIST:
            //             newData = minPriceWithoutSpecialPrice(
            //                 "withSpecialPrice",
            //                 "specialPrice",
            //                 stepComposition
            //             );
            //             break;
            //         default:
            //             newData = minPriceWithoutSpecialPrice(
            //                 "withSpecialPrice",
            //                 "specialPrice",
            //                 stepComposition
            //             );
            //     }

            //     stepComposition = { ...stepComposition, ...newData };
            //     if (process.env.REACT_APP_CATALOGUE_TYPE == 3) {
            //         const newStepPrice = getStepPrice(stepComposition);
            //         menuPrice += newStepPrice;
            //     }
            //     newState.price = menuPrice;

            //     set(newState.shopCart, stepPath.join("."), stepComposition);
            // } else if (objectToModify.withStepPrice) {
            //     // const stepPrice = getStepPrice(stepComposition);
            //     // menuPrice = newState.price - stepPrice;
            //     switch (objectToModify.StepPriceRule) {
            //         case ISCHEAPEST:
            //             newData = minPriceWithoutSpecialPrice(
            //                 "withSpecialPrice",
            //                 "specialPrice",
            //                 stepComposition
            //             );
            //             break;
            //         case ISEXPENSIVE:
            //             newData = maxPriceWithoutSpecialPrice(
            //                 "withSpecialPrice",
            //                 "specialPrice",
            //                 stepComposition
            //             );
            //             break;
            //         case IS_NEXT_IN_LIST:
            //             newData = minPriceWithoutSpecialPrice(
            //                 "withSpecialPrice",
            //                 "specialPrice",
            //                 stepComposition
            //             );

            //             break;
            //         default:
            //             newData = minPriceWithStepPrice(stepComposition);
            //     }

            //     stepComposition = { ...stepComposition, ...newData };
            //     // if (process.env.REACT_APP_CATALOGUE_TYPE == 3) {
            //     //     const newStepPrice = getStepPrice(stepComposition);
            //     //     menuPrice += newStepPrice;
            //     // }
            //     // newState.price = menuPrice;
            //     set(newState.shopCart, stepPath.join("."), stepComposition);
            // }
            // getStepDetails(stepComposition);

            // state.item = newState;
        },
        deleteBasicCompositionFromShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });
                path.length > 0 && unset(draftState.shopCart, path);
            });

            // const { newState, path } = getPathWithStateCopy(
            //     state.item,
            //     action.payload
            // );

            // path.length > 0 && unset(newState.shopCart, path);

            // state.item = newState;
        },
        deleteWorkFlowStepShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });
                path.pop();
                let objectToModify = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                if (Object.keys(objectToModify).length > 0) {
                    unset(draftState.shopCart, path);
                }

                draftState.price = draftState.price - action.payload.price;
            });

            // const { newState, path } = getPathWithStateCopy(
            //     state.item,
            //     action.payload
            // );

            // path.pop();
            // let objectToModify = path.reduce((prev, key) => {
            //     return prev[key] || {};
            // }, newState.shopCart);

            // if (Object.keys(objectToModify).length > 0) {
            //     unset(newState.shopCart, path);
            // }

            // newState.price = newState.price - action.payload.price;
            // state.item = newState;
        },

        setiuudWorkflow: (state, action) => {
            state.item.iuudworkflow = action.payload;
        },
        setIsModification: (state, action) => {
            state.isModification = action.payload;
        },

        ClearWorkflowItem: () => initialState,
    },
});

export const {
    addWorkFlowItemShopCart,
    removeFromWorkFlowItemShopCart,
    addToOrderworkflow,
    ClearWorkflowItem,
    deleteWorkFlowStepShopCart,
    deleteBasicCompositionFromShopCart,
    setiuudWorkflow,
    setIsModification,
} = orderWorkflowItemSlice.actions;

export default orderWorkflowItemSlice.reducer;
