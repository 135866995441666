import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore } from "@pages/Kiosk/store";

import { ScrollContainer } from "@components/ScrollContainer";

import { ImageContent } from "./ImageContent";

export function MainContentWrapper(): JSX.Element {
    const { mainContentItems, customerLanguage } = useSnapshot(kioskStore);

    const activeItems = (
        (mainContentItems as PaymentMainContentItemType[]).filter(
            (item: PaymentMainContentItemType) =>
                item.shortName === "creditCard"
        )[0].languages[customerLanguage.name].paymentInstructions
            .TPAValina as TPAValinaType
    ).items;

    const activeItemsLength = activeItems.length;

    return (
        <React.Fragment>
            {activeItemsLength <= 2 ? (
                <div
                    className="d-flex justify-content-center p-2"
                    style={{
                        gap: "100px",
                    }}
                >
                    {activeItems?.map(
                        (item: TPAValinaItemType, index: number) => (
                            <ImageContent
                                item={item}
                                key={index}
                                activeItemsLength={activeItemsLength}
                            />
                        )
                    )}
                </div>
            ) : activeItemsLength > 2 && activeItemsLength <= 4 ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ gap: "25px" }}
                >
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeItems
                            ?.slice(0, 2)
                            .map((item: TPAValinaItemType, index: number) => (
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            ))}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeItems
                            ?.slice(2)
                            .map((item: TPAValinaItemType, index: number) => (
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            ))}
                    </div>
                </div>
            ) : (
                <ScrollContainer
                    style={{
                        maxHeight: "950px",
                        overflowY: "scroll",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2,1fr)",
                            gridTemplateRows: `repeat(${Math.ceil(
                                activeItems.length / 2
                            )},1fr)`,
                            rowGap: "35px",
                            columnGap: "25px",
                            padding: "50px 0px 50px 0px",
                        }}
                    >
                        {activeItems.map((item: TPAValinaItemType) => (
                            <ImageContent
                                item={item}
                                activeItemsLength={activeItemsLength}
                                key={item.id}
                            />
                        ))}
                    </div>
                </ScrollContainer>
            )}
        </React.Fragment>
    );
}
