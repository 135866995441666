import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import {
    setIsAuthenticationErrorModalOpened,
    kioskStore as store,
} from "@pages/Kiosk/store";

export function AuthenticationErrorModal(): JSX.Element {
    const {
        isPrm,
        isAuthenticationErrorModalOpened,
        project: {
            files: images,
            template: {
                content: { generalDesign, actionsButtons },
                pages,
            },
        },
        selectedAuthMode,
        customerLanguage,
    } = useSnapshot(store);

    return (
        <Modal
            isOpen={isAuthenticationErrorModalOpened}
            ariaHideApp={false}
            onRequestClose={() => {
                setIsAuthenticationErrorModalOpened(
                    !isAuthenticationErrorModalOpened
                );
            }}
            contentLabel="auth-error-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },

                content: {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "30px",
                    color: "black",
                    height: "1230px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    gap: "50px",
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "300px" }}
                onClick={() => {
                    setIsAuthenticationErrorModalOpened(
                        !isAuthenticationErrorModalOpened
                    );
                }}
            >
                <img
                    src={getAlertActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType,
                        images,
                        customerLanguage.name
                    )}
                />
            </div>
            <div
                className="d-flex flex-column justify-content-between"
                style={{ height: "850px" }}
                onClick={() => {
                    setIsAuthenticationErrorModalOpened(
                        !isAuthenticationErrorModalOpened
                    );
                }}
            >
                <div className="d-flex flex-column" style={{ gap: "30px" }}>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <span style={{ fontSize: "60px" }}>
                            {t(pages.subSteps[selectedAuthMode].name)}
                        </span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center">
                        <span style={{ fontSize: "40px" }}>
                            {selectedAuthMode !== "emailAndPassword"
                                ? t(
                                      "Oups!! This number is not associated with a valid customer account"
                                  )
                                : t("Your email or password is incorrect")}
                        </span>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center w-100">
                    <span style={{ fontSize: "40px" }}>
                        {t("Touch the screen to continue")}
                    </span>
                </div>
            </div>
        </Modal>
    );
}
