import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workflowData: [],
};

export const workFlowDataSlice = createSlice({
    name: "workFlowDataSlice",
    initialState,
    reducers: {
        setWorkflowData: (state, action) => {
            state.workflowData = action.payload;
        },
        //@typescript-eslint/no-unused-vars
        setNextWorkflowStep: (state) => {
            state.workflowData[state.workflowData.length - 1].index += 1;
        },
        setPreviousWorkflowStep: (state) => {
            state.workflowData[state.workflowData.length - 1].index -= 1;
        },
        steWorkflowStepIndex: (state, { payload }) => {
            state.workflowData[state.workflowData.length - 1].index = payload;
        },
        steWorkflowStep: (state, { payload }) => {
            state.workflowData[state.workflowData.length - 1].index = payload;
        },
        toggleBasicCompositionitem: (state, { payload }) => {
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].Items[payload].basicCompositionActive =
                !state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].Items[payload].basicCompositionActive;
        },
        addWorkflowStepChoice: (state, { payload }) => {
            const {
                NbrOfChoices,
                selectedChoicesWithStepPrice,
                selectedChoicesWithspecialPrice,
                haveWorkflow,
            } = payload;
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].NbrOfChoices = NbrOfChoices;
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].selectedChoicesWithStepPrice = selectedChoicesWithStepPrice;
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].selectedChoicesWithspecialPrice = selectedChoicesWithspecialPrice;

            if (
                state.workflowData[state.workflowData.length - 1].index <
                    state.workflowData[state.workflowData.length - 1].step &&
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].maxNbrOfChoices === NbrOfChoices &&
                !haveWorkflow
            ) {
                state.workflowData[state.workflowData.length - 1].index += 1;
            }
        },
    },
});

export const {
    setWorkflowData,
    setNextWorkflowStep,
    setPreviousWorkflowStep,
    steWorkflowStepIndex,
    toggleBasicCompositionitem,
    addWorkflowStepChoice,
} = workFlowDataSlice.actions;

export default workFlowDataSlice.reducer;
