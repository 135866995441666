import React from "react";

export function useLatestRef<TValue>(
    value: TValue
): React.MutableRefObject<TValue> {
    const ref = React.useRef(value);

    React.useLayoutEffect(() => {
        ref.current = value;
    });

    return ref;
}
