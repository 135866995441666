import React from "react";

export const SynchronizeSvgIcon = (
    props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
            background: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        viewBox="0 0 512 512"
        width={512}
        height={512}
        {...props}
    >
        <path
            style={{
                fill: "#3cc",
            }}
            d="M256 0c-54.973 0-106.72 16.157-149.646 46.726C69.392 73.045 40.507 108.861 22 151.083V27.61c0-6.075-4.925-11-11-11s-11 4.925-11 11v145.339c0 6.075 4.925 11 11 11h145.339c6.075 0 11-4.925 11-11s-4.925-11-11-11H41.289C77.982 76.425 160.625 22 256 22c131.215 0 234 102.785 234 234 0 6.075 4.925 11 11 11s11-4.925 11-11C512 112.448 399.552 0 256 0z"
        />
        <path d="M501 328.051H355.661c-6.075 0-11 4.925-11 11s4.925 11 11 11h115.05C434.018 435.575 351.375 490 256 490 124.785 490 22 387.215 22 256c0-6.075-4.925-11-11-11s-11 4.925-11 11c0 143.552 112.448 256 256 256 54.973 0 106.72-16.157 149.647-46.726 36.961-26.319 65.846-62.136 84.353-104.357V484.39c0 6.075 4.925 11 11 11s11-4.925 11-11V339.051c0-6.075-4.925-11-11-11z" />
    </svg>
);
