import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { SpinnerWrapper } from "@components/common/SpinnerWrapper";

import { StandbyStep } from "@pages/Kiosk/Steps/StandbyStep";
import { kioskStore } from "@pages/Kiosk/store";

export function StepComponent(): JSX.Element {
    const { kioskWays, navigationIndex } = useSnapshot(kioskStore);
    const catalogueIsLoading = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.orderSlice.catalogueIsLoading,
        shallowEqual
    );
    const Component = kioskWays[navigationIndex]?.stepContent;
    if (catalogueIsLoading) {
        return <SpinnerWrapper />;
    }

    return Component !== undefined ? <Component /> : <StandbyStep />;
}
