import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export function Tag({ tag }) {
    const { secondaryColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                gap: "8px",
                // visibility: tag.isVisible ? "visible" : "hidden",
            }}
        >
            <div style={{ width: "45px", height: "45px" }}>
                <img
                    className="w-100 h-100 p-1"
                    style={{ borderRadius: "100%" }}
                    src={tag.imageUrl}
                />
            </div>
            <div
                style={{
                    color: tag.active == true ? secondaryColor : "black",
                    font: "normal normal 600 20px/27px Segoe UI",
                }}
            >
                {tag.title}
            </div>
        </div>
    );
}

Tag.propTypes = {
    tag: PropTypes.object,
};
