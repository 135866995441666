import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { kioskStore, setIsShutdownModalOpened } from "@pages/Kiosk/store";

export function ShutdownModalWrapper(): JSX.Element {
    const { isShutdownModalOpened } = useSnapshot(kioskStore);

    return (
        <Modal
            isOpen={isShutdownModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "1368px",
                    width: "914px",
                    marginTop: "275px",
                    marginLeft: "84px",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px",
                    color: "black",
                    height: "580px",
                    width: "606px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #c2bebe",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                    gap: "50px",
                }}
            >
                <div
                    style={{
                        textAlign: "left",
                        font: "normal normal bold 35px/55px Segoe UI",
                    }}
                >
                    {t("Confirmation")}
                </div>
                <div
                    style={{
                        textAlign: "center",
                        font: "normal normal normal 25px/33px Segoe UI",
                        marginLeft: "150px",
                        marginRight: "150px",
                    }}
                >
                    {t("Would you like to switch off your post ?")}
                </div>
                <div className="d-flex mt-5">
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        style={{
                            width: "179px",
                            height: "61px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "black",
                        }}
                        onClick={() => {
                            setIsShutdownModalOpened(false);
                        }}
                    >
                        {t("Back")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        style={{
                            width: "233px",
                            height: "61px",
                            background: "#0a0a0a 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "white",
                        }}
                        onClick={() => {
                            (
                                window as RendererWindow
                            ).electronAPI.sendShutdownEvent();
                        }}
                    >
                        {t("Confirm")}
                    </StyledButton>
                </div>
            </div>
        </Modal>
    );
}
