import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import React from "react";
import Modal from "react-modal";
import { useSnapshot } from "valtio";

import {
    setIsLoginErrorModalWrapperOpened,
    syncStore,
} from "@components/Synchronization/store";

import { setKeyboardInputsValues } from "@pages/Kiosk/store";

export function LoginErrorModalWrapper(): JSX.Element {
    const { isLoginErrorModalWrapperOpened } = useSnapshot(syncStore);

    return (
        <Modal
            isOpen={isLoginErrorModalWrapperOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "1368px",
                    width: "914px",
                    marginTop: "275px",
                    marginLeft: "84px",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "580px",
                    width: "606px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #c2bebe",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                    gap: "60px",
                }}
            >
                <img
                    src="./images/alert.png"
                    style={{
                        height: "124px",
                        width: "144px",
                        marginTop: "30px",
                    }}
                />
                <div
                    style={{
                        textAlign: "left",
                        font: "normal normal bold 35px/55px Segoe UI",
                    }}
                >
                    {t("Unknown user !")}
                </div>
                <div
                    style={{
                        textAlign: "center",
                        font: "normal normal normal 25px/33px Segoe UI",
                    }}
                >
                    {t("Please verify your username or password")}
                </div>
                <StyledButton
                    rounded={true}
                    className="m-2"
                    style={{
                        width: "179px",
                        height: "61px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #00000029",
                        border: "1px solid #C9C9C9",
                        borderRadius: "12px",
                        color: "black",
                    }}
                    onClick={() => {
                        setIsLoginErrorModalWrapperOpened(false);
                        setKeyboardInputsValues({
                            [t("Enter your ID")]: "",
                            [t("Enter your password")]: "",
                        });
                    }}
                >
                    {t("Back")}
                </StyledButton>
            </div>
        </Modal>
    );
}
