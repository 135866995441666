import { t } from "i18next";
import enLayout from "simple-keyboard-layouts/build/layouts/english";
import frLayout from "simple-keyboard-layouts/build/layouts/french";
import { useSnapshot } from "valtio";

import React from "react";
import Keyboard, { KeyboardInput } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { kioskStore, setKeyboardInputsValues } from "@pages/Kiosk/store";

export function KeyboardWrapper(): JSX.Element {
    const [, setInputs] = React.useState<KeyboardInput>({});
    const [layoutName, setLayoutName] = React.useState<string>("default");
    const [inputName] = React.useState<string>("");

    const { customerLanguage } = useSnapshot(kioskStore);
    // eslint-disable-next-line
    const keyboard = React.useRef<any>(null);

    function handleOnChangeAllEvent(inputs: KeyboardInput) {
        setKeyboardInputsValues(inputs);
        setInputs({ ...inputs });
    }

    function handleShift() {
        setLayoutName(layoutName === "default" ? "shift" : "default");
    }

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        }
    }

    return (
        <FlexboxGrid
            className="w-100"
            alignItemsCentered={true}
            justifyContentCentered={true}
        >
            <Keyboard
                layout={
                    customerLanguage.name === "fr"
                        ? frLayout.layout
                        : enLayout.layout
                }
                theme="keyboard__clz hg-theme-default"
                keyboardRef={(r) => (keyboard.current = r)}
                inputName={inputName}
                layoutName={layoutName}
                onChangeAll={handleOnChangeAllEvent}
                onKeyPress={handleKeyboardOnKeyPressEvent}
                display={{
                    "{bksp}": `<img src="./images/backspace.svg" />`,
                    "{enter}": t("Enter key"),
                    "{tab}": `<img src="./images/tab.svg" width="25" height="25" />`,
                    "{lock}": `<img src="./images/caps.svg" width="25" height="25" />`,
                    "{shift}": `<img src="./images/shift.svg" />`,
                    "{space}": " ",
                }}
            />
        </FlexboxGrid>
    );
}
