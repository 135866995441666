import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { ScrollContainer } from "@components/ScrollContainer";

import { kioskStore as store } from "@pages/Kiosk/store";

import { PeripheralDevicesCardWrapper } from "./PeripheralDevicesCardWrapper";

export function PeripheralDevices(): JSX.Element {
    const { activeTabTitle } = useSnapshot(store);

    const mainContentPeripheralDevices = [
        "Printer",
        "TPE",
        "Change machine",
        "Scanner",
        "LED",
    ];

    return (
        <div className="d-flex flex-column" style={{ marginLeft: 30 }}>
            <div
                style={{
                    font: "normal normal 600 37px/49px Segoe UI",
                    marginLeft: "30px",
                    marginTop: "30px",
                }}
            >
                {activeTabTitle}
            </div>
            <ScrollContainer
                style={{
                    maxHeight: "880px",
                    overflowY: "auto",
                }}
            >
                <div
                    style={{
                        margin: "30px",
                        gap: "40px",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridTemplateRows: `repeat(${Math.round(
                            mainContentPeripheralDevices.length / 2
                        )}, 1fr)`,
                    }}
                >
                    {mainContentPeripheralDevices.map((device: string) => {
                        return (
                            <div key={device.toLowerCase()}>
                                <span
                                    className="cursor__clz"
                                    style={{
                                        font: "normal normal normal 15px/20px Segoe UI",
                                        color: "#B2B2B2",
                                    }}
                                >
                                    {t(device)}
                                </span>
                                <PeripheralDevicesCardWrapper device={device} />
                            </div>
                        );
                    })}
                </div>
            </ScrollContainer>
        </div>
    );
}
