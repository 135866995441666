import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { store } from "@store";

import {
    getImageContentById,
    getPreviousSkippedStepNumber,
} from "@helpers/general";

import {
    kioskStore,
    setAmountDeposited,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsCashTransactionValidated,
    setIsChangeMachineRenderModalOpened,
    setIsPaymentRefused,
    setIsPaymentTechnicalProblemDetected,
    setIsRegulationModesModalBackButtonClicked,
    setIsTransactionCanceled,
    setIsWithdrawalProblemModalOpened,
    setLeftToPayAfterCashMachineValidation,
    setNavigationIndex,
    setTotalAmountDeposited,
} from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";
import { PrmMainContentWrapper } from "./PrmMainContentWrapper";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function RegulationModesModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isPrm,
        isRegulationModesModalOpened,
        creditCardPaymentChoice,
        project: {
            files: images,
            template: {
                content: { generalDesign },
            },
        },
        isPaymentRefused,
        selectedRegulationModeValue,
        customerLanguage,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        totalAmountDeposited,
        isRegulationModesModalBackButtonClicked,
    } = useSnapshot(kioskStore);
    const { middlewareApiUrl } = useSnapshot(store);

    const logoImage = getImageContentById(
        (generalDesign as GeneralDesignType).logo.id as string,
        images,
        customerLanguage.name
    );
    const logoSrc =
        logoImage?.includes("http") || logoImage?.includes("https")
            ? logoImage
            : `./images/${logoImage}`;

    function handleBackButtonOnClickEvent() {
        if (creditCardPaymentChoice === "") {
            setIsBackWithoutCreditCardPaymentChoice(true);
        }
        setIsPaymentRefused(false);
        setIsBackButtonDisabled(true);
        setIsAnotherPaymentModeButtonDisabled(true);
        setIsPaymentTechnicalProblemDetected(false);
        setIsCashTransactionValidated(false);
        setLeftToPayAfterCashMachineValidation(0);
        setIsChangeMachineRenderModalOpened(false);
        setAmountDeposited(0);
        setIsPaymentRefused(false);
        setIsTransactionCanceled(false);

        if (totalAmountDeposited === 0) {
            setNavigationIndex(
                navigationIndex -
                    Number(
                        getPreviousSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue
                        )
                    )
            );
        } else {
            fetch(
                `${middlewareApiUrl}/RetraitMontant?unitaire=${-totalAmountDeposited}`
            )
                .then((response) => response.json())
                .then((response) => {
                    const localRenderAmount = Number(
                        response.response
                            .split(`:`)[2]
                            .split(`,`)[0]
                            .substr(
                                1,
                                response.response.split(`:`)[2].split(`,`)[0]
                                    .length - 2
                            )
                    );

                    if (localRenderAmount > 0) {
                        setNavigationIndex(
                            navigationIndex -
                                Number(
                                    getPreviousSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue
                                    )
                                )
                        );
                        setTotalAmountDeposited(0);
                    } else {
                        setIsWithdrawalProblemModalOpened(true);
                    }
                })
                .catch((error) => {
                    console.log(
                        `Error when withdrawing the deposited amount, with the message: ${error.message}`
                    );
                });
        }
    }

    React.useEffect(() => {
        if (isRegulationModesModalBackButtonClicked) {
            handleBackButtonOnClickEvent();
        }
    }, [isRegulationModesModalBackButtonClicked]);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isRegulationModesModalOpened}
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: isPrm ? "end" : "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1742px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img
                    src={logoSrc}
                    alt="logo"
                    style={{ height: "230px", width: "230px" }}
                />
            </div>
            <div
                className="d-flex justify-content-center align-items-center px-5 text-center"
                style={{ height: "370px" }}
            >
                {isPaymentRefused ? (
                    <div>
                        {selectedRegulationModeValue === "changeMachine" ? (
                            <span
                                style={{
                                    font: "normal normal 600 50px/80px Segoe UI",
                                }}
                            >
                                {t(
                                    "The payment with the change machine is refused"
                                )}
                            </span>
                        ) : (
                            <span
                                style={{
                                    font: "normal normal 600 50px/80px Segoe UI",
                                }}
                            >
                                {t(
                                    "The payment with the credit card is refused"
                                )}
                            </span>
                        )}
                        <br />
                        <span
                            style={{
                                font: "normal normal 600 50px/80px Segoe UI",
                            }}
                        >
                            {t("Please choose another payment method")}
                        </span>
                    </div>
                ) : (
                    <span
                        style={{ font: "normal normal 600 55px/80px Segoe UI" }}
                    >
                        {t("Choose another payment method")}
                    </span>
                )}
            </div>

            <div
                className="d-flex justify-content-center w-100"
                style={{
                    height: "900px",
                    alignItems: isPrm ? "end" : "center",
                }}
            >
                {isPrm ? <PrmMainContentWrapper /> : <MainContentWrapper />}
            </div>

            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "145px" }}
            >
                <img
                    src="./images/back.png"
                    alt="action button"
                    style={{
                        pointerEvents: isRegulationModesModalBackButtonClicked
                            ? "none"
                            : "auto",
                        opacity: isRegulationModesModalBackButtonClicked
                            ? "0.5"
                            : 1,
                    }}
                    onClick={() =>
                        setIsRegulationModesModalBackButtonClicked(true)
                    }
                />
            </div>
        </Modal>
    );
}
