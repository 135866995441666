import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { getSkippedStepNumber } from "@helpers/general";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import {
    kioskStore,
    setIsAnimationIn,
    setIsInvalidQrCodeModalOpened,
    setIsLogoutProblemModalOpened,
    setIsOrderProcessedModalWrapperOpened,
    setIsQrCodeCorrectOrderNotProcessedModalOpened,
    setIsStandbyModalOpened,
    setNavigationIndex,
} from "@pages/Kiosk/store";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

import { OrderProcessedModalWrapper } from "../OrderProcessedModalWrapper";
import { QrCodeCorrectOrderNotProcessedModalWrapper } from "../QrCodeCorrectOrderNotProcessedModalWrapper";
import { QrCodeIncorrectModalWrapper } from "../QrCodeIncorrectModalWrapper";
import "./index.css";

export function UsualMode(): JSX.Element {
    const {
        isSubStepTopBannerActive,
        isSubStepIconActive,
        isLanguagesActive,
        isSubStepActionButtonsActive,
        isSubStepInformationMessageActive,
        isConfigOptionsActive,
        isHelpModalOpened,
        isStandbyModalOpened,
        subStepInformationMessageItems,
        subStepActionButtons,
        customerLanguage,
        isUserAccountModalOpened,
        isInvalidQrCodeModalOpened,
        isOrderProcessedModalWrapperOpened,
        isQrCodeCorrectOrderNotProcessedModalOpened,
        timeout,
        kioskWays,
        navigationIndex,
        chosenTransition,
        isAnimationIn,
        selectedRegulationModeValue,
        isAuthenticationAccepted,
        isUserAuthenticated,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedSaleModeValue,
        selectedInformationMode,
        isLogoutProblemModalOpened,
        project: {
            template: { content },
        },
    } = useSnapshot(kioskStore);

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });
    const [qrCode, setQrCode] = React.useState("");

    const [isQrCodeShown, setIsQrCodeShown] = React.useState(false);

    function handleOnChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
        setQrCode(e.target.value);
    }

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
        setIsLogoutProblemModalOpened(false);
    }, []);

    React.useEffect(() => {
        if (qrCode === "2222") {
            setIsInvalidQrCodeModalOpened(true);
        } else if (qrCode === "0000") {
            setIsOrderProcessedModalWrapperOpened(true);
        } else if (qrCode === "1111") {
            setIsQrCodeCorrectOrderNotProcessedModalOpened(true);
        }
    }, [qrCode]);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <PreviewBackgroundWrapper selectedMode={selectedInformationMode}>
                <UsualModeSubStepContainerWrapper
                    isSubStepTopBannerActive={isSubStepTopBannerActive}
                    isSubStepIconActive={isSubStepIconActive}
                    isSubStepInformationMessageActive={
                        isSubStepInformationMessageActive
                    }
                    isSubStepActionButtonsActive={isSubStepActionButtonsActive}
                    isLanguagesActive={isLanguagesActive}
                    isConfigOptionsActive={isConfigOptionsActive}
                    ref={(ref) => setContainerRef(ref)}
                >
                    {isHelpModalOpened === true ? <HelpModalWrapper /> : null}
                    {isStandbyModalOpened === true ? (
                        <StandbyModalWrapper />
                    ) : null}
                    {containerRef ? (
                        isUserAccountModalOpened === true ? (
                            <UserAccountModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}
                    {containerRef ? (
                        isInvalidQrCodeModalOpened === true ? (
                            <QrCodeIncorrectModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}
                    {containerRef ? (
                        isOrderProcessedModalWrapperOpened === true ? (
                            <OrderProcessedModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}
                    {containerRef ? (
                        isQrCodeCorrectOrderNotProcessedModalOpened === true ? (
                            <QrCodeCorrectOrderNotProcessedModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}
                    {isLogoutProblemModalOpened === true ? (
                        <LogoutProblemModal />
                    ) : null}

                    <IdleDetection
                        timeout={timeout * 1000}
                        once={true}
                        onIdle={() => {
                            if (navigationIndex !== 0) {
                                setIsStandbyModalOpened(true);
                            }
                        }}
                    />

                    <SubStepsLogoWrapper />

                    {isSubStepIconActive ? <SubStepsIconWrapper /> : null}
                    {isSubStepInformationMessageActive ? (
                        <div className="d-flex justify-content-center align-items-center text-center">
                            <span style={{ fontSize: "55px" }}>
                                {
                                    subStepInformationMessageItems[
                                        customerLanguage.name
                                    ].content
                                }
                            </span>
                        </div>
                    ) : null}
                    <div
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ gap: "100px" }}
                    >
                        <img
                            src="./images/qr-code.png"
                            alt="qrCode"
                            style={{ height: "330px", width: "330px" }}
                            onClick={() => {
                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content
                                            )
                                        )
                                );
                            }}
                        />
                        <div
                            style={{
                                borderBottom: "1px solid #00000029",
                            }}
                        >
                            <input
                                className="form-input text-center qr-code__clz"
                                type={isQrCodeShown ? "text" : "password"}
                                value={qrCode}
                                autoFocus
                                placeholder={t("QR CODE")}
                                onChange={handleOnChangeEvent}
                            />
                            {isQrCodeShown === false ? (
                                <EyeIcon
                                    className="mb-3"
                                    height={30}
                                    width={30}
                                    onClick={() => {
                                        setIsQrCodeShown(!isQrCodeShown);
                                    }}
                                />
                            ) : (
                                <EyeCrossedIcon
                                    className="mb-3"
                                    height={30}
                                    width={30}
                                    onClick={() => {
                                        setIsQrCodeShown(!isQrCodeShown);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {isSubStepActionButtonsActive ? (
                        <ActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                        />
                    ) : null}

                    {isLanguagesActive ? <UsualModeLanguagesWrapper /> : null}

                    {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
                </UsualModeSubStepContainerWrapper>
            </PreviewBackgroundWrapper>
        </CSSTransition>
    );
}
