import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function SubStepsIconWrapper(): JSX.Element {
    const { customerLanguage, subStepIconId } = useSnapshot(kioskStore);

    const {
        project: { files: images },
        isPrm,
    } = useSnapshot(kioskStore);

    const iconImageContent = getImageContentById(
        subStepIconId,
        images,
        customerLanguage.name
    );

    const imageAltAttr = iconImageContent?.split(".")[0];

    const imageSrc =
        iconImageContent?.includes("http") ||
        iconImageContent?.includes("https")
            ? iconImageContent
            : `./images/${iconImageContent}`;

    return (
        <div className="d-flex justify-content-center align-items-center">
            <img
                src={imageSrc}
                alt={imageAltAttr}
                style={{
                    height: isPrm ? "170px" : "230px",
                    width: isPrm ? "170px" : "230px",
                }}
            />
        </div>
    );
}
