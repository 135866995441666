import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Avatar from "react-avatar";
import { Tooltip } from "reactstrap";

import { getImageContentById } from "@helpers/general";

import {
    setAuthModalStepIndex,
    setIsHelpModalOpened,
    setIsLogoutProblemModalOpened,
    setIsPrm,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setUser,
    kioskStore as store,
} from "@pages/Kiosk/store";

export function ConfigOptionsWrapper(): JSX.Element {
    const {
        activeConfigOptions,
        navigationIndex,
        isUserAuthenticated,
        isPrm,
        isUserAccountModalOpened,
        kioskWays,
        customerLanguage,
        user,
        selectedSaleModeValue,
        project: { files: images },
    } = useSnapshot(store);
    const [isLogoutButtonClicked, setIsLogoutButtonClicked] =
        React.useState<boolean>(false);

    function handleConfigOptionOnClickEvent(option: OptionsItemType) {
        if (
            option.title.toLowerCase() === "prm/child" ||
            option.title.toLowerCase() === "usual"
        ) {
            setIsPrm(!isPrm);
        } else if (option.title.toLowerCase() === "ask for help") {
            setIsHelpModalOpened(true);
        } else if (
            option.title.toLowerCase() === "customer account" &&
            !isUserAuthenticated
        ) {
            setIsUserAccountModalOpened(true);
            setAuthModalStepIndex(navigationIndex);
        }
    }

    const localActiveConfigOptions = isPrm
        ? activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== "prm/child"
          )
        : activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== "usual"
          );

    const localConfigOptions = !isUserAuthenticated
        ? localActiveConfigOptions
        : localActiveConfigOptions.filter(
              (config) => config.title.toLowerCase() !== "customer account"
          );

    return (
        <div>
            <div
                className="d-flex justify-content-end align-items-center px-3"
                style={{ gap: "5px" }}
            >
                {isLogoutButtonClicked &&
                kioskWays[navigationIndex].name !== "meansOfPayment" ? (
                    <Tooltip
                        placement="top"
                        isOpen={isLogoutButtonClicked}
                        target={"Tooltip"}
                        onClick={() => {
                            if (selectedSaleModeValue === "Delivery") {
                                setIsLogoutProblemModalOpened(true);
                                setIsLogoutButtonClicked(false);
                            } else {
                                setIsUserAuthenticated(false);
                                setIsLogoutButtonClicked(false);
                                setUser({
                                    firstName: "",
                                    lastName: "",
                                    phoneNumber: "",
                                    email: "",
                                    loyaltyAccount: 0,
                                    loyaltyAmount: 0,
                                });
                            }
                        }}
                        style={{
                            fontSize: "30px",
                            marginLeft: "130px",
                        }}
                    >
                        {t("Sign out")}
                    </Tooltip>
                ) : null}
                {isUserAuthenticated ? (
                    <div id="Tooltip">
                        <Avatar
                            email={user.email}
                            name={user.lastName}
                            size="65px"
                            round="10px"
                            onClick={() =>
                                setIsLogoutButtonClicked(!isLogoutButtonClicked)
                            }
                        />
                    </div>
                ) : null}

                {localConfigOptions.map(
                    (option: OptionsItemType, index: number) => {
                        const imageContent = getImageContentById(
                            option.id,
                            images,
                            customerLanguage.name
                        );

                        const imageSrc =
                            imageContent?.includes("http") ||
                            imageContent?.includes("https")
                                ? imageContent
                                : `./images/${imageContent}`;

                        return (
                            <img
                                key={index}
                                src={imageSrc}
                                alt={option.title}
                                onClick={() => {
                                    handleConfigOptionOnClickEvent(option);
                                }}
                                style={{
                                    height: "70px",
                                    width: "70px",
                                    opacity:
                                        option.title.toLowerCase() ===
                                            "customer account" &&
                                        (isUserAccountModalOpened ||
                                            kioskWays[navigationIndex].name ===
                                                "connection" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModes" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModeConsommation")
                                            ? "0.5"
                                            : "1",
                                    pointerEvents:
                                        option.title.toLowerCase() ===
                                            "customer account" &&
                                        (isUserAccountModalOpened ||
                                            kioskWays[navigationIndex].name ===
                                                "connection" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModes" ||
                                            kioskWays[navigationIndex].name ===
                                                "authenticationModeConsommation")
                                            ? "none"
                                            : "auto",
                                }}
                            />
                        );
                    }
                )}
            </div>
        </div>
    );
}
