import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    kioskStore,
    setActionsButtonsItems,
    setCashUuID,
    setChosenTransition,
    setCreditCardUuId,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsTopBannerActive,
    setIsUserAccountActive,
    setLoyaltyUuId,
    setMainContentItems,
    setUserAccountMessageItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function RegulationModesStep(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
    } = useSnapshot(kioskStore);

    const { transitions } = useSnapshot(store);

    React.useEffect(() => {
        setMainContentItems(
            (content.meansOfPayment as ProjectContentItemType).items
        );
        setIsUserAccountActive(
            // eslint-disable-next-line
            (ways.meansOfPayment as PageType).header.userAccount!.active
        );
        setUserAccountMessageItems(
            // eslint-disable-next-line
            (ways.meansOfPayment as PageType).header.userAccount!.languages
        );

        setIsLogoActive(
            (ways.meansOfPayment as PageType).topBanner.logo.active
        );

        setIsTopBannerActive(
            (ways.meansOfPayment as PageType).topBanner.active
        );
        setIsHeaderActive((ways.meansOfPayment as PageType).header.active);

        setIsInformationMessageActive(
            (ways.meansOfPayment as PageType).header.informationMessage.active
        );

        setInformationMessageItems(
            (ways.meansOfPayment as PageType).header.informationMessage
                .languages
        );

        setIsActionButtonsActive(
            (ways.meansOfPayment as PageType).actionsButtons.active
        );

        setActionsButtonsItems(
            (
                (ways.meansOfPayment as PageType)
                    .actionsButtons as ActionsButtonsDesignType
            ).items
        );

        setChosenTransition(
            transitions[(ways.meansOfPayment as PageType).animation]
        );

        setCashUuID(
            // eslint-disable-next-line
            (
                (content.meansOfPayment as ProjectContentItemType)
                    .items as ProjectMainContentItemType[]
            )
                // eslint-disable-next-line
                .find((item) => item.shortName! === "changeMachine")?.id!
        );

        setCreditCardUuId(
            // eslint-disable-next-line
            (
                (content.meansOfPayment as ProjectContentItemType)
                    .items as ProjectMainContentItemType[]
            )
                // eslint-disable-next-line
                .find((item) => item.shortName! === "creditCard")?.id!
        );

        setLoyaltyUuId(
            // eslint-disable-next-line
            (
                (content.meansOfPayment as ProjectContentItemType)
                    .items as ProjectMainContentItemType[]
            )
                // eslint-disable-next-line
                .find((item) => item.shortName! === "loyalty")?.id!
        );
    }, []);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
