import { BASIC_COMPOSITION, BASIC_COMPOSITION_TYPE } from "@constants";

import {
    addWorkFlowItemShopCart,
    deleteBasicCompositionFromShopCart,
} from "@components/OrderTaking/Store/feature";

export function NextOnClickEvent(
    listworkflow,
    AddorderBasicCompositionWorkflow,
    addIndexstep,
    NextStep
) {
    if (
        listworkflow.workflow[listworkflow.index]?.NbrOfChoices <
        listworkflow.workflow[listworkflow.index]?.minNbrOfChoices
    )
        return;
    if (listworkflow) {
        if (
            listworkflow.workflow[listworkflow.index].type === BASIC_COMPOSITION
        ) {
            AddorderBasicCompositionWorkflow();
        }
        if (listworkflow.step > listworkflow.index) {
            addIndexstep(listworkflow);
        } else {
            NextStep(listworkflow);
        }
    }
}

export function AddOrderBasicCompositionWorkflow(
    workflowData,
    dispatch,
    orderWorkflowItemSlice,
    globalcard
) {
    let listProduct =
        workflowData[workflowData.length - 1].workflow[
            workflowData[workflowData.length - 1]?.index
        ].Items;
    const stepPath =
        workflowData[workflowData.length - 1].workflow[
            workflowData[workflowData.length - 1]?.index
        ].path;

    const path = [
        ...stepPath,
        {
            name: BASIC_COMPOSITION_TYPE,
            iuud: BASIC_COMPOSITION_TYPE,
            type: BASIC_COMPOSITION_TYPE,
        },
    ];

    const canAddBasicCompositionShopCart = listProduct.some(
        (prod) => prod.basicCompositionActive === false
    );

    if (canAddBasicCompositionShopCart) {
        const listItems = listProduct.reduce((prev, curr) => {
            if (!curr.basicCompositionActive) {
                return {
                    ...prev,
                    [curr.title]: {
                        name: "SANS " + curr.title,
                        urlImage: curr.image,
                        type: BASIC_COMPOSITION,
                        quantity: 1,
                        path: [...curr.path],
                        categoryParent: globalcard.categories[
                            curr.categoryParent
                        ]?.categoryParent
                            ? globalcard.categories[curr.categoryParent]
                                  .categoryParent
                            : curr.categoryParent,
                        categoryTitle: curr.categoryParent
                            ? globalcard.categories[curr.categoryParent]
                                  ?.categoryParent
                                ? globalcard.categories[
                                      globalcard.categories[curr.categoryParent]
                                          .categoryParent
                                  ].title
                                : globalcard.categories[curr.categoryParent]
                                      .title
                            : "",
                        categoryVisibility: curr.categoryParent
                            ? globalcard.categories[curr.categoryParent]
                                  ?.KioskVisibility
                            : {},

                        subcategoryTitle: curr.categoryParent
                            ? globalcard.categories[curr.categoryParent]
                                  ?.categoryParent
                                ? globalcard.categories[curr.categoryParent]
                                      .title
                                : ""
                            : "",
                        reference: curr.reference,
                        iuud: curr.iuud,
                        price: 0,
                        modifier: curr?.modifier,
                        fidelity: curr.fidelity,
                        workflow: [...workflowData],
                        date: Date.now(),
                        advancedItemPrice: curr.price,
                        productVisibility: curr.KioskVisibility,
                    },
                };
            }
            return { ...prev };
        }, {});

        let data = {
            [BASIC_COMPOSITION_TYPE]: {
                type: BASIC_COMPOSITION_TYPE,
                urlImage: "./images_order/iconecompobase.png",
                price: 0,
                path,
                compositions: listItems,
            },
        };

        dispatch(addWorkFlowItemShopCart(data[BASIC_COMPOSITION_TYPE]));
    } else {
        dispatch(
            deleteBasicCompositionFromShopCart({
                type: BASIC_COMPOSITION_TYPE,
                urlImage: "./images_order/iconecompobase.png",
                path,
            })
        );
    }
}
