import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { getImageContentById } from "@helpers/general";

import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeSubStepContainerWrapper } from "@components/common/PrmModeSubStepContainerWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { IdleDetection } from "@components/IdleDetection";
import { PrmKeyboardWrapper } from "@components/PrmKeyboardWrapper";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import {
    kioskStore,
    setIsAnimationIn,
    setIsStandbyModalOpened,
} from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        isStandbyModalOpened,
        isHelpModalOpened,
        isSubStepActionButtonsActive,
        subStepActionButtons,
        timeout,
        chosenTransition,
        isAnimationIn,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        customerLanguage,
        subStepIconId,
        isAuthenticationErrorModalOpened,
        navigationIndex,
        project: { files: images },
        selectedAuthMode,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    const iconImageContent = getImageContentById(
        subStepIconId,
        images,
        customerLanguage.name
    );

    const imageSrc =
        !iconImageContent?.includes("http") ||
        iconImageContent?.includes("https")
            ? `./images/${iconImageContent}`
            : iconImageContent;

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
    }, []);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <GlobalPrmModeWrapper>
                <PrmBackgroundWrapper />
                <div>
                    <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
                        <PrmModeSubStepContainerWrapper
                            isSubStepIconActive={isSubStepIconActive}
                            isSubStepInformationMessageActive={
                                isSubStepInformationMessageActive
                            }
                            isSubStepActionButtonsActive={
                                isSubStepActionButtonsActive
                            }
                            isLanguagesActive={isLanguagesActive}
                            isConfigOptionsActive={isConfigOptionsActive}
                        >
                            <IdleDetection
                                timeout={timeout * 1000}
                                once={true}
                                onIdle={() => {
                                    if (navigationIndex !== 0) {
                                        setIsStandbyModalOpened(true);
                                    }
                                }}
                            />

                            {isHelpModalOpened === true ? (
                                <HelpModalWrapper />
                            ) : null}
                            {isStandbyModalOpened === true ? (
                                <StandbyModalWrapper />
                            ) : null}

                            {isAuthenticationErrorModalOpened === true ? (
                                <AuthenticationErrorModal />
                            ) : null}

                            {isSubStepIconActive ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <img
                                        src={imageSrc}
                                        alt="email"
                                        style={{
                                            height: "120px",
                                            width: "120px",
                                            zIndex: 1,
                                        }}
                                    />
                                </div>
                            ) : null}

                            {isSubStepInformationMessageActive ? (
                                <SubStepsInformationMessage />
                            ) : null}

                            <PrmKeyboardWrapper />

                            {isSubStepActionButtonsActive ? (
                                <PrmActionsButtonsWrapper
                                    actionsButtonsItems={
                                        mainActiveActionsButtons
                                    }
                                />
                            ) : null}

                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                            >
                                {isLanguagesActive ? (
                                    <PrmLanguagesWrapper />
                                ) : (
                                    <div />
                                )}

                                {isConfigOptionsActive ? (
                                    <ConfigOptionsWrapper />
                                ) : null}
                            </div>
                        </PrmModeSubStepContainerWrapper>
                    </PreviewBackgroundWrapper>
                </div>
            </GlobalPrmModeWrapper>
        </CSSTransition>
    );
}
