import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { ListItems } from "./ListItems";
import PmrProducts from "./PmrProducts";

export default function ProductWrapper() {
    const { isPrm } = useSnapshot(kioskStore);

    return isPrm ? <PmrProducts /> : <ListItems />;
}
