import moment from "moment";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { configCashMachine, initCardReader } from "@helpers/general";

import { TechnicalProblemModalWrapper } from "@components/common/TechnicalProblemModalWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { LocalSettings } from "@components/LocalSettings";
import { AuthenticationModalWrapper } from "@components/LocalSettings/AuthenticationModalWrapper";
import { ConfirmationStandbyStateModal } from "@components/LocalSettings/ConfirmationStandbyStateModal";
import { CloseAppModalWrapper } from "@components/LocalSettings/ConfirmationSystemActionsModalWrapper/CloseAppModalWrapper";
import { RestartModalWrapper } from "@components/LocalSettings/ConfirmationSystemActionsModalWrapper/RestartModalWrapper";
import { ShutdownModalWrapper } from "@components/LocalSettings/ConfirmationSystemActionsModalWrapper/ShutdownModalWrapper";
import { ErrorLogInModalWrapper } from "@components/LocalSettings/ErrorLogInModalWrapper";
import { LocalConfigModalWrapper } from "@components/LocalSettings/LocalConfigModalWrapper";
import { MenuModalWrapper } from "@components/LocalSettings/MenuModalWrapper";
import { ScanQrCodeModalWrapper } from "@components/LocalSettings/ScanQrCodeModalWrapper";
import { UsersListModalWrapper } from "@components/LocalSettings/UsersListModalWrapper";
import {
    clearMiddlwareOrder,
    clearOrder,
} from "@components/OrderTaking/Store/feature";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";
import { EntityChildrenSelectionModalWrapper } from "@components/Synchronization/EntityChildrenSelectionModalWrapper";
import { EntitySelectionModalWrapper } from "@components/Synchronization/EntitySelectionModalWrapper";
import { syncStore } from "@components/Synchronization/store";

import { PausedAppSwiperWrapper } from "@pages/Kiosk/Steps/StandbyStep/PausedAppSwiperWrapper";
import { ProdAppSwiperWrapper } from "@pages/Kiosk/Steps/StandbyStep/ProdAppSwiperWrapper";
import {
    initStoreVariablesInStandbyScreenStep,
    kioskStore,
    setActiveStandbyScreenItems,
    setCustomerLanguage,
    setIsLocalSettingModalOpened,
    setIsStandbyModalOpened,
    setStandbyScreenItems,
} from "@pages/Kiosk/store";

import "./index.css";

export function StandbyStep(): JSX.Element {
    const {
        navigationIndex,
        isLocalSettingModalOpened,
        isAuthenticationModalOpened,
        isLocalConfigModalOpened,
        isErrorLogInModalWrapperOpened,
        isMenuModalOpened,
        isAppPaused,
        isConfirmationStandbyStateModalOpened,
        isStandbyModalOpened,
        timeout,
        isUsersListModalOpened,
        isScanQrCodeModalOpened,
        isRestartModalOpened,
        isShutdownModalOpened,
        isCloseAppModalOpened,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        isLogout,
        isCashMachineConfigured,
        isFenceProblemDetected,
        changeMachineConfigurationDate,
    } = useSnapshot(kioskStore);

    const {
        selectedShopApp,
        machineIpAddress,
        isEntitySelectionModalOpened,
        isEntityChildrenSelectionModalOpened,
    } = useSnapshot(syncStore);

    const dispatch = useDispatch();

    React.useEffect(() => {
        initStoreVariablesInStandbyScreenStep();
        setCustomerLanguage(
            (content.languages as LanguagesType).items.filter(
                (language) => language.isDefault === true
            )[0]
        );

        dispatch(clearOrder());
        dispatch(clearMiddlwareOrder());
        setIsStandbyModalOpened(false);
    }, []);

    // React.useEffect(() => {
    //     if (isLogout === false) {
    //         initCardReader(machineIpAddress);
    //     }
    //     if (isCashMachineConfigured === false) {
    //         const ipCashMachine = // eslint-disable-next-line
    //         (Object.values(selectedShopApp.cash_managements as any)[0] as any)
    //             ?.ip;

    //         configCashMachine(ipCashMachine);
    //     }
    // }, [isLogout, isCashMachineConfigured, selectedShopApp]);

    React.useEffect(() => {
        setActiveStandbyScreenItems(
            (ways.standbyScreen as StandbyScreenPageType).items.filter(
                (item) => item.active === true
            )
        );
        setStandbyScreenItems(
            (
                (content.standbyStateScreen as StandbyStateScreenItemsType)
                    .items as StandbyScreenItemType[]
            ).filter((item) => item.active === true)
        );
    }, [content.standbyStateScreen, ways.standbyScreen]);

    // const {
    //     schema: franchiseSchema,
    //     id: shopId,
    //     franchise_uid,
    //     shop_uid,
    // }: ObjectType = JSON.parse(localStorage.getItem("shopDetail") as string);

    // React.useEffect(() => {
    //     getProjectByFranchiseUidAndShopUid(
    //         franchise_uid as string,
    //         shop_uid as string,
    //         dispatch,
    //         // activate it before exe generation
    //         false
    //     );
    //     getCatalog(
    //         franchiseSchema as string,
    //         shopId as string,
    //         dispatch,
    //         // activate it before exe generation
    //         false
    //     );
    // }, []);

    return (
        <React.Fragment>
            <div>
                <IdleDetection
                    timeout={timeout * 1000}
                    once={true}
                    onIdle={() => {
                        if (navigationIndex !== 0) {
                            setIsStandbyModalOpened(true);
                        }
                    }}
                />
                {isLocalSettingModalOpened === true ? <LocalSettings /> : null}
                {isAuthenticationModalOpened === true ? (
                    <AuthenticationModalWrapper />
                ) : null}
                {isLocalConfigModalOpened === true ? (
                    <LocalConfigModalWrapper />
                ) : null}
                {isErrorLogInModalWrapperOpened === true ? (
                    <ErrorLogInModalWrapper />
                ) : null}
                {isMenuModalOpened === true ? <MenuModalWrapper /> : null}
                {isConfirmationStandbyStateModalOpened === true ? (
                    <ConfirmationStandbyStateModal />
                ) : null}
                {isStandbyModalOpened === true ? <StandbyModalWrapper /> : null}
                {isUsersListModalOpened === true ? (
                    <UsersListModalWrapper />
                ) : null}
                {isRestartModalOpened === true ? <RestartModalWrapper /> : null}
                {isShutdownModalOpened === true ? (
                    <ShutdownModalWrapper />
                ) : null}
                {isCloseAppModalOpened === true ? (
                    <CloseAppModalWrapper />
                ) : null}
                {isScanQrCodeModalOpened === true ? (
                    <ScanQrCodeModalWrapper />
                ) : null}
                {isEntitySelectionModalOpened === true ? (
                    <EntitySelectionModalWrapper />
                ) : null}
                {isEntityChildrenSelectionModalOpened === true ? (
                    <EntityChildrenSelectionModalWrapper />
                ) : null}
                {isAppPaused === true ? (
                    <PausedAppSwiperWrapper />
                ) : (
                    <ProdAppSwiperWrapper />
                )}
                <div
                    style={{
                        position: "fixed",
                        zIndex: isFenceProblemDetected ? 3 : 2,
                        bottom: "0px",
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setIsLocalSettingModalOpened(true);
                    }}
                />
            </div>
            <TechnicalProblemModalWrapper />
        </React.Fragment>
    );
}
