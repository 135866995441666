import React from "react";
import { useSnapshot } from "valtio";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import { kioskStore } from "@pages/Kiosk/store";

import { ImageContent } from "./ImageContent";

import "./index.css";
import "swiper/swiper-bundle.min.css";

export function PrmMainContentWrapper(): JSX.Element {
    const { mainContentItems, customerLanguage } = useSnapshot(kioskStore);

    const activeItems = (
        (mainContentItems as PaymentMainContentItemType[]).filter(
            (item: PaymentMainContentItemType) =>
                item.shortName === "creditCard"
        )[0].languages[customerLanguage.name].paymentInstructions
            .TPAValina as TPAValinaType
    ).items;

    const activeItemsLength = activeItems.length;

    return (
        <React.Fragment>
            {activeItemsLength <= 3 ? (
                <div
                    className="d-flex justify-content-center p-2"
                    style={{
                        gap: "100px",
                    }}
                >
                    {activeItems?.map(
                        (item: TPAValinaItemType, index: number) => (
                            <ImageContent
                                item={item}
                                key={index}
                                activeItemsLength={activeItemsLength}
                            />
                        )
                    )}
                </div>
            ) : (
                <Swiper
                    slidesPerView={3}
                    slidesPerGroup={3}
                    spaceBetween={40}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                >
                    {activeItems.map(
                        (item: TPAValinaItemType, index: number) => (
                            <SwiperSlide
                                key={item.id}
                                className="main-content-prm-swiper-slide__clz"
                            >
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            )}
        </React.Fragment>
    );
}
