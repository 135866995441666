import { BACK_BUTTON_ROLE, VALIDATE_IN_STEP_BUTTON_ROLE } from "@constants";
import { Box, Dialog, Slide } from "@mui/material";
import PropTypes from "prop-types";
import shortid from "shortid";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import { getImageContentById } from "@helpers/general";

import { AllergenInfoIcon } from "@components/OrderTaking/Components/Icons/AllergenInfoIcon";
import { AllergenPrmLogoWrapper } from "@components/OrderTaking/Components/Prm/Dialogs/Allergens/AllergenPrmLogoWrapper";
import {
    memoizedGlobalAllergens,
    setGlobalAllergens,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "../NutritionalInformation/index.css";
import { AllergenGroup } from "./AllergenGroup";
import "./index.css";
import { useStyles } from "./styles";

import "swiper/swiper-bundle.min.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AllergensDialog({ open, onClose }) {
    const { middlewareApiUrl } = useSnapshot(store);
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const styles = useStyles();
    const dispatch = useDispatch();

    const {
        isPrm,
        project: {
            files: images,
            template: { content },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);

    const globalAllergens = useSelector((state) =>
        memoizedGlobalAllergens(state)
    );
    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const filteredButtons = actionsButtons.filter((item) => {
        return item.role === VALIDATE_IN_STEP_BUTTON_ROLE;
    })[0];

    const imageContent = getImageContentById(
        filteredButtons.id,
        images,
        customerLanguage.name
    );

    const validateImageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;
    const [localAllergens, setLocalAllergens] = React.useState(globalAllergens);

    const backButton = content.actionsButtons.items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backOmageContent = getImageContentById(
        backButton.id,
        images,
        customerLanguage.name
    );

    const backImageSrc =
        backOmageContent?.includes("http") ||
        backOmageContent?.includes("https")
            ? backOmageContent
            : `./images_order/${backOmageContent}`;

    function handleActiveAllergenOnClickEvent(id, group) {
        const updatedGlobalAllergens = localAllergens[group].map((allergen) => {
            if (allergen.id === id) {
                return {
                    ...allergen,
                    active: !allergen.active,
                };
            } else {
                return allergen;
            }
        });
        setLocalAllergens({
            ...localAllergens,
            [group]: [...updatedGlobalAllergens],
        });
    }

    function handleValikdateAllergenOnClickEvent() {
        dispatch(setGlobalAllergens(localAllergens));
        onClose();
    }

    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "1010px!important",
                    height: "1823px!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    borderRadius: "111px 111px 0px 0px!important",
                    borderTop: `15px solid ${globalColor}!important`,
                    maxWidth: "unset!important",
                    padding: "unset !important",
                    gap: "30px!important",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "unset",
                    top: "45px!important",
                    alignItems: "center!important",
                },
            }}
            TransitionComponent={Transition}
            style={{ backdropFilter: "blur(5px)" }}
        >
            {isPrm ? (
                <div style={{ height: "800px" }}>
                    <AllergenPrmLogoWrapper />
                </div>
            ) : null}
            <Box className={styles.title}> Informations Allergènes </Box>

            <Box className={styles.customCard}>
                <AllergenInfoIcon />
                <Box className={styles.subTitle}>
                    <span>
                        {
                            "Pour permettre à notre borne d'identifier les produits contenant des allergènes, veuillez les sélectionnez ."
                        }
                    </span>
                </Box>
            </Box>
            <div
                className="d-flex"
                style={{
                    flexDirection: isPrm ? "column-reverse" : "column",
                    gap: "30px",
                    height: isPrm ? "750px" : "1200px",
                }}
            >
                <Box className={styles.mainCard}>
                    <Box
                        className="d-flex"
                        style={{
                            gap: "10px",
                            height: isPrm ? "333px" : "auto",
                        }}
                    >
                        <div
                            className="d-flex justify-content-center my-3"
                            style={{ width: "100%", gap: "50px" }}
                        >
                            {Object.keys(localAllergens).length === 1 ? (
                                Object.keys(localAllergens).map((group) => {
                                    return (
                                        <div key={shortid.generate()}>
                                            <Box
                                                className="d-flex flex-column align-items-center"
                                                style={{
                                                    gap: "10px",
                                                    width: "100%",
                                                    overflowY: "auto",
                                                    scrollbarWidth: "none",
                                                    height: isPrm
                                                        ? "310px"
                                                        : "800px",
                                                }}
                                            >
                                                <div className="allergens-in-one-group p-4">
                                                    {localAllergens[group].map(
                                                        (allergen, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="allergen-card__clz"
                                                                    onClick={() => {
                                                                        handleActiveAllergenOnClickEvent(
                                                                            allergen.id,
                                                                            group
                                                                        );
                                                                    }}
                                                                    style={{
                                                                        background:
                                                                            allergen.active ===
                                                                            true
                                                                                ? "linear-gradient(180deg, black 40%, var(--global-color) 100%)"
                                                                                : "transparent",
                                                                        color:
                                                                            allergen.active ===
                                                                            true
                                                                                ? "white"
                                                                                : "black",
                                                                        height: "50px",
                                                                        lineHeight:
                                                                            "1em",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={`${middlewareApiUrl}/static/${allergen.icon
                                                                            .split(
                                                                                "/"
                                                                            )
                                                                            .pop()}`}
                                                                        style={{
                                                                            height: "42px",
                                                                            width: "42px",
                                                                            backgroundColor:
                                                                                allergen.active ===
                                                                                true
                                                                                    ? "#938585"
                                                                                    : globalColor,
                                                                        }}
                                                                    />

                                                                    <span
                                                                        className="two-lines-preview-text"
                                                                        style={{
                                                                            width: "175px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            allergen.name
                                                                        }
                                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </Box>
                                            {!isPrm ? (
                                                localAllergens[group].length >
                                                30 ? (
                                                    <hr className="separation-line" />
                                                ) : null
                                            ) : localAllergens[group].length >
                                              10 ? (
                                                <hr className="separation-line" />
                                            ) : null}
                                        </div>
                                    );
                                })
                            ) : Object.keys(localAllergens).length <= 3 ? (
                                Object.keys(localAllergens).map(
                                    (group, index) => {
                                        return (
                                            <AllergenGroup
                                                key={`group${index}`}
                                                globalAllergens={localAllergens}
                                                handleActiveAllergenOnClickEvent={
                                                    handleActiveAllergenOnClickEvent
                                                }
                                                group={group}
                                            />
                                        );
                                    }
                                )
                            ) : (
                                <Swiper
                                    slidesPerView={3}
                                    slidesPerGroup={3}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    style={{ height: isPrm ? "335px" : "auto" }}
                                >
                                    {Object.keys(localAllergens).map(
                                        (group) => {
                                            return (
                                                <SwiperSlide
                                                    key={shortid.generate()}
                                                >
                                                    <AllergenGroup
                                                        globalAllergens={
                                                            localAllergens
                                                        }
                                                        handleActiveAllergenOnClickEvent={
                                                            handleActiveAllergenOnClickEvent
                                                        }
                                                        group={group}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                                </Swiper>
                            )}
                        </div>
                    </Box>
                </Box>

                <Box className={styles.text}>
                    {`L’information du client sur la présence de substances allergènes dans tous les produits proposés au menu est devenue obligatoire
                     pour les restaurateurs depuis le 1er juillet 2015, date d’entrée en vigueur du décret n°2015-447 du 17 avril 2015 relatif à l’information
                      des consommateurs sur les allergènes et les denrées alimentaires non-préemballées.
                      Il y a lieu de rappeler que le décret n°2015-447 a retenu 14 allergènes majeurs qui doivent obligatoirement être informés aux
                      consommateurs dans les restaurants, afin qu’ils puissent choisir les plats en connaissance de cause et afin de prévenir tout risque lié à
                      l’allergie alimentaire .`}
                </Box>
            </div>

            <Box
                className="d-flex justify-content-center align-items-center pb-2"
                sx={{ gap: "15px" }}
            >
                {backImageSrc !== "" ? (
                    <div
                        className="mx-2"
                        style={{
                            backgroundSize: " 100% 100%",
                            backgroundImage: `url(${backImageSrc})`,
                            height: "87px",
                            width: "350px",
                        }}
                        onClick={onClose}
                    />
                ) : (
                    <img
                        alt="backButton"
                        src="./images_order/back.png"
                        style={{ height: "87px", width: "350px" }}
                        onClick={onClose}
                    />
                )}
                {validateImageSrc !== "" ? (
                    <div
                        className="mx-2"
                        style={{
                            backgroundSize: " 100% 100%",
                            backgroundImage: `url(${validateImageSrc})`,
                            height: "87px",
                            width: "350px",
                        }}
                        onClick={() => {
                            handleValikdateAllergenOnClickEvent();
                        }}
                    />
                ) : (
                    <img
                        alt="addToBasketButton"
                        src="./images_order/validate.png"
                        style={{ height: "87px", width: "350px" }}
                        onClick={() => {
                            handleValikdateAllergenOnClickEvent();
                        }}
                    />
                )}
            </Box>
        </Dialog>
    );
}

AllergensDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
