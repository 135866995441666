import { StyledButton } from "@aureskonnect/react-ui";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { t } from "i18next";

import React from "react";

import { setIsMiddlewareApiUrlExists, setMiddlewareApiUrl } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

export function MiddlewareApiUrlInputWrapper(): JSX.Element {
    function handleInputOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setMiddlewareApiUrl(event.target.value);
    }

    function handleValidateButtonOnClickEvent() {
        setIsMiddlewareApiUrlExists(true);
    }

    return (
        <FlexboxGrid
            justifyContentCentered={true}
            alignItemsCentered={true}
            gap="30px"
            className="flex-column"
        >
            <label
                className="d-flex flex-column"
                style={{
                    font: "normal 27px/32px Segoe UI",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: 1,
                    whiteSpace: "nowrap",
                    gap: "10px",
                }}
            >
                {t("Middleware api URL")}

                <AvForm>
                    <AvField
                        style={{
                            height: "65px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                        }}
                        id="middlewareApiUrl"
                        name="middlewareApiUrl"
                        type="text"
                        placeholder="http://localhost:5001/api/v1"
                        onChange={handleInputOnChangeEvent}
                        className="px-3"
                        validate={{
                            required: {
                                value: true,
                                errorMessage: t("This field is required"),
                            },
                        }}
                    />
                </AvForm>
            </label>
            <StyledButton
                onClick={handleValidateButtonOnClickEvent}
                block={true}
                rounded={true}
                variant="primary"
            >
                {t("Validate")}
            </StyledButton>
        </FlexboxGrid>
    );
}
