import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import {
    kioskStore,
    setIsAuthenticationAccepted,
    setNavigationIndex,
    setSelectedAuthMode,
} from "@pages/Kiosk/store";

type ImageContentPropsType = {
    item: ProjectMainContentItemType;
    activeAuthenticationModesItemsLength: number;
};

export function ImageContent({ item }: ImageContentPropsType): JSX.Element {
    const {
        kioskWays,
        customerLanguage,
        project: { files: images },
    } = useSnapshot(kioskStore);

    const imageContent = getImageContentById(
        item.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleOnClickEvent() {
        setSelectedAuthMode(item.shortName as string);
        setIsAuthenticationAccepted(true);
        const authModeConsammationStepIndex = kioskWays.indexOf(
            kioskWays.filter(
                (step) => step.name === "authenticationModeConsommation"
            )[0]
        );
        setNavigationIndex(authModeConsammationStepIndex);
    }

    return (
        <div
            className="justify-content-center align-items-center"
            onClick={handleOnClickEvent}
            style={{ display: "grid", gridTemplateRows: "6fr 1fr" }}
        >
            <div className="d-flex justify-content-center align-items-end h-100">
                <img
                    src={imageSrc}
                    alt={item.name}
                    style={{
                        height: "234px",
                        width: "234px",
                        objectFit: "fill",
                    }}
                />
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <span style={{ fontSize: "20px", whiteSpace: "nowrap" }}>
                    {item.languages[customerLanguage.name].name}
                </span>
            </div>
        </div>
    );
}
