import PropTypes from "prop-types";

import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export function ShopCardSkeleton({ item }) {
    return (
        <div
            className="d-flex flex-column justify-content-start align-items-center py-2"
            style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #0000001A",
                border: "1px solid  #E5E5E5",
                borderRadius: "12px",
                height: "219px",
                width:
                    item.compositions !== undefined || item.price > 0
                        ? "334px"
                        : "195px",
            }}
        >
            <div
                className="d-flex align-items-center px-2"
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    className="d-flex align-items-center flex-column"
                    style={{ width: "100%", gap: "5px" }}
                >
                    <div
                        className="d-flex align-items-start"
                        style={{
                            width: "100%",
                            justifyContent:
                                item.price > 0 ? "space-between" : "center",
                        }}
                    >
                        <SkeletonTheme
                            className="cart-image-product__clz py-2"
                            color="lightGray"
                            height={"70px"}
                            width={item.price > 0 ? "120px" : "90px"}
                            borderRadius={"10px"}
                        >
                            <Skeleton />
                        </SkeletonTheme>
                        {item.price > 0 && (
                            <SkeletonTheme
                                className="price-product-preview"
                                color="lightGray"
                                height={"40px"}
                                width={"70px"}
                                // width={isPrm ? 180 : 250}
                                // height={isPrm ? "100px" : "234px"}
                                borderRadius={"10px"}
                            >
                                <Skeleton />
                            </SkeletonTheme>
                        )}
                    </div>
                    <div
                        className="title-product-details"
                        style={{
                            font: "normal normal bold 16px/21px Segoe UI",
                            textAlign: "center",
                        }}
                    >
                        <SkeletonTheme
                            className="title-product-details"
                            color="lightGray"
                            height={"40px"}
                            width={item.price > 0 ? "200px" : "120px"}
                            borderRadius={"10px"}
                        >
                            <Skeleton />
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
            <div>
                <SkeletonTheme
                    // className="title-product-details"
                    color="lightGray"
                    height={"40px"}
                    width={"120px"}
                    // width={isPrm ? 180 : 250}
                    // height={isPrm ? "100px" : "234px"}
                    borderRadius={"10px"}
                >
                    <Skeleton />
                </SkeletonTheme>
            </div>
        </div>
    );
}
ShopCardSkeleton.propTypes = {
    item: PropTypes.object,
};
