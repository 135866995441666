import React from "react";
import { useSnapshot } from "valtio";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import OrderTakingScreen from "@components/OrderTaking/Screens/OrderTakingScreen";
import PrmOrderTakingScreen from "@components/OrderTaking/Screens/PrmOrderTakingScreen";

import { kioskStore } from "@pages/Kiosk/store";

export function OrderTakingStep(): JSX.Element {
    const { isPrm } = useSnapshot(kioskStore);

    return (
        <CustomErrorBoundary>
            {isPrm ? <PrmOrderTakingScreen /> : <OrderTakingScreen />}
        </CustomErrorBoundary>
    );
}
