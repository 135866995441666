import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { setWorkflowData } from "@components/OrderTaking/Store/feature";

function useRemoveNbrOfChoiceStep(listworkflow) {
    const dispatch = useDispatch();
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );

    return () => {
        if (listworkflow.length === 0) return null;
        const workflowStep = listworkflow.workflow[listworkflow.index];

        let NbrOfChoices = workflowStep.NbrOfChoices - 1;

        const copyworkflowData = JSON.parse(JSON.stringify(workflowData));
        copyworkflowData[copyworkflowData.length - 1].workflow[
            copyworkflowData[copyworkflowData.length - 1].index
        ].NbrOfChoices = NbrOfChoices;

        // ! in progress

        const {
            selectedChoicesWithspecialPrice,
            selectedChoicesWithStepPrice,
        } = JSON.parse(
            localStorage.getItem("stepDetails") || {
                selectedChoicesWithspecialPrice: 0,
                selectedChoicesWithStepPrice: 0,
            }
        );

        copyworkflowData[copyworkflowData.length - 1].workflow[
            copyworkflowData[copyworkflowData.length - 1].index
        ].NbrOfChoices = NbrOfChoices;

        copyworkflowData[copyworkflowData.length - 1].workflow[
            copyworkflowData[copyworkflowData.length - 1].index
        ].selectedChoicesWithStepPrice = selectedChoicesWithStepPrice;

        copyworkflowData[copyworkflowData.length - 1].workflow[
            copyworkflowData[copyworkflowData.length - 1].index
        ].selectedChoicesWithspecialPrice = selectedChoicesWithspecialPrice;
        dispatch(setWorkflowData(copyworkflowData));
    };
}

export default useRemoveNbrOfChoiceStep;
