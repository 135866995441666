import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import { clearMiddlwareOrder } from "@components/OrderTaking/Store/feature/MiddlwareOrderSlice";

import {
    kioskStore,
    setIsAmountReturned,
    setIsCommandInsertionProblemModalOpened,
    setIsOrderTakingPreviousStep,
    setLeftToPayAfterCashMachineValidation,
    setNavigationIndex,
    setPaymentModes,
} from "@pages/Kiosk/store";

export function CommandInsertionProblemModalWrapper(): JSX.Element {
    const {
        isPrm,
        project: {
            files: images,
            template: { content },
        },
        customerLanguage,
        isCommandInsertionProblemModalOpened,
        kioskWays,
    } = useSnapshot(kioskStore);

    const dispatch = useDispatch();

    const alertImgSrc = getAlertActionButtonImageSrc(
        content.actionsButtons as ActionsButtonsDesignType,
        images,
        customerLanguage.name
    );

    function handleOnClickEvent() {
        const meansOfPaymentStepIndex = kioskWays.indexOf(
            kioskWays.filter((step) => step.name === "meansOfPayment")[0]
        );
        const orderTakingStepIndex = kioskWays.indexOf(
            kioskWays.filter((step) => step.name === "orderTaking")[0]
        );

        if (
            meansOfPaymentStepIndex !== -1 &&
            kioskWays[meansOfPaymentStepIndex].skipped === false
        ) {
            setNavigationIndex(meansOfPaymentStepIndex);
        } else {
            setIsOrderTakingPreviousStep(true);
            setNavigationIndex(orderTakingStepIndex);
        }

        dispatch(clearMiddlwareOrder());
        setPaymentModes([]);
        setIsCommandInsertionProblemModalOpened(false);
        setIsAmountReturned(false);
        setLeftToPayAfterCashMachineValidation(0);
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isCommandInsertionProblemModalOpened}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (content.generalDesign as GeneralDesignType).colors[0]
                            .content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img src={alertImgSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Technical problem")}
                </span>
            </div>
            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "200px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("Problem inserting command")}
                </span>
            </div>

            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "200px" }}
            >
                <div
                    style={{
                        backgroundColor: "beige",
                        width: "500px",
                        height: "150px",
                        borderRadius: "20px",
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                    className="d-flex flex-column justify-content-center"
                >
                    <span>contact@support.com</span>
                    <span>01.12.34.56.78</span>
                </div>
            </div>
            <div style={{ height: "100px" }} />
            <div
                style={{ height: "120px" }}
                className="d-flex justify-content-center align-items-center"
            >
                <img
                    src="./images/back.png"
                    alt="action button"
                    onClick={() => handleOnClickEvent()}
                />
            </div>
        </Modal>
    );
}
