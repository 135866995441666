import { StyledButton, StyledModal } from "@aureskonnect/react-ui";
import classNames from "classnames";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { ModalBody } from "reactstrap";

import { store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { setIsSyncModalOpened } from "@components/Synchronization/store";
import { MiddlewareApiUrlInputWrapper } from "@components/SyncModal/MiddlewareApiUrlInputWrapper";

import "./index.css";

export function SyncModal(): JSX.Element {
    const { isAppSynchronized, isMiddlewareApiUrlExists } = useSnapshot(store);

    const [isSyncLocalModalOpened, setIsSyncLocalModalOpened] =
        React.useState(false);

    function toggle() {
        setIsSyncLocalModalOpened((prevState) => !prevState);
    }

    function handleLeaveButtonOnClickEvent() {
        // eslint-disable-next-line
        // (window as any).electronAPI.sendQuitEvent();
    }

    // React.useEffect(() => {
    //     setIsSyncLocalModalOpened(!isAppSynchronized);
    // }, []);

    return (
        <StyledModal
            className={classNames({
                "sync-modal__clz": isMiddlewareApiUrlExists,
            })}
            centered={true}
            isOpen={isSyncLocalModalOpened}
        >
            <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                {isMiddlewareApiUrlExists ? (
                    <React.Fragment>
                        <FlexboxGrid
                            justifyContentCentered={true}
                            alignItemsCentered={true}
                            gap="30px"
                            className="flex-column"
                        >
                            <video
                                autoPlay={true}
                                muted={true}
                                loop={true}
                                style={{
                                    height: "200px",
                                    width: "200px",
                                }}
                            >
                                <source
                                    src="./images/diagram.mp4"
                                    type="video/mp4"
                                />
                            </video>
                            <h2
                                className="text-uppercase text-center"
                                style={{
                                    font: "normal normal bold 50px/55px Bebas Neue",
                                    letterSpacing: "2px",
                                    color: "#171717",
                                }}
                            >
                                {t("App not synchronized")}
                            </h2>
                        </FlexboxGrid>
                        <div className="d-flex flex-column align-items-center">
                            <span>contact@support.com</span>
                            <span>01.98.47.35.43</span>
                        </div>

                        <FlexboxGrid
                            alignItemsCentered={true}
                            justifyContentCentered={true}
                            gap="10px"
                        >
                            <StyledButton
                                outline={true}
                                rounded={true}
                                variant="light"
                                // onClick={handleLeaveButtonOnClickEvent}
                                className="sync-btn__clz text-uppercase"
                            >
                                {t("Leave")}
                            </StyledButton>
                            <StyledButton
                                variant="primary"
                                rounded={true}
                                className="sync-btn__clz text-uppercase"
                                onClick={() => {
                                    toggle();
                                    setIsSyncModalOpened(true);
                                }}
                            >
                                {t("Sync My App")}
                            </StyledButton>
                        </FlexboxGrid>
                    </React.Fragment>
                ) : (
                    <MiddlewareApiUrlInputWrapper />
                )}
            </ModalBody>
        </StyledModal>
    );
}
