import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    kioskStore,
    setChosenTransition,
    setIsSubStepActionButtonsActive,
    setIsSubStepIconActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepLogoActive,
    setIsSubStepTopBannerActive,
    setSubStepActionButtons,
    setSubStepIconId,
    setSubStepInformationMessageItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function ClickAndCollectStep(): JSX.Element {
    const { transitions } = useSnapshot(store);
    const {
        isPrm,
        project: {
            template: {
                pages: { subSteps },
            },
        },
    } = useSnapshot(kioskStore);

    React.useEffect(() => {
        setIsSubStepIconActive(
            // eslint-disable-next-line
            (subSteps["clickAndCollect"] as PageType).header.icon!.active
        );
        setIsSubStepInformationMessageActive(
            (subSteps["clickAndCollect"] as PageType).header.informationMessage
                .active
        );
        setSubStepInformationMessageItems(
            (subSteps["clickAndCollect"] as PageType).header.informationMessage
                .languages
        );
        setIsSubStepTopBannerActive(
            (subSteps["clickAndCollect"] as PageType).topBanner.active
        );
        setIsSubStepLogoActive(
            (subSteps["clickAndCollect"] as PageType).topBanner.logo.active
        );
        setIsSubStepActionButtonsActive(
            (subSteps["clickAndCollect"] as PageType).actionsButtons.active
        );
        setSubStepActionButtons(
            (subSteps["clickAndCollect"] as PageType).actionsButtons.items
        );

        setSubStepIconId(
            // eslint-disable-next-line
            (subSteps["clickAndCollect"] as PageType).header.icon!.id
        );
        setChosenTransition(
            transitions[(subSteps["clickAndCollect"] as PageType).animation]
        );
    });

    return isPrm ? <PrmMode /> : <UsualMode />;
}
