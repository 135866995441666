import {
    NO_THANKS_BUTTON_ROLE,
    VALIDATE_IN_STEP_BUTTON_ROLE,
} from "@constants";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import PrmProducts from "@components/OrderTaking/Components/Products/ProductWrapper/PmrProducts";
import {
    setAdditionalItems,
    validateAdditionnalItems,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { ListItems } from "../../Products/ProductWrapper/ListItems";
import "./index.css";

export default function AdditionalSale({ open, onClose }) {
    const dispatch = useDispatch();
    const {
        isPrm,
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const { additionalSale } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const noThanksButton = actionsButtons.filter((item) => {
        return item.active === true && item.role === NO_THANKS_BUTTON_ROLE;
    })[0];

    const validateButton = actionsButtons.filter((item) => {
        return (
            item.active === true && item.role === VALIDATE_IN_STEP_BUTTON_ROLE
        );
    })[0];

    const noThnxImageContent = getImageContentById(
        noThanksButton.id,
        images,
        customerLanguage.name
    );

    const noThnxImageSrc =
        noThnxImageContent?.includes("http") ||
        noThnxImageContent?.includes("https")
            ? noThnxImageContent
            : `./images_order/${noThnxImageContent}`;

    const validateImageContent = getImageContentById(
        validateButton.id,
        images,
        customerLanguage.name
    );

    const validateImageSrc =
        validateImageContent?.includes("http") ||
        validateImageContent?.includes("https")
            ? validateImageContent
            : `./images_order/${validateImageContent}`;

    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    function handleAddtoOrderOnCLickEvent() {
        dispatch(validateAdditionnalItems(additionalSale?.selectedItems));
        dispatch(
            setAdditionalItems({
                name: "",
                listItems: [],
                selectedItems: [],
            })
        );
    }
    function handleCancelOnCLickEvent() {
        dispatch(
            setAdditionalItems({
                name: "",
                listItems: [],
                selectedItems: [],
            })
        );
    }

    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "944px!important",
                    height: isPrm ? "35%!important" : "1724px!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    borderRadius: "65px!important",
                    borderTop: `15px solid ${globalColor}!important`,
                    maxWidth: "unset!important",
                    padding: "25px!important",
                    display: "flex",
                    justifyContent: "start",
                    maxHeight: "unset",
                    alignItems: "center!important",
                    top: isPrm ? "32%!important" : "unset!important",
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div className="column-centred-items-additional-sale header-additional-sale-dialog">
                <span className="text-header-additional-sale">
                    {t("Do you want to")}
                </span>
                <span className="title-additional-sale">
                    {additionalSale?.name}
                </span>
            </div>
            {!isPrm ? <hr className="separation-line" /> : null}
            <div className="list-additional-products">
                {!isPrm ? (
                    <ListItems
                        isAdditionalSale={true}
                        listAdditionnalItems={additionalSale?.listItems}
                    />
                ) : (
                    <PrmProducts
                        isAdditionalSale={true}
                        listAdditionnalItems={additionalSale?.listItems}
                    />
                )}
            </div>
            {!isPrm ? <hr className="separation-line" /> : null}
            <div className="centered-items w-100" style={{ height: "150px" }}>
                {noThnxImageSrc !== "" ? (
                    <div
                        className="additional-sale-button  mx-2"
                        style={{
                            backgroundSize: " 100% 100%",
                            backgroundImage: `url(${noThnxImageSrc})`,
                        }}
                        onClick={() => {
                            handleCancelOnCLickEvent();
                            onClose();
                        }}
                    ></div>
                ) : (
                    <button
                        className="additional-sale-button mx-2"
                        style={{
                            background: `${globalColor}`,
                        }}
                        onClick={() => {
                            handleCancelOnCLickEvent();
                            onClose();
                        }}
                    >
                        <div className="text-button">{t("No thanks")}</div>
                    </button>
                )}
                {additionalSale?.selectedItems.length > 0 &&
                    (validateImageSrc !== "" ? (
                        <div
                            className="additional-sale-button mx-2"
                            style={{
                                backgroundSize: " 100% 100%",
                                backgroundImage: `url(${validateImageSrc})`,
                            }}
                            onClick={() => {
                                onClose();
                                handleAddtoOrderOnCLickEvent();
                            }}
                        ></div>
                    ) : (
                        <button
                            className="additional-sale-button"
                            style={{
                                background: `${globalColor}`,
                            }}
                            onClick={() => {
                                onClose();
                                handleAddtoOrderOnCLickEvent();
                            }}
                        >
                            <div className="text-button">{t("Validate")}</div>
                        </button>
                    ))}
            </div>
        </Dialog>
    );
}

AdditionalSale.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
