import React from "react";
import { useSnapshot } from "valtio";
import Modal from "react-modal";
import { t } from "i18next";

import {
    setIsInvalidQrCodeModalOpened,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

type PropsType = {
    containerRef: HTMLElement | null;
};
export function QrCodeIncorrectModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const { isInvalidQrCodeModalOpened, isPrm } = useSnapshot(store);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isInvalidQrCodeModalOpened}
            onRequestClose={() => {
                setIsInvalidQrCodeModalOpened(!isInvalidQrCodeModalOpened);
            }}
            contentLabel="qr-code -incorrect"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px solid #040404",
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                },
            }}
        >
            <div
                onClick={() => {
                    setIsInvalidQrCodeModalOpened(!isInvalidQrCodeModalOpened);
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "400px" }}
                >
                    <img src="./images/exclamation-icon.png" />
                </div>

                <div
                    className="d-flex justify-content-center align-items-center text-center w-100"
                    style={{ height: "170px", fontSize: "55px" }}
                >
                    {t("Withdrawal C&C")}
                </div>
                <div
                    className="d-flex flex-column justify-content-center align-items-center text-center w-100"
                    style={{ height: "230px", fontSize: "45px" }}
                >
                    <span>{t("Unknown QR code.")}</span>
                    <span>{t("Please contact a manager")}</span>
                </div>
                <div style={{ height: "370px" }}></div>
                <div
                    className="d-flex justify-content-center align-items-center text-center w-100"
                    style={{ height: "180px", fontSize: "35px" }}
                >
                    {t("Touch the screen to continue")}
                </div>
            </div>
        </Modal>
    );
}
