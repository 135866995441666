import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getAlertActionButtonImageSrc,
    handleAbortCashMachineTransaction,
} from "@helpers/general";

import {
    kioskStore,
    setFinalMessageCounter,
    setIsCashMachineTransactionCanceled,
    setIsHelpModalOpened,
    setIsPrm,
    setIsStandbyModalOpened,
    setNavigationIndex,
} from "@pages/Kiosk/store";

export function StandbyModalWrapper(): JSX.Element {
    const {
        initialStepTimeout,
        isStandbyModalOpened,
        isPrm,
        project: {
            files: images,
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        customerLanguage,
        isCommandInsertionProblemModalOpened,
        kioskWays,
        navigationIndex,
    } = useSnapshot(kioskStore);

    const [counter, setCounter] = React.useState<number>(initialStepTimeout);

    React.useEffect(() => {
        let timer: NodeJS.Timeout;

        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        }

        if (counter === 0) {
            setNavigationIndex(0);
            setIsPrm(false);
            setIsHelpModalOpened(false);

            if (kioskWays[navigationIndex].name === "payment") {
                handleAbortCashMachineTransaction();
                setIsCashMachineTransactionCanceled(true);
            }
        }

        return () => clearTimeout(timer);
    }, [counter]);

    return (
        <Modal
            isOpen={isStandbyModalOpened}
            onRequestClose={() => {
                setIsStandbyModalOpened(false);
                if (isCommandInsertionProblemModalOpened) {
                    setFinalMessageCounter(0);
                }
            }}
            contentLabel="standby-modal"
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    color: "black",
                    height: "1230px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "111px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "400px" }}
                onClick={() => {
                    setIsStandbyModalOpened(false);
                }}
            >
                <img
                    src={getAlertActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType,
                        images,
                        customerLanguage.name
                    )}
                />
            </div>
            <div
                className="d-flex flex-column text-center"
                style={{ height: "202px", fontSize: "55px", gap: "10px" }}
                onClick={() => {
                    setIsStandbyModalOpened(false);
                }}
            >
                <span>{t("Your order")}</span>
                <span>{t("will be abandoned in")}</span>
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{
                    height: "400px",
                    font: "normal 70px Segoe UI",
                }}
                onClick={() => {
                    setIsStandbyModalOpened(false);
                }}
            >
                {counter} {t("secondes")}
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{ height: "180px", fontSize: "35px" }}
                onClick={() => {
                    setIsStandbyModalOpened(false);
                }}
            >
                {t("Touch the screen to continue")}
            </div>
        </Modal>
    );
}
