import { StyledSelectInput } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { setSelectedSaleModeValue, kioskStore } from "@pages/Kiosk/store";

export function SalesMethodsSelectWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: {
            template: {
                content: { salesMethods },
            },
        },
    } = useSnapshot(kioskStore);

    const [salesMethodsOptions, setSalesMethodsOptions] = React.useState<
        LocalOptionsType[]
    >([]);

    const [selectedSalesMethodsOption, setSelectedSalesMethodsOption] =
        React.useState<LocalOptionsType>(salesMethodsOptions[0]);

    function handleSelectOnChangeEvent(selectedValue: LocalOptionsType) {
        setSelectedSaleModeValue(selectedValue.value as string);
        setSelectedSalesMethodsOption(selectedValue);
    }

    React.useEffect(() => {
        const options = (salesMethods as ProjectContentItemType).items
            .filter((saleMethod) => {
                return saleMethod.name !== "Retrait C&C";
            })
            .map((option) => ({
                label: t(option.name) as string,
                value: option.name,
            }));

        setSalesMethodsOptions(options);
    }, [salesMethods]);

    return (
        <StyledSelectInput
            id="sales-methods-select"
            name="sales-methods-select"
            options={salesMethodsOptions}
            value={
                selectedSalesMethodsOption !== undefined
                    ? selectedSalesMethodsOption
                    : salesMethodsOptions[0]
            }
            onChange={handleSelectOnChangeEvent}
            noOptionsMessage={() => t("There's no more choice")}
            className="w-25 ml-4"
        />
    );
}
