import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function LogoWrapper({ width, height }) {
    const {
        logo,
        isTopBannerActive,
        isLogoActive,
        customerLanguage,
        project: { files: images },
    } = useSnapshot(kioskStore);

    const imageContent = getImageContentById(
        logo.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <React.Fragment>
            {isTopBannerActive ? (
                isLogoActive ? (
                    <img
                        src={imageSrc}
                        alt="logo"
                        style={{
                            width: width,
                            height: height,
                            borderRadius: "100%",
                        }}
                    />
                ) : null
            ) : null}
        </React.Fragment>
    );
}

LogoWrapper.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};
