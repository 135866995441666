import PropTypes from "prop-types";

import React from "react";
import { FixedSizeGrid as Grid } from "react-window";

import { MemoizedCardProduct } from "@components/OrderTaking/Components/Products/ProductWrapper/CardProduct";

const getIdByGridPosition = (col, row) => row * 2 + col;

export const FixedSizeGrid = ({ visibleItems, listworkflow, orderItems }) => {
    const rowCount =
        visibleItems.length % 2 === 0
            ? visibleItems.length / 2
            : visibleItems.length / 2 + 1;
    return (
        <Grid
            height={1000}
            width={860}
            itemData={visibleItems}
            columnCount={2}
            columnWidth={400}
            rowCount={rowCount}
            overscanRowCount={1}
            rowHeight={480}
            innerElementType="div"
            className="list-container"
        >
            {({ columnIndex, rowIndex, data, style }) => {
                const id = getIdByGridPosition(columnIndex, rowIndex);

                if (
                    !visibleItems ||
                    visibleItems.length === 0 ||
                    id >= visibleItems.length
                )
                    return null;
                return (
                    <React.Fragment key={data[id].iuud}>
                        <div
                            className="col-6 centered-block item mx-4 "
                            style={{
                                ...style,
                                margin: "20px",
                            }}
                        >
                            <MemoizedCardProduct
                                product={data[id]}
                                listworkflow={listworkflow}
                                orderItems={orderItems}
                            />
                        </div>
                    </React.Fragment>
                );
            }}
        </Grid>
    );
};
FixedSizeGrid.propTypes = {
    listworkflow: PropTypes.object,
    visibleItems: PropTypes.array,
    orderItems: PropTypes.array,
};
