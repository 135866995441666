import PropTypes from "prop-types";

import React from "react";

export function OrderTakingBackgroundWrapper({
    children,
    filename,
    isBackgroundActive,
}) {
    const possibleExtensions = ["mp4", "mov", "avi", "flv", "wmv"];
    const extension = filename.split(".")[filename.split(".").length - 1];

    return (
        <React.Fragment>
            {isBackgroundActive ? (
                possibleExtensions.includes(extension) ? (
                    <React.Fragment>
                        <video
                            autoPlay
                            muted
                            loop
                            style={{
                                objectFit: "cover",
                                height: "100%",
                                width: "100%",
                                position: "fixed",
                            }}
                        >
                            <source src={filename} />
                        </video>
                        {children}
                    </React.Fragment>
                ) : (
                    <div
                        className="d-flex flex-column align-items-center"
                        style={{
                            backgroundImage: `url(${filename})`,
                            backgroundSize: "100% 100%",
                            height: "100%",
                            width: "100%",
                            overflow: "auto",
                            scrollbarWidth: "none",
                        }}
                    >
                        {children}
                    </div>
                )
            ) : (
                { children }
            )}
        </React.Fragment>
    );
}
OrderTakingBackgroundWrapper.propTypes = {
    filename: PropTypes.string,
    children: PropTypes.object,
    isBackgroundActive: PropTypes.bool,
};
