import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { LogoWrapper } from "@components/common/LogoWrapper";

import {
    setChosenInformationMode,
    setFinalMessageSelectedInformationMode,
    setIsInformationModesModalOpened,
    setIsUserAccountModalOpened,
    setOrderModificationMode,
    setSelectedInformationMode,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    kioskStore as store,
} from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function UsualMode(): JSX.Element {
    const {
        isInformationModesModalOpened,
        customerLanguage,
        project: {
            template: {
                content: { informationModes, generalDesign },
            },
            files: images,
        },
        isHeaderActive,
        isUserAccountActive,
        isUserAuthenticated,
        userAccountMessageItems,
        user,
        isInformationMessageActive,
        informationMessageItems,
        saleMode,
    } = useSnapshot(store);

    const informationModesItems = (informationModes as ProjectContentItemType)
        .items;

    const activeInformationModesItems = informationModesItems.filter(
        (item: ProjectMainContentItemType) => {
            return (item.active as ItemContentType)[saleMode.name] === true;
        }
    );

    const activeInformationModesItemsLength =
        activeInformationModesItems.length;

    const backButtonImage = images.filter((item: ImageItemType) => {
        return item.name?.toLowerCase() === "ignore";
    })[0].content as ImageItemLanguagesType;

    const backButtonImageSrc =
        backButtonImage[customerLanguage.name].path?.includes("http") ||
        backButtonImage[customerLanguage.name].path?.includes("https")
            ? backButtonImage[customerLanguage.name].path
            : `./images/${backButtonImage[customerLanguage.name].path}`;

    return (
        <Modal
            isOpen={
                activeInformationModesItemsLength !== 0 &&
                isInformationModesModalOpened
            }
            ariaHideApp={false}
            onRequestClose={() => {
                setIsInformationModesModalOpened(
                    !isInformationModesModalOpened
                );
            }}
            contentLabel="userAccount-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1742px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <LogoWrapper />

            {isHeaderActive ? (
                <div
                    style={{
                        display: "grid",
                    }}
                    className="justify-content-center align-items-center"
                >
                    {isUserAccountActive && isUserAuthenticated ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ gap: "20px" }}
                        >
                            <img
                                src="./images/man-head-header.png"
                                alt="header"
                                style={{
                                    height: "35px",
                                    width: "35px",
                                }}
                            />
                            <span className="mb-0" style={{ fontSize: "35px" }}>
                                {
                                    userAccountMessageItems[
                                        customerLanguage.name
                                    ].content
                                }{" "}
                                {user.firstName === ""
                                    ? user.lastName
                                    : user.firstName}
                            </span>
                        </div>
                    ) : null}

                    {isInformationMessageActive ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <span
                                style={{
                                    fontSize: "50px",
                                    textAlign: "center",
                                }}
                            >
                                {
                                    informationMessageItems[
                                        customerLanguage.name
                                    ].content
                                }
                            </span>
                        </div>
                    ) : null}
                </div>
            ) : null}

            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "928px", width: "865px" }}
            >
                <MainContentWrapper />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "144px", width: "865px" }}
            >
                <img
                    src={backButtonImageSrc}
                    alt="back"
                    onClick={() => {
                        if (saleMode.name === "Delivery") {
                            if (isUserAuthenticated === false) {
                                setIsUserAccountModalOpened(true);
                            } else {
                                setSelectedSaleModeValue(saleMode.name);
                                setSelectedSaleModeId(saleMode.id);
                                setSelectedSaleModeOrderTaking(
                                    saleMode.settingKey
                                );
                                setOrderModificationMode();
                                setFinalMessageSelectedInformationMode("none");
                                setSelectedInformationMode("");
                            }
                        } else {
                            setFinalMessageSelectedInformationMode("none");
                            setSelectedInformationMode("");
                            setChosenInformationMode({
                                type: "",
                                data: { infoModeUuId: "", value: "" },
                            });
                        }
                        setIsInformationModesModalOpened(
                            !isInformationModesModalOpened
                        );
                    }}
                />
            </div>
        </Modal>
    );
}
