import { BASIC_COMPOSITION_TYPE } from "@constants";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { groupWorkflowShopCard } from "@components/OrderTaking/Helpers";

import { ShowCardProductMemo } from "./ShowCardProduct";

export default function WorkflowShopCarts() {
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const { shopCardMessage } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const { workflowData } = useSelector((state) => state.workFlowDataSlice);

    let workflowCards = {};

    if (workflowData.length === 1) {
        const copyOrderWorkflowItem = orderWorkflowItemSlice;

        let data = {};
        Object.keys(copyOrderWorkflowItem.item.shopCart).forEach((key) => {
            if (key === BASIC_COMPOSITION_TYPE) {
                data = {
                    ...data,
                    [BASIC_COMPOSITION_TYPE]:
                        copyOrderWorkflowItem.item.shopCart[key],
                };
            } else {
                data = {
                    ...data,
                    ...copyOrderWorkflowItem.item.shopCart[key].compositions,
                };
            }
        });

        if (Object.keys(data).length > 0) {
            workflowCards = data;
        }
    } else {
        let listworkflow = workflowData[workflowData.length - 1];
        if (listworkflow) {
            if (listworkflow?.workflow[listworkflow.index]?.path) {
                let path = [];
                listworkflow?.workflow[listworkflow.index].path.forEach(
                    (elm) => {
                        path.push(elm.name);
                    }
                );
                let objectTostep = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, orderWorkflowItemSlice.item.shopCart);
                workflowCards = objectTostep;
            }
        }
    }
    const groupedItems = groupWorkflowShopCard(workflowCards);

    return (
        <div className="workflow-basket">
            {groupedItems.length > 0 ? (
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    watchOverflow
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="shopcart-products-swiper-container swiper-pagination-bullet__clz"
                    style={{
                        width: "100% !important",
                        height: "100% !important",
                    }}
                >
                    {groupedItems.reverse().map((item) => {
                        return (
                            <SwiperSlide
                                key={item.iuud}
                                className="slide-popup my-2"
                            >
                                <ShowCardProductMemo item={item} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            ) : (
                <div
                    style={{ height: "100%" }}
                    className="d-flex justify-content-center d-flex align-items-center"
                >
                    <h3>{shopCardMessage} </h3>
                </div>
            )}
        </div>
    );
}
