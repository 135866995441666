import { StyledButton } from "@aureskonnect/react-ui";
import swal from "@sweetalert/with-react";
import classNames from "classnames";
import { t } from "i18next";
import { snapshot, useSnapshot } from "valtio";

import React from "react";
import Avatar from "react-avatar";
import Modal from "react-modal";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { useLocalWays } from "@hooks/useLocalWays";

import { AdvertisingScreen } from "@components/LocalSettings/LocalConfigModalWrapper/AdvertisingScreen";
import { CustomerAccount } from "@components/LocalSettings/LocalConfigModalWrapper/CustomerAccount";
import { InformationModes } from "@components/LocalSettings/LocalConfigModalWrapper/InformationModes";
import { Languages } from "@components/LocalSettings/LocalConfigModalWrapper/Languages";
import { Options } from "@components/LocalSettings/LocalConfigModalWrapper/Options";
import { Others } from "@components/LocalSettings/LocalConfigModalWrapper/Others";
import { PeripheralDevices } from "@components/LocalSettings/LocalConfigModalWrapper/PeripheralDevices";
import { RegulationModes } from "@components/LocalSettings/LocalConfigModalWrapper/RegulationModes";
import { SalesModes } from "@components/LocalSettings/LocalConfigModalWrapper/SalesModes";
import { KioskAppLogoSvgIcon } from "@components/SvgIcons";
import { MenuSvgIcon } from "@components/SvgIcons/MenuSvgIcon";
import {
    setLoginKeyboardInputsValues,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setActiveConfigOptions,
    setActiveLanguages,
    setActiveTabTitle,
    setIsAuthenticationModalOpened,
    setIsConfigOptionsActive,
    setIsLanguagesActive,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setIsMenuModalOpened,
    setKioskWays,
    setLocalProject,
    setPagesItems,
    setProject,
    setQrCodeValue,
} from "@pages/Kiosk/store";

import "./index.css";

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
};

export function LocalConfigModalWrapper(): JSX.Element {
    const { isLocalConfigModalOpened, localProject, isMenuModalOpened } =
        useSnapshot(kioskStore);

    const { selectedShopApp, connectedUser } = useSnapshot(syncStore);

    const [firstName, setFirstName] = React.useState<string>(
        (connectedUser.firstName as string) || ""
    );

    const [
        isSavingNewProjectRequestBegins,
        setIsSavingNewProjectRequestBegins,
    ] = React.useState(false);

    const localKioskWays = useLocalWays(localProject);

    const shopDetail = JSON.parse(localStorage.getItem("shopDetail") as string);

    const [activeTab, setActiveTab] = React.useState<number>(0);

    const tabs: TabsMenuType[] = [
        {
            title: t("Options"),
            content: <Options />,
        },
        {
            title: t("Sales methods"),
            content: <SalesModes />,
        },
        {
            title: t("Regulation modes"),
            content: <RegulationModes />,
        },
        {
            title: t("Information modes"),
            content: <InformationModes />,
        },
        {
            title: t("Customer account"),
            content: <CustomerAccount />,
        },
        {
            title: t("Languages"),
            content: <Languages />,
        },
        {
            title: t("Advertising screen"),
            content: <AdvertisingScreen />,
        },
        {
            title: t("Peripheral devices"),
            content: <PeripheralDevices />,
        },
        {
            title: t("Others"),
            content: <Others />,
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    async function handleUpdateKioskButtonOnClickEvent() {
        setIsSavingNewProjectRequestBegins(true);
        const { localProject } = snapshot(kioskStore);

        const apiUrl = `${process.env.REACT_APP_SETTINGS_APP_API}/settings/application/kiosk/template`;

        const data: ObjectType = {};

        data[localProject.projectId as string] = {
            ...localProject,
        };
        const requestBody = JSON.stringify({
            shopId: shopDetail.shop_uid,
            userId: 106,
            franchiseId: shopDetail.franchise_uid,
            appId: "KIOSK",
            appId_children: selectedShopApp.name,
            data,
        });

        try {
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: requestBody,
                method: "POST",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("Error while saving template !");
                    }

                    setIsSavingNewProjectRequestBegins(false);

                    setLocalProject(localProject);

                    return swal({
                        icon: "success",
                        content: <p>{t("Template saved successfully")}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                });
        } catch (error: unknown) {
            setIsSavingNewProjectRequestBegins(false);
            console.log(
                `Error while saving project template, with message: ${
                    (error as Error).message
                }`
            );
            return swal({
                icon: "error",
                content: <p>{t("There's an error")}</p>,
                buttons: false,
                timer: 2000,
            });
        }
    }

    function handleOnClickEvent() {
        const { localProject } = snapshot(kioskStore);

        setIsConfigOptionsActive(
            (
                (localProject.template.content as ProjectContentType)
                    .options as OptionsType
            ).active
        );

        setActiveConfigOptions(
            (
                (localProject.template.content as ProjectContentType)
                    .options as OptionsType
            ).items.filter((option) => option.active === true)
        );

        setIsLanguagesActive(
            (
                (localProject.template.content as ProjectContentType)
                    .languages as LanguagesType
            ).active &&
                (
                    (localProject.template.content as ProjectContentType)
                        .languages as LanguagesType
                )?.items.filter((option) => option.active === true).length !== 1
        );

        setActiveLanguages(
            (
                (localProject.template.content as ProjectContentType)
                    .languages as LanguagesType
            ).items.filter((language) => language.active === true)
        );
        setPagesItems(localProject.template.pages);
        setKioskWays(localKioskWays);

        setProject(localProject);

        setIsMenuModalOpened(false);
        handleUpdateKioskButtonOnClickEvent();
    }

    React.useEffect(() => {
        setActiveTabTitle(tabs[0].title);
    }, []);

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_CONSOLE_APP_API}/decryptWord`, {
            credentials: "omit",
            headers: {
                Accept: "text/plain",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `wordToDecrypted=${connectedUser.firstName}`,
            method: "POST",
        })
            .then((response) => response.text())
            .then((data) => {
                setFirstName(data);
            })
            .catch((error) => {
                setFirstName("");
                console.log(
                    `Error while decrypting word, with message: ${error.message}`
                );
            });
    });

    return (
        <Modal
            isOpen={isLocalConfigModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1366px",
                    width: "914px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <div
                style={{
                    display: "grid",
                    height: 160,
                    width: 900,
                    position: "relative",
                    gridTemplateColumns: "2fr 3fr",
                    gridTemplateRows: "1fr",
                }}
            >
                <video
                    autoPlay
                    muted
                    loop
                    style={{
                        objectFit: "cover",
                        position: "fixed",
                        height: 160,
                        width: 914,
                        zIndex: -1,
                    }}
                >
                    <source src="./images/background.mp4" type="video/mp4" />
                </video>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "40px",
                        gap: 70,
                    }}
                >
                    <KioskAppLogoSvgIcon width="300" height="125" />
                    <div
                        style={{
                            height: "125px",
                            width: "1px",
                            borderRight: "1px solid #c4c2c2",
                        }}
                    />
                </div>

                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: 50, marginBottom: "10px" }}
                >
                    <div className="d-flex flex-column">
                        <div
                            className="d-flex"
                            style={{
                                color: "#FFFFFF",
                                font: "normal bold 30px / 55px Segoe UI",
                                textAlign: "left",
                                letterSpacing: "1.2px",
                                gap: "10px",
                            }}
                        >
                            <span>{t("Hello")}</span>
                            <span className="text-capitalize">
                                {" "}
                                {firstName}
                            </span>
                        </div>
                        <div
                            style={{
                                color: "#FFFFFF",
                                font: "normal 27px/36px Segoe UI",
                                textAlign: "left",
                                letterSpacing: "1.2px",
                            }}
                        >
                            {selectedShopApp.name as string}
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                height: 20,
                                gap: 10,
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: "50%",
                                    borderStyle: "solid",
                                    borderWidth: 0.5,
                                    borderColor: "white",
                                    backgroundColor: "#00d640",
                                    height: 12,
                                    width: 12,
                                }}
                            />
                            <div
                                style={{
                                    color: "#FFFFFF",
                                    font: "normal normal normal 15px/55px Segoe UI",
                                    textAlign: "left",
                                    letterSpacing: "1.2px",
                                }}
                            >
                                votre borne est à jour
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <Avatar
                            email={connectedUser.email as string}
                            name={firstName as string}
                            size="70px"
                            round={true}
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "8fr 1fr",
                    height: "1203px",
                    display: "grid",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                        gridTemplateRows: "1fr",
                        margin: "20px",
                        border: "1px solid #707070",
                        borderRadius: "10px",
                    }}
                    onClick={() => {
                        setIsMenuModalOpened(false);
                    }}
                >
                    <div
                        style={{
                            background: "#323333",
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                        }}
                    >
                        <Nav
                            tabs={true}
                            className="d-flex flex-column"
                            style={{
                                backgroundColor: "#323333",
                                border: "unset",
                                borderRadius: "10px",
                                marginTop: 40,
                            }}
                        >
                            {tabs.map((element, index) => {
                                return (
                                    <NavItem
                                        key={element.title}
                                        className="pb-1"
                                        style={{
                                            borderRadius: "10px",
                                            marginBottom: 20,
                                        }}
                                    >
                                        <NavLink
                                            className={classNames(
                                                "border-0 menu-wrapper-nav-link__clz",
                                                {
                                                    "active menu-wrapper-items-nav-link-active__clz":
                                                        activeTab === index,
                                                }
                                            )}
                                            onClick={() => {
                                                toggleNav(index);
                                                setActiveTabTitle(
                                                    element.title
                                                );
                                            }}
                                        >
                                            <span className="px-2">
                                                {element.title}
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>
                    </div>
                    <div>
                        <TabContent activeTab={activeTab}>
                            {tabs.map((element, index) => {
                                return (
                                    <TabPane
                                        tabId={index}
                                        key={element.title}
                                        className={classNames("h-100", {
                                            "design-active-tab__clz":
                                                activeTab === 4,
                                        })}
                                    >
                                        {element.content}
                                    </TabPane>
                                );
                            })}
                        </TabContent>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                        background: " #E9E9E9 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #00000029",
                        border: "1px solid #FFFFFF",
                    }}
                >
                    <MenuSvgIcon
                        style={{
                            height: "75px !important",
                            width: "75px !important",
                            marginLeft: "15px",
                        }}
                        onClick={() => {
                            setIsMenuModalOpened(!isMenuModalOpened);
                        }}
                    />
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginRight: "15px" }}
                    >
                        <StyledButton
                            rounded={true}
                            className="m-2"
                            style={{
                                width: "179px",
                                height: "75px",
                                font: "normal normal 600 18px/24px Segoe UI",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #C9C9C9",
                                borderRadius: "12px",
                                color: "black",
                            }}
                            onClick={() => {
                                setIsAuthenticationModalOpened(false);
                                setIsLocalSettingModalOpened(false);
                                setIsLocalConfigModalOpened(false);
                                setIsMenuModalOpened(false);
                                setQrCodeValue("");
                                setLoginKeyboardInputsValues({
                                    email: "",
                                    password: "",
                                });
                            }}
                        >
                            {t("Leave")}
                        </StyledButton>
                        <StyledButton
                            rounded={true}
                            disabled={isSavingNewProjectRequestBegins === true}
                            className="m-2"
                            style={{
                                width: "179px",
                                height: "75px",
                                background: "#545454",
                                font: "normal normal 600 18px/24px Segoe UI",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #FFFFFF",
                                borderRadius: "12px",
                                marginRight: "0px",
                            }}
                            onClick={handleOnClickEvent}
                        >
                            {t("Save")}
                        </StyledButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
