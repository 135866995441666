import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

export function StepImage({ stepImageUrl, isBasicComposition }) {
    const { middlewareApiUrl } = useSnapshot(store);
    const stepImg = !isBasicComposition
        ? process.env.REACT_APP_CARD_ENV != "dev"
            ? `${middlewareApiUrl}/static/${stepImageUrl.split("/").pop()}`
            : stepImageUrl
        : stepImageUrl;
    return (
        <div className="d-flex align-items-center justify-content-center w-100 h-50">
            <img
                style={{
                    maxWidth: "119px",
                    height: "100%",
                }}
                src={stepImg}
            />
        </div>
    );
}
StepImage.propTypes = {
    stepImageUrl: PropTypes.string,
    isBasicComposition: PropTypes.bool,
};
