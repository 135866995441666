import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import {
    kioskStore,
    setFinalMessageSelectedInformationMode,
    setIsConfirmationModalOpened,
    setIsInformationModesConsommationModalOpened,
    setLocalSelectedInformationMode,
    setSelectedInformationMode,
} from "@pages/Kiosk/store";

type ImageContentPropsType = {
    item: ActionsButtonsDesignItemType;
};

export function ActionsButtonsImageContent({
    item,
}: ImageContentPropsType): JSX.Element {
    const {
        customerLanguage,
        numericKeyboardInputValue,
        keyboardInputsValues,
        project: { files: images },
        pseudoNameValue,
        saleMode,
        isUserAuthenticated,
    } = useSnapshot(kioskStore);

    const imageContent = getImageContentById(
        item.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleOnClickEvent(item: ActionsButtonsDesignItemType) {
        switch (item.name.toLowerCase()) {
            case "Validate".toLowerCase(): {
                setIsConfirmationModalOpened(true);

                break;
            }
            case "Back".toLowerCase(): {
                setIsInformationModesConsommationModalOpened(false);
                break;
            }
            case "Ignore".toLowerCase(): {
                if (
                    saleMode.name === "Delivery" &&
                    isUserAuthenticated === false
                ) {
                    setLocalSelectedInformationMode("");
                } else {
                    setFinalMessageSelectedInformationMode("none");
                    setSelectedInformationMode("");
                }
                setIsInformationModesConsommationModalOpened(false);
                break;
            }
        }
    }

    return (
        <img
            src={imageSrc}
            alt="action button"
            onClick={() => {
                handleOnClickEvent(item);
            }}
            style={{
                height: "unset",
                width: "unset",
                opacity:
                    numericKeyboardInputValue === "" &&
                    keyboardInputsValues[t("Enter your email")] === undefined &&
                    keyboardInputsValues[t("Enter your password")] ===
                        undefined &&
                    pseudoNameValue === "" &&
                    imageContent?.toLowerCase().includes("validate")
                        ? "0.5"
                        : 1,
                pointerEvents:
                    numericKeyboardInputValue === "" &&
                    keyboardInputsValues[t("Enter your email")] === undefined &&
                    keyboardInputsValues[t("Enter your password")] ===
                        undefined &&
                    pseudoNameValue === "" &&
                    imageContent?.toLowerCase().includes("validate")
                        ? "none"
                        : "auto",
            }}
        />
    );
}
