import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { decryptWord } from "@helpers/general";

import {
    setConnectedUser,
    setConnectedUserEntity,
    setIsEntitySelectionModalOpened,
    setIsLoginErrorModalWrapperOpened,
    setIsSyncAuthenticationModalOpened,
    setLoginKeyboardInputsValues,
    syncStore,
} from "@components/Synchronization/store";

export function Footer(): JSX.Element {
    const { loginKeyboardInputsValues } = useSnapshot(syncStore);

    const encodedEmail = encodeURIComponent(loginKeyboardInputsValues["email"]);
    const encodedPassword = encodeURIComponent(
        loginKeyboardInputsValues["password"]
    );

    async function handleLoginButtonOnClickEvent() {
        const url = `${process.env.REACT_APP_CONSOLE_APP_API}/verifyCustomerAccount`;

        await fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `email=${encodedEmail}&password=${encodedPassword}`,
            method: "POST",
        })
            .then((response) => response.json())
            .then(async (data) => {
                if (data.error === true) {
                    setIsLoginErrorModalWrapperOpened(true);
                } else {
                    const email = await decryptWord(data["data"]["email"]);

                    await fetch(
                        `${process.env.REACT_APP_CONSOLE_APP_API}/auth/get-data-user?useremail=${email}`
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error === true) {
                                setIsLoginErrorModalWrapperOpened(true);
                            } else {
                                setConnectedUserEntity(
                                    data["data"]["uidentitie"]
                                );
                                setConnectedUser(
                                    JSON.parse(data["data"]["datauser"])
                                );
                                setIsSyncAuthenticationModalOpened(false);
                                setIsEntitySelectionModalOpened(true);
                                setLoginKeyboardInputsValues({
                                    email: "",
                                    password: "",
                                });
                            }
                        })
                        .catch((error) =>
                            console.log(
                                `Error while getting user data, with message: ${error.message}`
                            )
                        );
                }
            })
            .catch((error) => {
                console.log(
                    `Error while verifying customer account, with message: ${error.message}`
                );
                setIsLoginErrorModalWrapperOpened(true);
            });
    }

    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{
                backgroundColor: "#E9E9E9",
                height: "133px",
                borderTop: "1px solid #9d9a9a",
                marginTop: "17px",
            }}
        >
            <StyledButton
                rounded={true}
                className="m-2 text-uppercase"
                style={{
                    width: "255px",
                    height: "61px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #00000029",
                    border: "1px solid #C9C9C9",
                    borderRadius: "12px",
                    color: "black",
                    letterSpacing: "0px",
                    font: "normal normal 600 18px/22px Segoe UI",
                }}
                onClick={() => {
                    setIsSyncAuthenticationModalOpened(false);
                }}
            >
                {t("Back")}
            </StyledButton>
            <StyledButton
                rounded={true}
                className="m-2 text-uppercase"
                disabled={Object.keys(loginKeyboardInputsValues).length === 0}
                style={{
                    width: "386px",
                    height: "61px",
                    background: "#545454",
                    boxShadow: "0px 3px 6px #00000029",
                    border: "1px solid #FFFFFF",
                    borderRadius: "12px",
                    letterSpacing: "0px",
                    font: "normal normal 600 18px/22px Segoe UI",
                }}
                onClick={handleLoginButtonOnClickEvent}
            >
                {t("Login")}
            </StyledButton>
        </div>
    );
}
