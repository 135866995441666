import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

import { ListItems } from "../../Products/ProductWrapper/ListItems";
import PmrProducts from "../../Products/ProductWrapper/PmrProducts";

function WorkflowProduct({ listProducts, listworkflow }) {
    const { isPrm } = useSnapshot(kioskStore);
    const { isBasketActive } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    return !isPrm ? (
        <ListItems
            listProducts={listProducts}
            listworkflow={listworkflow}
            isBasketWorkflowActive={isBasketActive}
        />
    ) : (
        <div className="d-flex justify-content-center">
            <PmrProducts
                listProducts={listProducts}
                listworkflow={listworkflow}
            />
        </div>
    );
}

export default WorkflowProduct;
WorkflowProduct.propTypes = {
    listProducts: PropTypes.array,
    listworkflow: PropTypes.object,
};
