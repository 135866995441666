import classNames from "classnames";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import {
    changeLanguage,
    getImageContentById,
    moveArrayElementToTheBegin,
} from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import {
    kioskStore,
    setActiveLanguages,
    setCustomerLanguage,
} from "@pages/Kiosk/store";

import "./index.css";

import "swiper/swiper-bundle.min.css";

export function UsualModeLanguagesWrapper(): JSX.Element {
    const {
        activeLanguages,
        customerLanguage,
        navigationIndex,
        project: { files: images },
    } = useSnapshot(kioskStore);

    const [languagesWithPairIndex, setLanguagesWithPairIndex] = React.useState<
        LanguagesItemType[]
    >([]);
    const [languagesWithImpairIndex, setLanguagesWithImpairIndex] =
        React.useState<LanguagesItemType[]>([]);

    function syncCustomerLanguageWithAppLanguage(language: LanguagesItemType) {
        setCustomerLanguage(language);
        changeLanguage(language.name);
    }

    React.useEffect(() => {
        setActiveLanguages(
            moveArrayElementToTheBegin(
                activeLanguages.filter(
                    (item) => item.name === customerLanguage.name
                )[0],
                activeLanguages
            )
        );
    }, [customerLanguage, navigationIndex]);

    React.useEffect(() => {
        if (activeLanguages.length > 6) {
            const localLanguagesWithPairIndex: LanguagesItemType[] = [];
            const localLanguagesWithImpairIndex: LanguagesItemType[] = [];

            activeLanguages.forEach(
                (language: LanguagesItemType, index: number) => {
                    if (index % 2 === 0) {
                        localLanguagesWithPairIndex.push(language);
                    } else {
                        localLanguagesWithImpairIndex.push(language);
                    }
                }
            );

            setLanguagesWithPairIndex(localLanguagesWithPairIndex);
            setLanguagesWithImpairIndex(localLanguagesWithImpairIndex);
        }
    }, [activeLanguages]);

    return (
        <React.Fragment>
            {activeLanguages.length <= 2 ? (
                <FlexboxGrid
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                    className="mx-3"
                    gap="40px"
                >
                    {activeLanguages.map(
                        (item: LanguagesItemType, index: number) => {
                            const imageContent = getImageContentById(
                                item.id,
                                images,
                                customerLanguage.name
                            );

                            const imgSrc =
                                item.flag !== ""
                                    ? item.flag
                                    : imageContent?.includes("http") ||
                                      imageContent?.includes("https")
                                    ? imageContent
                                    : `./images/${imageContent}`;

                            return (
                                <div
                                    key={item.id}
                                    className="d-flex flex-row"
                                    style={{
                                        placeSelf: "center",
                                        placeItems: "center",
                                        gap: "10px",
                                    }}
                                    onClick={() => {
                                        syncCustomerLanguageWithAppLanguage(
                                            item
                                        );
                                    }}
                                >
                                    <img
                                        src={imgSrc}
                                        alt={item.title}
                                        style={{
                                            height: "80px",
                                            width: "120px",
                                        }}
                                    />
                                    <span
                                        className={classNames({
                                            "customer-language-title__clz":
                                                index === 0,
                                        })}
                                        style={{
                                            fontSize: "35px",
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            );
                        }
                    )}
                </FlexboxGrid>
            ) : activeLanguages.length === 3 ? (
                <div
                    className="d-grid__clz mx-3"
                    style={{
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gap: "10px",
                    }}
                >
                    {activeLanguages.map(
                        (item: LanguagesItemType, index: number) => (
                            <div
                                key={index}
                                className="d-flex flex-row"
                                style={{
                                    placeSelf: "center",
                                    placeItems: "center",
                                    gap: "10px",
                                }}
                                onClick={() => {
                                    syncCustomerLanguageWithAppLanguage(item);
                                }}
                            >
                                <img
                                    src={`./images/${item.flag}`}
                                    alt={`./images/${item.title}`}
                                    style={{
                                        height: "80px",
                                        width: "120px",
                                    }}
                                />
                                <span
                                    className={classNames({
                                        "customer-language-title__clz":
                                            index === 0,
                                    })}
                                    style={{
                                        fontSize: "35px",
                                    }}
                                >
                                    {item.title}
                                </span>
                            </div>
                        )
                    )}
                </div>
            ) : activeLanguages.length > 3 && activeLanguages.length <= 6 ? (
                <div
                    className="mx-5"
                    style={{
                        display: "grid",
                        gridTemplateRows: "1fr 1fr",
                        gap: "10px",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gridTemplateRows: "1fr",
                            gap: "10px",
                        }}
                    >
                        {activeLanguages
                            .slice(0, 3)
                            .map((item: LanguagesItemType, index: number) => (
                                <div
                                    key={index}
                                    className="d-flex justify-content-start align-items-center"
                                    style={{
                                        gap: "10px",
                                        opacity:
                                            item.name !== customerLanguage.name
                                                ? "0.5"
                                                : "1",
                                        pointerEvents:
                                            item.name === customerLanguage.name
                                                ? "none"
                                                : "auto",
                                    }}
                                    onClick={() => {
                                        syncCustomerLanguageWithAppLanguage(
                                            item
                                        );
                                    }}
                                >
                                    <img
                                        src={`./images/${item.flag}`}
                                        alt={`./images/${item.title}`}
                                        style={{
                                            height: "80px",
                                            width: "120px",
                                        }}
                                    />
                                    <span
                                        className={classNames({
                                            "customer-language-title__clz":
                                                index === 0,
                                        })}
                                        style={{
                                            fontSize: "35px",
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            ))}
                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${
                                activeLanguages.length - 3
                            },1fr)`,
                            gridTemplateRows: "1fr",
                            placeSelf:
                                activeLanguages.length < 6 ? "center" : "unset",
                            gap: "45px",
                        }}
                    >
                        {activeLanguages
                            .slice(3)
                            .map((item: LanguagesItemType, index: number) => (
                                <div
                                    key={index}
                                    className="d-flex justify-content-start align-items-center"
                                    style={{
                                        gap: "10px",
                                        opacity:
                                            item.name !== customerLanguage.name
                                                ? "0.5"
                                                : "1",
                                        pointerEvents:
                                            item.name === customerLanguage.name
                                                ? "none"
                                                : "auto",
                                    }}
                                    onClick={() => {
                                        syncCustomerLanguageWithAppLanguage(
                                            item
                                        );
                                    }}
                                >
                                    <img
                                        src={`./images/${item.flag}`}
                                        alt={`./images/${item.title}`}
                                        style={{
                                            height: "80px",
                                            width: "120px",
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: "35px",
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                <Swiper
                    navigation={true}
                    spaceBetween={50}
                    slidesPerView={3}
                    className="kiosk-languages-swiper-container__clz"
                    style={{ zIndex: 0 }}
                    modules={[Navigation]}
                >
                    {languagesWithPairIndex.map((item, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className="d-flex flex-column justify-content-center"
                                style={{ gap: "30px" }}
                            >
                                <div
                                    className="d-flex align-items-center"
                                    style={{
                                        gap: "10px",
                                    }}
                                    onClick={() => {
                                        syncCustomerLanguageWithAppLanguage(
                                            item
                                        );
                                    }}
                                >
                                    <img
                                        src={`./images/${item.flag}`}
                                        alt={item.title}
                                        style={{
                                            height: "80px",
                                            width: "120px",
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: "35px",
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </div>

                                {languagesWithImpairIndex[index] !==
                                undefined ? (
                                    <div
                                        className="d-flex align-items-center"
                                        style={{
                                            gap: "10px",
                                        }}
                                        onClick={() => {
                                            syncCustomerLanguageWithAppLanguage(
                                                languagesWithImpairIndex[index]
                                            );
                                        }}
                                    >
                                        <img
                                            src={`./images/${languagesWithImpairIndex[index].flag}`}
                                            alt={
                                                languagesWithImpairIndex[
                                                    index
                                                ] !== undefined
                                                    ? `./images/${languagesWithImpairIndex[index].title}`
                                                    : ""
                                            }
                                            style={{
                                                height: "80px",
                                                width: "120px",
                                            }}
                                        />
                                        <span
                                            className={classNames({
                                                "customer-language-title__clz":
                                                    index === 0,
                                            })}
                                            style={{
                                                fontSize: "35px",
                                            }}
                                        >
                                            {
                                                languagesWithImpairIndex[index]
                                                    .title
                                            }
                                        </span>
                                    </div>
                                ) : null}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}
        </React.Fragment>
    );
}
