import React from "react";
import { Provider } from "react-redux";
import { Navigate } from "react-router-dom";

import store from "@components/OrderTaking/Store";
import { SpinnerWrapper } from "@components/common/SpinnerWrapper";

import { Kiosk } from "@pages/Kiosk";
import { Steps } from "@pages/Kiosk/Steps";

const Layout = React.lazy(() => import("@components/Layout"));

export const routes = [
    {
        path: "/",
        element: (
            <Provider store={store}>
                <Kiosk />
            </Provider>
        ),
    },
    {
        element: (
            <React.Suspense fallback={<SpinnerWrapper />}>
                <Layout />
            </React.Suspense>
        ),
        children: [
            {
                path: "/kiosk-steps",
                element: (
                    <Provider store={store}>
                        <Steps />
                    </Provider>
                ),
            },
        ],
    },
    {
        path: "*",
        element: <Navigate to="/" />,
    },
];
