import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    kioskStore,
    setActionsButtonsItems,
    setChosenTransition,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsTopBannerActive,
    setIsUserAccountActive,
    setMainContentItems,
    setUserAccountMessageItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function SalesMethodStep(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
    } = useSnapshot(kioskStore);

    const { transitions } = useSnapshot(store);

    React.useEffect(() => {
        setMainContentItems(
            (content.salesMethods as ProjectContentItemType).items
        );
        setIsUserAccountActive(
            // eslint-disable-next-line
            (ways.salesMethods as PageType).header.userAccount!.active
        );
        setUserAccountMessageItems(
            // eslint-disable-next-line
            (ways.salesMethods as PageType).header.userAccount!.languages
        );

        setIsLogoActive((ways.salesMethods as PageType).topBanner.logo.active);

        setIsTopBannerActive((ways.salesMethods as PageType).topBanner.active);
        setIsHeaderActive((ways.salesMethods as PageType).header.active);

        setIsInformationMessageActive(
            (ways.salesMethods as PageType).header.informationMessage.active
        );

        setInformationMessageItems(
            (ways.salesMethods as PageType).header.informationMessage.languages
        );

        setIsActionButtonsActive(
            (ways.salesMethods as PageType).actionsButtons.active
        );

        setActionsButtonsItems(
            (
                (ways.salesMethods as PageType)
                    .actionsButtons as ActionsButtonsDesignType
            ).items
        );

        setChosenTransition(
            transitions[(ways.salesMethods as PageType).animation]
        );
    }, [ways, content]);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
