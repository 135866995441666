import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getImageContentById } from "@helpers/general";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";
import { PrmMainContentWrapper } from "./PrmMainContentWrapper";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function TpaValinaModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { generalDesign },
            },
            files: images,
        },
        isTpaValinaModalOpened,
        isTpaValinaIconActive,
        tpaValinaIconId,
        customerLanguage,
        isTpaValinaInformationMessageActive,
        tpaValinaInformationMessageItems,
        isTpaValinaActionButtonsActive,
        tpaValinaActionButtons,
    } = useSnapshot(kioskStore);

    const imageContent = getImageContentById(
        tpaValinaIconId,
        images,
        customerLanguage.name
    );

    const activeActionsButtons = tpaValinaActionButtons.filter((item) => {
        return item.active === true;
    });

    const activeActionsButtonsSorted = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isTpaValinaModalOpened}
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: isPrm ? "end" : "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: isPrm ? "1334px" : "1742px",
                    width: isPrm ? "860px" : "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            {isTpaValinaIconActive ? (
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "400px" }}
                >
                    <img src={imageSrc} style={{ height: "330px" }} />
                </div>
            ) : null}

            {isTpaValinaInformationMessageActive ? (
                <div
                    className="d-flex justify-content-center align-items-center px-5 text-center"
                    style={{ height: "170px" }}
                >
                    <span
                        style={{ font: "normal normal 600 55px/80px Segoe UI" }}
                    >
                        {
                            tpaValinaInformationMessageItems[
                                customerLanguage.name
                            ].content
                        }
                    </span>
                </div>
            ) : null}

            <div
                className="d-flex justify-content-center w-100"
                style={{
                    height: "1000px",
                    alignItems: isPrm ? "end" : "center",
                }}
            >
                {isPrm ? <PrmMainContentWrapper /> : <MainContentWrapper />}
            </div>

            {isTpaValinaActionButtonsActive ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "145px" }}
                >
                    <ActionsButtonsWrapper
                        actionsButtonsItems={activeActionsButtonsSorted}
                    />
                </div>
            ) : null}
        </Modal>
    );
}
