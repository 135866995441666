import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";
import { UsualKeyboardWrapper } from "@components/UsualKeyboardWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import {
    kioskStore,
    setIsAnimationIn,
    setIsStandbyModalOpened,
} from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        isStandbyModalOpened,
        isHelpModalOpened,
        subStepActionButtons,
        timeout,
        chosenTransition,
        isAnimationIn,
        isSubStepTopBannerActive,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isSubStepActionButtonsActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isAuthenticationErrorModalOpened,
        selectedAuthMode,
        navigationIndex,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
    }, []);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
                <UsualModeSubStepContainerWrapper
                    isSubStepTopBannerActive={isSubStepTopBannerActive}
                    isSubStepIconActive={isSubStepIconActive}
                    isSubStepInformationMessageActive={
                        isSubStepInformationMessageActive
                    }
                    isSubStepActionButtonsActive={isSubStepActionButtonsActive}
                    isLanguagesActive={isLanguagesActive}
                    isConfigOptionsActive={isConfigOptionsActive}
                >
                    <IdleDetection
                        timeout={timeout * 1000}
                        once={true}
                        onIdle={() => {
                            if (navigationIndex !== 0) {
                                setIsStandbyModalOpened(true);
                            }
                        }}
                    />

                    {isHelpModalOpened === true ? <HelpModalWrapper /> : null}
                    {isStandbyModalOpened === true ? (
                        <StandbyModalWrapper />
                    ) : null}

                    {isAuthenticationErrorModalOpened === true ? (
                        <AuthenticationErrorModal />
                    ) : null}

                    <SubStepsLogoWrapper />

                    {isSubStepIconActive ? <SubStepsIconWrapper /> : null}

                    {isSubStepInformationMessageActive ? (
                        <SubStepsInformationMessage />
                    ) : null}

                    <div className="d-flex justify-content-center align-items-center">
                        <UsualKeyboardWrapper />
                    </div>

                    {isSubStepActionButtonsActive ? (
                        <ActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                        />
                    ) : null}

                    {isLanguagesActive ? <UsualModeLanguagesWrapper /> : null}

                    {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
                </UsualModeSubStepContainerWrapper>
            </PreviewBackgroundWrapper>
        </CSSTransition>
    );
}
