import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { useSnapshot } from "valtio";

import { formatCurrency } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function CreditCard(): JSX.Element {
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
        leftToPayAfterCashMachineValidation,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        isOrderPartialPaidWithLoyaltyAccountBalance,
    } = useSnapshot(kioskStore);
    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);

    return (
        <Card
            style={{
                width: "910px",
                height: "265px",
                borderRadius: "31px",
                boxShadow: "0px 3px 6px #00000029",
                font: "Segoe UI",
                borderBottom: `12px solid ${
                    (generalDesign as GeneralDesignType).colors[0].content
                }`,
            }}
        >
            <div
                className="d-grid__clz"
                style={{
                    gridTemplateRows: "1fr 0.1fr 0.8fr 0.8fr",
                    height: "100%",
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        gap: "280px",
                        fontSize: "31px",
                    }}
                >
                    <div>
                        {t("consider contactless payment", {
                            amount: formatCurrency(50),
                        })}
                    </div>
                </div>
                <hr
                    style={{
                        height: "2px",
                        width: "88%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                    }}
                />

                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-2"
                    style={{
                        fontSize: "45px",
                    }}
                >
                    <div>{t("Amount to be paid")}</div>
                    <div>
                        {formatCurrency(
                            leftToPayAfterCashMachineValidation !== 0
                                ? leftToPayAfterCashMachineValidation
                                : isOrderPartialPaidWithLoyaltyAccountBalance
                                ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                : totalPrice
                        )}
                    </div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-4"
                    style={{
                        fontSize: "30px",
                        color: "#7C7C7C",
                    }}
                >
                    <div>{t("Total order")}</div>
                    <div>{formatCurrency(totalPrice)}</div>
                </div>
            </div>
        </Card>
    );
}
