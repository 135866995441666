import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { memoizedTags, setTags } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { Tag } from "./Tag";
import "./tags.css";

import "swiper/swiper.min.css";

export function TagsSwiperWrapper() {
    const { isPrm } = useSnapshot(kioskStore);
    const dispatch = useDispatch();

    const tags = useSelector((state) => memoizedTags(state));
    const localTags = tags.filter((tag) => tag.isVisible);

    function handleActiveTagOnClickEvent(id) {
        const newTags = tags.map((tag) => {
            if (tag.id === id) {
                return {
                    ...tag,
                    active: !tag.active,
                };
            }
            return { ...tag };
        });

        dispatch(setTags(newTags));
    }
    if (localTags.length === 0) return null;
    return (
        <Swiper
            className="tags-swiper__clz"
            spaceBetween={5}
            slidesPerView={5}
            slidesPerGroup={5}
            style={{ height: isPrm === true ? "60px" : "70px" }}
        >
            {localTags.map((item, itemIndex) => {
                return (
                    <SwiperSlide
                        className={classNames({
                            "active-tags-swiper-width__clz":
                                item.active === true,
                            "tags-swiper-width__clz": item.active === false,
                        })}
                        key={itemIndex}
                        onClick={() => {
                            handleActiveTagOnClickEvent(item.id);
                        }}
                    >
                        <Tag tag={item} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
export const TagsSwiperWrapperMemoised = React.memo(TagsSwiperWrapper);
