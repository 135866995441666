import { CATEGORY_TYPE } from "@constants";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

import {
    AddRemoveActionsButton,
    InformationProductIcon,
    ProductAllergen,
} from "./";

export function SimpleProductActionsButtons({
    product,
    handleNutritionalInformationDialogOnCloseEvent,
    handleRemoveProductFromOrder,
    isAllergenIconActive,
    opacity,
    quantity,
}) {
    const { isPrm } = useSnapshot(kioskStore);
    const {
        isInformationProductIconActive,
        informationProductIconContent,

        //@eslint-disable-next-line
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    return (
        product.type !== CATEGORY_TYPE && (
            <Box className="button__clz">
                <InformationProductIcon
                    product={product}
                    isInformationProductIconActive={
                        isInformationProductIconActive
                    }
                    handleNutritionalInformationDialogOnCloseEvent={
                        handleNutritionalInformationDialogOnCloseEvent
                    }
                    informationProductIconContent={
                        informationProductIconContent
                    }
                />

                <ProductAllergen
                    isPrm={isPrm}
                    isAllergenIconActive={isAllergenIconActive}
                />
                <AddRemoveActionsButton
                    product={product}
                    opacity={opacity}
                    handleRemoveProductFromOrder={handleRemoveProductFromOrder}
                    quantity={quantity}
                />
            </Box>
        )
    );
}
SimpleProductActionsButtons.propTypes = {
    product: PropTypes.object.isRequired,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    isAllergenIconActive: PropTypes.bool,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
    handleRemoveProductFromOrder: PropTypes.func,
};
