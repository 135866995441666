import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { VideoBackgroundHeaderWrapper } from "@components/common/VideoBackgroundHeaderWrapper";
import { Footer } from "@components/Synchronization/AuthenticationModalWrapper/Footer";
import { LoginErrorModalWrapper } from "@components/Synchronization/LoginErrorModalWrapper";
import { syncStore } from "@components/Synchronization/store";
import { UsualKeyboardWrapper } from "@components/Synchronization/UsualKeyboardWrapper";

export function AuthenticationModalWrapper(): JSX.Element {
    const { isSyncAuthenticationModalOpened } = useSnapshot(syncStore);

    return (
        <Modal
            isOpen={isSyncAuthenticationModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1353px",
                    width: "914px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <VideoBackgroundHeaderWrapper />
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "2fr 1fr 5fr auto",
                    height: "1069px",
                    gap: "40px",
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        height: "206px",
                        position: "relative",
                    }}
                >
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            position: "fixed",
                            height: "206px",
                        }}
                    >
                        <source src="./images/profile.mp4" type="video/mp4" />
                    </video>
                </div>
                <span
                    className="text-uppercase"
                    style={{
                        font: "normal normal bold 35px/42px Segoe UI",
                        textAlign: "center",
                        letterSpacing: "0px",
                    }}
                >
                    {t("Connection")}
                </span>
                <UsualKeyboardWrapper />
                <Footer />
            </div>
            <LoginErrorModalWrapper />
        </Modal>
    );
}
