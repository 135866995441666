import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function ConfigOptionItem({ option, handleConfigOptionOnClickEvent }) {
    const {
        navigationIndex,
        isUserAuthenticated,

        isUserAccountModalOpened,
        kioskWays,
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const imageContent = getImageContentById(
        option.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <img
            src={imageSrc}
            alt={option.title}
            onClick={() => {
                handleConfigOptionOnClickEvent(option);
            }}
            style={{
                height: "70px",
                width: "70px",
                boxShadow: "0px 3px 6px #00000080",
                borderRadius: "15px",
                opacity:
                    option.title.toLowerCase() === "customer account" &&
                    (isUserAccountModalOpened ||
                        isUserAuthenticated ||
                        kioskWays[navigationIndex]?.name === "connection" ||
                        kioskWays[navigationIndex]?.name ===
                            "authenticationModes")
                        ? "0.5"
                        : "1",
                pointerEvents:
                    option.title.toLowerCase() === "customer account" &&
                    (isUserAccountModalOpened ||
                        isUserAuthenticated ||
                        kioskWays[navigationIndex]?.name === "connection" ||
                        kioskWays[navigationIndex]?.name ===
                            "authenticationModes")
                        ? "none"
                        : "auto",
            }}
        />
    );
}
ConfigOptionItem.propTypes = {
    option: PropTypes.object,
    handleConfigOptionOnClickEvent: PropTypes.func,
};
