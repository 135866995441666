import { DESIGN_NO_THANKS_BUTTON_ROLE } from "@constants";
import { Card } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import { useNextStep } from "../ProductWrapper/hooks";

export default function NoThanks() {
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );
    const {
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    if (workflowData.length === 0) return null;
    const NextStep = useNextStep(workflowData[workflowData.length - 1]);

    const { designItems } = useSelector((state) => state.settingSlice);

    const noThanksButton = designItems.filter(
        (item) => item.role === DESIGN_NO_THANKS_BUTTON_ROLE
    )[0];

    const noThnxImageContent = getImageContentById(
        noThanksButton.id,
        images,
        customerLanguage.name
    );

    const noThnxImageSrc =
        noThnxImageContent !== undefined
            ? noThnxImageContent?.includes("http") ||
              noThnxImageContent?.includes("https")
                ? noThnxImageContent
                : `./images_order/${noThnxImageContent}`
            : `./images_order/noThanks.png`;

    return (
        <Card style={{ borderRadius: "15px" }}>
            <img
                onClick={NextStep}
                loading="lazy"
                alt={noThnxImageSrc}
                className="product-image w-100 h-100 "
                src={noThnxImageSrc}
            />
        </Card>
    );
}

NoThanks.propTypes = {
    handleClick: PropTypes.func,
};
