import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import ReactSwitch from "react-switch";

import { setIsAutoLaunchActivated, store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { ScrollContainer } from "@components/ScrollContainer";

import { kioskStore } from "@pages/Kiosk/store";

export function Others(): JSX.Element {
    const { activeTabTitle } = useSnapshot(kioskStore);
    const { isAutoLaunchActivated } = useSnapshot(store);

    return (
        <FlexboxGrid className="flex-column" styles={{ marginLeft: "30px" }}>
            <div
                style={{
                    font: "normal normal 600 37px/49px Segoe UI",
                    marginLeft: "30px",
                    marginTop: "30px",
                }}
            >
                {activeTabTitle}
            </div>
            <ScrollContainer
                style={{
                    maxHeight: "880px",
                    overflowY: "auto",
                }}
            >
                <FlexboxGrid gap="30px" className="ml-4 my-4 font-weight-bold">
                    <div className="text-uppercase">{t("Auto launch")}</div>
                    <ReactSwitch
                        disabled={false}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isAutoLaunchActivated}
                        onChange={() => {
                            setIsAutoLaunchActivated(!isAutoLaunchActivated);
                            (
                                window as RendererWindow
                            ).electronAPI.activateAutoLaunch({
                                isAutoLaunchActivated: !isAutoLaunchActivated,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </FlexboxGrid>
            </ScrollContainer>
        </FlexboxGrid>
    );
}
