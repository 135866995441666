import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { kioskStore } from "@pages/Kiosk/store";

import { SimpleProductActionsButtons } from "./SimpleProductActionsButtons";

export function FirstBoxCardProduct({
    product,
    productId,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
    handleAddProductClick,
    handleNutritionalInformationDialogOnCloseEvent,
    opacity,
    quantity,
    handleRemoveProductFromOrder,
}) {
    const { middlewareApiUrl } = useSnapshot(store);
    const { isPrm } = useSnapshot(kioskStore);
    const { image } = product;
    const productImg =
        process.env.REACT_APP_CARD_ENV !== "dev"
            ? `${middlewareApiUrl}/static/${image?.split("/").pop()}`
            : image;

    return (
        <div
            className="first-box"
            style={{
                width: "100%",
                flex: 80,
            }}
            onClick={() => {
                opacity !== "0.2"
                    ? isAllergenIconActive === true
                        ? setIsAllergenConfirmationDialogOpened(true)
                        : handleAddProductClick()
                    : null;
            }}
        >
            <div className="d-flex justify-content-center align-items-center w-100">
                <img
                    loading="lazy"
                    id={`key-img${productId}`}
                    className="product-image py-2"
                    src={productImg}
                    alt={product.title}
                    height={isPrm ? "unset" : "234px"}
                />
            </div>
            <SimpleProductActionsButtons
                product={product}
                handleNutritionalInformationDialogOnCloseEvent={
                    handleNutritionalInformationDialogOnCloseEvent
                }
                handleRemoveProductFromOrder={handleRemoveProductFromOrder}
                isAllergenIconActive={isAllergenIconActive}
                opacity={opacity}
                quantity={quantity}
            />
        </div>
    );
}
FirstBoxCardProduct.propTypes = {
    product: PropTypes.object.isRequired,
    productId: PropTypes.string,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    isAllergenIconActive: PropTypes.bool,
    setIsAllergenConfirmationDialogOpened: PropTypes.func,
    handleAddProductClick: PropTypes.func,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
    handleRemoveProductFromOrder: PropTypes.func,
};
