import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { MainContentWrapper } from "@components/common/UsualModeMainContentWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { ContainerWrapper } from "@pages/Kiosk/Steps/LoyaltyConsommationStep/ContainerWrapper";
import {
    kioskStore,
    setIsAnimationIn,
    setIsStandbyModalOpened,
} from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        isStandbyModalOpened,
        subStepActionButtons,
        timeout,
        chosenTransition,
        isAnimationIn,
        customerLanguage,
        isHeaderActive,
        isTopBannerActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        selectedAuthMode,
        navigationIndex,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
    }, []);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
                <ContainerWrapper
                    isHeaderActive={isHeaderActive}
                    isTopBannerActive={isTopBannerActive}
                    isActionButtonsActive={isActionButtonsActive}
                >
                    <IdleDetection
                        timeout={timeout * 1000}
                        once={true}
                        onIdle={() => {
                            if (navigationIndex !== 0) {
                                setIsStandbyModalOpened(true);
                            }
                        }}
                    />
                    {isStandbyModalOpened === true ? (
                        <StandbyModalWrapper />
                    ) : null}

                    <LogoWrapper />

                    {isHeaderActive ? (
                        <div>
                            {isInformationMessageActive ? (
                                <div className="d-flex text-center justify-content-center align-items-center">
                                    <span style={{ fontSize: "55px" }}>
                                        {
                                            informationMessageItems[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            ) : null}{" "}
                        </div>
                    ) : null}

                    <MainContentWrapper />

                    <ActionsButtonsWrapper
                        actionsButtonsItems={mainActiveActionsButtons}
                    />
                </ContainerWrapper>
            </PreviewBackgroundWrapper>
        </CSSTransition>
    );
}
