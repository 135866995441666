import { proxy, subscribe } from "valtio";

import { KeyboardInput } from "react-simple-keyboard";

type StoreType = {
    franchise: {
        id: string;
        schema: string;
    };
    isSyncModalOpened: boolean;
    isEntitySelectionModalOpened: boolean;
    isEntityChildrenSelectionModalOpened: boolean;
    isSyncAuthenticationModalOpened: boolean;
    isSyncConfirmationModalWrapperOpened: boolean;
    isSyncProgressModalWrapperOpened: boolean;
    selectedEntity: EntityType | undefined;
    selectedEntityChild: EntityType | undefined;
    selectedEntityChildren: EntityType[] | undefined;
    isLoginErrorModalWrapperOpened: boolean;
    connectedUserEntity: string;
    connectedUser: ObjectType;
    loginKeyboardInputsValues: KeyboardInput;
    selectedShopApp: ObjectType;
    machineIpAddress: string;
    progressBarValue: number;
};

export const syncStore = proxy<StoreType>({
    franchise: {
        id: "",
        schema: "",
    },
    isSyncModalOpened: false,
    isEntitySelectionModalOpened: false,
    isEntityChildrenSelectionModalOpened: false,
    isSyncAuthenticationModalOpened: false,
    isSyncConfirmationModalWrapperOpened: false,
    isSyncProgressModalWrapperOpened: false,
    selectedEntity: undefined,
    selectedEntityChild:
        JSON.parse(localStorage.getItem("selectedEntityChild") as string) || {},
    selectedEntityChildren: undefined,
    isLoginErrorModalWrapperOpened: false,
    connectedUserEntity: "",
    connectedUser: {},
    loginKeyboardInputsValues: {},
    selectedShopApp:
        JSON.parse(localStorage.getItem("selectedShopApp") as string) || {},
    machineIpAddress:
        JSON.parse(localStorage.getItem("machineIpAddress") as string) ||
        "0.0.0.0",
    progressBarValue: 0,
});

subscribe(syncStore, () => {
    localStorage.setItem(
        "machineIpAddress",
        JSON.stringify(syncStore.machineIpAddress)
    );
});

export {
    setFranchiseId,
    setFranchiseSchema,
    setIsSyncModalOpened,
    setIsEntitySelectionModalOpened,
    setIsEntityChildrenSelectionModalOpened,
    setIsSyncAuthenticationModalOpened,
    setIsSyncConfirmationModalWrapperOpened,
    setSelectedEntity,
    setSelectedEntityChildren,
    setSelectedEntityChild,
    setIsSyncProgressModalWrapperOpened,
    setIsLoginErrorModalWrapperOpened,
    setConnectedUserEntity,
    setLoginKeyboardInputsValues,
    setSelectedShopApp,
    setMachineIpAddress,
    setConnectedUser,
    setProgressBarValue,
} from "./actions";
