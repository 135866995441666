import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeSubStepContainerWrapper } from "@components/common/PrmModeSubStepContainerWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { IdleDetection } from "@components/IdleDetection";
import { PrmNumericKeyboardWrapper } from "@components/PrmNumericKeyboardWrapper";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import {
    kioskStore,
    setIsAnimationIn,
    setIsLogoutProblemModalOpened,
    setIsStandbyModalOpened,
} from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        isStandbyModalOpened,
        isHelpModalOpened,
        isSubStepActionButtonsActive,
        subStepActionButtons,
        timeout,
        chosenTransition,
        isAnimationIn,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isAuthenticationErrorModalOpened,
        isConfirmationModalOpened,
        selectedAuthMode,
        navigationIndex,
        isLogoutProblemModalOpened,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = subStepActionButtons.filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsAnimationIn(true);
        setIsLogoutProblemModalOpened(false);
    }, []);

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <GlobalPrmModeWrapper>
                <PrmBackgroundWrapper />
                <div>
                    <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
                        <PrmModeSubStepContainerWrapper
                            isSubStepIconActive={isSubStepIconActive}
                            isSubStepInformationMessageActive={
                                isSubStepInformationMessageActive
                            }
                            isSubStepActionButtonsActive={
                                isSubStepActionButtonsActive
                            }
                            isLanguagesActive={isLanguagesActive}
                            isConfigOptionsActive={isConfigOptionsActive}
                            ref={(ref) => setContainerRef(ref)}
                        >
                            <IdleDetection
                                timeout={timeout * 1000}
                                once={true}
                                onIdle={() => {
                                    if (navigationIndex !== 0) {
                                        setIsStandbyModalOpened(true);
                                    }
                                }}
                            />

                            {isHelpModalOpened === true ? (
                                <HelpModalWrapper />
                            ) : null}

                            {isStandbyModalOpened === true ? (
                                <StandbyModalWrapper />
                            ) : null}

                            {isAuthenticationErrorModalOpened === true ? (
                                <AuthenticationErrorModal />
                            ) : null}

                            {containerRef ? (
                                isConfirmationModalOpened === true ? (
                                    <ConfirmationModalWrapper
                                        containerRef={containerRef}
                                    />
                                ) : null
                            ) : null}

                            {isLogoutProblemModalOpened === true ? (
                                <LogoutProblemModal />
                            ) : null}

                            {isSubStepIconActive ? (
                                <SubStepsIconWrapper />
                            ) : null}

                            {isSubStepInformationMessageActive ? (
                                <SubStepsInformationMessage />
                            ) : null}

                            <div className="d-flex justify-content-center align-items-center">
                                <PrmNumericKeyboardWrapper />
                            </div>

                            {isSubStepActionButtonsActive ? (
                                <PrmActionsButtonsWrapper
                                    actionsButtonsItems={
                                        mainActiveActionsButtons
                                    }
                                />
                            ) : null}

                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                            >
                                {isLanguagesActive ? (
                                    <PrmLanguagesWrapper />
                                ) : (
                                    <div />
                                )}

                                {isConfigOptionsActive ? (
                                    <ConfigOptionsWrapper />
                                ) : null}
                            </div>
                        </PrmModeSubStepContainerWrapper>
                    </PreviewBackgroundWrapper>
                </div>
            </GlobalPrmModeWrapper>
        </CSSTransition>
    );
}
