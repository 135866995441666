import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function SalesMethodsItems({
    salesMode,
    handleOnCLickEvent,
    setSalesMethod,
}) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const {
        project: { files: images },
        customerLanguage,
        selectedSaleModeValue,
    } = useSnapshot(kioskStore);
    const imageContent = getImageContentById(
        salesMode.id,
        images,
        customerLanguage.name
    );
    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <img
            className="sales-method-image"
            src={imageSrc}
            style={{
                borderBottom:
                    salesMode.name === selectedSaleModeValue
                        ? `${globalColor} 10px solid`
                        : "",
            }}
            onClick={() => {
                handleOnCLickEvent(salesMode), setSalesMethod(salesMode);
            }}
        />
    );
}

SalesMethodsItems.propTypes = {
    salesMode: PropTypes.object,
    handleOnCLickEvent: PropTypes.func,
    setSalesMethod: PropTypes.func,
};
