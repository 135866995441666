import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

import { ProductCalorie } from "./ProductCalorie";
import { ProductDetails } from "./ProductDetails";
import { ProductTitle } from "./ProductTitle";

export function SecondBoxCardProduct({
    product,
    quantity,
    isDescriptionActive,
    handleAddProductClick,
    opacity,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
}) {
    const { isPrm } = useSnapshot(kioskStore);

    const {
        isProductDescriptionActive,

        //@eslint-disable-next-line
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    return (
        <div
            style={{
                display: "grid",
                paddingTop: isPrm ? "0" : "unset",
                gridTemplateRows: !isPrm
                    ? "25px 60px 40px auto"
                    : "18px 40px 22px min-content",
                height: isPrm ? "45%" : "50%",

                background:
                    quantity > 0
                        ? `transparent linear-gradient(180deg, #ffffff00 0%, ${globalColor} 700%) 100% 100% repeat`
                        : "",
            }}
            onClick={() => {
                if (!product.outOfStock && opacity !== "0.2") {
                    isAllergenIconActive === true
                        ? setIsAllergenConfirmationDialogOpened(true)
                        : handleAddProductClick();
                }
            }}
        >
            <ProductCalorie product={product} />

            <ProductTitle quantity={quantity} product={product} />
            <ProductDetails
                product={product}
                isDescriptionActive={isDescriptionActive}
                isProductDescriptionActive={isProductDescriptionActive}
            />
        </div>
    );
}
SecondBoxCardProduct.propTypes = {
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number,
    isDescriptionActive: PropTypes.bool,
    handleAddProductClick: PropTypes.func,
    opacity: PropTypes.string,
    isAllergenIconActive: PropTypes.bool,
    setIsAllergenConfirmationDialogOpened: PropTypes.func,
};
