export const haveAllergens = (product, globalAllergens) => {
    if (product.allergens !== undefined && product.allergens?.length !== 0) {
        let allergens = [];

        for (const group in globalAllergens) {
            allergens = [
                ...allergens,
                ...globalAllergens[group].filter((allergen) => {
                    return allergen.active === true;
                }),
            ];
        }

        return allergens
            .map((allergen) => {
                return allergen.id;
            })
            .some((datum) => product.allergens.includes(datum));
    }
    return false;
};
