import React from "react";

import { useLatestRef } from "@hooks/useLatestRef";

type TimerParams = {
    timeout: number;
    onIdle: () => void;
    onActivity?: () => void;
    once: boolean;
};

export function IdleDetection({
    timeout = 1000,
    // eslint-disable-next-line
    onIdle = (): void => {},
    // eslint-disable-next-line
    onActivity = (): void => {},
    once = true,
}: TimerParams): null {
    const timerRef = React.useRef<{ current: number }>();
    const onIdleRef = useLatestRef(onIdle);
    const onActivityRef = useLatestRef(onActivity);

    const clearTimer = once ? clearTimeout : clearInterval;
    const setTimer = once ? setTimeout : setInterval;

    React.useEffect(() => {
        function resetIdleTimeout() {
            // eslint-disable-next-line
            clearTimer(timerRef!.current as unknown as number);
            onActivityRef.current();
            // eslint-disable-next-line
            (timerRef!.current as unknown as NodeJS.Timeout) = setTimer(() => {
                onIdleRef.current();
            }, timeout);
        }

        window.addEventListener("load", resetIdleTimeout);
        document.addEventListener("click", resetIdleTimeout);
        document.addEventListener("mousemove", resetIdleTimeout);
        document.addEventListener("keydown", resetIdleTimeout);
        document.addEventListener("wheel", resetIdleTimeout);
        document.addEventListener("touchmove", resetIdleTimeout);

        return () => {
            window.removeEventListener("load", resetIdleTimeout);
            document.removeEventListener("click", resetIdleTimeout);
            document.removeEventListener("mousemove", resetIdleTimeout);
            document.removeEventListener("keydown", resetIdleTimeout);
            document.removeEventListener("wheel", resetIdleTimeout);
            document.removeEventListener("touchmove", resetIdleTimeout);
            // eslint-disable-next-line
            clearTimer(timerRef!.current as unknown as number);
        };
    }, [timeout, onIdleRef, onActivityRef, setTimer, clearTimer]);

    return null;
}
