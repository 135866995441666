import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore } from "@pages/Kiosk/store";

import { UsualMode } from "./UsualMode";
import { PrmMode } from "./PrmMode";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function UserAccountModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const { isPrm } = useSnapshot(kioskStore);

    return isPrm ? (
        <PrmMode containerRef={containerRef} />
    ) : (
        <UsualMode containerRef={containerRef} />
    );
}
