import { BASIC_COMPOSITION, NO_ThANKS_CARD, REDIRECTION } from "@constants";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { NoThanks } from "../Cart";
import CardBasicComposition from "./CardBasicComposition";
import CardProductSimple from "./CardProductSimple";
import "./index.css";
import { RedirectCardProduct } from "./RedirectCardProduct";

export default function ComponentRender({
    product,
    productType,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
    handleAddProductClick,
    productId,
    handleProductSizeDialogOnCloseEvent,
    handleRemoveProductFromOrder,
    opacity,
    quantity,
    handleNutritionalInformationDialogOnCloseEvent,
    globalColor,
    isPrm,
}) {
    const { isDescriptionActive, isInfoActive } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );

    let Component = null;

    switch (productType) {
        case REDIRECTION:
            Component = (
                <RedirectCardProduct
                    productName={product.name.content}
                    productImage={product.image}
                    productId={productId}
                    quantity={quantity}
                    isAllergenIconActive={isAllergenIconActive}
                    setIsAllergenConfirmationDialogOpened={
                        setIsAllergenConfirmationDialogOpened
                    }
                    handleAddProductClick={handleAddProductClick}
                    isPrm={isPrm}
                    globalColor={globalColor}
                    isVisibleName={product?.name?.isVisible}
                />
            );
            break;
        case BASIC_COMPOSITION:
            Component = (
                <CardBasicComposition
                    product={product}
                    isInfoActive={isInfoActive}
                    isAllergenIconActive={isAllergenIconActive}
                />
            );
            break;
        case NO_ThANKS_CARD:
            Component = <NoThanks />;
            break;
        default:
            Component = (
                <CardProductSimple
                    globalColor={globalColor}
                    product={product}
                    productId={productId}
                    opacity={opacity}
                    quantity={quantity}
                    isInfoActive={isInfoActive}
                    isAllergenIconActive={isAllergenIconActive}
                    isDescriptionActive={isDescriptionActive}
                    setIsAllergenConfirmationDialogOpened={
                        setIsAllergenConfirmationDialogOpened
                    }
                    handleAddProductClick={handleAddProductClick}
                    handleNutritionalInformationDialogOnCloseEvent={
                        handleNutritionalInformationDialogOnCloseEvent
                    }
                    handleProductSizeDialogOnCloseEvent={
                        handleProductSizeDialogOnCloseEvent
                    }
                    handleRemoveProductFromOrder={handleRemoveProductFromOrder}
                />
            );
            break;
    }
    return Component;
}
