import PropTypes from "prop-types";
import { EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";

import { getPrice } from "../../../Helpers/getPrice";
import "./index.css";

import "swiper/swiper-bundle.min.css";

export function ProductSizeSwiper({
    productProperties,
    selectedSizeIndex,
    setSelectedSizeIndex,
    visiblePrice,
    listOptions,
    productPrice,
}) {
    let optionPrice = 0;
    if (visiblePrice) {
        const { price } = getPrice(listOptions, productPrice);
        optionPrice = price;
    }

    return (
        <Swiper
            className="product-size-swiper-container m-2"
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView="auto"
            loop={true}
            coverflowEffect={{
                rotate: 0,
                stretch: -40,
                depth: 225,
                modifier: 1,
                slideShadows: false,
            }}
            slideToClickedSlide={true}
            onActiveIndexChange={(swiper) =>
                setSelectedSizeIndex(swiper.realIndex)
            }
            spaceBetween={12}
            modules={[EffectCoverflow]}
        >
            {productProperties.map((item, index) => {
                return (
                    <SwiperSlide
                        key={index}
                        className="product-size-swiper-slide-img product-size-swiper-slide"
                        onClick={() => {
                            setSelectedSizeIndex(index);
                        }}
                        style={{
                            opacity: index !== selectedSizeIndex ? "0.5" : "1",
                        }}
                    >
                        <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ gap: "15px" }}
                        >
                            <div
                                className="swiper-slide-product-name"
                                style={{
                                    marginTop:
                                        index !== selectedSizeIndex
                                            ? "56px"
                                            : "unset",
                                }}
                            >
                                {item.name}
                            </div>
                            {index === selectedSizeIndex && visiblePrice ? (
                                <div className="swiper-slide-product-price">
                                    <span className="price-text">
                                        + {optionPrice.toFixed(2)} €
                                    </span>
                                </div>
                            ) : null}
                            <div
                                className="swiper-slide-product-img"
                                style={{
                                    border:
                                        index === selectedSizeIndex
                                            ? "3px solid var(--global-color)"
                                            : "unset",
                                }}
                            >
                                <img src={item.image} />
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

ProductSizeSwiper.propTypes = {
    productProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSizeIndex: PropTypes.number,
    setSelectedSizeIndex: PropTypes.func,
    visiblePrice: PropTypes.bool,
    listOptions: PropTypes.array,
    productPrice: PropTypes.object,
};
