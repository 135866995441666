import {
    BASIC_COMPOSITION,
    NEXT_IN_STEP,
    PREVIOUS_IN_STEP,
    VALID_IN_STEP,
} from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

// import { isLoadingWorkflow } from "@components/OrderTaking/Helpers";
import {
    SelectProduct,
    setNextWorkflowStep,
    setPreviousWorkflowStep,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature/";

import { kioskStore } from "@pages/Kiosk/store";

import { AddOrderBasicCompositionWorkflow } from "./hooks";
import "./index.css";

function WorkflowFooter({ listworkflow, onClose }) {
    const { globalcard } = useSelector((state) => state.orderSlice);

    const dispatch = useDispatch();
    const { compositeProductActionsButtons } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const {
        project: { files: images },
        customerLanguage,
        isPrm,
    } = useSnapshot(kioskStore);

    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );
    const isLastStepValid =
        listworkflow?.workflow[listworkflow.index]?.NbrOfChoices >=
            listworkflow?.workflow[listworkflow.index]?.minNbrOfChoices &&
        listworkflow?.index === listworkflow?.step;
    const isValid =
        isLastStepValid ||
        (orderWorkflowItemSlice.item.isModification &&
            listworkflow?.workflow[listworkflow.index]?.NbrOfChoices >=
                listworkflow?.workflow[listworkflow.index]?.minNbrOfChoices) ||
        listworkflow?.index === listworkflow?.step;

    function addIndexstep() {
        dispatch(setNextWorkflowStep());
    }

    function NextStep(workflow) {
        if (workflow.step > workflow.index) {
            addIndexstep(workflow);
        } else {
            const copyworkflowData = JSON.parse(JSON.stringify(workflowData));
            copyworkflowData.splice(copyworkflowData.length - 1, 1);
            const copyDataworkflow = [...copyworkflowData];
            let index = copyDataworkflow.length;
            while (index > 0) {
                let workflowIndex = index - 1;
                let workflow = copyDataworkflow[workflowIndex];
                if (
                    workflow.workflow[workflow.index].NbrOfChoices <=
                    workflow.workflow[workflow.index].maxNbrOfChoices
                ) {
                    dispatch(setWorkflowData(copyworkflowData));

                    return;
                } else if (workflow.step > workflow.index) {
                    addIndexstep(workflow);
                    return;
                } else {
                    index -= 1;
                    copyDataworkflow.splice(workflowIndex, 1);
                }
            }
            // ** ici on efface workflow
            if (copyDataworkflow.length === 0) {
                dispatch(setWorkflowData([]));
                dispatch(SelectProduct({}));
            }
        }
    }

    function NextOnClickEvent() {
        if (
            listworkflow.workflow[listworkflow.index]?.NbrOfChoices <
            listworkflow.workflow[listworkflow.index]?.minNbrOfChoices
        )
            return;

        if (listworkflow) {
            if (
                listworkflow.workflow[listworkflow.index].type ===
                BASIC_COMPOSITION
            ) {
                AddOrderBasicCompositionWorkflow(
                    workflowData,
                    dispatch,
                    orderWorkflowItemSlice,
                    globalcard
                );
            }
            if (listworkflow.step > listworkflow.index) {
                addIndexstep(listworkflow);
            } else {
                NextStep(listworkflow);
            }
        }
    }

    function PreviousOnClickEvent() {
        if (listworkflow.index > 0) {
            dispatch(setPreviousWorkflowStep());
        }
    }

    function handleActionsButtonsOnClickEvent(button) {
        button.name.toLowerCase() === NEXT_IN_STEP ||
        button.name.toLowerCase() === VALID_IN_STEP
            ? NextOnClickEvent(button)
            : button.name.toLowerCase() === PREVIOUS_IN_STEP
            ? PreviousOnClickEvent()
            : onClose();
    }

    return (
        <div className="workflow-footer py-1">
            <div
                className="workflow-buttons px-2"
                style={{
                    gap: workflowData.length === 1 ? "5px" : "28px",
                    marginBottom: isPrm ? "4rem" : "",
                }}
            >
                <div
                    className="d-flex justify-content-around w-100"
                    style={{ gap: "10px", height: "75px" }}
                >
                    {compositeProductActionsButtons
                        .filter(
                            (item) =>
                                item.active === true &&
                                item.name.toLowerCase() !==
                                    (!isValid ? VALID_IN_STEP : NEXT_IN_STEP)
                        )
                        .sort((a, b) => {
                            return a.role > b.role ? -1 : 1;
                        })
                        .map((button) => {
                            const imageContent = getImageContentById(
                                button.id,
                                images,
                                customerLanguage.name
                            );

                            const imageSrc =
                                imageContent?.includes("http") ||
                                imageContent?.includes("https")
                                    ? imageContent
                                    : `./images_order/${imageContent}`;

                            return (
                                <div
                                    key={button.id}
                                    style={{
                                        backgroundSize: " 100% 100%",
                                        backgroundImage: `url(${imageSrc})`,
                                        width: "50%",
                                        height: "95%",
                                        opacity:
                                            (button.name.toLowerCase() ===
                                                NEXT_IN_STEP &&
                                                listworkflow?.workflow[
                                                    listworkflow.index
                                                ]?.NbrOfChoices <
                                                    listworkflow?.workflow[
                                                        listworkflow.index
                                                    ]?.minNbrOfChoices) ||
                                            (button.name.toLowerCase() ===
                                                VALID_IN_STEP &&
                                                listworkflow?.workflow[
                                                    listworkflow.index
                                                ]?.NbrOfChoices <
                                                    listworkflow?.workflow[
                                                        listworkflow.index
                                                    ]?.minNbrOfChoices) ||
                                            (button.name.toLowerCase() ===
                                                PREVIOUS_IN_STEP &&
                                                listworkflow?.index === 0)
                                                ? "0.5"
                                                : 1,
                                    }}
                                    onClick={() => {
                                        handleActionsButtonsOnClickEvent(
                                            button
                                        );
                                    }}
                                />
                            );
                        })}
                </div>

                <div className="d-flex align-items-center">
                    <hr className="workflow-vertical-separation-line" />

                    <div className="workflow-total px-1">
                        Total {orderWorkflowItemSlice.item.price.toFixed(2)} €
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkflowFooter;
WorkflowFooter.propTypes = {
    listworkflow: PropTypes.object,
    onClose: PropTypes.func,
};
