import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { decryptWord } from "@helpers/general";

import {
    setConnectedUser,
    setConnectedUserEntity,
    setIsLoginErrorModalWrapperOpened,
    setLoginKeyboardInputsValues,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setIsAuthenticationModalOpened,
    setIsErrorLogInModalWrapperOpened,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
} from "@pages/Kiosk/store";

import { LocalSettingsHeader } from "../LocalSettingsHeader";
import { UsualKeyboardWrapper } from "../UsualKeyboardWrapper";

export function AuthenticationModalWrapper(): JSX.Element {
    const { isAuthenticationModalOpened } = useSnapshot(kioskStore);
    const { loginKeyboardInputsValues } = useSnapshot(syncStore);

    const encodedEmail = encodeURIComponent(loginKeyboardInputsValues["email"]);
    const encodedPassword = encodeURIComponent(
        loginKeyboardInputsValues["password"]
    );

    const [isLoginRequestSent, setIsLoginRequestSent] = React.useState(false);

    async function handleLoginButtonOnClickEvent() {
        const url = `${process.env.REACT_APP_CONSOLE_APP_API}/verifyCustomerAccount`;

        setIsLoginRequestSent(true);

        await fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `email=${encodedEmail}&password=${encodedPassword}`,
            method: "POST",
        })
            .then((response) => response.json())
            .then(async (data) => {
                setIsLoginRequestSent(false);

                if (data.error === true) {
                    setIsErrorLogInModalWrapperOpened(true);
                } else {
                    const email = await decryptWord(data["data"]["email"]);

                    await fetch(
                        `${process.env.REACT_APP_CONSOLE_APP_API}/auth/get-data-user?useremail=${email}`
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error === true) {
                                setIsLoginErrorModalWrapperOpened(true);
                            } else {
                                setConnectedUserEntity(
                                    data["data"]["uidentitie"]
                                );
                                setConnectedUser(
                                    JSON.parse(data["data"]["datauser"])
                                );
                                setIsAuthenticationModalOpened(false);
                                setIsLocalSettingModalOpened(false);

                                setIsLocalConfigModalOpened(true);
                            }
                        });
                }
            })
            .catch((error: unknown) => {
                console.log(
                    `Error while verifying custom account, with message ${
                        (error as Error).message
                    }`
                );
                setIsLoginRequestSent(false);
                setIsErrorLogInModalWrapperOpened(true);
            });
    }

    return (
        <Modal
            isOpen={isAuthenticationModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1366px",
                    width: "914px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <LocalSettingsHeader />
            <div
                style={{
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "2fr 1fr 5fr 2fr",
                    height: "1180px",
                    gap: "45px",
                    display: "grid",
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        height: 206,
                        position: "relative",
                    }}
                >
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            position: "fixed",
                            height: "206px",
                        }}
                    >
                        <source src="./images/profile.mp4" type="video/mp4" />
                    </video>
                </div>
                <span
                    style={{
                        height: "50px",
                        font: "normal normal bold 35px / 47px Segoe UI",
                        marginLeft: "360px",
                    }}
                >
                    {t("Connection")}
                </span>
                <UsualKeyboardWrapper />
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                        backgroundColor: "#E9E9E9",
                        height: "133px",
                        borderTop: "1px solid #9d9a9a",
                        marginTop: "17px",
                    }}
                >
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        style={{
                            width: "255px",
                            height: "75px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "black",
                        }}
                        onClick={() => {
                            setIsAuthenticationModalOpened(false);
                            setIsLocalSettingModalOpened(true);
                            setLoginKeyboardInputsValues({
                                email: "",
                                password: "",
                            });
                        }}
                    >
                        {t("Back")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        disabled={
                            isLoginRequestSent ||
                            Object.keys(loginKeyboardInputsValues).length === 0
                        }
                        style={{
                            width: "386px",
                            height: "75px",
                            background: "#545454",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #FFFFFF",
                            borderRadius: "12px",
                        }}
                        onClick={handleLoginButtonOnClickEvent}
                    >
                        {t("Login")}
                    </StyledButton>
                </div>
            </div>
        </Modal>
    );
}
