import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { configCashMachine } from "@helpers/general";

import { useLocalStorage } from "@hooks/useLocalStorage";
import { useLocalWays } from "@hooks/useLocalWays";
import { useProject } from "@hooks/useProject";

import { getCard } from "@components/OrderTaking/Helpers/card";
import { setOrderTakingSetting } from "@components/OrderTaking/Helpers/Commonfunction";
import { setCarte, setSetting } from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import {
    setActiveConfigOptions,
    setActiveLanguages,
    setCustomerLanguage,
    setInitialStepTimeout,
    setIsCashMachineConfigured,
    setIsConfigOptionsActive,
    setIsLanguagesActive,
    setKioskWays,
    setLogo,
    setProject,
    setProjectTimeout,
} from "@pages/Kiosk/store";

export function useSteps(): void {
    const dispatch = useDispatch();
    const { selectedShopApp } = useSnapshot(syncStore);
    const { project } = useProject();
    const localKioskWays = useLocalWays(project);
    // eslint-disable-next-line
    const [catalog] = useLocalStorage<any>("card", {});

    React.useEffect(() => {
        if (
            catalog !== null &&
            Object.keys(catalog).length > 0 &&
            process.env.REACT_APP_CARD_ENV !== ""
        ) {
            dispatch(setCarte(catalog));
        } else {
            dispatch(getCard());
        }
    }, [catalog]);

    setKioskWays(localKioskWays);

    React.useEffect(() => {
        if (
            selectedShopApp.cash_managements !== undefined &&
            Object.values(selectedShopApp.cash_managements as ObjectType)
                .length > 0
        ) {
            const ipCashMachine = (
                Object.values(
                    selectedShopApp.cash_managements as ObjectType
                )[0] as ObjectType
            ).ip as string;

            configCashMachine(ipCashMachine);
            setIsCashMachineConfigured(true);
        }
    }, []);

    setProjectTimeout(project.template.timeout);

    setIsConfigOptionsActive(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).active
    );

    setActiveConfigOptions(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).items.filter((option) => option.active === true)
    );

    setIsLanguagesActive(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).active &&
            (
                (project.template.content as ProjectContentType)
                    .languages as LanguagesType
            )?.items.filter((option) => option.active === true).length !== 1
    );

    setActiveLanguages(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).items.filter((language) => language.active === true)
    );

    setCustomerLanguage(
        (project.template.content.languages as LanguagesType).items.filter(
            (language) => language.isDefault === true
        )[0]
    );
    const customLang = (
        project.template.content.languages as LanguagesType
    ).items.filter((language) => language.isDefault === true)[0];

    setInitialStepTimeout(project.template.initialStepTimeout as number);

    setLogo((project.template.content.generalDesign as GeneralDesignType).logo);

    dispatch(
        setSetting(
            setOrderTakingSetting(
                project.template.pages.ways.orderTaking as PageType,
                customLang.name,
                (project.template.content.generalDesign as GeneralDesignType)
                    .colors[0].content,
                (project.template.content.generalDesign as GeneralDesignType)
                    .colors[1].content
            )
        )
    );

    setProject(project);
}
