import { BASIC_COMPOSITION_TYPE } from "@constants";

import { generateItemPriceUuid } from "@helpers/general";

const getIuud = (newList, productPrice) => {
    const uidPrice = generateItemPriceUuid(newList);
    if (
        productPrice?.advancedPrice[uidPrice] === undefined &&
        newList.length > 2
    ) {
        newList.length = newList.length - 2;
        return getIuud(newList);
    }
    return uidPrice;
};
export const getPrice = (listOptions, productPrice) => {
    const listOptionsShallow = [...listOptions];
    const uidPrice = getIuud(listOptionsShallow, productPrice);
    return {
        price: productPrice?.advancedPrice[uidPrice]?.pricettc
            ? productPrice?.advancedPrice[uidPrice]?.pricettc
            : productPrice?.content,
        tva: productPrice?.advancedPrice[uidPrice]?.tva
            ? productPrice?.advancedPrice[uidPrice]?.tva
            : 0,
    };
};

export const getItemPriceWithOption = (
    selectedOptionsUuid,
    itemOptions,
    itemAdvancedPrice,
    defaultPrice
) => {
    let haveParentOption = false;
    const parentOption = selectedOptionsUuid.slice(2);
    if (itemOptions[parentOption[0]]) {
        haveParentOption =
            Object.values(itemOptions[parentOption[0]]).filter(
                (opt) => opt === parentOption[1]
            ).length > 0;
        if (haveParentOption) {
            return getPrice([...selectedOptionsUuid], itemAdvancedPrice);
        }
    }
    return defaultPrice;
};
export const updateObject = (
    keyName,
    object,
    salesModeId,
    parentItem,
    totalParentItemPrice
) => {
    for (var key in object) {
        if (key === keyName) {
            let initialItemOption = [salesModeId, "KIOSK"];
            let newPrice = getPrice(
                initialItemOption,
                object.advancedItemPrice
            );
            if (object.withSpecialPrice) {
                newPrice.price = parseFloat(object.specialPrice);
            } else {
                newPrice.price = parseFloat(object.priceOfItemInStep);
                // if (
                //     parentItem?.selectedOptions.length > 0 &&
                //     object.options
                // ) {
                //     newPrice = getItemPriceWithOption(
                //         parentItem?.selectedOptionsUuid,
                //         object.options,
                //         object.advancedItemPrice,
                //         itemPrice
                //     );
                // }
            }

            totalParentItemPrice.totalItemPrice += newPrice.price;
            object[key] = newPrice.price;
            object["tva"] = newPrice.tva;
        } else {
            if (
                typeof object[key] === "object" &&
                key !== "workflow" &&
                key !== BASIC_COMPOSITION_TYPE
            ) {
                object[key] = updateObject(keyName, object[key]);
            }
        }
    }

    return object;
};
