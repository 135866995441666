import { BASIC_COMPOSITION_TYPE } from "@constants";
import PropTypes from "prop-types";

import React from "react";

import { ModifyIcon } from "@components/OrderTaking/Components/Icons/ModifyIcon";

export default function ProductCardPopup({
    Item,
    Editproduct,
    isSalesModeChange,
}) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    if (
        Item?.compositions?.[BASIC_COMPOSITION_TYPE] &&
        !Item.basicComposition
    ) {
        Object.keys(
            Item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions
        ).forEach((key) => {
            let quantity =
                Item?.compositions[BASIC_COMPOSITION_TYPE].compositions[key]
                    .quantity;
            Item.basicComposition = Item.basicComposition
                ? `${Item.basicComposition},${quantity}x ${key} `
                : `${quantity}x ${key} `;
        });
    }

    if (Item?.compositions && !Item.compositionsItems) {
        Object.keys(Item?.compositions).forEach((key) => {
            if (key !== BASIC_COMPOSITION_TYPE) {
                let quantity = Item?.compositions[key].quantity;
                Item.compositionsItems = Item.compositionsItems
                    ? `${Item.compositionsItems},${quantity}x ${
                          key.split("|")[0]
                      } `
                    : `${quantity}x ${key.split("|")[0]} `;
            }
        });
    }

    return (
        <div className="composed-product-element my-2 px-4">
            <div
                className="composed-product-info h-100"
                style={{
                    gap: "20px",
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center h-100"
                    style={{
                        gap: "20px",
                    }}
                >
                    {Item.quantity > 1 ? (
                        <span
                            className="number-composed-product"
                            style={{
                                color: globalColor,
                            }}
                        >
                            {Item.quantity + "X"}
                        </span>
                    ) : null}
                    <div
                        className="image-composed-product"
                        style={{
                            backgroundImage: `url(${Item.urlImage})`,
                        }}
                    ></div>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        gap: "10px",
                        height: "100%",
                    }}
                >
                    <div className="d-flex flex-column ">
                        <span className="product-title">{`${Item.name}`}</span>
                        <span className="summary-options-product">
                            {` ${
                                Item.selectedOptions
                                    ? Item.selectedOptions?.join(",")
                                    : ""
                            }`}
                        </span>

                        <div className="d-flex flex-column">
                            {Item?.basicComposition && (
                                <span className="basic-composition my-2">
                                    {Item?.basicComposition}
                                </span>
                            )}
                            {Item?.compositionsItems && (
                                <span className="composed-product-characteristic">
                                    {Item.compositionsItems}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {!isSalesModeChange ? (
                <div
                    className="d-flex flex-column"
                    onClick={() => {
                        Editproduct(Item);
                    }}
                >
                    <ModifyIcon></ModifyIcon>
                    <span className="pl-2">Modifier</span>
                </div>
            ) : isSalesModeChange && Item.isDeleted === true ? (
                <img
                    style={{ width: "50px", height: "50px" }}
                    src="./images_order/crossIcon.png"
                />
            ) : null}
        </div>
    );
}

ProductCardPopup.propTypes = {
    Item: PropTypes.object,
    Editproduct: PropTypes.func,
    isSalesModeChange: PropTypes.bool,
};
