import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { setIsTechProblemModalOpened, store } from "@store";

import { getImageContentById, getSkippedStepNumber } from "@helpers/general";

import { useLocalStorage } from "@hooks/useLocalStorage";

import { setMachineIpAddress } from "@components/Synchronization/store";
import { TechnicalProblemModal } from "@components/TechnicalProblemModal";

import {
    kioskStore,
    setIsFenceProblemDetected,
    setNavigationIndex,
} from "@pages/Kiosk/store";

import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function ProdAppSwiperWrapper(): JSX.Element {
    const { middlewareApiUrl } = useSnapshot(store);
    const {
        customerLanguage,
        project: {
            files: images,
            template: { content },
        },
        activeStandbyScreenItems,
        navigationIndex,
        kioskWays,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
    } = useSnapshot(kioskStore);

    const [counter, setCounter] = React.useState(0);

    const [machineIpAddress] = useLocalStorage<string>(
        "machineIpAddress",
        "0.0.0.0"
    );

    async function handleNavigationClick() {
        setNavigationIndex(
            navigationIndex +
                Number(
                    getSkippedStepNumber(
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        isUserAuthenticated,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        content
                    )
                )
        );
        // // disable all clicks on the page
        // document.body.style.pointerEvents = "none";

        // await fetch(`${middlewareApiUrl}/checkKioskFence`)
        //     .then((response) => {
        //         if (response.ok === false) {
        //             throw new Error(response.statusText);
        //         }

        //         return response.json();
        //     })
        //     .then(({ code, fence_problem }) => {
        //         if (code === 2) {
        //             // this is a hidden bug coming from the middleware
        //             // @todo find a better solution
        //             if (fence_problem === "false") {
        //                 setNavigationIndex(
        //                     navigationIndex +
        //                         Number(
        //                             getSkippedStepNumber(
        //                                 kioskWays,
        //                                 navigationIndex,
        //                                 isAuthenticationAccepted,
        //                                 isUserAuthenticated,
        //                                 selectedSaleModeValue,
        //                                 selectedInformationMode,
        //                                 isOrderPaidWithLoyaltyAccountBalance,
        //                                 selectedRegulationModeValue,
        //                                 content
        //                             )
        //                         )
        //                 );
        //             } else {
        //                 setIsFenceProblemDetected(true);
        //                 console.log(
        //                     "The middleware return an error while checking kiosk fence!"
        //                 );
        //             }
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(
        //             `Error while checking kiosk fence, with message: ${error.message}`
        //         );
        //         setIsTechProblemModalOpened(true);
        //     })
        //     .finally(() => {
        //         // re-enable clicks on the page
        //         document.body.style.pointerEvents = "auto";
        //     });
    }

    // React.useEffect(() => {
    //     let timer: NodeJS.Timeout;

    //     if (counter >= 0) {
    //         timer = setTimeout(() => setCounter(counter + 1), 1000);
    //     }

    //     if (counter === 10) {
    //         setIsTechProblemModalOpened(false);
    //         setCounter(0);
    //     }

    //     return () => clearTimeout(timer);
    // }, [counter]);

    // React.useEffect(() => {
    //     if (machineIpAddress === "0.0.0.0") {
    //         (window as RendererWindow).electronAPI
    //             .getAppIpAddressEvent()
    //             .then((response: string) => {
    //                 setMachineIpAddress(response);
    //             });
    //     }
    // }, []);

    return (
        <div
            style={{
                position: "fixed",
                zIndex: -1,
                height: "100%",
                width: "100%",
            }}
        >
            {activeStandbyScreenItems.length > 1 ? (
                <Swiper
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    className="standby-screen-swiper-wrapper__clz"
                    onClick={handleNavigationClick}
                >
                    {activeStandbyScreenItems.map(
                        (item: StandbyScreenItemType) => {
                            const imageContent = getImageContentById(
                                item.id,
                                images,
                                customerLanguage.name
                            );

                            return (
                                <SwiperSlide
                                    key={item.id}
                                    data-swiper-autoplay={item.timeout * 1000}
                                >
                                    {item.type.includes("video") ? (
                                        <video
                                            autoPlay={true}
                                            muted={true}
                                            loop={true}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: "fill",
                                            }}
                                        >
                                            <source
                                                src={
                                                    imageContent?.includes(
                                                        "http"
                                                    )
                                                        ? imageContent
                                                        : `./images/${imageContent}`
                                                }
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : (
                                        <img
                                            src={
                                                imageContent?.includes("http")
                                                    ? imageContent
                                                    : `./images/${imageContent}`
                                            }
                                            alt={item.id}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: "fill",
                                            }}
                                        />
                                    )}
                                </SwiperSlide>
                            );
                        }
                    )}
                </Swiper>
            ) : (
                activeStandbyScreenItems.map((item: StandbyScreenItemType) =>
                    item.type.includes("video") ? (
                        <video
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            key={item.id}
                            className="h-100 w-100"
                            style={{
                                objectFit: "fill",
                            }}
                            onClick={handleNavigationClick}
                        >
                            <source
                                src={
                                    getImageContentById(
                                        item.id,
                                        images,
                                        customerLanguage.name
                                    )?.includes("http") ||
                                    getImageContentById(
                                        item.id,
                                        images,
                                        customerLanguage.name
                                    )?.includes("https")
                                        ? getImageContentById(
                                              item.id,
                                              images,
                                              customerLanguage.name
                                          )
                                        : `./images/${getImageContentById(
                                              item.id,
                                              images,
                                              customerLanguage.name
                                          )}`
                                }
                                type="video/mp4"
                            />
                        </video>
                    ) : (
                        <img
                            key={item.id}
                            src={
                                getImageContentById(
                                    item.id,
                                    images,
                                    customerLanguage.name
                                )?.includes("http") ||
                                getImageContentById(
                                    item.id,
                                    images,
                                    customerLanguage.name
                                )?.includes("https")
                                    ? getImageContentById(
                                          item.id,
                                          images,
                                          customerLanguage.name
                                      )
                                    : `./images/${getImageContentById(
                                          item.id,
                                          images,
                                          customerLanguage.name
                                      )}`
                            }
                            className="h-100 w-100"
                            style={{
                                objectFit: "fill",
                            }}
                            onClick={handleNavigationClick}
                        />
                    )
                )
            )}
            <TechnicalProblemModal />
        </div>
    );
}
