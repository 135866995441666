import { Alert } from "@material-ui/lab";
import { Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import { t } from "i18next";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import BasketActionsButtons from "@components/OrderTaking/common/BasketActionButtons";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";
import { memoizedOrderItems } from "@components/OrderTaking/Store/feature";

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={700} ref={ref} {...props} />;
});

export default function DialogCart({ openDialogCart, setOpenDialogCart }) {
    const orderItems = useSelector((state) => memoizedOrderItems(state));
    const { itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );

    if (openDialogCart && orderItems.length === 0) {
        setOpenDialogCart(false);
    }

    return (
        <React.Fragment>
            <Dialog
                TransitionComponent={TransitionSlide}
                open={openDialogCart}
                container={() => document.querySelector(".fullscreen")}
                PaperProps={{
                    sx: {
                        minWidth: "1080px !important",
                        minHeight: "1800px !important",
                        position: "fixed",
                        bottom: "-32px",
                        overflowY: "visible",
                    },
                }}
            >
                <div
                    className="cart-close-button"
                    onClick={() => setOpenDialogCart(false)}
                >
                    <span className="label-close-cart">{t("Reduce")}</span>
                </div>
                <div className="header-popup__clz">
                    <div className="title-popup-cart__clz">
                        {t("Your order")}
                    </div>
                    <div className="number-poroduct-in-cart">
                        {itemsCount} {t("Articles in your cart")}
                    </div>
                </div>
                <hr className="separation-line" />
                <div className="cart-products-list__clz py-2">
                    {!orderItems.length ? (
                        <Alert severity="error">
                            {t("No product available in cart")}
                        </Alert>
                    ) : (
                        orderItems.map((product) => (
                            <ComposedProductCartPopup
                                key={product.iuud}
                                product={product}
                            />
                        ))
                    )}
                </div>
                <hr className="separation-line" />
                <BasketActionsButtons
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                />
            </Dialog>
        </React.Fragment>
    );
}

DialogCart.propTypes = {
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
    order: PropTypes.object,
    settings: PropTypes.object,
};
