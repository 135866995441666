import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import "./index.css";

export function AddIcon({ opacity, width, height, onClick }) {
    const { secondaryColor, globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 68 68"
            style={{
                opacity,
            }}
        >
            <defs>
                <filter
                    id="Ellipse_1120"
                    width={width}
                    height={height}
                    x="0"
                    y="0"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3"></feOffset>
                    <feGaussianBlur
                        result="blur"
                        stdDeviation="3"
                    ></feGaussianBlur>
                    <feFlood floodOpacity="0.243"></feFlood>
                    <feComposite in2="blur" operator="in"></feComposite>
                    <feComposite in="SourceGraphic"></feComposite>
                </filter>
            </defs>
            <g
                data-name="Groupe 30414"
                transform="translate(8.69 5.755)"
                onClick={onClick}
            >
                <g data-name="Groupe 26897">
                    <g
                        filter="url(#Ellipse_1120)"
                        transform="translate(-8.69 -5.76)"
                    >
                        <circle
                            cx="26"
                            cy="26"
                            r="26"
                            fill="#FFFFFF"
                            data-name="Ellipse 1120"
                            transform="translate(9 6)"
                            stroke="none"
                        ></circle>
                    </g>
                    <g
                        data-name="Groupe 26895"
                        transform="translate(1.31 1.004)"
                    >
                        <path
                            fill={globalColor}
                            d="M25 0A25 25 0 110 25 25 25 0 0125 0z"
                            data-name="Tracé 21196"
                            transform="translate(0 .241)"
                            stroke="none"
                        ></path>
                        <g
                            fill="#FFFFFF"
                            stroke="#FFFFFF"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            data-name="Icon feather-plus"
                            transform="translate(19.09 18.291)"
                        >
                            <path
                                d="M6.449 0v13.689"
                                data-name="Tracé 2454"
                                fill={secondaryColor}
                                stroke={secondaryColor}
                                strokeWidth="2"
                            ></path>
                            <path
                                d="M0 6.844h12.9"
                                data-name="Tracé 2455"
                                fill={secondaryColor}
                                stroke={secondaryColor}
                                strokeWidth="2"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

AddIcon.propTypes = {
    opacity: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    onClick: PropTypes.func,
};

export function AddIconWrapper({ onClick, ...props }) {
    return (
        <button className="icons-button__clz ">
            <AddIcon {...props} onClick={onClick} />
        </button>
    );
}

AddIconWrapper.propTypes = {
    onClick: PropTypes.func.isRequired,
};
