import {
    BACK_BUTTON_ROLE,
    DELIVERY_MODE,
    VALIDATE_BUTTON_ROLE,
} from "@constants";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
    getAlertActionButtonImageSrc,
    getImageContentById,
} from "@helpers/general";

import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";
import {
    checkVisibilityNewOrder,
    deletedInvisibleNewOrder,
    editSalesMode,
} from "@components/OrderTaking/Helpers";
import { orderSalesModeModification } from "@components/OrderTaking/Store/feature";

import {
    setChosenInformationMode,
    setFinalMessageSelectedInformationMode,
    setIsInformationModesModalOpened,
    setIsUserAccountModalOpened,
    setSaleMode,
    setSelectedInformationMode,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    kioskStore as store,
} from "@pages/Kiosk/store";

export function SalesModesChangeConfirmationDialog({
    open,
    onClose,
    salesMode,
    isPrm,
    filledBasketIconContent,
}) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    const { orderItems } = useSelector((state) => state.orderSlice.order);

    const dispatchStore = useDispatch();
    const {
        project: {
            files: images,
            template: {
                content: { actionsButtons, informationModes },
                pages: { ways },
            },
        },
        customerLanguage,
        selectedInformationMode,
        isUserAuthenticated,
        kioskWays,
    } = useSnapshot(store);
    const { t } = useTranslation();

    const backButton = actionsButtons.items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backOmageContent = getImageContentById(
        backButton.id,
        images,
        customerLanguage.name
    );
    const filteredButtons = actionsButtons.items.filter((item) => {
        return item.role === VALIDATE_BUTTON_ROLE;
    })[0];

    const imageContent = getImageContentById(
        filteredButtons.id,
        images,
        customerLanguage.name
    );
    const backImageSrc =
        backOmageContent?.includes("http") ||
        backOmageContent?.includes("https")
            ? backOmageContent
            : `./images_order/${backOmageContent}`;
    const validateImageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;

    let order = JSON.parse(JSON.stringify(orderItems));

    const [newOrder] = React.useState(
        checkVisibilityNewOrder(order, salesMode.settingKey)
    );
    const [newModifiedOrder] = React.useState(
        deletedInvisibleNewOrder(newOrder, salesMode.settingKey)
    );
    const [newOrderItems] = React.useState(
        editSalesMode(newModifiedOrder, salesMode.id)
    );
    const { totalPrice } = newOrderItems;

    const handleModificationSalesMode = () => {
        setSaleMode(salesMode);
        if (
            salesMode.name !== DELIVERY_MODE ||
            (salesMode.name === DELIVERY_MODE && isUserAuthenticated === true)
        ) {
            setSelectedSaleModeValue(salesMode.name);
            setSelectedSaleModeId(salesMode.id);
            setSelectedSaleModeOrderTaking(salesMode.settingKey);

            dispatchStore(orderSalesModeModification(newOrderItems));
        }

        const activesInformationModes = informationModes.items.filter(
            (item) => item.active[salesMode.name]
        );
        const orderTakingStepIndex = kioskWays.indexOf(
            kioskWays.filter((page) => page?.name === "orderTaking")[0]
        );
        const informationModesStepIndex = kioskWays.indexOf(
            kioskWays.filter((page) => page?.name === "informationModes")[0]
        );

        if (
            (activesInformationModes.filter(
                (infoMode) => infoMode.shortName === selectedInformationMode
            ).length === 0 ||
                activesInformationModes.length > 1) &&
            ways.informationModes !== undefined &&
            orderTakingStepIndex > informationModesStepIndex
        ) {
            if (activesInformationModes.length === 0) {
                setFinalMessageSelectedInformationMode("none");
                setSelectedInformationMode("");
                setChosenInformationMode({
                    type: "",
                    data: { infoModeUuId: "", value: "" },
                });
                !isUserAuthenticated && setIsUserAccountModalOpened(true);
            } else {
                setIsInformationModesModalOpened(true);
            }
        } else if (
            salesMode.name === DELIVERY_MODE &&
            isUserAuthenticated === false &&
            ((ways.informationModes !== undefined &&
                ((activesInformationModes.filter(
                    (infoMode) => infoMode.shortName === selectedInformationMode
                ).length !== 0 &&
                    orderTakingStepIndex > informationModesStepIndex) ||
                    orderTakingStepIndex < informationModesStepIndex)) ||
                ways.informationModes === undefined)
        ) {
            setIsUserAccountModalOpened(true);
        }

        onClose();
    };
    return (
        <Dialog
            onClose={onClose}
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "5px!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: isPrm ? "50%!important" : "90%!important",
                    width: "98%!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderTop: `15px solid ${globalColor}!important`,
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: isPrm ? "20%" : "unset",
                    right: "unset!important",
                    left: "unset!important",
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <DialogTitle style={{ height: isPrm ? "50%" : "" }}>
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: isPrm ? "60%" : "" }}
                >
                    <img
                        src={getAlertActionButtonImageSrc(
                            actionsButtons,
                            images,
                            customerLanguage.name
                        )}
                        style={{
                            height: "188px",
                            width: "188px",
                        }}
                    />
                </div>
                <div className="text-confirmed-alert">
                    <span>
                        {t("Your order can be modified")}.
                        <br /> {t("Do you want to continue ?")}
                    </span>
                </div>
            </DialogTitle>
            <div
                className="d-flex flex-column justify-content-start align-items-center w-100"
                style={{
                    overflowY: "auto",
                    scrollbarWidth: "none",
                    height: "80%",
                }}
            >
                {newOrder.map((product) => (
                    <ComposedProductCartPopup
                        key={product.iuudOrder}
                        product={product}
                        isSalesModeChange={true}
                    />
                ))}
            </div>

            <div
                className="d-flex align-items-center justify-content-between w-100 px-4"
                style={{ backgroundColor: "#e3e3e3" }}
            >
                <div className="d-flex align-items-center p-1">
                    {filledBasketIconContent !== "" ? (
                        <div className="d-flex justify-content-start align-items-center h-100">
                            <img
                                src={
                                    filledBasketIconContent?.includes("http")
                                        ? filledBasketIconContent
                                        : `./images_order/${filledBasketIconContent}`
                                }
                                alt={filledBasketIconContent}
                                style={{
                                    width: " 70px",
                                    height: "70px",
                                    borderRadius: "15px",
                                }}
                            />
                        </div>
                    ) : (
                        <CartIconFilled />
                    )}
                    <span
                        style={{
                            font: "normal normal 600 20px/48px Segoe UI",
                            paddingLeft: "0.5rem",
                        }}
                    >
                        NOUVEAU PRIX :{" "}
                    </span>
                </div>

                <span style={{ font: "normal normal 600 29px/35px Segoe UI" }}>
                    {totalPrice.toFixed(2)} €
                </span>
            </div>
            <DialogActions>
                <div
                    className="d-flex justify-content-center align-items-center pb-2"
                    sx={{ gap: "15px", height: "10%" }}
                >
                    {backImageSrc !== "" ? (
                        <div
                            className="mx-2"
                            style={{
                                backgroundSize: " 100% 100%",
                                backgroundImage: `url(${backImageSrc})`,
                                height: "87px",
                                width: "350px",
                            }}
                            onClick={onClose}
                        />
                    ) : (
                        <img
                            alt="backButton"
                            src="./images_order/back.png"
                            style={{ height: "87px", width: "350px" }}
                            onClick={onClose}
                        />
                    )}
                    {validateImageSrc !== "" ? (
                        <div
                            className="mx-2"
                            style={{
                                backgroundSize: " 100% 100%",
                                backgroundImage: `url(${validateImageSrc})`,
                                height: "87px",
                                width: "350px",
                            }}
                            onClick={() => {
                                handleModificationSalesMode();
                            }}
                        />
                    ) : (
                        <img
                            alt="addToBasketButton"
                            src="./images_order/validate.png"
                            style={{ height: "87px", width: "350px" }}
                            onClick={() => {
                                handleModificationSalesMode();
                            }}
                        />
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
}

SalesModesChangeConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    salesMode: PropTypes.object,
    isPrm: PropTypes.bool,
    filledBasketIconContent: PropTypes.string,
};
