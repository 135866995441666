import React from "react";

import { ActionsButtonsImageContent } from "./ActionsButtonsImageContent";

type ActionsButtonsPropsType = {
    actionsButtonsItems: ActionsButtonsDesignItemType[];
};

export function PrmActionsButtonsWrapper({
    actionsButtonsItems,
}: ActionsButtonsPropsType): JSX.Element {
    return (
        <React.Fragment>
            {actionsButtonsItems?.length === 1 ? (
                <div className="d-flex justify-content-center align-items-center">
                    <ActionsButtonsImageContent item={actionsButtonsItems[0]} />
                </div>
            ) : actionsButtonsItems?.length === 2 ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: "25px" }}
                >
                    {actionsButtonsItems.map(
                        (item: ActionsButtonsDesignItemType, index: number) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={index}
                                    item={item}
                                />
                            );
                        }
                    )}
                </div>
            ) : actionsButtonsItems?.length === 3 ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: "25px" }}
                >
                    {actionsButtonsItems.map(
                        (item: ActionsButtonsDesignItemType, index: number) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={index}
                                    item={item}
                                />
                            );
                        }
                    )}
                </div>
            ) : null}
        </React.Fragment>
    );
}
