import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";

import { formatCurrency, getImageContentById } from "@helpers/general";

import {
    setIsAmountReturned,
    setIsChangeMachineRenderModalOpened,
    kioskStore as store,
} from "@pages/Kiosk/store";

type PropsType = {
    containerRef?: HTMLElement | null;
};

export function ChangeMachineRenderModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isChangeMachineRenderModalOpened,
        isPrm,
        project: {
            files: images,
            template: {
                content: { generalDesign, meansOfPayment },
            },
        },
        renderAmount,
        customerLanguage,
        isCommandInsertionProblemModalOpened,
        isCreatingVoucherAccepted,
        voucherAmount,
    } = useSnapshot(store);

    const { middlwareOrder } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.middlwareOrderSlice
    );

    const [counter, setCounter] = React.useState<number>(0);

    const changeMachineImageContent = getImageContentById(
        (
            (meansOfPayment as ProjectContentItemType).items.find(
                (meanOfPayment) => meanOfPayment.shortName === "changeMachine"
            ) as ProjectMainContentItemType
        ).id,
        images,
        customerLanguage.name
    );
    const changeMachineImageSrc =
        changeMachineImageContent?.includes("http") ||
        changeMachineImageContent?.includes("https")
            ? changeMachineImageContent
            : `./images/${changeMachineImageContent}`;

    const calculatedVoucherAmount = isCreatingVoucherAccepted
        ? voucherAmount
        : 0;

    React.useEffect(() => {
        if (isCommandInsertionProblemModalOpened) {
            let timer: NodeJS.Timeout;

            if (counter >= 0) {
                timer = setTimeout(() => setCounter(counter + 1), 1000);
            }

            if (counter === 3) {
                setIsChangeMachineRenderModalOpened(false);
                setIsAmountReturned(true);
            }
            return () => clearTimeout(timer);
        }
    }, [counter]);

    return (
        <Modal
            isOpen={isChangeMachineRenderModalOpened}
            ariaHideApp={false}
            contentLabel="bridge-number"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "30px",
                    color: "black",
                    height: "1335px",
                    maxHeight: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflowY: "hidden",
                },
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateRows: "0.8fr 0.7fr 1.2fr",
                    gap: "30px",
                    width: "100%",
                    height: "100%",
                }}
            >
                <div className="d-flex justify-content-center align-items-center">
                    <img src={changeMachineImageSrc} />
                </div>
                <div
                    className="d-flex flex-column align-items-center"
                    style={{ gap: "100px" }}
                >
                    <span
                        style={{
                            font: "font: normal normal 600 55px/60px Segoe UI",
                        }}
                    >
                        {t("Coin operated payment")}
                    </span>

                    <span
                        className="text-center"
                        style={{
                            font: "normal normal normal 35px/47px Segoe UI",
                        }}
                    >
                        {t("Please collect your change")}
                    </span>
                </div>
                <div className="d-flex justify-content-center align-items-end">
                    <Card
                        style={{
                            width: "771px",
                            height: "159px",
                            borderRadius: "31px",
                            borderBottom: "12px solid #D51B1B",
                            boxShadow: "0px 3px 6px #00000029",
                            marginBottom: "100px",
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                            }}
                        >
                            <div
                                className="d-flex justify-content-between align-items-center mx-5 px-2"
                                style={{
                                    font: "normal normal 600 45px/128px Segoe UI",
                                }}
                            >
                                <div>{t("Render")}</div>
                                <div>
                                    {formatCurrency(
                                        isCommandInsertionProblemModalOpened
                                            ? Number(middlwareOrder.totalttc) +
                                                  calculatedVoucherAmount
                                            : renderAmount
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Modal>
    );
}
