import { BACK_BUTTON_ROLE } from "@constants";
import { Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function RemoveProductConfirmation({
    open,
    onClose,
    items,
    isWorkflow,
}) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const {
        isPrm,
        project: {
            files: images,
            template: {
                content: { actionsButtons },
            },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);
    items?.length === 0 && onClose();
    const backButton = actionsButtons.items.filter((button) => {
        return button.role === BACK_BUTTON_ROLE;
    })[0];
    const imageContent = getImageContentById(
        backButton.id,
        images,
        customerLanguage.name
    );

    const backButtonImageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;
    return (
        <>
            <Dialog
                onClose={onClose}
                open={open}
                container={() => document.querySelector(".fullscreen")}
                PaperProps={{
                    sx: {
                        display: "flex!important",
                        alignItems: "center!important",
                        flexDirection: "column!important",
                        paddingTop: "0px!important",
                        paddingBottom: "0px!important",
                        color: "black!important",
                        height: isPrm ? "35% !important" : "1855px!important",
                        width: "1010px!important",
                        maxWidth: "unset!important",
                        maxHeight: "unset",
                        borderTop: `15px solid ${globalColor}!important`,
                        backgroundColor: "#FFFFFF!important",
                        borderRadius: isPrm
                            ? "111px 111px 0px 0px!important"
                            : "111px !important",
                        borderLeft: "1px solid #707070!important",
                        borderRight: "1px solid #707070!important",
                        borderBottom: "1px solid #707070!important",
                        top: isPrm ? "33%" : "unset",
                        right: "unset!important",
                        left: "unset!important",
                        background: `transparent linear-gradient(180deg, #FFFFFF 0%, ${globalColor} 300%) 0% 0% no-repeat padding-box`,
                    },
                }}
                style={{ backdropFilter: "blur(5px)" }}
            >
                <div
                    className="d-flex flex-column justify-content-center align-items-center w-100"
                    style={{ height: "245px", gap: "20px" }}
                >
                    <span
                        style={{
                            font: "normal normal 400 30px/40px Segoe UI",
                        }}
                    >
                        Quel produit
                    </span>
                    <span
                        style={{
                            height: "100px",
                            font: "normal normal 600 55px/60px Nunito Sans",
                        }}
                    >
                        Voulez vous supprimer ?
                    </span>
                </div>
                <div
                    className="d-flex flex-column align-items-center"
                    style={{
                        width: "100%",
                        height: "100%",
                        overflowY: "auto",
                        scrollbarWidth: "none",
                    }}
                >
                    {items?.map((item, index) => {
                        return (
                            <ComposedProductCartPopup
                                key={index}
                                product={item}
                                isRemovedProductDialog={true}
                                isWorkflow={isWorkflow}
                            />
                        );
                    })}
                </div>
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "250px" }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${backButtonImageSrc})`,
                            backgroundSize: "100% 100%",
                            height: "50%",
                            width: "30%",
                        }}
                        onClick={onClose}
                    />
                </div>
            </Dialog>
        </>
    );
}

RemoveProductConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    items: PropTypes.array,
    isWorkflow: PropTypes.bool,
};
