import { CrossIcon, StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import { setIsAppSynchronized } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { CheckInCircle, KioskAppLogoSvgIcon } from "@components/SvgIcons";
import { SynchronizeSvgIcon } from "@components/SvgIcons/SynchronizeSvgIcon";
import {
    setIsEntityChildrenSelectionModalOpened,
    setIsSyncProgressModalWrapperOpened,
    syncStore,
} from "@components/Synchronization/store";

import "./index.css";

export function SyncProgressModalWrapper(): JSX.Element {
    const navigate = useNavigate();

    const { isSyncProgressModalWrapperOpened, progressBarValue } =
        useSnapshot(syncStore);

    const [isSyncEnds, setIsSyncEnds] = React.useState<boolean>(false);

    function handleLaunchAppButtonOnClickEvent() {
        setIsSyncProgressModalWrapperOpened(false);
        setIsEntityChildrenSelectionModalOpened(false);
        navigate("/kiosk-steps");
    }

    React.useEffect(() => {
        (window as RendererWindow).electronAPI.sendResizeWindowEvent({
            width: 980,
            height: 500,
        });
    }, []);

    React.useEffect(() => {
        if (progressBarValue >= 100) {
            setIsSyncEnds(true);
            setIsAppSynchronized(true);
        }
    }, [progressBarValue]);

    return (
        <Modal
            isOpen={isSyncProgressModalWrapperOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "30px",
                    color: "black",
                    height: "500px",
                    width: "980px",
                    zIndex: 2,
                    background: "#000000 0% 0% no-repeat padding-box",
                    border: "1px solid #FFFFFF",
                    gap: "50px",
                    borderRadius: "18px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <FlexboxGrid className="justify-content-between">
                <KioskAppLogoSvgIcon height="88px" width="220px" />

                <CrossIcon
                    onClick={() => {
                        (window as RendererWindow).electronAPI.sendQuitEvent();
                    }}
                    height={20}
                    width={20}
                    fill="white"
                    style={{ cursor: "pointer" }}
                />
            </FlexboxGrid>
            <FlexboxGrid className="justify-content-between" gap="160px">
                <FlexboxGrid
                    className="flex-column"
                    gap="20px"
                    justifyContentCentered={true}
                >
                    <span
                        style={{
                            font: "normal normal bold 27px/33px Segoe UI",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                        }}
                    >
                        {t(
                            isSyncEnds
                                ? "Installation complete"
                                : "Synchronization in progress"
                        )}
                    </span>
                    {isSyncEnds ? null : (
                        <span
                            style={{
                                font: "normal normal normal 15px/17px Segoe UI",
                                color: "#FFFFFF",
                                textTransform: "uppercase",
                            }}
                        >
                            {t(
                                "Please wait a few minutes to be able to use your application"
                            )}
                        </span>
                    )}
                </FlexboxGrid>
                {isSyncEnds ? (
                    <CheckInCircle height={200} width={200} />
                ) : (
                    <SynchronizeSvgIcon fill="white" height={200} width={200} />
                )}
            </FlexboxGrid>

            {isSyncEnds ? (
                <FlexboxGrid className="justify-content-between">
                    <StyledButton
                        rounded={true}
                        className="primary-outline-btn-hover__clz"
                        style={{
                            font: "normal normal normal 22px/26px Segoe UI",
                            background: "#000000 0% 0% no-repeat padding-box",
                            border: "1px solid #FFFFFF",
                            height: "64px",
                            width: "218px",
                        }}
                        onClick={() => {
                            (
                                window as RendererWindow
                            ).electronAPI.sendQuitEvent();
                        }}
                    >
                        {t("Close")}
                    </StyledButton>
                    <StyledButton
                        outline={true}
                        rounded={true}
                        className="primary-btn-hover__clz"
                        style={{
                            font: "normal normal normal 22px/26px Segoe UI",
                            background: "#F4F4F4 0% 0% no-repeat padding-box",
                            border: "1px solid #FFFFFF",
                            height: "64px",
                            width: "555px",
                        }}
                        onClick={handleLaunchAppButtonOnClickEvent}
                    >
                        {t("Launch my app")}
                    </StyledButton>
                </FlexboxGrid>
            ) : (
                <div className="progress progress__clz">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progressBarValue}%` }}
                    >
                        {progressBarValue}%
                    </div>
                </div>
            )}
        </Modal>
    );
}
