import { BASIC_COMPOSITION_TYPE } from "@constants";
import classname from "classnames";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
    getItemComposition,
    refactorWorkflowData,
} from "@components/OrderTaking/Helpers";
import { setWorkflowData } from "@components/OrderTaking/Store/feature";

import { ShopCardSkeleton } from "./ShopCardSkeleton";
import ShowDetailsDialog from "./ShowDetailsDialog";

export function ShowCardProduct({ item }) {
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );
    //  const { isShopCardLoading } = useSnapshot(kioskStore);

    const [isBackActive, setIsBackActive] = React.useState(false);

    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );
    const listworkflow = workflowData[workflowData.length - 1];
    const canEditItem =
        listworkflow &&
        listworkflow?.workflow[listworkflow.index]?.NbrOfChoices >=
            listworkflow?.workflow[listworkflow.index]?.minNbrOfChoices;

    const dispatchStore = useDispatch();

    const [isShowDetailsOpened, setIsShowDetailsOpened] = React.useState(false);

    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    const [title, setTitle] = React.useState(item.name);
    const [path, setPath] = React.useState(item.path);
    const [isShopCardLoading, setIsShopCardLoading] = React.useState(true);

    const itemsCompositionView =
        item?.compositions &&
        Object.keys(item?.compositions).length > 0 &&
        item.type !== BASIC_COMPOSITION_TYPE
            ? getItemComposition(item?.compositions)
            : [];

    function handleModifyProductOnCLickEvent() {
        if (!canEditItem) return;

        const workflowModify = refactorWorkflowData(
            item.workflow,
            orderWorkflowItemSlice.item.shopCart
        );
        dispatchStore(setWorkflowData([...workflowModify]));
    }

    function handleShowDetailOnClickEvent() {
        setIsShowDetailsOpened(!isShowDetailsOpened);
        setPath(item.path);
        setIsBackActive(false);
    }
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsShopCardLoading(false);
        }, 100);
        return () => clearTimeout(timer);
    }, []);
    return isShopCardLoading ? (
        <ShopCardSkeleton item={item} />
    ) : (
        <React.Fragment>
            {isShowDetailsOpened && (
                <ShowDetailsDialog
                    open={isShowDetailsOpened}
                    onClose={handleShowDetailOnClickEvent}
                    path={path}
                    title={title}
                    item={item}
                    setTitle={setTitle}
                    setPath={setPath}
                    setIsBackActive={setIsBackActive}
                    isBackActive={isBackActive}
                />
            )}

            <div
                className="d-flex flex-column justify-content-start align-items-center py-2"
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #0000001A",
                    border: "1px solid  #E5E5E5",
                    borderRadius: "12px",
                    height: "219px",
                    width:
                        item.compositions !== undefined || item.price > 0
                            ? "334px"
                            : "195px",
                }}
            >
                <div
                    className="d-flex align-items-center px-2"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        className="d-flex align-items-center flex-column"
                        style={{ width: "100%", gap: "5px" }}
                    >
                        <div
                            className="d-flex align-items-start"
                            style={{
                                width: "100%",
                                justifyContent:
                                    item.price > 0 ? "space-between" : "center",
                            }}
                        >
                            <div
                                className="cart-image-product__clz"
                                style={{
                                    backgroundImage: `url(${item.urlImage})`,
                                }}
                            ></div>
                            {item.price > 0 && (
                                <div className="price-product-preview">
                                    {item.price.toFixed(2) + " €"}
                                </div>
                            )}
                        </div>
                        <div
                            className="title-product-details two-lines-preview-text"
                            style={{
                                font: "normal normal bold 16px/21px Segoe UI",
                                textAlign: "center",
                            }}
                        >
                            {item.quantity > 1 ? (
                                <span
                                    style={{
                                        color: globalColor,
                                    }}
                                >
                                    {item.quantity > 0
                                        ? item.quantity + "X "
                                        : ""}
                                </span>
                            ) : null}
                            <span>{item.name}</span>
                        </div>
                        {item.selectedOptions?.length > 0 && (
                            <span
                                className="preview-text"
                                style={{ color: "#b7b7b7" }}
                            >
                                {item.selectedOptions.join(",")}
                            </span>
                        )}

                        {itemsCompositionView?.length > 0 && (
                            <span className="product-details__clz preview-text">
                                {itemsCompositionView.join(", ")}
                            </span>
                        )}
                    </div>
                </div>
                <div
                    className={classname(
                        "d-flex justify-content-center align-items-center ",
                        {
                            "disable-edit": !canEditItem,
                        }
                    )}
                >
                    {item.compositions !== undefined ? (
                        <button
                            className="d-flex justify-content-center align-items-center  see-details"
                            style={{
                                backgroundColor: globalColor,
                            }}
                            onClick={() => {
                                setIsShowDetailsOpened(!isShowDetailsOpened);
                            }}
                        >
                            Voir détails
                        </button>
                    ) : (
                        <img
                            src="./images_order/editButton.png"
                            onClick={() => {
                                setIsShowDetailsOpened(false);
                                handleModifyProductOnCLickEvent();
                            }}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
export const ShowCardProductMemo = React.memo(ShowCardProduct);
ShowCardProduct.propTypes = {
    item: PropTypes.object,
};
