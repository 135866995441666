import { Box, CardContent } from "@mui/material";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export function RedirectCardProduct({
    productName,
    productImage,

    // isVisibleName,
    productId,
    quantity,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
    handleAddProductClick,
    isPrm,
    globalColor,
}) {
    const { isCategorySubCategoryTitleActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );
    return (
        <>
            <div className="first-box" style={{ width: "100%" }}>
                <img
                    loading="lazy"
                    // height="140"
                    id={`key-img${productId}`}
                    className="product-image"
                    src={productImage}
                    alt={productName}
                    height={isPrm ? "unset" : "234px"}
                    onClick={() => {
                        isAllergenIconActive === true
                            ? setIsAllergenConfirmationDialogOpened(true)
                            : handleAddProductClick();
                    }}
                />
            </div>
            <div className="first-box"></div>
            <CardContent
                style={{
                    display: "grid",
                    paddingTop: isPrm ? "0" : "unset",
                    gridTemplateRows: !isPrm
                        ? "25px 60px 40px auto"
                        : "18px 40px 22px 20px",
                    background:
                        quantity > 0
                            ? `transparent
                                    linear-gradient(180deg, #ffffff00 0%, ${globalColor} 700%) 100% 100% repeat`
                            : "",
                }}
            >
                <div></div>
                {isCategorySubCategoryTitleActive ? (
                    <Box
                        className={`centredItems__clz px-1 ${
                            isPrm ? "prm-product-title" : "product-title__clz"
                        }`}
                    >
                        <Box>{productName}</Box>
                    </Box>
                ) : null}
            </CardContent>
        </>
    );
}

RedirectCardProduct.propTypes = {
    productName: PropTypes.string,
    productImage: PropTypes.string,
    productId: PropTypes.string,
    quantity: PropTypes.number,
    isAllergenIconActive: PropTypes.bool,
    setIsAllergenConfirmationDialogOpened: PropTypes.func,
    handleAddProductClick: PropTypes.func,
    isPrm: PropTypes.bool,
    globalColor: PropTypes.string,
    isVisibleName: PropTypes.bool,
};
