import { StyledModal } from "@aureskonnect/react-ui";
import { t } from "i18next";
import React from "react";
import { ModalBody } from "reactstrap";
import { useSnapshot } from "valtio";

import { store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import "./index.css";

export function TechnicalProblemModal(): JSX.Element {
    const { isTechProblemModalOpened } = useSnapshot(store);

    return (
        <StyledModal
            className="tech-problem-modal__clz"
            centered={true}
            isOpen={isTechProblemModalOpened}
        >
            <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                <FlexboxGrid
                    justifyContentCentered={true}
                    alignItemsCentered={true}
                    gap="100px"
                    className="flex-column"
                >
                    <img
                        src="./images/alert.png"
                        style={{
                            height: "124px",
                            width: "144px",
                            marginTop: "30px",
                        }}
                    />
                    <h2
                        className="text-uppercase text-center"
                        style={{
                            font: "normal normal bold 50px/55px Bebas Neue",
                            letterSpacing: "2px",
                            color: "#171717",
                        }}
                    >
                        {t("Technical problem")}
                    </h2>
                </FlexboxGrid>
                <div
                    className="d-flex flex-column align-items-center px-5"
                    style={{
                        font: "normal normal 300 35px/42px Segoe UI",
                        letterSpacing: "0.17px",
                        textAlign: "center",
                    }}
                >
                    {t("Please contact technical support")}
                </div>

                <FlexboxGrid
                    className="w-100 p-4 flex-column"
                    alignItemsCentered={true}
                    gap="10px"
                    styles={{
                        background: "#EDE8E8 0% 0% no-repeat padding-box",
                        borderRadius: "20px",
                        textAlign: "center",
                        font: "normal normal 300 22px/27px Segoe UI",
                        letterSpacing: "0.11px",
                        color: "#747474",
                    }}
                >
                    <span>contact@support.com</span>
                    <span>01.98.47.35.43</span>
                </FlexboxGrid>
            </ModalBody>
        </StyledModal>
    );
}
