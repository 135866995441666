import { Box } from "@material-ui/core";
import PropTypes from "prop-types";

import React from "react";

import { AddIconWrapper } from "@components/OrderTaking/Components/Icons/AddIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";

export function AddRemoveActionsButton({
    product,
    opacity,
    handleRemoveProductFromOrder,
    quantity,
}) {
    return product.outOfStock === false ? (
        <Box className="action-buttons">
            <Box
                style={{
                    marginTop: -7,
                }}
            >
                <AddIconWrapper opacity={opacity} width={68} height={68} />
            </Box>
            {quantity > 0 ? (
                <Box
                    style={{
                        marginTop: -20,
                    }}
                >
                    <RemoveIcon
                        width={68}
                        height={68}
                        onClick={handleRemoveProductFromOrder}
                    />
                </Box>
            ) : null}
        </Box>
    ) : null;
}

AddRemoveActionsButton.propTypes = {
    product: PropTypes.object.isRequired,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    handleRemoveProductFromOrder: PropTypes.func,
};
