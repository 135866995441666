import { EffectCoverflow, Mousewheel, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
    memoizedActiveCategory,
    memoizedCategories,
    setActiveCategory,
    setActiveCategoryParent,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./categories.css";
import { CategoryCardMemoised } from "./CategoryCard.jsx";

import "swiper/swiper-bundle.min.css";

export function CategoriesSwiperWrapper() {
    const dispatchStore = useDispatch();
    const { nestedCategories } = useSelector((state) => state.orderSlice);
    const globalcategorys = useSelector((state) => memoizedCategories(state));

    const { isPrm, selectedSaleModeOrderTaking } = useSnapshot(kioskStore);

    const activeCategory = useSelector((state) =>
        memoizedActiveCategory(state)
    );

    const { isOrderTakingCategoryTitleActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );
    const listVisibleCategories = globalcategorys.filter(
        (category) =>
            category?.visibilityInfo.isVisible &&
            (category?.KioskVisibility[selectedSaleModeOrderTaking] ||
                category?.KioskVisibility[selectedSaleModeOrderTaking] ===
                    undefined)
    );

    const initialActiveCategory = listVisibleCategories.findIndex(
        (category) => category.iuud === activeCategory
    );
    const categoryIsExist = listVisibleCategories.some(
        (cat) => cat.iuud === activeCategory
    );
    if (!categoryIsExist && nestedCategories.length === 0) {
        dispatchStore(setActiveCategoryParent(""));
        dispatchStore(setActiveCategory(listVisibleCategories[0].iuud));
    }

    return (
        <Swiper
            className="category-swiper__clz h-100"
            spaceBetween={
                isPrm ? 10 : isOrderTakingCategoryTitleActive ? 70 : 40
            }
            speed={200}
            effect={isPrm ? "slide" : "coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView="auto" //3
            loop={true}
            mousewheel={true}
            coverflowEffect={
                isPrm
                    ? undefined
                    : {
                          rotate: 0,
                          stretch: 10,
                          depth: 110,
                          modifier: 1,
                          slideShadows: false,
                      }
            }
            direction="vertical"
            slideToClickedSlide={true}
            initialSlide={initialActiveCategory}
            modules={[EffectCoverflow, Mousewheel, Virtual]}
            loopedSlides={
                listVisibleCategories.length < 20
                    ? listVisibleCategories.length
                    : 5
            }
        >
            {listVisibleCategories.map((category, index) => {
                return (
                    <SwiperSlide
                        className="category-swiper-slide__clz"
                        key={category.iuud}
                        virtualIndex={index}
                    >
                        <CategoryCardMemoised category={category} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
export const CategoriesSwiperWrapperMemoised = React.memo(
    CategoriesSwiperWrapper
);
