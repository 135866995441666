import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function PausedAppSwiperWrapper(): JSX.Element {
    const {
        customerLanguage,
        project: { files: images },
        standbyStateScreenItems,
    } = useSnapshot(kioskStore);

    return (
        <div
            style={{
                position: "fixed",
                zIndex: -1,
                height: "100%",
                width: "100%",
            }}
        >
            {standbyStateScreenItems.length > 1 ? (
                <Swiper
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    className="standby-screen-swiper-wrapper__clz"
                >
                    {standbyStateScreenItems.map(
                        (item: StandbyScreenItemType) => {
                            const imageContent = getImageContentById(
                                item.id,
                                images,
                                customerLanguage.name
                            );

                            return (
                                <SwiperSlide
                                    key={item.id}
                                    data-swiper-autoplay={item.timeout * 1000}
                                >
                                    {item.type.includes("video") ? (
                                        <video
                                            autoPlay={true}
                                            muted={true}
                                            loop={true}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: "fill",
                                            }}
                                        >
                                            <source
                                                src={
                                                    imageContent?.includes(
                                                        "http"
                                                    )
                                                        ? imageContent
                                                        : `./images/${imageContent}`
                                                }
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : (
                                        <img
                                            src={
                                                imageContent?.includes("http")
                                                    ? imageContent
                                                    : `./images/${imageContent}`
                                            }
                                            alt={item.id}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: "fill",
                                            }}
                                        />
                                    )}
                                </SwiperSlide>
                            );
                        }
                    )}
                </Swiper>
            ) : (
                standbyStateScreenItems.map((item: StandbyScreenItemType) =>
                    item.type.includes("video") ? (
                        <video
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            key={`is_app_paused-video__${item.id}`}
                            className="h-100 w-100"
                            style={{
                                objectFit: "fill",
                            }}
                        >
                            <source
                                src={
                                    getImageContentById(
                                        item.id,
                                        images,
                                        customerLanguage.name
                                    )?.includes("http") ||
                                    getImageContentById(
                                        item.id,
                                        images,
                                        customerLanguage.name
                                    )?.includes("https")
                                        ? getImageContentById(
                                              item.id,
                                              images,
                                              customerLanguage.name
                                          )
                                        : `./images/${getImageContentById(
                                              item.id,
                                              images,
                                              customerLanguage.name
                                          )}`
                                }
                                type="video/mp4"
                            />
                        </video>
                    ) : (
                        <img
                            key={`is_app_paused-img__${item.id}`}
                            src={
                                getImageContentById(
                                    item.id,
                                    images,
                                    customerLanguage.name
                                )?.includes("http") ||
                                getImageContentById(
                                    item.id,
                                    images,
                                    customerLanguage.name
                                )?.includes("https")
                                    ? getImageContentById(
                                          item.id,
                                          images,
                                          customerLanguage.name
                                      )
                                    : `./images/${getImageContentById(
                                          item.id,
                                          images,
                                          customerLanguage.name
                                      )}`
                            }
                            className="h-100 w-100"
                            style={{
                                objectFit: "fill",
                            }}
                        />
                    )
                )
            )}
        </div>
    );
}
