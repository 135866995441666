import { proxy, subscribe } from "valtio";

import { KeyboardInput } from "react-simple-keyboard";

type StoreType = {
    customerLanguage: LanguagesItemType;
    isPrm: boolean;
    isStandbyModalOpened: boolean;
    isHelpModalOpened: boolean;
    isUserAccountActive: boolean;
    isLanguagesActive: boolean;
    activeLanguages: LanguagesItemType[];
    isConfigOptionsActive: boolean;
    activeConfigOptions: OptionsItemType[];
    isLogoActive: boolean;
    isActionButtonsActive: boolean;
    isInformationMessageActive: boolean;
    actionsButtonsItems: ActionsButtonsDesignItemType[];
    mainContentItems:
        | ProjectMainContentItemType[]
        | PaymentMainContentItemType[];
    saleMethodsNumber: number;
    regulationModesNumber: number;
    informationMessageItems: HeaderLanguageItemsType;
    subStepInformationMessageItems: HeaderLanguageItemsType;
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    userAccountMessageItems: HeaderLanguageItemsType;
    activeStandbyScreenItems: StandbyScreenItemType[];
    timeout: number;
    activeActionsButtons: ActionsButtonsDesignItemType[];
    initialStepTimeout: number;
    isUserAccountModalOpened: boolean;
    selectedAuthMode: string;
    chosenTransition: TransitionType;
    isAnimationIn: boolean;
    selectedSaleModeValue: string;
    kioskWays: WaysType[];
    selectedInformationMode: string;
    isSubStepIconActive: boolean;
    isSubStepTopBannerActive: boolean;
    isSubStepLogoActive: boolean;
    isSubStepInformationMessageActive: boolean;
    isSubStepActionButtonsActive: boolean;
    navigationIndex: number;
    authModalStepIndex: number;
    project: ProjectType;
    subStepActionButtons: ActionsButtonsDesignItemType[];
    isUserAuthenticated: boolean;
    subStepIconId: string;
    isIDontIdentifyMySelfClicked: boolean;
    isInvalidQrCodeModalOpened: boolean;
    isOrderProcessedModalWrapperOpened: boolean;
    isQrCodeCorrectOrderNotProcessedModalOpened: boolean;
    selectedRegulationModeValue: string;
    isConfirmationModalOpened: boolean;
    numericKeyboardInputValue: string;
    isAuthenticationErrorModalOpened: boolean;
    keyboardInputsValues: KeyboardInput;
    logo: GeneralDesignLogoType;
    ticketNumberMessageFooter: HeaderLanguageItemsType;
    isTicketNumberMessageFooterActive: boolean;
    finalMessageFooter: HeaderLanguageItemsType;
    isFinalMessageFooterActive: boolean;
    finalMessage: string;
    finalMessageSelectedInformationMode: string;
    finalMessageSelectedRegulationMode: string;
    totalOrderAfterPaidWithLoyaltyAccountBalance: number;
    isChangeMachineRenderModalOpened: boolean;
    isRefundAnomalyModalOpened: boolean;
    amountDeposited: number;
    changeMachineBalance: number;
    isRegulationModesModalOpened: boolean;
    isTpaValinaActive: boolean;
    isTpaValinaAvailable: boolean;
    isTpaValinaModalOpened: boolean;
    creditCardPaymentChoice: string;
    isBackWithoutCreditCardPaymentChoice: boolean;
    isTpaValinaIconActive: boolean;
    tpaValinaIconId: string;
    isTpaValinaInformationMessageActive: boolean;
    tpaValinaInformationMessageItems: HeaderLanguageItemsType;
    tpaValinaActionButtons: ActionsButtonsDesignItemType[];
    isTpaValinaActionButtonsActive: boolean;
    isAuthenticationAccepted: boolean;
    isOrderPaidWithLoyaltyAccountBalance: boolean;
    isOrderPartialPaidWithLoyaltyAccountBalance: boolean;
    isLocalSettingModalOpened: boolean;
    isAuthenticationModalOpened: boolean;
    isLocalConfigModalOpened: boolean;
    isErrorLogInModalWrapperOpened: boolean;
    isScanQrCodeModalOpened: boolean;
    activeTabTitle: string;
    localProject: ProjectType;
    isMenuModalOpened: boolean;
    isAppPaused: boolean;
    activeStateScreenItems: StandbyScreenItemType[];
    standbyStateScreenItems: StandbyScreenItemType[];
    standbyScreenItems: StandbyScreenItemType[];
    isConfirmationStandbyStateModalOpened: boolean;
    isUsersListModalOpened: boolean;
    leftToPay: number;
    renderAmount: number;
    orderNumber: string;
    isAbortTransactionModalOpened: boolean;
    regulationModesModalKey: string;
    leftToPayAfterCashMachineValidation: number;
    qrCodeValue: string;
    selectedSaleModeId: string;
    selectedSaleModeOrderTaking: string;
    user: UserType;
    pseudoNameValue: string;
    chosenInformationMode: InformationModeType;
    paymentModes: PaymentModesType;
    cashUuID: string;
    creditCardUuId: string;
    loyaltyUuId: string;
    isCreatingVoucherAccepted: boolean;
    isRestartModalOpened: boolean;
    isShutdownModalOpened: boolean;
    isCloseAppModalOpened: boolean;
    isSelectedSaleModeWithoutInformationModes: boolean;
    isBackButtonDisabled: boolean;
    isAnotherPaymentModeButtonDisabled: boolean;
    isLogout: boolean;
    isCashMachineConfigured: boolean;
    isFenceProblemDetected: boolean;
    technicalProblemKey: string;
    // eslint-disable-next-line
    shopOpeningHour: any[];
    totalAmountDeposited: number;
    isTransactionCanceled: boolean;
    isWithdrawalProblemModalOpened: boolean;
    isPaymentRefused: boolean;
    voucherAmount: number;
    isCreationVoucherCanceled: boolean;
    pagesItems: ItemsPagesType;
    isCashTransactionValidated: boolean;
    isAnotherPaymentModeButtonCBDisabled: boolean;
    isPaymentTechnicalProblemModalOpened: boolean;
    isPaymentTechnicalProblemDetected: boolean;
    isCommandInsertionProblemModalOpened: boolean;
    finalMessageCounter: number;
    isAmountReturned: boolean;
    orderItemsLength: number;
    isInformationModesModalOpened: boolean;
    isInformationModesConsommationModalOpened: boolean;
    // eslint-disable-next-line
    saleMode: any;
    orderModificationMode: boolean;
    chosenLocalInformationMode: InformationModeType;
    localSelectedInformationMode: string;
    isOrderTakingPreviousStep: boolean;
    isOrderInsertedSuccessfully: boolean;
    returnedAmount: number;
    isOrderInsertionRefused: boolean;
    isLogoutProblemModalOpened: boolean;
    isChangeMachineReady: boolean;
    isAcceptAbortCBTransaction: boolean;
    isCashMachineTransactionCanceled: boolean;
    isOrderLoading: boolean;
    isWorkflowLoading: boolean;
    isAnimationTimeOut: boolean;
    isRegulationModesModalBackButtonClicked: boolean;
    isEventStreamProblem: boolean;
    changeMachineConfigurationDate: string;
};

export const kioskStore = proxy<StoreType>({
    customerLanguage: {
        id: "",
        flag: "",
        name: "fr",
        title: "",
        active: false,
        isDefault: false,
    },
    isPrm: false,
    isStandbyModalOpened: false,
    isHelpModalOpened: false,
    isInformationMessageActive: false,
    isLanguagesActive: false,
    activeLanguages: [],
    isConfigOptionsActive: false,
    activeConfigOptions: [],
    isLogoActive: false,
    isActionButtonsActive: false,
    isUserAccountActive: false,
    actionsButtonsItems: [],
    mainContentItems: [],
    saleMethodsNumber: 0,
    regulationModesNumber: 0,
    informationMessageItems: {},
    subStepInformationMessageItems: {},
    isTopBannerActive: false,
    isHeaderActive: false,
    userAccountMessageItems: {},
    activeStandbyScreenItems: [],
    timeout: 0,
    activeActionsButtons: [],
    initialStepTimeout: 0,
    isUserAccountModalOpened: false,
    selectedAuthMode: "",
    chosenTransition: { prettyName: "", timeout: 0, className: "" },
    isAnimationIn: false,
    selectedSaleModeValue: "",
    kioskWays: [],
    selectedInformationMode: "",
    isSubStepIconActive: false,
    isSubStepTopBannerActive: false,
    isSubStepLogoActive: false,
    isSubStepInformationMessageActive: false,
    isSubStepActionButtonsActive: false,
    navigationIndex: 0,
    authModalStepIndex: 0,
    // eslint-disable-next-line
    project: JSON.parse(localStorage.getItem("project")!) || {
        id: 0,
        name: "",
        template: {
            id: "",
            name: "",
            coverImage: "",
            pages: {
                elements: {},
                ways: {},
                subSteps: {},
            },
            content: {
                salesMethods: { items: [] },
                meansOfPayment: { items: [] },
                informationModes: { items: [] },
                options: { active: true, items: [] },
                actionsButtons: {
                    active: true,
                    items: [
                        {
                            id: "",
                            name: "",
                            active: true,
                            languages: {
                                fr: {
                                    content: "",
                                },
                                en: {
                                    content: "",
                                },
                            },
                            role: 1,
                        },
                    ],
                },
                languages: {
                    active: true,
                    items: [
                        {
                            id: "",
                            flag: "",
                            name: "",
                            title: "",
                            active: true,
                            isDefault: false,
                        },
                    ],
                },
                generalDesign: {
                    logo: {
                        id: "",
                        name: "",
                        content: "l",
                    },
                    prm: {
                        id: "",
                        name: "",
                        content: "l",
                        defaultContent: "",
                        type: "",
                        timeout: 0,
                    },
                    colors: [],
                    fonts: [],
                },
            },
            timeout: 15,
            initialStepTimeout: 10,
            keyboard: "azerty",
        },
        files: [
            {
                name: "",
                id: "",
                content: "",
                type: "",
                duration: 0,
                key: "",
                subName: "",
                defaultImage: "",
            },
        ],
        note: "",
        designation: "",
        hoursOfUse: [],
    },
    subStepActionButtons: [],
    isUserAuthenticated: false,
    subStepIconId: "",
    isIDontIdentifyMySelfClicked: false,
    isInvalidQrCodeModalOpened: false,
    isOrderProcessedModalWrapperOpened: false,
    isQrCodeCorrectOrderNotProcessedModalOpened: false,
    selectedRegulationModeValue: "",
    isConfirmationModalOpened: false,
    numericKeyboardInputValue: "",
    isAuthenticationErrorModalOpened: false,
    keyboardInputsValues: {},
    logo: { name: "", content: "", id: "" },
    ticketNumberMessageFooter: {},
    isTicketNumberMessageFooterActive: false,
    finalMessageFooter: {},
    isFinalMessageFooterActive: false,
    finalMessage: "",
    finalMessageSelectedInformationMode: "none",
    finalMessageSelectedRegulationMode: "At the counter",
    isChangeMachineRenderModalOpened: false,
    isRefundAnomalyModalOpened: false,
    amountDeposited: 0,
    totalOrderAfterPaidWithLoyaltyAccountBalance: 0,
    changeMachineBalance: 30,
    isRegulationModesModalOpened: false,
    isTpaValinaActive: false,
    isTpaValinaAvailable: false,
    isTpaValinaModalOpened: false,
    creditCardPaymentChoice: "",
    isBackWithoutCreditCardPaymentChoice: false,
    isTpaValinaIconActive: false,
    tpaValinaIconId: "",
    isTpaValinaInformationMessageActive: false,
    tpaValinaInformationMessageItems: {},
    tpaValinaActionButtons: [],
    isTpaValinaActionButtonsActive: false,
    isAuthenticationAccepted: false,
    isOrderPaidWithLoyaltyAccountBalance: false,
    isOrderPartialPaidWithLoyaltyAccountBalance: false,
    isLocalSettingModalOpened: false,
    isAuthenticationModalOpened: false,
    isLocalConfigModalOpened: false,
    isErrorLogInModalWrapperOpened: false,
    activeTabTitle: "",
    localProject: {
        id: 0,
        name: "",
        template: {
            id: "",
            name: "",
            coverImage: "",
            pages: {
                elements: {},
                ways: {},
                subSteps: {},
            },
            content: {
                salesMethods: { items: [] },
                meansOfPayment: { items: [] },
                informationModes: { items: [] },
                options: { active: true, items: [] },
                actionsButtons: {
                    active: true,
                    items: [
                        {
                            id: "",
                            name: "",
                            active: true,
                            languages: {
                                fr: {
                                    content: "",
                                },
                                en: {
                                    content: "",
                                },
                            },
                            role: 1,
                        },
                    ],
                },
                languages: {
                    active: true,
                    items: [
                        {
                            id: "",
                            flag: "",
                            name: "",
                            title: "",
                            active: true,
                            isDefault: false,
                        },
                    ],
                },
                generalDesign: {
                    logo: {
                        id: "",
                        name: "",
                        content: "l",
                    },
                    prm: {
                        id: "",
                        name: "",
                        content: "l",
                        defaultContent: "",
                        type: "",
                        timeout: 0,
                    },
                    colors: [],
                    fonts: [],
                },
            },
            timeout: 15,
            initialStepTimeout: 10,
            keyboard: "azerty",
        },
        files: [
            {
                name: "",
                id: "",
                content: "",
                type: "",
                duration: 0,
                key: "",
                subName: "",
                defaultImage: "",
            },
        ],
        note: "",
        designation: "",
        hoursOfUse: [],
    },
    isMenuModalOpened: false,
    isAppPaused: false,
    activeStateScreenItems: [],
    standbyStateScreenItems: [],
    standbyScreenItems: [],
    isConfirmationStandbyStateModalOpened: false,
    isUsersListModalOpened: false,
    isScanQrCodeModalOpened: false,
    leftToPay: 0,
    renderAmount: 0,
    orderNumber: "",
    isAbortTransactionModalOpened: false,
    regulationModesModalKey: "",
    leftToPayAfterCashMachineValidation: 0,
    qrCodeValue: "",
    selectedSaleModeId: "",
    user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        loyaltyAccount: 0,
        loyaltyAmount: 0,
    },
    pseudoNameValue: "",
    chosenInformationMode: { type: "", data: { infoModeUuId: "", value: "" } },
    paymentModes: [],
    cashUuID: "",
    creditCardUuId: "",
    loyaltyUuId: "",
    isCreatingVoucherAccepted: false,
    isRestartModalOpened: false,
    isShutdownModalOpened: false,
    isCloseAppModalOpened: false,
    isSelectedSaleModeWithoutInformationModes: false,
    isBackButtonDisabled: false,
    isAnotherPaymentModeButtonDisabled: true,
    isLogout: true,
    isCashMachineConfigured: true,
    isFenceProblemDetected: false,
    technicalProblemKey: "",
    shopOpeningHour: [],
    totalAmountDeposited: 0,
    isTransactionCanceled: false,
    isWithdrawalProblemModalOpened: false,
    isPaymentRefused: false,
    voucherAmount: 0,
    isCreationVoucherCanceled: false,
    pagesItems: {
        elements: {},
        ways: {},
        subSteps: {},
    },
    isCashTransactionValidated: false,
    selectedSaleModeOrderTaking: "",
    isAnotherPaymentModeButtonCBDisabled: true,
    isPaymentTechnicalProblemModalOpened: false,
    isPaymentTechnicalProblemDetected: false,
    isCommandInsertionProblemModalOpened: false,
    finalMessageCounter: 0,
    isAmountReturned: false,
    orderItemsLength: 0,
    isInformationModesModalOpened: false,
    isInformationModesConsommationModalOpened: false,
    saleMode: {},
    orderModificationMode: false,
    chosenLocalInformationMode: {
        type: "",
        data: { infoModeUuId: "", value: "" },
    },
    localSelectedInformationMode: "",
    isOrderTakingPreviousStep: false,
    isOrderInsertedSuccessfully: false,
    returnedAmount: 0,
    isOrderInsertionRefused: false,
    isLogoutProblemModalOpened: false,
    isChangeMachineReady: false,
    isAcceptAbortCBTransaction: false,
    isCashMachineTransactionCanceled: false,
    isOrderLoading: false,
    isWorkflowLoading: false,
    isAnimationTimeOut: false,
    isRegulationModesModalBackButtonClicked: false,
    isEventStreamProblem: false,
    changeMachineConfigurationDate: "",
});

subscribe(kioskStore, () => {
    localStorage.setItem("project", JSON.stringify(kioskStore.project));
});

export {
    setCustomerLanguage,
    setIsPrm,
    setIsStandbyModalOpened,
    setIsHelpModalOpened,
    setSaleMethodsNumber,
    setRegulationModesNumber,
    setIsTopBannerActive,
    setIsInformationMessageActive,
    setIsUserAccountActive,
    setIsLanguagesActive,
    setActiveLanguages,
    setIsConfigOptionsActive,
    setActiveConfigOptions,
    setIsLogoActive,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setActionsButtonsItems,
    setMainContentItems,
    setInformationMessageItems,
    setUserAccountMessageItems,
    setActiveStandbyScreenItems,
    setProjectTimeout,
    setActiveActionsButtons,
    setInitialStepTimeout,
    setIsUserAccountModalOpened,
    setSelectedAuthMode,
    setChosenTransition,
    setIsAnimationIn,
    setSelectedSaleModeValue,
    setKioskWays,
    setSelectedInformationMode,
    setIsSubStepIconActive,
    setIsSubStepTopBannerActive,
    setIsSubStepLogoActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepActionButtonsActive,
    setNavigationIndex,
    setAuthModalStepIndex,
    setProject,
    setSubStepActionButtons,
    setIsUserAuthenticated,
    setSubStepInformationMessageItems,
    setSubStepIconId,
    setIsInvalidQrCodeModalOpened,
    setIsOrderProcessedModalWrapperOpened,
    setIsQrCodeCorrectOrderNotProcessedModalOpened,
    setSelectedRegulationModeValue,
    setIsConfirmationModalOpened,
    setNumericKeyboardInputValue,
    setIsAuthenticationErrorModalOpened,
    setKeyboardInputsValues,
    setLogo,
    setIsTicketNumberMessageFooterActive,
    setTicketNumberMessageFooter,
    setFinalMessageFooter,
    setIsFinalMessageFooterActive,
    setFinalMessage,
    setFinalMessageSelectedRegulationMode,
    setFinalMessageSelectedInformationMode,
    setTotalOrderAfterPaidWithLoyaltyAccountBalance,
    setIsChangeMachineRenderModalOpened,
    setIsRefundAnomalyModalOpened,
    setAmountDeposited,
    setChangeMachineBalance,
    initStoreVariablesInStandbyScreenStep,
    setIsRegulationModesModalOpened,
    setIsTpaValinaActive,
    setIsTpaValinaModalOpened,
    setCreditCardPaymentChoice,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsAuthenticationAccepted,
    setIsOrderPaidWithLoyaltyAccountBalance,
    setIsTpaValinaActionButtonsActive,
    setIsTpaValinaIconActive,
    setIsTpaValinaInformationMessageActive,
    setTpaValinaActionButtons,
    setTpaValinaIconId,
    setTpaValinaInformationMessageItems,
    setIsLocalSettingModalOpened,
    setIsAuthenticationModalOpened,
    setIsLocalConfigModalOpened,
    setIsErrorLogInModalWrapperOpened,
    setActiveTabTitle,
    setLocalProjectItems,
    setLocalProject,
    setIsMenuModalOpened,
    setIsAppPaused,
    setConfigInformationModes,
    setActiveStateScreenItems,
    setStandbyStateScreenItems,
    setStandbyScreenItems,
    setIsConfirmationStandbyStateModalOpened,
    setIsUsersListModalOpened,
    setIsScanQrCodeModalOpened,
    setLeftToPay,
    setRenderAmount,
    setOrderNumber,
    setIsAbortTransactionModalOpened,
    setRegulationModesModalKey,
    setLeftToPayAfterCashMachineValidation,
    setQrCodeValue,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setUser,
    setIsOrderPartialPaidWithLoyaltyAccountBalance,
    setPseudoNameValue,
    setChosenInformationMode,
    setPaymentModes,
    setCashUuID,
    setCreditCardUuId,
    setLoyaltyUuId,
    setIsCreatingVoucherAccepted,
    setIsRestartModalOpened,
    setIsShutdownModalOpened,
    setIsCloseAppModalOpened,
    setIsSelectedSaleModeWithoutInformationModes,
    setIsBackButtonDisabled,
    setIsAnotherPaymentModeButtonDisabled,
    setIsLogout,
    setIsCashMachineConfigured,
    setIsFenceProblemDetected,
    setTechnicalProblemKey,
    setShopOpeningHour,
    setTotalAmountDeposited,
    setIsTransactionCanceled,
    setIsWithdrawalProblemModalOpened,
    setIsPaymentRefused,
    setVoucherAmount,
    setIsCreationVoucherCanceled,
    setPagesItems,
    setIsCashTransactionValidated,
    setIsAnotherPaymentModeButtonCBDisabled,
    setIsPaymentTechnicalProblemModalOpened,
    setIsPaymentTechnicalProblemDetected,
    setIsCommandInsertionProblemModalOpened,
    setFinalMessageCounter,
    setIsAmountReturned,
    setOrderItemsLength,
    setIsInformationModesModalOpened,
    setIsInformationModesConsommationModalOpened,
    setSaleMode,
    setOrderModificationMode,
    setChosenLocalInformationMode,
    setLocalSelectedInformationMode,
    setIsOrderTakingPreviousStep,
    setIsOrderInsertedSuccessfully,
    setReturnedAmount,
    setIsOrderInsertionRefused,
    setIsLogoutProblemModalOpened,
    setIsChangeMachineReady,
    setIsAcceptAbortCBTransaction,
    setIsCashMachineTransactionCanceled,
    setIsOrderLoading,
    setIsWorkflowLoading,
    setIsAnimationTimeOut,
    setIsRegulationModesModalBackButtonClicked,
    setIsEventStreamProblem,
    setChangeMachineConfigurationDate,
} from "./actions";
