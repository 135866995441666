import { Box, Card } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import { isLoadingProduct } from "@components/OrderTaking/Helpers";

import {
    memoizedActiveCategory,
    setActiveCategory,
    setActiveCategoryParent,
} from "../../Store/feature";
import "./categories.css";

export default function CategoryCard({ category }) {
    const { middlewareApiUrl } = useSnapshot(store);

    const dispatchStore = useDispatch();
    const { isOrderTakingCategoryTitleActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );

    const activeCategory = useSelector((state) =>
        memoizedActiveCategory(state)
    );
    const isActive = category.iuud === activeCategory;

    const categoryImg = `${middlewareApiUrl}/static/${category.image
        .split("/")
        .pop()}`;

    const handleCategoryClick = () => {
        dispatchStore(setActiveCategoryParent(""));

        dispatchStore(setActiveCategory(category.iuud));
        isLoadingProduct();
    };

    return (
        <Box
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ gap: "10px" }}
        >
            <Card
                className={classNames({
                    "category-card__clz": isActive === false,
                    "active-category-card__clz": isActive === true,
                })}
                onClick={handleCategoryClick}
            >
                <Box
                    className="d-flex justify-content-center align-items-center h-100"
                    style={{ position: "relative" }}
                >
                    <img
                        loading="lazy"
                        alt={category.title}
                        src={categoryImg !== "" ? categoryImg : category.image}
                        style={{
                            width: "124px auto",
                            height: "125px",
                        }}
                    />
                </Box>
            </Card>
            {isOrderTakingCategoryTitleActive ? (
                <Box
                    className={classNames({
                        "category-title__clz": isActive === false,
                        "active-category-title__clz": isActive === true,
                    })}
                >
                    {category.title}
                </Box>
            ) : null}
        </Box>
    );
}
export const CategoryCardMemoised = React.memo(CategoryCard);
CategoryCard.propTypes = {
    stateOrder: PropTypes.object,
    category: PropTypes.object,
    settings: PropTypes.object,
    activateCategory: PropTypes.func,
};
