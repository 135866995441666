import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { getEntityData } from "@helpers/general";

import { useResource } from "@hooks/useResource";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { ClickableSvgIcon } from "@components/SvgIcons";
import { Table } from "@components/Synchronization/EntitySelectionModalWrapper/EntitySelection/Table";
import {
    setFranchiseSchema,
    setIsEntityChildrenSelectionModalOpened,
    setIsEntitySelectionModalOpened,
    setSelectedEntity,
    setSelectedEntityChild,
    setSelectedEntityChildren,
    syncStore,
} from "@components/Synchronization/store";

import "./index.css";

export function EntitySelection(): JSX.Element {
    const { selectedEntity, selectedEntityChild, connectedUserEntity } =
        useSnapshot(syncStore);

    const { resourceData: entity } = useResource(
        `${process.env.REACT_APP_CONSOLE_APP_API}/entity/${connectedUserEntity}`
    );

    const [data, setData] = React.useState<EntityType[]>([]);

    React.useEffect(() => {
        const entities = getEntityData(entity.data);

        setData(entities);

        if (entity.data.length === 1) {
            setSelectedEntity(entities[0]);
            setFranchiseSchema(entities[0].franchise);
            setSelectedEntityChildren(entities[0].children as EntityType[]);
        }
    }, [entity.data]);

    function handleActionButtonOnClick(selectedRow: EntityType) {
        setData(selectedRow.children !== undefined ? selectedRow.children : []);

        if (selectedRow.children?.length === 0) {
            setSelectedEntityChild(selectedRow);
            setIsEntitySelectionModalOpened(false);
            setIsEntityChildrenSelectionModalOpened(true);
        }
    }

    const columns: TableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("CRM ID"),
                accessor: ({ crm_id }: { crm_id: string }) =>
                    crm_id !== "" ? crm_id : "-",
            },
            {
                Header: t("Name"),
                accessor: ({ name }: { name: string }) =>
                    name !== "" ? (
                        <span className="text-capitalize">{name}</span>
                    ) : (
                        "-"
                    ),
            },
            {
                Header: t("Nature"),
                accessor: ({ nature }: { nature: string }) =>
                    nature !== "" ? (
                        <span className="text-capitalize">{nature}</span>
                    ) : (
                        "-"
                    ),
            },
            {
                Header: t("Address"),
                accessor: ({ address }: { address: string }) =>
                    address !== "" ? (
                        <span className="text-capitalize">{address}</span>
                    ) : (
                        "-"
                    ),
            },
            {
                Header: t("Action"),
                disableGlobalFilter: true,
                accessor: (row: EntityType) => (
                    <StyledButton
                        rounded={true}
                        variant="primary"
                        style={{
                            boxShadow: "0px 5px 10px #00000042",
                        }}
                        onClick={() => handleActionButtonOnClick(row)}
                    >
                        {t("Select")}
                    </StyledButton>
                ),
            },
        ],
        [t]
    );

    function selectEntity(selectedEntity: EntityType): void {
        if (selectedEntityChild !== undefined) {
            setSelectedEntityChild(undefined);
            setData(selectedEntity.children as EntityType[]);
        }
    }

    return (
        <CustomErrorBoundary>
            <FlexboxGrid className="p-4 flex-column" gap="10px">
                <FlexboxGrid className="flex-column" gap="20px">
                    <span
                        className="text-uppercase"
                        style={{
                            font: "normal normal bold 42px/46px Bebas Neue",
                        }}
                    >
                        {selectedEntity?.uid !== undefined ? (
                            <React.Fragment>
                                <span
                                    className="selected-entity__clz"
                                    onClick={() => selectEntity(selectedEntity)}
                                >
                                    <ClickableSvgIcon
                                        className="mr-1"
                                        style={{
                                            display:
                                                selectedEntityChild?.uid !==
                                                undefined
                                                    ? "unset"
                                                    : "none",
                                        }}
                                    />
                                    {selectedEntity.name}
                                </span>
                            </React.Fragment>
                        ) : (
                            t("Please select an entity")
                        )}
                        {selectedEntityChild?.uid !== undefined
                            ? ` / ${selectedEntityChild?.name}`
                            : null}
                    </span>
                </FlexboxGrid>
                <Table columns={columns} data={data} />
            </FlexboxGrid>
        </CustomErrorBoundary>
    );
}
