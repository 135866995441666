import { useDispatch, useSelector } from "react-redux";

import {
    SelectProduct,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

function addIndexStep(workflowStep, workflowData, dispatch) {
    const step = workflowStep.index + 1;
    let copyworkflowData = JSON.parse(JSON.stringify(workflowData));

    copyworkflowData[copyworkflowData.length - 1].workflow[
        workflowStep.index
    ].NbrOfChoices += 1;

    copyworkflowData[copyworkflowData.length - 1].index = step;

    dispatch(setWorkflowData(copyworkflowData));
}

function useNextStep(workflow) {
    const dispatch = useDispatch();
    const { workflowData } = useSelector((state) => state.workFlowDataSlice);

    return () => {
        if (workflow.step > workflow.index) {
            addIndexStep(workflow, workflowData, dispatch);
        } else {
            const copyworkflowData = JSON.parse(JSON.stringify(workflowData));

            copyworkflowData.splice(copyworkflowData.length - 1, 1);
            const copyDataworkflow = [...copyworkflowData];
            let index = copyDataworkflow.length;
            while (index > 0) {
                let workflowIndex = index - 1;
                let workflow = copyDataworkflow[workflowIndex];
                if (
                    workflow.workflow[workflow.index].NbrOfChoices <=
                    workflow.workflow[workflow.index].maxNbrOfChoices
                ) {
                    dispatch(setWorkflowData(copyworkflowData));

                    return;
                } else if (workflow.step > workflow.index) {
                    addIndexStep(workflow, workflowData, dispatch);
                    return;
                } else {
                    index -= 1;
                    copyDataworkflow.splice(workflowIndex, 1);
                }
            }
            // ** ici on efface workflow
            if (copyDataworkflow.length === 0) {
                dispatch(setWorkflowData([]));
                dispatch(SelectProduct({}));
            }
        }
    };
}

export default useNextStep;
