// eslint-disable-next-line
export async function fetcher(url: string): Promise<any> {
    try {
        const response = await fetch(url);

        const text = await response.text();

        const data = text ? JSON.parse(text) : null;

        if (response.ok) {
            return data;
        } else {
            throw new Error("Error while fetching data");
        }
        // eslint-disable-next-line
    } catch (error: any) {
        if (!error.data) {
            error.data = { message: error.message };
        }
        throw error;
    }
}
