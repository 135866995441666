import { io } from "socket.io-client";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { useSteps } from "@hooks/useSteps";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { setCarte, setSetting } from "@components/OrderTaking/Store/feature";

import { StepComponent } from "@pages/Kiosk/Steps/StepComponent";

import "./transitions.css";

export function Steps(): JSX.Element {
    const { middlewareApiUrl } = useSnapshot(store);
    console.log(
        "🚀 ~ file: index.tsx:19 ~ Steps ~ middlewareApiUrl:",
        middlewareApiUrl.split("/").slice(0, 3).join("")
    );

    useSteps();
    // eslint-disable-next-line
    // const shopDetail: any = JSON.parse(localStorage.getItem("shopDetail")!);

    // React.useEffect(() => {
    //     // eslint-disable-next-line
    //     (window as any).electronAPI!.checkKioskModeEvent();
    // });

    // React.useEffect(() => {
    //     const socket = io("http://localhost:5001");
    //     // const socket = io(process.env.REACT_APP_SOCKET as string);

    //     const username = `franchise_demo_51|2`;
    //     // const username = `${shopDetail?.schema}|${shopDetail?.id}`;
    //     const uuidCart: ModificationUuidType = JSON.parse(
    //         localStorage.getItem("uidModification") as string
    //     );

    //     socket.emit("checkModification", {
    //         username,
    //         message: uuidCart?.uuid,
    //     });

    //     socket.on("New_cart", ({ message }: { message: string }) => {
    //         console.log(
    //             "🚀 ~ file: index.tsx:103 ~ socket.on ~ New_cart:",
    //             message
    //         );

    //         // const uuidCart: ModificationUuidType = JSON.parse(
    //         //     localStorage.getItem("uidModification") as string
    //         // );

    //         if (uuidCart?.uuid !== message) {
    //             localStorage.setItem(
    //                 "uidModification",
    //                 JSON.stringify({
    //                     ...uuidCart,
    //                     uuid: message,
    //                 })
    //             );

    //             console.log(
    //                 "🚀 ~ file: index.tsx:61 ~ socket.on ~ fetch card:"
    //             );
    //             // fetch(
    //             //     `https://demo-catalogue-api.aureskonnect.com/api_etk_article_bd/v1/kiosk/franchise_demo_49/3/card`
    //             //     // `${middlewareApiUrl}/kiosk/${shopDetail?.schema}/${shopDetail?.id}/card`
    //             // )
    //             //     .then((response) => response.json())
    //             //     .then((data) => {
    //             //         if (data.error === true) {
    //             //             throw Error(
    //             //                 "There's an issue while getting catalog"
    //             //             );
    //             //         }

    //             //         try {
    //             //             localStorage.setItem("card", JSON.stringify(data));
    //             //         } catch (error) {
    //             //             console.log("can't save catalogue", error);
    //             //         }

    //             //         // dispatch(setCarte(data));

    //             //         // // eslint-disable-next-line
    //             //         // (window as any).electronAPI!.getCatalogEvent({
    //             //         //     data: JSON.stringify(data),
    //             //         // });
    //             //     })
    //             //     .catch((error) =>
    //             //         console.log(
    //             //             `Error while getting updating catalog, with message: ${error.message}`
    //             //         )
    //             //     );
    //         }
    //     });

    //     // socket.on("outofstock", ({ message }: { message: string }) => {
    //     //     if (uuidCart?.uuid !== message) {
    //     //         dispatch(getCard());
    //     //     }
    //     // });

    //     // socket.on(
    //     //     "New_setting",
    //     //     ({ message }: { message: Record<string, string> }) => {
    //     //         const modificationUuid: ModificationUuidType = JSON.parse(
    //     //             localStorage.getItem("uidModification") as string
    //     //         );

    //     //         if (modificationUuid?.settingId !== message.uid) {
    //     //             localStorage.setItem(
    //     //                 "uidModification",
    //     //                 JSON.stringify({
    //     //                     ...modificationUuid,
    //     //                     settingId: message.uid,
    //     //                 })
    //     //             );
    //     //             fetch(
    //     //                 `${middlewareApiUrl}/settings/consomation?franchise_uuid=${shopDetail?.franchise_uid}&shop_uuid=${shopDetail?.shop_uid}&nameKiosk=${message.nameKiosk}`
    //     //             )
    //     //                 .then((response) => response.json())
    //     //                 .then(({ kiosk }) => {
    //     //                     const modifiedProject = kiosk.filter(
    //     //                         (kiosk: Record<string, string>) => {
    //     //                             return kiosk.name === message.nameKiosk;
    //     //                         }
    //     //                     );
    //     //                     if (
    //     //                         Array.isArray(modifiedProject) &&
    //     //                         modifiedProject.length > 0
    //     //                     ) {
    //     //                         setProject(modifiedProject[0].project);
    //     //                         useUpdateProjectSteps(
    //     //                             modifiedProject[0].project
    //     //                         );
    //     //                         const customLang = (
    //     //                             modifiedProject[0].project.template.content
    //     //                                 .languages as LanguagesType
    //     //                         ).items.filter(
    //     //                             (language) => language.isDefault === true
    //     //                         )[0];

    //     //                         dispatch(
    //     //                             setSetting(
    //     //                                 setOrderTakingSetting(
    //     //                                     modifiedProject[0].project.template
    //     //                                         .pages.ways
    //     //                                         .orderTaking as PageType,
    //     //                                     customLang.name,
    //     //                                     (
    //     //                                         modifiedProject[0].project
    //     //                                             .template.content
    //     //                                             .generalDesign as GeneralDesignType
    //     //                                     ).colors[0].content,
    //     //                                     (
    //     //                                         modifiedProject[0].project
    //     //                                             .template.content
    //     //                                             .generalDesign as GeneralDesignType
    //     //                                     ).colors[1].content
    //     //                                 )
    //     //                             )
    //     //                         );

    //     //                         // eslint-disable-next-line
    //     //                         (window as any).electronAPI!.getProjectEvent({
    //     //                             data: JSON.stringify(
    //     //                                 modifiedProject[0].project,
    //     //                                 null,
    //     //                                 4
    //     //                             ),
    //     //                         });
    //     //                     }
    //     //                 })
    //     //                 .catch((error) =>
    //     //                     console.log(
    //     //                         `Error while updating project from setting, with message: ${error.message}`
    //     //                     )
    //     //                 );
    //     //         }
    //     //     }
    //     // );

    //     socket.on("disconnect", function () {
    //         socket.disconnect();
    //     });

    //     return () => {
    //         socket.disconnect();
    //         socket.off("New_cart");
    //         socket.off("outofstock");
    //         socket.off("New_setting");
    //     };
    // }, []);

    // React.useEffect(() => {
    //     getEndServicesHours();
    // }, []);

    return (
        <CustomErrorBoundary>
            <StepComponent />
        </CustomErrorBoundary>
    );
}
