import { BASIC_COMPOSITION_TYPE } from "@constants";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { refactorWorkflowData } from "@components/OrderTaking/Helpers";
import { setWorkflowData } from "@components/OrderTaking/Store/feature";

export function ShowDetailsCartProduct({
    item,
    setPath,
    setTitle,
    onClose,
    setIsBackActive,
}) {
    const dispatchStore = useDispatch();

    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");

    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    if (
        item?.compositions?.[BASIC_COMPOSITION_TYPE] &&
        !item.basicComposition
    ) {
        Object.keys(
            item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions
        ).forEach((key) => {
            let quantity =
                item?.compositions[BASIC_COMPOSITION_TYPE].compositions[key]
                    .quantity;
            let name = item.name;
            item.basicComposition = item.basicComposition
                ? item.basicComposition + "," + quantity + "x " + name
                : quantity + "x " + name;
        });
    }
    if (item?.compositions && !item.compositionsItems) {
        Object.keys(item?.compositions).forEach((key) => {
            if (key !== BASIC_COMPOSITION_TYPE) {
                let quantity = item?.compositions[key].quantity;
                let name = item.name;
                item.compositionsItems = item.compositionsItems
                    ? item.compositionsItems + "," + quantity + "x " + name
                    : quantity + "x " + name;
            }
        });
    }

    function handleModifyProductOnCLickEvent() {
        const workflowModify = refactorWorkflowData(
            item.workflow,
            orderWorkflowItemSlice.item.shopCart
        );
        dispatchStore(setWorkflowData([...workflowModify]));
        onClose();
    }
    const handleShowDetailsClick = () => {
        if (item?.path?.length > 0) {
            setPath(item.path);
            setTitle(item.name);
            setIsBackActive(true);
        }
    };

    return (
        <>
            <div
                className="d-flex flex-column justify-content-start align-items-center py-2"
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #0000001A",
                    border: "1px solid #E5E5E5",
                    borderRadius: "12px",
                    height: "219px",
                    width:
                        item.compositions !== undefined || item.price > 0
                            ? "334px"
                            : "195px",
                }}
            >
                <div
                    className="d-flex align-items-center px-2"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        className="d-flex align-items-center flex-column"
                        style={{ width: "100%", gap: "5px" }}
                    >
                        <div
                            className="d-flex align-items-start"
                            style={{
                                width: "100%",
                                justifyContent:
                                    item.price > 0 ? "space-between" : "center",
                            }}
                        >
                            <div
                                className="cart-image-product__clz"
                                style={{
                                    backgroundImage: `url(${item.urlImage})`,
                                }}
                            ></div>
                            {item.price > 0 && (
                                <div className="price-product-preview">
                                    {item.price.toFixed(2) + " €"}
                                </div>
                            )}
                        </div>
                        <div
                            className="title-product-details two-lines-preview-text"
                            style={{
                                font: "normal normal bold 16px/21px Segoe UI",
                                textAlign: "center",
                            }}
                        >
                            {item.quantity > 1 ? (
                                <span
                                    style={{
                                        color: globalColor,
                                    }}
                                >
                                    {item.quantity > 0
                                        ? item.quantity + "X "
                                        : ""}
                                </span>
                            ) : null}
                            <span>{item.name}</span>
                        </div>
                        {item.type !== BASIC_COMPOSITION_TYPE &&
                            (item?.basicComposition && (
                                <span className="product-details__clz preview-text">
                                    {item?.basicComposition}
                                </span>
                            ),
                            item?.compositionsItems && (
                                <span className="product-details__clz preview-text">
                                    {item.compositionsItems}
                                </span>
                            ))}
                    </div>
                </div>
                <div className=" d-flex justify-content-center align-items-center">
                    {item.compositions !== undefined ? (
                        <button
                            className=" d-flex justify-content-center align-items-center see-details "
                            style={{
                                backgroundColor: globalColor,
                            }}
                            onClick={handleShowDetailsClick}
                        >
                            Voir détails
                        </button>
                    ) : (
                        <img
                            src="./images_order/editButton.png"
                            onClick={() => {
                                handleModifyProductOnCLickEvent(item);
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
ShowDetailsCartProduct.propTypes = {
    item: PropTypes.object,
    onClose: PropTypes.func,
    setPath: PropTypes.func,
    setTitle: PropTypes.func,
    setIsBackActive: PropTypes.func,
};
