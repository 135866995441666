import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import WorkflowAlert from "@components/OrderTaking/common/WorkflowAlert";
import OrderSummary from "@components/OrderTaking/Components/Dialogs/OrderSummary";

import { setNavigationIndex, kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

export default function BasketActionsButtons({
    openDialogCart,
    setOpenDialogCart,
}) {
    const [isSummaryOrderOpened, setIsSummaryOrderOpened] =
        React.useState(false);

    const {
        project: { files: images },
        customerLanguage,
        isOrderTakingPreviousStep,
    } = useSnapshot(store);
    const [isAlertOpened, setIsAlertOpened] = React.useState(false);
    const message = "Would you like to abandon your order ?";

    function handleOrderSummaryOnCLickEvent() {
        setIsSummaryOrderOpened(!isSummaryOrderOpened);
    }

    const { orderItems, totalPrice } = useSelector(
        (state) => state.orderSlice.order
    );

    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.basketSettings
    );

    if (openDialogCart && orderItems.length === 0) {
        setOpenDialogCart(false);
    }

    function handleBasketActionsButtonsOnClickEvent(button) {
        if (button.name.toLowerCase() === "pay") {
            handleOrderSummaryOnCLickEvent();
        } else {
            handleAlertOnCLickEvent();
        }
    }
    function handleAlertOnCLickEvent() {
        orderItems.length > 0
            ? setIsAlertOpened(!isAlertOpened)
            : setNavigationIndex(0);
    }
    function onCloseWorkflowOnClickEvent() {
        setNavigationIndex(0);
        setIsAlertOpened(!isAlertOpened);
    }

    React.useEffect(() => {
        if (isOrderTakingPreviousStep) {
            setIsSummaryOrderOpened(true);
        }
    }, [isOrderTakingPreviousStep]);

    return (
        <React.Fragment>
            {isSummaryOrderOpened && (
                <OrderSummary
                    open={isSummaryOrderOpened}
                    onClose={handleOrderSummaryOnCLickEvent}
                    setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                />
            )}
            {isAlertOpened && (
                <WorkflowAlert
                    open={isAlertOpened}
                    onClose={handleAlertOnCLickEvent}
                    onCloseWorkflowOnClickEvent={onCloseWorkflowOnClickEvent}
                    message={message}
                />
            )}
            <div className="footer-button-popup-card px-3">
                {actionsButtons
                    .filter((item) => {
                        return item.active === true;
                    })
                    .sort((a, b) => {
                        return a.role > b.role ? -1 : 1;
                    })
                    .map((button, index) => {
                        const imageContent = getImageContentById(
                            button.id,
                            images,
                            customerLanguage.name
                        );

                        const imageSrc =
                            imageContent?.includes("http") ||
                            imageContent?.includes("https")
                                ? imageContent
                                : `./images_order/${imageContent}`;

                        return button.name.toLowerCase() === "pay" ? (
                            <div
                                className="total-order-footer"
                                key={index}
                                onClick={() => {
                                    handleBasketActionsButtonsOnClickEvent(
                                        button
                                    );
                                }}
                            >
                                <div
                                    className="basket-cart-buttons"
                                    style={{
                                        backgroundSize: "100% 100%",
                                        backgroundImage: `url(${imageSrc})`,
                                        opacity:
                                            button.name.toLowerCase() ===
                                                "pay" &&
                                            orderItems.length === 0 &&
                                            "0.5",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />

                                <div className="total-order px-3">
                                    {totalPrice.toFixed(2)} €
                                </div>
                            </div>
                        ) : (
                            <div
                                className="basket-cart-buttons"
                                style={{ backgroundImage: `url(${imageSrc})` }}
                                key={index}
                                onClick={() => {
                                    handleBasketActionsButtonsOnClickEvent(
                                        button
                                    );
                                }}
                            />
                        );
                    })}
            </div>
        </React.Fragment>
    );
}

BasketActionsButtons.propTypes = {
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
};
