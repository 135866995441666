import React from "react";
import Modal from "react-modal";
import { useSnapshot } from "valtio";

import { AuthenticationModalWrapper } from "@components/Synchronization/AuthenticationModalWrapper";
import { EntityChildrenSelectionModalWrapper } from "@components/Synchronization/EntityChildrenSelectionModalWrapper";
import { EntitySelectionModalWrapper } from "@components/Synchronization/EntitySelectionModalWrapper";
import { ModalMainContentWrapper } from "@components/Synchronization/ModalMainContentWrapper";
import { syncStore } from "@components/Synchronization/store";
import { VideoBackgroundHeaderWrapper } from "@components/common/VideoBackgroundHeaderWrapper";

import "./index.css";

export function Synchronization(): JSX.Element {
    const {
        isEntitySelectionModalOpened,
        isSyncModalOpened,
        isEntityChildrenSelectionModalOpened,
        isSyncAuthenticationModalOpened,
    } = useSnapshot(syncStore);

    return (
        <Modal
            isOpen={isSyncModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "960px",
                    width: "914px",
                    fontSize: "50px",
                    zIndex: 2,
                    boxShadow: "0px 3px 6px #00000029",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #FFFFFF",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <VideoBackgroundHeaderWrapper />
            {isSyncAuthenticationModalOpened !== true ? (
                isEntitySelectionModalOpened === true ? (
                    <EntitySelectionModalWrapper />
                ) : isEntityChildrenSelectionModalOpened === true ? (
                    <EntityChildrenSelectionModalWrapper />
                ) : (
                    <ModalMainContentWrapper />
                )
            ) : (
                <AuthenticationModalWrapper />
            )}
        </Modal>
    );
}
