import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore as store } from "@pages/Kiosk/store";

export default function SummaryHeader() {
    const { summaryHeaderBravoText, summaryHeaderLoyaltyPointText } =
        useSelector((state) => state.settingSlice.summary, shallowEqual);
    const { fidelityTotal } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { isUserAuthenticated } = useSnapshot(store);
    if (!isUserAuthenticated) return null;
    return (
        <div className="header-text pl-4">
            <span
                style={{
                    font: "normal normal bold 40px/54px Nunito",
                }}
            >
                {summaryHeaderBravoText}
            </span>
            <br />
            <span
                style={{
                    font: "normal normal 400 30px/40px Segoe UI",
                }}
            >
                {summaryHeaderLoyaltyPointText} {fidelityTotal} points
            </span>
        </div>
    );
}
SummaryHeader.propTypes = {
    summaryHeaderBravoText: PropTypes.string,
    summaryHeaderLoyaltyPointText: PropTypes.string,
};
