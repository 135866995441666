import { useSnapshot } from "valtio";

import React from "react";
import { CSSTransition } from "react-transition-group";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { MainContentWrapper } from "@components/common/UsualModeMainContentWrapper";
import { ContainerWrapper } from "@components/common/UsualModeMainContentWrapper/ContainerWrapper";
import { IdleDetection } from "@components/IdleDetection";
import { StandbyModalWrapper } from "@components/StandbyModalWrapper";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import {
    kioskStore,
    setIsAnimationIn,
    setIsLogoutProblemModalOpened,
    setIsStandbyModalOpened,
    setSelectedInformationMode,
} from "@pages/Kiosk/store";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

export function UsualMode(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isUserAccountActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        userAccountMessageItems,
        timeout,
        customerLanguage,
        isHelpModalOpened,
        isStandbyModalOpened,
        isUserAccountModalOpened,
        actionsButtonsItems,
        isUserAuthenticated,
        isAnimationIn,
        chosenTransition,
        navigationIndex,
        user,
        isLogoutProblemModalOpened,
    } = useSnapshot(kioskStore);

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    React.useEffect(() => {
        setIsStandbyModalOpened(false);
        setIsLogoutProblemModalOpened(false);
        setIsAnimationIn(true);
        setSelectedInformationMode("");
    }, []);
    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <PreviewBackgroundWrapper>
                <ContainerWrapper
                    isTopBannerActive={isTopBannerActive}
                    isHeaderActive={isHeaderActive}
                    isLanguagesActive={isLanguagesActive}
                    isConfigOptionsActive={isConfigOptionsActive}
                    isActionButtonsActive={isActionButtonsActive}
                    ref={(ref) => setContainerRef(ref)}
                >
                    <IdleDetection
                        timeout={timeout * 1000}
                        once={true}
                        onIdle={() => {
                            if (navigationIndex !== 0) {
                                setIsStandbyModalOpened(true);
                            }
                        }}
                    />

                    {isHelpModalOpened === true ? <HelpModalWrapper /> : null}

                    {isStandbyModalOpened === true ? (
                        <StandbyModalWrapper />
                    ) : null}

                    {containerRef ? (
                        isUserAccountModalOpened === true ? (
                            <UserAccountModalWrapper
                                containerRef={containerRef}
                            />
                        ) : null
                    ) : null}

                    {isLogoutProblemModalOpened === true ? (
                        <LogoutProblemModal />
                    ) : null}

                    <LogoWrapper />

                    {isHeaderActive ? (
                        <div
                            style={{
                                display: "grid",
                            }}
                            className="justify-content-center align-items-center"
                        >
                            {isUserAccountActive ? (
                                isUserAuthenticated ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ gap: "20px" }}
                                    >
                                        <img
                                            src="./images/man-head-header.png"
                                            alt="header"
                                            style={{
                                                height: "35px",
                                                width: "35px",
                                            }}
                                        />
                                        <span
                                            className="mb-0"
                                            style={{ fontSize: "35px" }}
                                        >
                                            {
                                                userAccountMessageItems[
                                                    customerLanguage.name
                                                ].content
                                            }{" "}
                                            {user.firstName === ""
                                                ? user.lastName
                                                : user.firstName}
                                        </span>
                                    </div>
                                ) : null
                            ) : null}

                            {isInformationMessageActive ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span
                                        style={{
                                            fontSize: "50px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {
                                            informationMessageItems[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    <MainContentWrapper />

                    {isActionButtonsActive ? (
                        <ActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                        />
                    ) : null}

                    {isLanguagesActive ? <UsualModeLanguagesWrapper /> : null}

                    {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
                </ContainerWrapper>
            </PreviewBackgroundWrapper>
        </CSSTransition>
    );
}
