import PropTypes from "prop-types";

import React from "react";

import { getPrice } from "../../../Helpers/getPrice";
import "./index.css";

export function ProductSizeSwiperTwo({
    productProperties,
    selectedSizeIndex,
    setSelectedSizeIndex,
    visiblePrice,
    listOptions,
    productPrice,
}) {
    let optionPrice = 0;
    if (visiblePrice) {
        const { price } = getPrice(listOptions, productPrice);
        optionPrice = price;
    }
    return (
        <div className="product-size-swiper-container-item-equal-two">
            {productProperties.map((item, index) => {
                return (
                    <div
                        key={index}
                        className="product-size-swiper-slide-img-item-equal-two product-size-swiper-slide-item-equal-two"
                        onClick={() => {
                            setSelectedSizeIndex(index);
                        }}
                        style={{
                            opacity: index !== selectedSizeIndex ? "0.5" : "1",
                            transform:
                                index !== selectedSizeIndex
                                    ? "scale(0.7)"
                                    : "scale(1)",
                        }}
                    >
                        <div
                            className="swiper-slide-product-name"
                            style={{
                                marginTop:
                                    index !== selectedSizeIndex
                                        ? "56px"
                                        : "unset",
                            }}
                        >
                            {item.name}
                        </div>
                        {index === selectedSizeIndex && visiblePrice ? (
                            <div className="swiper-slide-product-price">
                                <span className="price-text">
                                    + {optionPrice.toFixed(2)} €
                                </span>
                            </div>
                        ) : null}
                        <div
                            className="swiper-slide-product-img"
                            style={{
                                border:
                                    index === selectedSizeIndex
                                        ? "3px solid var(--global-color)"
                                        : "unset",
                            }}
                        >
                            <img src={item.image} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

ProductSizeSwiperTwo.propTypes = {
    productProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSizeIndex: PropTypes.number,
    setSelectedSizeIndex: PropTypes.func,
    visiblePrice: PropTypes.bool,
    listOptions: PropTypes.array,
    productPrice: PropTypes.object,
};
