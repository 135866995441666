import { WITHOUT_BUTTON } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { CloseIcon } from "@components/OrderTaking/Components/Icons/CloseIcon";

import { kioskStore } from "@pages/Kiosk/store";

export function WithoutImage({ product }) {
    const { basicCompositionActive, id, title } = product;
    const {
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const { designItems } = useSelector(
        (state) => state.settingSlice,
        shallowEqual
    );
    const withoutButton = designItems.filter((item) => {
        return item.active === true && item.role === WITHOUT_BUTTON;
    });
    const withoutButtonContent = getImageContentById(
        withoutButton[0].id,
        images,
        customerLanguage.name
    );
    return (
        !basicCompositionActive &&
        (withoutButtonContent !== "" ? (
            <img
                style={{
                    position: " absolute",
                    backgroundSize: "unset",
                    backgroundColor: "unset",
                }}
                loading="lazy"
                id={`key-img${id}`}
                className="product-image "
                src={
                    withoutButtonContent?.includes("http")
                        ? withoutButtonContent
                        : `./images_order/${withoutButtonContent}`
                }
                alt={title}
            />
        ) : (
            <div
                className="product-image "
                style={{
                    position: " absolute",
                    backgroundSize: "unset",
                    backgroundColor: "unset",
                }}
            >
                <CloseIcon />
            </div>
        ))
    );
}
WithoutImage.propTypes = {
    product: PropTypes.object,
};
