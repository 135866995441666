import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

import { HeaderConfigOptionsWrapper } from "../Components/OrderTakingConfigOptionsWrapper/HeaderOptions";

export function HelloUser({
    userAccountMessage,
    handleAllergenDialogOnCLickEvent,
}) {
    const { user, isUserAuthenticated } = useSnapshot(kioskStore);

    const { isOrderTakingUserAccountActive } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const optionsButton = (
        <HeaderConfigOptionsWrapper
            handleAllergenDialogOnCLickEvent={handleAllergenDialogOnCLickEvent}
        />
    );
    const isVisible = isOrderTakingUserAccountActive || optionsButton !== null;
    return (
        isVisible && (
            <div className="d-flex justify-content-between align-items-center authenticated-user px-3">
                {isUserAuthenticated && isOrderTakingUserAccountActive ? (
                    <div className="authenticated-user-font">
                        {userAccountMessage} {"   "}
                        <span className="font-weight-bold">
                            {user.firstName === ""
                                ? user.lastName
                                : user.firstName}
                        </span>
                    </div>
                ) : (
                    <div></div>
                )}
                {optionsButton}
            </div>
        )
    );
}

export const MemoisedHelloUser = React.memo(HelloUser);
HelloUser.propTypes = {
    handleAllergenDialogOnCLickEvent: PropTypes.func,
    userAccountMessage: PropTypes.string,
};
