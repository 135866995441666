import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function SubStepsLogoWrapper(): JSX.Element {
    const {
        logo,
        customerLanguage,
        isSubStepTopBannerActive,
        isSubStepLogoActive,
        project: { files: images },
        isPrm,
    } = useSnapshot(kioskStore);

    const imageContent = getImageContentById(
        logo.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <React.Fragment>
            {isSubStepTopBannerActive ? (
                <div className="d-flex justify-content-center align-items-center">
                    {isSubStepLogoActive ? (
                        <img
                            src={imageSrc}
                            alt="logo"
                            className={classNames({
                                "usual-mode-logo__clz": !isPrm,
                                "prm-mode-logo__clz": isPrm,
                            })}
                        />
                    ) : null}
                </div>
            ) : null}
        </React.Fragment>
    );
}
