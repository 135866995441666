import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { updateNestedCategories } from "../Store/feature/OrderSlice";

function CategoriesBreadCrumb() {
    const dispatch = useDispatch();

    const nestedCategories = useSelector(
        (state) => state.orderSlice.nestedCategories,
        shallowEqual
    );
    const globalcard = useSelector(
        (state) => state.orderSlice.globalcard,
        shallowEqual
    );
    return (
        <Swiper spaceBetween={10} slidesPerView={4} slidesPerGroup={10}>
            {nestedCategories.map((category, index) => {
                return (
                    <SwiperSlide
                        key={index}
                        className="d-flex align-items-center categories-swiper-width__clz my-2  "
                    >
                        <div
                            className={classNames(
                                " d-flex justify-content-center d-flex align-items-center authenticated-user-font mr-1 ",
                                {
                                    "font-weight-bold__clz":
                                        index === nestedCategories.length,
                                }
                            )}
                            style={{
                                backgroundColor:
                                    index < nestedCategories.length - 1
                                        ? "#F4F4F4"
                                        : "white",
                                // color: "white",,
                                opacity:
                                    index < nestedCategories.length - 1
                                        ? "35%"
                                        : "1",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                index < nestedCategories.length - 1 &&
                                dispatch(updateNestedCategories(category))
                            }
                        >
                            <span
                                className={classNames(
                                    "d-flex justify-content-center d-flex align-items-center mx-2 px-2",
                                    {
                                        "font-weight-bold":
                                            index >=
                                            nestedCategories.length - 1,
                                    }
                                )}
                            >
                                {globalcard.categories[category].title}
                            </span>
                        </div>

                        {index < nestedCategories.length - 1 && <div> / </div>}
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

export default CategoriesBreadCrumb;
