import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    kioskStore,
    setActionsButtonsItems,
    setChosenTransition,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsTopBannerActive,
    setIsUserAuthenticated,
    setMainContentItems,
    setSelectedAuthMode,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function AuthenticationModesStep(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
    } = useSnapshot(kioskStore);
    const { transitions } = useSnapshot(store);

    React.useEffect(() => {
        setSelectedAuthMode("");
        setIsUserAuthenticated(false);
        setMainContentItems(
            (content.authenticationModes as ProjectContentItemType).items
        );

        setIsLogoActive(
            (ways.authenticationModes as PageType).topBanner.logo.active
        );

        setIsTopBannerActive(
            (ways.authenticationModes as PageType).topBanner.active
        );
        setIsHeaderActive((ways.authenticationModes as PageType).header.active);

        setIsInformationMessageActive(
            (ways.authenticationModes as PageType).header.informationMessage
                .active
        );

        setInformationMessageItems(
            (ways.authenticationModes as PageType).header.informationMessage
                .languages
        );

        setIsActionButtonsActive(
            (ways.authenticationModes as PageType).actionsButtons.active
        );

        setActionsButtonsItems(
            (
                (ways.authenticationModes as PageType)
                    .actionsButtons as ActionsButtonsDesignType
            ).items
        );

        setChosenTransition(
            transitions[(ways.authenticationModes as PageType).animation]
        );
    }, []);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
