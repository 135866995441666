import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { SpinnerWrapper } from "@components/common/SpinnerWrapper";
import { EntitySelection } from "@components/Synchronization/EntitySelectionModalWrapper/EntitySelection";
import { Header } from "@components/Synchronization/Header";
import { KeyboardWrapper } from "@components/Synchronization/KeyboardWrapper";
import {
    setIsEntitySelectionModalOpened,
    setIsSyncAuthenticationModalOpened,
    syncStore,
} from "@components/Synchronization/store";

import "./index.css";

export function EntitySelectionModalWrapper(): JSX.Element {
    const { isEntitySelectionModalOpened } = useSnapshot(syncStore);

    React.useEffect(() => {
        localStorage.removeItem("card");
        localStorage.removeItem("selectedShopApp");
        localStorage.removeItem("selectedEntityChild");
    }, []);

    function handleGoBackButtonOnClickEvent() {
        setIsSyncAuthenticationModalOpened(true);
        setIsEntitySelectionModalOpened(false);
    }

    return (
        <Modal
            isOpen={isEntitySelectionModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1400px",
                    width: "914px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <Header />
            <FlexboxGrid className="h-100 flex-column justify-content-between">
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <EntitySelection />
                </React.Suspense>
                <FlexboxGrid
                    className="flex-column"
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                    gap="10px"
                >
                    <KeyboardWrapper />
                    <FlexboxGrid
                        alignItemsCentered={true}
                        justifyContentCentered={true}
                        className="w-100"
                        styles={{
                            background: "#E9E9E9 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #FFFFFF",
                        }}
                    >
                        <StyledButton
                            rounded={true}
                            className="text-uppercase m-2"
                            style={{
                                width: "197px",
                                height: "61px",
                                font: "normal normal 600 18px/24px Segoe UI",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #C9C9C9",
                                borderRadius: "12px",
                                color: "black",
                            }}
                            onClick={handleGoBackButtonOnClickEvent}
                        >
                            {t("Back")}
                        </StyledButton>
                    </FlexboxGrid>
                </FlexboxGrid>
            </FlexboxGrid>
        </Modal>
    );
}
