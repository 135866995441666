import { Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";

import {
    getAlertActionButtonImageSrc,
    getNoActionButtonImageSrc,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import { kioskStore as store } from "@pages/Kiosk/store";

export default function WorkflowAlert({
    open,
    onClose,
    onCloseWorkflowOnClickEvent,
    message,
}) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const {
        isPrm,
        project: {
            files: images,
            template: {
                content: { actionsButtons },
            },
        },
        customerLanguage,
    } = useSnapshot(store);
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={onClose}
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "50px!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "1230px!important",
                    width: "860px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    borderTop: `15px solid ${globalColor}!important`,
                    top: "unset!important",
                    right: "unset!important",
                    left: "unset!important",
                    bottom: isPrm ? "-14%" : "unset",
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "400px" }}
            >
                <img
                    src={getAlertActionButtonImageSrc(
                        actionsButtons,
                        images,
                        customerLanguage.name
                    )}
                    style={{ width: "35%", height: "70%" }}
                />
            </div>
            <div
                className="text-center"
                style={{ height: "202px", fontSize: "45px", gap: "10px" }}
            >
                <span>{t(message)}</span>
            </div>
            <div
                style={{ height: "450px", gap: "10%" }}
                className="d-flex justify-content-center"
            >
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                        src={getNoActionButtonImageSrc(
                            actionsButtons,
                            images,
                            customerLanguage.name
                        )}
                        style={{ height: "270px", width: "270px" }}
                        onClick={() => {
                            onClose();
                        }}
                    />
                    <span
                        style={{
                            font: "normal normal 600 33px/49px Segoe UI",
                        }}
                    >
                        Non
                    </span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                        src={getYesActionButtonImageSrc(
                            actionsButtons,
                            images,
                            customerLanguage.name
                        )}
                        style={{ height: "270px", width: "270px" }}
                        onClick={() => {
                            onCloseWorkflowOnClickEvent();
                        }}
                    />
                    <span
                        style={{
                            font: "normal normal 600 33px/49px Segoe UI",
                        }}
                    >
                        Oui
                    </span>
                </div>
            </div>
        </Dialog>
    );
}

WorkflowAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCloseWorkflowOnClickEvent: PropTypes.func,
    message: PropTypes.string,
};
