import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { store } from "@store";

import { getImageContentById, getSkippedStepNumber } from "@helpers/general";

import {
    kioskStore,
    setCreditCardPaymentChoice,
    setIsRegulationModesModalOpened,
    setIsTpaValinaModalOpened,
    setNavigationIndex,
} from "@pages/Kiosk/store";

import "./index.css";

type ImageContentPropsType = {
    item: TPAValinaItemType;
    activeItemsLength: number;
};

export function ImageContent({ item }: ImageContentPropsType): JSX.Element {
    const { middlewareApiUrl } = useSnapshot(store);
    const {
        customerLanguage,
        isPrm,
        project: {
            files: images,
            template: { content },
        },
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
    } = useSnapshot(kioskStore);

    const imageContent = getImageContentById(
        item.id,
        images,
        customerLanguage.name
    );

    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    async function handlePaymentCommand() {
        const url = `${middlewareApiUrl}/ReglementCommande?unitaire=${totalPrice}`;

        await fetchEventSource(url, {
            onmessage(event) {
                switch (event.event) {
                    case "result": {
                        const code = JSON.parse(event.data).code;
                        switch (code) {
                            case 1002: {
                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content
                                            )
                                        )
                                );
                                break;
                            }
                            case 0: {
                                setIsRegulationModesModalOpened(true);
                                break;
                            }
                        }
                        break;
                    }
                    case "usermessage": {
                        console.log(JSON.parse(event.data).message);
                    }
                }
            },
            onclose() {
                console.log("connection close");
            },
            onerror(error) {
                console.log(
                    `Error during the payment with the TPA VALINA, with the message: ${error.message}`
                );
                throw error;
            },
        });
    }

    function handleOnClickEvent() {
        setCreditCardPaymentChoice(item.shortName);
        setIsTpaValinaModalOpened(false);
        handlePaymentCommand();
    }

    return (
        <div
            className="justify-content-center align-items-center"
            style={{ display: "grid", gridTemplateRows: "6fr 1fr" }}
            onClick={handleOnClickEvent}
        >
            <div className="d-flex justify-content-center align-items-end h-100">
                <img
                    src={imageSrc}
                    alt={item.name}
                    style={{
                        height: isPrm ? "75%" : "",
                    }}
                />
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <span style={{ fontSize: isPrm ? "20px" : "35px" }}>
                    {(item as TPAValinaItemType).isNameVisible
                        ? (item as TPAValinaItemType).name
                        : null}
                </span>
            </div>
        </div>
    );
}
