import { NO_BUTTON_ROLE, YES_BUTTON_ROLE } from "@constants";
import { Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { addToOrder } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function AddProductConfirmation({ open, onClose, onCancel, product }) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    const dispatchStore = useDispatch();
    const {
        isPrm,
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const filteredButtons = actionsButtons.filter((item) => {
        return item.role === YES_BUTTON_ROLE || item.role === NO_BUTTON_ROLE;
    });

    function handleButtonOnCLickEvent(button) {
        button.name.toLowerCase() === "yes"
            ? (dispatchStore(addToOrder(product)), onClose())
            : (onCancel(), onClose());
    }
    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "50px!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "1335px!important",
                    width: "860px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderTop: `15px solid ${globalColor}!important`,
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: isPrm ? "15%" : "unset",
                    right: "unset!important",
                    left: "unset!important",
                    background: `transparent linear-gradient(180deg, #FFFFFF 0%, ${globalColor} 300%) 0% 0% no-repeat padding-box`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: "70%", gap: "20px" }}
            >
                <span
                    style={{
                        font: "normal normal 400 30px/40px Segoe UI",
                    }}
                >
                    Souhaitez-vous garder
                </span>
                <span
                    style={{
                        height: "100px",
                        font: "normal normal 600 55px/60px Nunito Sans",
                    }}
                >
                    La même composition ?
                </span>
            </div>
            <div className="centered-items" style={{ height: "100%" }}>
                <div
                    className="image-product__clz"
                    style={{
                        height: "500px",
                        width: "500px",
                        backgroundImage: `url(${product.urlImage})`,
                    }}
                ></div>
            </div>
            <div
                style={{ height: "40%", gap: "30px", width: "100%" }}
                className="centered-items"
            >
                {filteredButtons
                    .sort((a, b) => {
                        return a.role > b.role ? -1 : 1;
                    })
                    .map((button) => {
                        const imageContent = getImageContentById(
                            button.id,
                            images,
                            customerLanguage.name
                        );

                        const imageSrc =
                            imageContent?.includes("http") ||
                            imageContent?.includes("https")
                                ? imageContent
                                : `./images_order/${imageContent}`;

                        return (
                            <div
                                key={button.id}
                                className="mx-2"
                                style={{
                                    backgroundSize: "100% 100%",
                                    backgroundImage: `url(${imageSrc})`,
                                    width: "35%",
                                    height: "35%",
                                }}
                                onClick={() => {
                                    handleButtonOnCLickEvent(button);
                                }}
                            />
                        );
                    })}
            </div>
        </Dialog>
    );
}

AddProductConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    product: PropTypes.object,
};
