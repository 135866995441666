import { EMPTY_BASKET_ICON_ROLE, FULL__BASKET_ICON_ROLE } from "@constants";
import { motion } from "framer-motion/dist/framer-motion";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import BasketActionsButtons from "@components/OrderTaking/common/BasketActionButtons";
import DialogCart from "@components/OrderTaking/Components/Dialogs/DialogCart";
import { CartIconEmpty } from "@components/OrderTaking/Components/Icons/CartIconEmpty";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import { MemoizedSimpleProductCartPreview } from "@components/OrderTaking/Components/Products/Cart/SimpleProductCartPreview";
import { memoizedOrderItems } from "@components/OrderTaking/Store/feature";

import { HelpModalWrapper } from "@pages/Kiosk/HelpModalWrapper";
import { kioskStore } from "@pages/Kiosk/store";

import { OrderTakingConfigOptionsWrapper } from "../OrderTakingConfigOptionsWrapper";
import "./index.css";

import "swiper/swiper.min.css";

const SwiperCart = ({ orderItems }) => {
    const localOrderItems = [...orderItems].reverse();

    return (
        <Swiper
            slidesPerView={3}
            loop={false}
            spaceBetween={16}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
            className="swiper-product-cart__clz"
        >
            {localOrderItems.length === 0
                ? t("No product available in cart")
                : localOrderItems.map((product) => (
                      <SwiperSlide
                          className="product-swiper p-1"
                          key={product.iuudOrder}
                      >
                          <MemoizedSimpleProductCartPreview product={product} />
                      </SwiperSlide>
                  ))}
        </Swiper>
    );
};
const MemoizedSwiperCart = React.memo(SwiperCart);

export default function CartLayout() {
    const { itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );

    const orderItems = useSelector((state) => memoizedOrderItems(state));
    const {
        isHelpModalOpened,
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);

    const { generalShopCartMessage } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const [openDialogCart, setOpenDialogCart] = React.useState(false);

    const { designItems } = useSelector((state) => state.settingSlice);
    const emptyBasketIcon = designItems.filter((item) => {
        return item.role === EMPTY_BASKET_ICON_ROLE;
    });
    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });

    const emptyBasketIconContent =
        emptyBasketIcon.length > 0
            ? getImageContentById(
                  emptyBasketIcon[0].id,
                  images,
                  customerLanguage.name
              )
            : "";

    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(
                  filledBasketIcon[0].id,
                  images,
                  customerLanguage.name
              )
            : "";

    return (
        <motion.div
            key={filledBasketIcon}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -300, opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            {openDialogCart && (
                <DialogCart
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                />
            )}
            {isHelpModalOpened === true ? <HelpModalWrapper /> : null}
            <div className="header-footer">
                <div
                    id="basket-cart"
                    className="cart-icon justify-content-start px-2"
                >
                    {orderItems.length === 0 ? (
                        emptyBasketIconContent !== "" ? (
                            <img
                                className="w-50 h-100"
                                src={
                                    emptyBasketIconContent?.includes("http")
                                        ? emptyBasketIconContent
                                        : `./images_order/${emptyBasketIconContent}`
                                }
                                alt={emptyBasketIconContent}
                            />
                        ) : (
                            <CartIconEmpty />
                        )
                    ) : filledBasketIconContent !== "" ? (
                        <img
                            className="w-50 h-100"
                            src={
                                filledBasketIconContent?.includes("http")
                                    ? filledBasketIconContent
                                    : `./images_order/${filledBasketIconContent}`
                            }
                            alt={
                                orderItems.length === 0
                                    ? emptyBasketIconContent
                                    : filledBasketIconContent
                            }
                        />
                    ) : (
                        <CartIconFilled />
                    )}

                    <span className="number-articles w-50 ">
                        {itemsCount} Article(s)
                    </span>
                </div>
                <button
                    className="cart-open-button"
                    onClick={() => setOpenDialogCart(true)}
                    disabled={orderItems.length === 0}
                >
                    <span className="label-open-cart">{t("Enlarge")}</span>
                </button>
                <div className="cart-pmr-button">
                    <OrderTakingConfigOptionsWrapper />
                </div>
            </div>
            <div className="footer-contents__clz pt-2">
                {orderItems.length === 0 ? (
                    generalShopCartMessage
                ) : (
                    <MemoizedSwiperCart orderItems={orderItems} />
                )}
            </div>

            <div className="footer-button">
                <BasketActionsButtons />
            </div>
        </motion.div>
    );
}
export const MemoizedCartLayout = React.memo(CartLayout);
SwiperCart.propTypes = {
    orderItems: PropTypes.array,
};
