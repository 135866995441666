import PropTypes from "prop-types";

import React from "react";

import { AllergenIcon } from "@components/OrderTaking/Components/Icons/AllergenIcon";

export function ProductAllergen({ isAllergenIconActive, isPrm }) {
    return isAllergenIconActive === true ? (
        <div className="d-flex justify-content-center ">
            <AllergenIcon
                width={isPrm ? "30" : "39"}
                height={isPrm ? "30" : "30"}
            />
        </div>
    ) : null;
}

ProductAllergen.propTypes = {
    isPrm: PropTypes.bool,
    isAllergenIconActive: PropTypes.bool,
};
