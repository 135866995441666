import { Box } from "@material-ui/core";
import PropTypes from "prop-types";

import React from "react";

export function BasicCompositionTitle({
    isProductTitleActive,
    product,
    isPrm,
}) {
    const { name } = product;
    return isProductTitleActive
        ? name.isVisible && (
              <Box
                  className={`centredItems__clz px-1 h-50 ${
                      isPrm ? "prm-product-title" : "product-title__clz"
                  }`}
              >
                  <Box>{name.content}</Box>
              </Box>
          )
        : null;
}
BasicCompositionTitle.propTypes = {
    isProductTitleActive: PropTypes.bool,
    product: PropTypes.object,
    isPrm: PropTypes.bool,
};
