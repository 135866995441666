import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    kioskStore,
    setActionsButtonsItems,
    setChosenTransition,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsTopBannerActive,
    setMainContentItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function LoyaltyConsommationStep(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
    } = useSnapshot(kioskStore);
    const { transitions } = useSnapshot(store);

    React.useEffect(() => {
        setMainContentItems(
            (content.loyaltyConsommation as ProjectContentItemType).items
        );

        setIsLogoActive(
            (ways.loyaltyConsommation as PageType).topBanner.logo.active
        );

        setIsTopBannerActive(
            (ways.loyaltyConsommation as PageType).topBanner.active
        );
        setIsHeaderActive((ways.loyaltyConsommation as PageType).header.active);

        setIsInformationMessageActive(
            (ways.loyaltyConsommation as PageType).header.informationMessage
                .active
        );

        setInformationMessageItems(
            (ways.loyaltyConsommation as PageType).header.informationMessage
                .languages
        );

        setIsActionButtonsActive(
            (ways.loyaltyConsommation as PageType).actionsButtons.active
        );

        setActionsButtonsItems(
            (
                (ways.loyaltyConsommation as PageType)
                    .actionsButtons as ActionsButtonsDesignType
            ).items
        );

        setChosenTransition(
            transitions[(ways.loyaltyConsommation as PageType).animation]
        );
    }, []);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
