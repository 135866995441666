import { BASIC_COMPOSITION, ITEM_TYPE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { InfoIcon } from "@components/OrderTaking/Components/Icons/InfoIcon";

import { kioskStore } from "@pages/Kiosk/store";

export function InformationProductIcon({
    product,
    isInformationProductIconActive,
    handleNutritionalInformationDialogOnCloseEvent,
    informationProductIconContent,
}) {
    const {
        project: { files: images },
    } = useSnapshot(kioskStore);
    const imageContent = getImageContentById(
        informationProductIconContent,
        images
    );
    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images_order/${imageContent}`;
    return isInformationProductIconActive &&
        (product.type === ITEM_TYPE || BASIC_COMPOSITION) ? (
        <div
            className="d-flex justify-content-center pt-1"
            onClick={(e) => {
                e.stopPropagation();
                !product.outOfStock &&
                    handleNutritionalInformationDialogOnCloseEvent();
            }}
        >
            {imageContent !== "" ? (
                <img
                    src={imageSrc}
                    style={{
                        width: "45%",
                    }}
                />
            ) : (
                <InfoIcon width={30} height={30} />
            )}
        </div>
    ) : null;
}

InformationProductIcon.propTypes = {
    product: PropTypes.object.isRequired,
    isInformationProductIconActive: PropTypes.bool,
    informationProductIconContent: PropTypes.string,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
};
