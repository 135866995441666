import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { SpinnerWrapper } from "@components/common/SpinnerWrapper";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { EntityChildrenSelection } from "@components/Synchronization/EntityChildrenSelectionModalWrapper/EntityChildrenSelection";
import { Header } from "@components/Synchronization/Header";
import { syncStore } from "@components/Synchronization/store";

import {
    setIsAuthenticationModalOpened,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setIsMenuModalOpened,
} from "@pages/Kiosk/store";

import "./index.css";

export function EntityChildrenSelectionModalWrapper(): JSX.Element {
    const { isEntityChildrenSelectionModalOpened } = useSnapshot(syncStore);

    function handleGoBackButtonOnClickEvent() {
        setIsAuthenticationModalOpened(false);
        setIsLocalSettingModalOpened(false);
        setIsLocalConfigModalOpened(false);
        setIsMenuModalOpened(false);
    }

    React.useEffect(() => {
        (window as RendererWindow).electronAPI.checkKioskModeEvent();
    });

    return (
        <Modal
            isOpen={isEntityChildrenSelectionModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1400px",
                    width: "914px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                },
            }}
        >
            <Header />
            <CustomErrorBoundary>
                <FlexboxGrid className="h-100 flex-column justify-content-between">
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <EntityChildrenSelection />
                    </React.Suspense>
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            background: "#E9E9E9 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #FFFFFF",
                        }}
                    >
                        <StyledButton
                            rounded={true}
                            className="d-none text-uppercase m-2"
                            style={{
                                width: "179px",
                                height: "75px",
                                font: "normal normal 600 18px/24px Segoe UI",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #C9C9C9",
                                borderRadius: "12px",
                                color: "black",
                            }}
                            onClick={handleGoBackButtonOnClickEvent}
                        >
                            {t("Back")}
                        </StyledButton>
                    </div>
                </FlexboxGrid>
            </CustomErrorBoundary>
        </Modal>
    );
}
