import {
    ALLERGENS_OPTION,
    ASK_FOR_HELP_BUTTON,
    EMPTY_BASKET_ICON_ROLE,
    FULL__BASKET_ICON_ROLE,
    PRM_BUTTON,
    PRM_PAY_BUTTON_ROLE,
    USUAL_MODE,
} from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import WorkflowAlert from "@components/OrderTaking/common/WorkflowAlert";
import AllergensDialog from "@components/OrderTaking/Components/Dialogs/Allergen/AllergensDialog";
import OrderSummary from "@components/OrderTaking/Components/Dialogs/OrderSummary";
import { CartIconEmpty } from "@components/OrderTaking/Components/Icons/CartIconEmpty";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import menuIcon from "@components/OrderTaking/Components/Icons/png_icons/menu-icon.png";
import {
    getPrmOrdertakingDesignOptions,
    useToggle,
} from "@components/OrderTaking/Helpers";

import {
    kioskStore,
    setAuthModalStepIndex,
    setIsHelpModalOpened,
    setIsPrm,
    setIsUserAccountModalOpened,
    setNavigationIndex,
} from "@pages/Kiosk/store";

import { ConfigOptionItem } from "./ConfigOptionItem";
import PrmDialogCart from "./Dialogs/PrmDialogCart";
import { IconsMenu } from "./IconsMenu";
import "./index.css";

import "swiper/swiper.min.css";

export default function PrmBasket() {
    const { orderItems, itemsCount, totalPrice } = useSelector(
        (state) => state.orderSlice.order
    );
    const { secondaryColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const {
        isPrm,
        activeConfigOptions,
        navigationIndex,
        isUserAuthenticated,
        project: { files: images },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const [isAlertOpened, setIsAlertOpened] = React.useState(false);

    const { prmActionsButtons } = useSelector(
        (state) => state.settingSlice.prmSettings
    );
    const { orderTakingOptions } = useSelector((state) => state.settingSlice);

    const { designItems } = useSelector((state) => state.settingSlice);
    const emptyBasketIcon = designItems.filter((item) => {
        return item.role === EMPTY_BASKET_ICON_ROLE;
    });
    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });

    const allergenOption = orderTakingOptions.find(
        (option) => option.title === ALLERGENS_OPTION
    );
    const newOptionConfig = getPrmOrdertakingDesignOptions(
        orderTakingOptions,
        activeConfigOptions
    );

    if (allergenOption?.active === true) {
        newOptionConfig.push(allergenOption);
    }

    const emptyBasketIconContent =
        emptyBasketIcon.length > 0
            ? getImageContentById(
                  emptyBasketIcon[0].id,
                  images,
                  customerLanguage.name
              )
            : "";

    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(
                  filledBasketIcon[0].id,
                  images,
                  customerLanguage.name
              )
            : "";

    const message = "Would you like to abandon your order ?";

    const [isMenuOpened, setIsMenuOpened] = React.useState(false);
    const [openDialogCart, setOpenDialogCart] = React.useState(false);

    const [isAllergenDialogOpened, setIsAllergenDialogOpened] =
        React.useState(false);

    const [isSummaryOrderOpened, setIsSummaryOrderOpened] = useToggle();

    function handleOrderSummaryOnCLickEvent() {
        setIsSummaryOrderOpened(!isSummaryOrderOpened);
    }

    const handleAllergenDialogOnCLickEvent = () => {
        setIsAllergenDialogOpened(!isAllergenDialogOpened);
    };

    function handleConfigOptionOnClickEvent(option) {
        switch (option.title.toLowerCase()) {
            case USUAL_MODE:
                setIsPrm(!isPrm);
                break;
            case ASK_FOR_HELP_BUTTON:
                setIsHelpModalOpened(true);
                break;
            case "customer account":
                isUserAuthenticated
                    ? (setIsUserAccountModalOpened(true),
                      setAuthModalStepIndex(navigationIndex))
                    : null;
                break;
            case "allergen":
                setIsAllergenDialogOpened(true);
                break;

            default:
                null;
        }
    }

    function handleBasketActionsButtonsOnClickEvent(button) {
        if (button.role === PRM_PAY_BUTTON_ROLE) {
            handleOrderSummaryOnCLickEvent();
        } else {
            handleAlertOnCLickEvent();
        }
    }
    function handleAlertOnCLickEvent() {
        orderItems.length > 0
            ? setIsAlertOpened(!isAlertOpened)
            : setNavigationIndex(0);
    }
    function onCloseWorkflowOnClickEvent() {
        setNavigationIndex(0);
        setIsAlertOpened(!isAlertOpened);
    }

    var option;
    const UsualModeOption = activeConfigOptions.filter(
        (config) => config.title.toLowerCase() === USUAL_MODE
    )[0];
    const UsualimageContent = getImageContentById(
        UsualModeOption.id,
        images,
        customerLanguage.name
    );

    const imageSrc =
        UsualimageContent?.includes("http") ||
        UsualimageContent?.includes("https")
            ? UsualimageContent
            : `./images/${UsualimageContent}`;
    return (
        <>
            {isAllergenDialogOpened && (
                <AllergensDialog
                    open={isAllergenDialogOpened}
                    onClose={handleAllergenDialogOnCLickEvent}
                />
            )}
            {isSummaryOrderOpened && (
                <OrderSummary
                    open={isSummaryOrderOpened}
                    onClose={handleOrderSummaryOnCLickEvent}
                    setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                />
            )}
            {openDialogCart && (
                <PrmDialogCart
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                />
            )}
            {isAlertOpened && (
                <WorkflowAlert
                    open={isAlertOpened}
                    onClose={handleAlertOnCLickEvent}
                    onCloseWorkflowOnClickEvent={onCloseWorkflowOnClickEvent}
                    message={message}
                />
            )}

            {prmActionsButtons
                .filter((item) => {
                    return item.active === true;
                })
                .sort((a, b) => {
                    return a.role > b.role ? -1 : 1;
                })
                .map((button, index) => {
                    const imageContent = getImageContentById(
                        button.id,
                        images,
                        customerLanguage.name
                    );

                    const imageSrc =
                        imageContent?.includes("http") ||
                        imageContent?.includes("https")
                            ? imageContent
                            : `./images_order/${imageContent}`;

                    return button.name.toLowerCase() === "pay pmr" ? (
                        <div
                            className="total-order-footer align-items-center"
                            key={index}
                            style={{ position: "relative" }}
                            onClick={() => {
                                orderItems.length !== 0 &&
                                    handleBasketActionsButtonsOnClickEvent(
                                        button
                                    );
                            }}
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${imageSrc})`,
                                    backgroundSize: "100% 100%",
                                    width: "100%",
                                    height: "100%",
                                    opacity:
                                        button.name.toLowerCase() ===
                                            "pay pmr" &&
                                        orderItems.length === 0 &&
                                        "0.5",
                                }}
                            />

                            <div
                                className="total-order pr-2"
                                style={{
                                    width: "100%",
                                    color: { secondaryColor },
                                    height: "100%",
                                }}
                            >
                                {totalPrice.toFixed(2)} €
                            </div>
                        </div>
                    ) : (
                        <div
                            key={index}
                            style={{
                                backgroundImage: `url(${imageSrc})`,
                                backgroundSize: "100% 100%",
                                width: "30%",
                                height: "65%",
                            }}
                            onClick={() => {
                                handleBasketActionsButtonsOnClickEvent(button);
                            }}
                        />
                    );
                })}

            <div
                id="prm-basket-cart"
                className="prm-cart-icon"
                onClick={() => setOpenDialogCart(true)}
                disabled={orderItems.length === 0}
            >
                {orderItems.length === 0 ? (
                    emptyBasketIconContent !== "" ? (
                        <img
                            className="w-50 h-100"
                            src={
                                emptyBasketIconContent?.includes("http")
                                    ? emptyBasketIconContent
                                    : `./images_order/${emptyBasketIconContent}`
                            }
                            alt={emptyBasketIconContent}
                        />
                    ) : (
                        <CartIconEmpty />
                    )
                ) : filledBasketIconContent !== "" ? (
                    <img
                        className="w-50 h-100"
                        src={
                            filledBasketIconContent?.includes("http")
                                ? filledBasketIconContent
                                : `./images_order/${filledBasketIconContent}`
                        }
                        alt={
                            orderItems.length === 0
                                ? emptyBasketIconContent
                                : filledBasketIconContent
                        }
                    />
                ) : (
                    <CartIconFilled />
                )}

                <span className="prm-number-articles">{itemsCount}</span>
            </div>

            <div
                className="pmr-icon"
                onClick={() => {
                    setIsPrm(!isPrm);
                }}
            >
                <img
                    src={imageSrc}
                    style={{
                        height: "65px",
                        width: "65px",
                        boxShadow: "0px 3px 6px #00000080",
                        borderRadius: "15px",
                    }}
                />
            </div>
            {isMenuOpened ? (
                <IconsMenu
                    isOpened={isMenuOpened}
                    setIsMenuOpened={setIsMenuOpened}
                    newOptionConfig={newOptionConfig}
                />
            ) : null}

            {newOptionConfig.length > 2 ? (
                isMenuOpened === false ? (
                    <div className="pmr-menu-icon">
                        <img
                            src={menuIcon}
                            onClick={() => {
                                setIsMenuOpened(!isMenuOpened);
                            }}
                        />
                    </div>
                ) : null
            ) : newOptionConfig.filter(
                  (option) =>
                      option.title.toLowerCase() !== PRM_BUTTON &&
                      option.title.toLowerCase() !== USUAL_MODE
              )[0] ? (
                ((option = newOptionConfig.filter(
                    (option) =>
                        option.title.toLowerCase() !== PRM_BUTTON &&
                        option.title.toLowerCase() !== USUAL_MODE
                )[0]),
                (
                    <div className="pb-2">
                        <ConfigOptionItem
                            option={option}
                            handleConfigOptionOnClickEvent={
                                handleConfigOptionOnClickEvent
                            }
                        />
                    </div>
                ))
            ) : null}
        </>
    );
}
