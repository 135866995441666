import { CheckIcon } from "@aureskonnect/react-ui";

import React from "react";
import { Card } from "reactstrap";

import { getPeripheralDeviceImageSrc } from "@helpers/general";

type PropsType = {
    device: string;
};

export function PeripheralDevicesCardWrapper({
    device,
}: PropsType): JSX.Element {
    return (
        <Card
            style={{
                width: "250px",
                height: "220px",
                borderRadius: "20px",
            }}
        >
            {["Printer", "TPE"].includes(device) ? (
                <CheckIcon className="mt-1 ml-1" height={45} width={45} />
            ) : (
                <div className="mt-1 ml-1" style={{ height: 45, width: 45 }} />
            )}
            <div className="d-flex justify-content-center align-items-end">
                <img
                    src={`./images/${getPeripheralDeviceImageSrc(device)}.png`}
                    width="110"
                    height="110"
                />
            </div>
            <div className="d-flex justify-content-center align-items-end">
                <img src="./images/settings-icon.svg" />
                <img src="./images/test-icon.svg" />
            </div>
        </Card>
    );
}
