export function animatePicture(productId) {
    const speed = 500;
    const curveDelay = 700;
    let srcImg = document.querySelector(`#key-img${productId}`);
    let topImg = srcImg.getBoundingClientRect().top;
    let leftImg = srcImg.getBoundingClientRect().left;
    document.documentElement.style.setProperty("--top-position", topImg);
    document.documentElement.style.setProperty("--left-position", leftImg);

    let basketCart = document.querySelector("#basket-cart");
    document.documentElement.style.setProperty(
        "--top-basket-position",
        basketCart.getBoundingClientRect().top
    );
    document.documentElement.style.setProperty(
        "--left-basket-position",
        basketCart.getBoundingClientRect().left
    );

    let clonedImage = srcImg.cloneNode();

    clonedImage.classList.add("cloned-image");

    clonedImage.style.position = "fixed";

    clonedImage.style.top = `${topImg}px`;
    clonedImage.style.left = `${leftImg}px`;

    clonedImage.style.opacity = "1";
    clonedImage.style.transition = `all ${speed / 1000}s ease, top ${
        (speed + curveDelay) / 1000
    }s ease, left ${speed / 1000}s ease, transform ${speed / 1000}s ease ${
        (speed - 10) / 1000
    }s`;

    document.getElementsByClassName("fullscreen")[0].appendChild(clonedImage);

    clonedImage.classList.add("cloned-image-animation");

    clonedImage.style.height = "5rem";
    clonedImage.style.width = "5rem";
    clonedImage.style.transform = "scale(0)";
    return clonedImage;
}
export function animatePicturePrm(productId) {
    const speed = 500;
    const curveDelay = 700;

    let srcImg = document.querySelector(`#key-img${productId}`);
    let topImg = srcImg.getBoundingClientRect().top;
    let leftImg = srcImg.getBoundingClientRect().left;
    document.documentElement.style.setProperty("--prm-top-position", topImg);
    document.documentElement.style.setProperty("--prm-left-position", leftImg);

    let basketCart = document.querySelector("#prm-basket-cart");

    document.documentElement.style.setProperty(
        "--prm-top-basket-position",
        basketCart.getBoundingClientRect().top
    );
    document.documentElement.style.setProperty(
        "--prm-left-basket-position",
        basketCart.getBoundingClientRect().left
    );

    let clonedImage = srcImg.cloneNode();

    clonedImage.classList.add("prm-cloned-image");

    clonedImage.style.position = "fixed";

    clonedImage.style.top = `${topImg}px`;
    clonedImage.style.left = `${leftImg}px`;

    clonedImage.style.opacity = "1";
    clonedImage.style.transition = `all ${speed / 1000}s ease, top ${
        (speed + curveDelay) / 1000
    }s ease, left ${speed / 1000}s ease, transform ${speed / 1000}s ease ${
        (speed - 10) / 1000
    }s`;

    document.getElementsByClassName("fullscreen")[0].appendChild(clonedImage);

    clonedImage.classList.add("prm-cloned-image-animation");

    clonedImage.style.height = "5rem";
    clonedImage.style.width = "5rem";
    clonedImage.style.transform = "scale(0)";
    return clonedImage;
}
