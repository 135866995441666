import React from "react";
import PropTypes from "prop-types";

export function AllergenIcon({ width, height }) {
    const globalColor =
        document.documentElement.style.getPropertyValue("--global-color");
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 39 39"
            stroke="none"
            width={width !== undefined ? width : "39"}
            height={height !== undefined ? height : "30"}
        >
            <g data-name="Groupe 30412" transform="translate(.069 -.34)">
                <g data-name="Groupe 29443">
                    <g>
                        <circle
                            cx="19.5"
                            cy="19.5"
                            r="19.5"
                            fill="#fff"
                            data-name="Ellipse 1226"
                            transform="translate(-.069 .34)"
                            stroke="none"
                        ></circle>
                        <path
                            fill={globalColor}
                            d="M86.691 74.467c.667 2.172 2.24 3.613 3.527 3.233s1.764-2.469 1.1-4.641A19.447 19.447 0 0088.258 68s-2.619 3.036-1.567 6.467z"
                            data-name="Tracé 21255"
                            transform="translate(-60.481 -47.117)"
                            stroke="none"
                        ></path>
                        <path
                            fill={globalColor}
                            d="M59.545 82.1c2.058.847 3.322 2.519 2.822 3.724s-2.572 1.49-4.624.65a18.79 18.79 0 01-4.633-3.439s3.2-2.278 6.435-.935z"
                            data-name="Tracé 21256"
                            transform="translate(-36.947 -56.774)"
                            stroke="none"
                        ></path>
                        <path
                            fill={globalColor}
                            d="M69.6 54.188c.608 1.984 2.058 3.3 3.21 2.939s1.617-2.252 1.008-4.227a18 18 0 00-2.788-4.62s-2.384 2.784-1.43 5.908z"
                            data-name="Tracé 21257"
                            transform="translate(-48.433 -33.193)"
                            stroke="none"
                        ></path>
                        <path
                            fill={globalColor}
                            d="M48.57 64.157c1.714.711 2.763 2.1 2.352 3.11s-2.146 1.243-3.857.532a15.955 15.955 0 01-3.865-2.872s2.675-1.881 5.37-.77z"
                            data-name="Tracé 21258"
                            transform="translate(-29.95 -44.155)"
                            stroke="none"
                        ></path>
                        <path
                            fill={globalColor}
                            d="M55.057 43.111c.517 1.687 1.74 2.807 2.731 2.5s1.373-1.919.855-3.6a15.153 15.153 0 00-2.369-3.921s-2.031 2.366-1.217 5.021z"
                            data-name="Tracé 21259"
                            transform="translate(-38.186 -25.999)"
                            stroke="none"
                        ></path>
                        <path
                            fill={globalColor}
                            d="M33.486 47.566c1.593.661 2.572 1.958 2.184 2.892s-2 1.158-3.589.494a14.959 14.959 0 01-3.6-2.669s2.503-1.76 5.005-.717z"
                            data-name="Tracé 21260"
                            transform="translate(-19.557 -32.456)"
                            stroke="none"
                        ></path>
                        <path
                            fill={globalColor}
                            d="M38.725 28.589c.473 1.54 1.59 2.563 2.5 2.287s1.252-1.764.779-3.292A14.081 14.081 0 0039.836 24a5.349 5.349 0 00-1.111 4.589z"
                            data-name="Tracé 21261"
                            transform="translate(-26.666 -16.05)"
                            stroke="none"
                        ></path>
                        <path
                            fill={globalColor}
                            d="M25.909 31.935c.938.773 1.379 1.787.985 2.266s-1.47.244-2.413-.529a11.521 11.521 0 01-1.94-2.46 4.1 4.1 0 013.368.723z"
                            data-name="Tracé 21262"
                            transform="translate(-15.363 -21.072)"
                            stroke="none"
                        ></path>
                        <g
                            data-name="Groupe 29480"
                            transform="translate(.551 .895)"
                        >
                            <path
                                fill={globalColor}
                                d="M18.813 37.625a18.813 18.813 0 1118.812-18.812 18.813 18.813 0 01-18.812 18.812zm0-35.274a16.461 16.461 0 1016.461 16.462A16.461 16.461 0 0018.813 2.352z"
                                data-name="Tracé 21263"
                                stroke="none"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
AllergenIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};
