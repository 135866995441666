import {
    ABANDON_BUTTON_ROLE,
    CANCEL_BUTTON_ROLE,
    CONTINUE_ORDER_BUTTON_ROLE,
    NEXT_IN_STEP_BUTTON_ROLE,
    PAY_BUTTON_ROLE,
    PREVIOUS_IN_STEP_BUTTON_ROLE,
    PRM_CANCEL_BUTTON_ROLE,
    PRM_PAY_BUTTON_ROLE,
    VALIDATE_IN_STEP_BUTTON_ROLE,
} from "@constants";

import { getStepDetails } from "./getStepDetails";

export function convertSalesModeValue(saleMode) {
    switch (saleMode) {
        case "To take":
            return "TAKE_OUT";
        case "On the spot":
            return "EAT_IN";
        case "Delivery":
            return "TO_TAKE";
        default:
            return "TAKE_OUT";
    }
}

export function getTags(card) {
    return Object.keys(card.tags)?.reduce((tagsData, id) => {
        tagsData.push({ id, ...card.tags[id], active: false, isVisible: true });
        return tagsData;
    }, []);
}

export function setOrderTakingSetting(
    orderTakingSettingsData,
    customerLanguage,
    primaryColor,
    secondaryColor
) {
    return {
        generalSetting: {
            isOrderTakingTopBannerActive:
                orderTakingSettingsData.topBanner.active,
            isOrderTakingTagsActive:
                orderTakingSettingsData.orderTakingProduct.tags,
            isHeaderActive: orderTakingSettingsData.header.active,
            isOrderTakingUserAccountActive:
                orderTakingSettingsData.header.userAccount.active,
            userAccountMessage:
                orderTakingSettingsData.header.userAccount.languages[
                    customerLanguage
                ].content,

            globalColor: primaryColor, //"#f84c00",
            isBackgroundActive: orderTakingSettingsData.background.active,
            backgroundContent: orderTakingSettingsData.background.content,
            secondaryColor: secondaryColor,
            actionsButtons: orderTakingSettingsData.actionsButtons.items,
            generalShopCartMessage:
                orderTakingSettingsData.options.shopCardMessage.languages[
                    customerLanguage
                ].message,
        },
        productSetting: {
            isCalorieActive:
                orderTakingSettingsData.orderTakingProduct.calorieIcon.active,
            calorieId:
                orderTakingSettingsData.orderTakingProduct.calorieIcon.id,
            isProductDescriptionActive:
                orderTakingSettingsData.orderTakingProduct
                    .isProductDescriptionActive,
            isProductTitleActive:
                orderTakingSettingsData.orderTakingProduct.isProductTitleActive,
            isCategorySubCategoryTitleActive:
                orderTakingSettingsData.orderTakingProduct
                    .categorySubCategoryTitle,
            isInformationProductIconActive:
                orderTakingSettingsData.orderTakingProduct.productAccessIcon
                    .active,
            informationProductIconContent:
                orderTakingSettingsData.orderTakingProduct.productAccessIcon.id,
        },
        categorySetting: {
            isOrderTakingCategoryTitleActive:
                orderTakingSettingsData.orderTakingProduct.categoryTitle,
            isSelectedCategoryActive:
                orderTakingSettingsData.orderTakingProduct
                    .selectedCategoryTitle,
        },
        orderTakingOptions: orderTakingSettingsData.options.items,
        basketSettings: {
            emptyBasketMessage: orderTakingSettingsData.options.shopCardMessage,
            actionsButtons: orderTakingSettingsData.actionsButtons.items.filter(
                (button) => {
                    return (
                        button.role === PAY_BUTTON_ROLE ||
                        button.role === CANCEL_BUTTON_ROLE
                    );
                }
            ),
        },
        designItems: orderTakingSettingsData.design.items,
        summary: {
            isSummaryBackgroundActive:
                orderTakingSettingsData.summary.background.active,
            summaryBackgroundContent: orderTakingSettingsData.background.id,

            isSummaryLogoActive:
                orderTakingSettingsData.summary.summaryHeader.logo.active,
            IsAccumulatedLoyaltyPointsActive:
                orderTakingSettingsData.summary.summaryHeader
                    .accumulatedLoyaltyPoints.active,
            summaryHeaderMessage:
                orderTakingSettingsData.summary.summaryHeader.languages[
                    customerLanguage
                ].content,
            isSalesMethodsChangeActive:
                orderTakingSettingsData.summary.salesMethodsChangeActive,
            summaryActionsButtons:
                orderTakingSettingsData.actionsButtons.items.filter(
                    (button) => {
                        return (
                            button.role === PAY_BUTTON_ROLE ||
                            button.role === CONTINUE_ORDER_BUTTON_ROLE
                        );
                    }
                ),
            summaryHeaderBravoText:
                orderTakingSettingsData.summary.summaryHeader
                    .accumulatedLoyaltyPoints.items[0].languages[
                    customerLanguage
                ].content,
            summaryHeaderLoyaltyPointText:
                orderTakingSettingsData.summary.summaryHeader
                    .accumulatedLoyaltyPoints.items[1].languages[
                    customerLanguage
                ].content,
            isSummaryOptionsItemsActive:
                orderTakingSettingsData.summary.options.active,
            summaryOptionsItems: orderTakingSettingsData.summary.options.items,
        },
        compositeProductSettings: {
            isBasketActive: orderTakingSettingsData.compositeProduct.basket,
            isHeaderColorActive:
                orderTakingSettingsData.compositeProduct.headerColor,
            isClosingButtonActive:
                orderTakingSettingsData.compositeProduct.closingButton.active,
            closingButtonContent:
                orderTakingSettingsData.compositeProduct.closingButton.id,
            isNextOrPreviousButtonsActive:
                orderTakingSettingsData.compositeProduct.nextOrPreviousButtons,
            courseProgressColor:
                orderTakingSettingsData.compositeProduct.courseProgressColor
                    .content,
            isBreadcrumbTrailActive:
                orderTakingSettingsData.compositeProduct.breadcrumbTrail,
            isProductInfoIconActive:
                orderTakingSettingsData.compositeProduct.productAccessIcon
                    .active,
            productInfoIconId:
                orderTakingSettingsData.compositeProduct.productAccessIcon.id,
            isInformationMessageActive:
                orderTakingSettingsData.compositeProduct.informationMessage
                    .active,
            compositeProductActionsButtons:
                orderTakingSettingsData.actionsButtons.items.filter(
                    (button) => {
                        return (
                            button.role === NEXT_IN_STEP_BUTTON_ROLE ||
                            button.role === PREVIOUS_IN_STEP_BUTTON_ROLE ||
                            button.role === ABANDON_BUTTON_ROLE ||
                            button.role === VALIDATE_IN_STEP_BUTTON_ROLE
                        );
                    }
                ),
            shopCardMessage:
                orderTakingSettingsData.compositeProduct.shopCardMessage
                    .languages[customerLanguage].message,
        },
        prmSettings: {
            prmActionsButtons:
                orderTakingSettingsData.actionsButtons.items.filter(
                    (button) => {
                        return (
                            button.role === PRM_PAY_BUTTON_ROLE ||
                            button.role === PRM_CANCEL_BUTTON_ROLE
                        );
                    }
                ),
        },
    };
}
export const getNumberOfChoiceV0 = (path, items, shopCart) => {
    const newPath = path?.reduce(
        (prev, curr) => (curr?.name ? [...prev, curr.name] : [...prev, curr]),
        []
    );
    const objectToModify = newPath.reduce((prev, key) => {
        return prev[key] || {};
    }, shopCart);

    return Object.keys(objectToModify).reduce((prev, curr) => {
        const found = items.find(
            (elm) => elm.iuud === objectToModify[curr].iuud
        );
        return found ? prev + objectToModify[curr].quantity : prev + 0;
    }, 0);
};

export const refactorWorkflowData = (workflows, shopCart) => {
    return workflows.map((work) => {
        const editwork = work.workflow.reduce((prev, curr) => {
            const NbrOfChoices = getNumberOfChoiceV0(
                curr?.path,
                curr?.Items,
                shopCart
            );
            const stepPath = curr?.path.map((elm) => elm.name);
            let stepComposition = stepPath.reduce((prev, key) => {
                return prev[key] || {};
            }, shopCart);

            const {
                selectedChoicesWithspecialPrice,
                selectedChoicesWithStepPrice,
            } = getStepDetails(stepComposition);

            return [
                ...prev,
                {
                    ...curr,
                    NbrOfChoices,
                    selectedChoicesWithspecialPrice,
                    selectedChoicesWithStepPrice,
                },
            ];
        }, []);
        return { ...work, workflow: editwork };
    });
};
