import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useNavigate } from "react-router-dom";

import { setIsTechProblemModalOpened, store } from "@store";

import { useQueryString } from "@hooks/useQueryString";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { Synchronization } from "@components/Synchronization";
import { SyncModal } from "@components/SyncModal";
import { TechnicalProblemModal } from "@components/TechnicalProblemModal";

import "@assets/css/global.css";

export function Kiosk(): JSX.Element {
    const navigate = useNavigate();

    const { isLicenseProblem } = useSnapshot(store);

    // this code is put here for `preview catalog` option
    // in `catalog` app
    // @todo put this code somewhere else
    const { schema, uuidCatalog, franchise_uid, shop_uid } = useQueryString();
    if (schema !== undefined && uuidCatalog) {
        localStorage.setItem("viewADB-schema", schema);
        localStorage.setItem("viewADB-iuudCard", uuidCatalog);
    }
    if (franchise_uid !== undefined && shop_uid) {
        localStorage.setItem("viewADB-franchise_uid", franchise_uid);
        localStorage.setItem("viewADB-shop_uid", shop_uid);
    }

    // const isChangeMachineActive = (
    //     content.meansOfPayment as ProjectContentItemType
    // ).items
    //     .map((item: ProjectMainContentItemType) => {
    //         if (item.active === true) {
    //             return item.shortName;
    //         }
    //     })
    //     .includes("changeMachine");

    // React.useEffect(() => {
    //     setIsTechProblemModalOpened(!navigator.onLine || isLicenseProblem);

    //     if (isChangeMachineActive === true) {
    //         handleChangeMachineStatus();
    //     }
    // }, [isChangeMachineActive]);

    function handleStartAppButtonOnClickEvent(): void {
        navigate("/kiosk-steps");
    }

    return (
        <CustomErrorBoundary>
            <React.Fragment>
                <div className="h-100vh__clz d-flex justify-content-center align-items-center">
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            objectFit: "cover",
                            position: "fixed",
                            zIndex: -1,
                        }}
                    >
                        <source
                            src="./images/default-kiosk-background.mp4"
                            type="video/mp4"
                        />
                    </video>
                    <StyledButton
                        rounded={true}
                        variant="success"
                        onClick={handleStartAppButtonOnClickEvent}
                    >
                        {t("Start Kiosk App")}
                    </StyledButton>
                </div>
                <SyncModal />
                <Synchronization />
                <TechnicalProblemModal />
            </React.Fragment>
        </CustomErrorBoundary>
    );
}
